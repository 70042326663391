<h3 id="requestMetrics" jhiTranslate="metrics.jvm.http.title">HTTP requests (time in millisecond)</h3>

<table class="table table-striped" *ngIf="!updating && requestMetrics" aria-describedby="requestMetrics">
  <thead>
    <tr>
      <th scope="col" jhiTranslate="metrics.jvm.http.table.code">Code</th>
      <th scope="col" jhiTranslate="metrics.jvm.http.table.count">Count</th>
      <th scope="col" class="text-end" jhiTranslate="metrics.jvm.http.table.mean">Mean</th>
      <th scope="col" class="text-end" jhiTranslate="metrics.jvm.http.table.max">Max</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let entry of requestMetrics['percode'] | keyvalue">
      <td>{{ entry.key }}</td>
      <td>
        <ngb-progressbar [max]="requestMetrics['all'].count" [value]="entry.value.count" [striped]="true" [animated]="false" type="success">
          <span>{{ entry.value.count }}</span>
        </ngb-progressbar>
      </td>
      <td class="text-end">
        {{ filterNaN(entry.value.mean) | number: '1.0-2' }}
      </td>
      <td class="text-end">{{ entry.value.max | number: '1.0-2' }}</td>
    </tr>
  </tbody>
</table>
