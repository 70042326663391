import { Component, ChangeDetectionStrategy } from '@angular/core';
import { StyleProperty } from 'app/shared/enum/style-property.enum';
import { EditorPropertiesBase } from 'app/style-editor/editor/editor-properties-form-panel/editor-properties-base';

@Component({
  selector: 'jhi-editor-properties-opacity',
  templateUrl: 'editor-properties-opacity.component.html',
  styleUrls: ['./editor-properties-opacity.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditorPropertyOpacitiesComponent extends EditorPropertiesBase {
  public opacityValue: number;

  getOpacity(): number {
    return +(this.editorPropertiesService.getProperty(this.styleElement, StyleProperty.OPACITY) ?? 1.0);
  }

  updateOpacity(): void {
    this.updateStyleProperty({ property: StyleProperty.OPACITY, value: `${this.opacityValue}` });
  }

  clearOpacity(): void {
    this.opacityValue = 1.0;
    this.updateStyleProperty({ property: StyleProperty.OPACITY, value: null });
  }

  protected init(): void {
    if (this.styleElement) {
      this.opacityValue = this.getOpacity();
    }
  }
}
