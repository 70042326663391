import { Pipe, PipeTransform } from '@angular/core';
import { IConcept, IEntryPointTable } from '../model/concept.model';
import { DEFAULT_ROLE } from 'app/shared/constants/xbrl.constants';
import { getConceptLanguages, getConceptValue, getPreferredLabelRole } from 'app/shared/util/xbrl-utils';
import { TranslateService } from '@ngx-translate/core';
import { JhiLanguageService } from 'app/shared/jhipster/service/language.service';

@Pipe({
  name: 'conceptTranslatedLabel',
  pure: true,
})
export class ConceptTranslatedLabelPipe implements PipeTransform {
  constructor(private translate: TranslateService, private languageService: JhiLanguageService) {}

  transform(concept: IConcept | IEntryPointTable | undefined, part = DEFAULT_ROLE, lang = ''): string {
    if (!concept) {
      return '';
    }
    const translations = getConceptLanguages(concept);
    if (lang === '') {
      lang = this.languageService.getCurrentLanguage();
    }
    switch (part) {
      case DEFAULT_ROLE:
        return getConceptValue(concept, translations, lang);
      case 'lang':
        return translations.includes(lang) ? lang : translations[0];
      case 'type': {
        const roleLabel = getPreferredLabelRole(concept);
        const translatedLabel = this.translate.instant(`dataEditor.taxonomy.${roleLabel}`);
        return translatedLabel.includes('translation-not-found') ? roleLabel : translatedLabel;
      }
      default:
        return getConceptValue(concept, translations, this.languageService.getCurrentLanguage());
    }
  }
}
