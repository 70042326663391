import { Injectable } from '@angular/core';
import { JhiEventManager } from 'app/shared/jhipster/service/event-manager.service';
import { HttpInterceptor, HttpRequest, HttpErrorResponse, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable()
export class ErrorHandlerInterceptor implements HttpInterceptor {
  constructor(private eventManager: JhiEventManager) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap(
        () => {},
        (err: HttpErrorResponse) => {
          if (err.status === 401) {
            this.eventManager.broadcast({ name: 'pgdpApp.httpError', content: err });
          }
        }
      )
    );
  }
}
