import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { AccountService } from 'app/core/auth/account.service';
import { FileService } from 'app/core/service/file.service';
import { PLUGIN_PANEL_COMPONENT_KEYS } from 'app/html-editor/plugins/plugin-panel-component-keys';

import pubsub from 'app/pubsub';
import { EDITOR_PLUGIN_TOGGLEPANEL } from 'app/pubsub.topics';
import { Authority } from 'app/shared/enum/authority.enum';
import { ImageFolder } from 'app/shared/enum/image-folder.enum';
import { IAsset } from 'app/shared/model/asset.model';
import { IMarginModel } from 'app/shared/model/margin.model';
import { forkJoin, Subscription } from 'rxjs';
import { PluginPanelService } from '../plugin-panel.service';
import { PluginComponent } from '../plugin.component';
import { FloatStyle, ImagePlaceholderService, PlaceHolderValue } from './image-placeholder.service';

export interface ImageMarginModel {
  top: number;
  right: number | 'auto';
  bottom: number;
  left: number | 'auto';
}

enum HORIZONTAL_ALIGNS {
  LEFT = 'left',
  CENTER = 'center',
  RIGHT = 'right',
}

@Component({
  selector: 'jhi-plugin-image-placeholder',
  templateUrl: './image-placeholder.component.html',
  styleUrls: ['./image-placeholder.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImagePlaceholderPluginComponent extends PluginComponent implements OnInit, OnDestroy {
  public readonly FloatStyle = FloatStyle;
  public readonly HORIZONTAL_ALIGNS = HORIZONTAL_ALIGNS;

  public horizontalAlign = HORIZONTAL_ALIGNS.LEFT;
  public width: number;
  public height: number;
  public naturalWidth: number;
  public naturalHeight: number;
  public naturalPtWidth: number;
  public naturalPtHeight: number;
  public margin: ImageMarginModel = { top: 0, right: 0, bottom: 0, left: 0 };
  public float: string;
  public alternate: string;
  public name: string;
  public src: string;
  public damId: string;
  public rightAuto = false;
  public leftAuto = false;
  public placeholder = false;
  public enableResize = false;
  public placeholderImage: IAsset;
  private generalImages: IAsset[];

  private dataSubscription: Subscription;

  constructor(
    pluginPanelService: PluginPanelService,
    private changeDetector: ChangeDetectorRef,
    private imagePlaceholderService: ImagePlaceholderService,
    private fileService: FileService,
    private accountService: AccountService
  ) {
    super(pluginPanelService);
  }

  ngOnInit(): void {
    const generalImages$ = this.fileService.getImages(ImageFolder.GENERAL);
    const placeholderImage$ = this.fileService.getPlaceholderImage();

    forkJoin({
      generalImages: generalImages$,
      placeholder: placeholderImage$,
    }).subscribe(({ generalImages, placeholder }: { generalImages: IAsset[]; placeholder: IAsset }) => {
      this.generalImages = generalImages;
      this.placeholderImage = placeholder;
      this.loadData();
    });

    this.dataSubscription = this.imagePlaceholderService.getDataObservable().subscribe((data: PlaceHolderValue) => {
      this.data = data;
      this.updateData();
    });
  }

  ngOnDestroy(): void {
    this.dataSubscription.unsubscribe();
  }

  private loadData(): void {
    this.imagePlaceholderService.setEditorId(this.getEditorTopicContext());
    if (this.data) {
      // From open panel
      this.imagePlaceholderService.setData(this.data);
    } else {
      this.data = this.imagePlaceholderService.getData();
    }
    this.updateData();
  }

  private getGeneralImageFromSrc(src: string): IAsset | null {
    if (!src) {
      return null;
    }
    const splitSrc = src.split('/');
    const id = splitSrc[splitSrc.length - 1];
    return this.generalImages.find((image: IAsset) => image.id === parseInt(id, 10)) ?? null;
  }

  private updateData(): void {
    if (this.accountService.hasAnyAuthority(Authority.RESIZE_IMAGE)) {
      const generalImage = this.getGeneralImageFromSrc(this.data?.src);
      this.enableResize = !!generalImage;
    }

    this.initImageWidthAndHeight();

    const margin = this.data?.margin;
    this.rightAuto = margin?.right === 'auto';
    this.leftAuto = margin?.left === 'auto';
    if (this.leftAuto) {
      if (this.rightAuto) {
        this.horizontalAlign = HORIZONTAL_ALIGNS.CENTER;
      } else {
        this.horizontalAlign = HORIZONTAL_ALIGNS.RIGHT;
      }
    } else {
      this.horizontalAlign = HORIZONTAL_ALIGNS.LEFT;
    }
    const marginRight = margin?.right ? margin.right.replace('pt', '') : 0;
    const marginLeft = margin?.left ? margin?.left.replace('pt', '') : 0;
    this.margin = {
      top: margin?.top?.replace('pt', ''),
      right: this.rightAuto ? null : marginRight,
      bottom: margin?.bottom?.replace('pt', ''),
      left: this.leftAuto ? null : marginLeft,
    };
    this.float = this.data?.float || FloatStyle.FULL;
    this.alternate = this.data?.alternate;
    this.name = this.data?.name;
    this.src = this.data?.src;
    this.damId = this.data?.damId;
    this.placeholder = this.data?.placeholder === 'true' || Number(this.damId) === this.placeholderImage?.id;

    this.udpatePanelTitle();
    this.changeDetector.markForCheck();
  }

  private initImageWidthAndHeight(): void {
    if (!Object.keys(this.data).length) {
      return;
    }
    this.naturalWidth = this.data?.naturalWidth;
    this.naturalHeight = this.data?.naturalHeight;

    // if we have the real image size (value in px), convert values in pt to display them in the view
    if (this.naturalWidth && this.naturalHeight) {
      this.naturalPtHeight = Math.round(this.naturalHeight * (72 / 96));
      this.naturalPtWidth = Math.round(this.naturalWidth * (72 / 96));
    }

    // Don't start with a 0, start with the natural value
    const actualWidth = this.data?.width?.length ? this.data?.width : this.naturalPtWidth;
    const actualHeight = this.data?.height?.length ? this.data?.height : this.naturalPtHeight;

    this.width = isNaN(actualWidth) && actualWidth.includes('pt') ? +actualWidth.replace('pt', '') : actualWidth ?? 0;
    this.height = isNaN(actualHeight) && actualHeight.includes('pt') ? +actualHeight.replace('pt', '') : actualHeight ?? 0;

    this.changeDetector.markForCheck();
  }

  private udpatePanelTitle(): void {
    this.pluginPanelService.panelTitle = `htmlEditor.plugins.imagePlaceholder.${this.placeholder ? 'title' : 'titleImage'}`;
  }

  public updateMargin(margin: IMarginModel): void {
    this.margin = {
      top: margin?.top || 0,
      right: margin?.right || 0,
      bottom: margin?.bottom || 0,
      left: margin?.left || 0,
    };
    this.updateProperties();
  }

  public onHorizontalAlign(align: HORIZONTAL_ALIGNS): void {
    if (align === HORIZONTAL_ALIGNS.RIGHT) {
      this.margin.left = 0;
      this.leftAuto = true;
      this.rightAuto = false;
      if (this.margin.right === null) {
        this.margin.right = 0;
      }
    } else if (align === HORIZONTAL_ALIGNS.CENTER) {
      this.leftAuto = true;
      this.rightAuto = true;
      this.margin.left = 0;
      this.margin.right = 0;
    } else {
      // left is the default
      this.leftAuto = false;
      this.rightAuto = false;
      if (this.margin.left === null) {
        this.margin.left = 0;
      }
      if (this.margin.right === null) {
        this.margin.right = 0;
      }
    }
    this.changeDetector.markForCheck();
    this.updateProperties();
  }

  /**
   * maintains the height-width ratio of the image
   */
  public updateHeight() {
    if (this.naturalWidth && this.naturalHeight) {
      // this.width can be null and null will be implicit converted to zero
      const height = (this.naturalHeight / this.naturalWidth) * this.width;
      this.height = height ? Math.round(height) : 0;
    }
    this.updateProperties();
  }

  /**
   * maintains the height-width ratio of the image
   */
  public updateWidth() {
    if (this.naturalWidth && this.naturalHeight) {
      // this.height can be null and null will be implicit converted to zero
      const width = (this.naturalWidth / this.naturalHeight) * this.height;
      this.width = width ? Math.round(width) : 0;
    }
    this.updateProperties();
  }

  public updateProperties(): void {
    const marginRightValue = this.margin.right != null ? `${this.margin.right}pt` : '0pt';
    const marginLeftValue = this.margin.left != null ? `${this.margin.left}pt` : '0pt';
    const data = {
      width: this.width != null ? `${this.width}pt` : 'auto',
      height: this.height != null ? `${this.height}pt` : 'auto',
      margin: {
        top: this.margin.top != null ? `${this.margin.top}pt` : '0pt',
        bottom: this.margin.bottom != null ? `${this.margin.bottom}pt` : '0pt',
        right: this.rightAuto ? 'auto' : marginRightValue,
        left: this.leftAuto ? 'auto' : marginLeftValue,
      },
      float: this.float,
      alternate: this.alternate,
      src: this.src,
      damId: this.damId,
      placeholder: this.placeholder ? 'true' : 'false',
      name: this.name,
    };
    this.imagePlaceholderService.updatePluginData(data);
  }

  public openImagePicker(): void {
    pubsub.fire(EDITOR_PLUGIN_TOGGLEPANEL, {
      component: PLUGIN_PANEL_COMPONENT_KEYS.IMAGE_PICKER,
      title: 'htmlEditor.plugins.imagePicker.title',
      data: {
        placeholder: this.data,
      },
    });
  }

  public resetPlaceholder(): void {
    this.imagePlaceholderService.replaceImage(this.placeholderImage, true);
  }
}
