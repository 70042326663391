<table mat-table [dataSource]="logs" aria-describedby="logs">
  <ng-container matColumnDef="code">
    <th id="codeLabel" mat-header-cell *matHeaderCellDef jhiTranslate="dataEditor.xbrl.dialog.errorWarning.code">Code</th>
    <td mat-cell *matCellDef="let logEntry">{{ logEntry.code }}</td>
  </ng-container>
  <ng-container matColumnDef="message">
    <th id="messageLabel" mat-header-cell *matHeaderCellDef jhiTanslate="dataEditor.xbrl.dialog.errorWarning.message">Message</th>
    <td mat-cell *matCellDef="let logEntry">{{ logEntry.message }}</td>
  </ng-container>
  <tr mat-header-row *matHeaderRowDef="logsColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: logsColumns"></tr>
</table>
