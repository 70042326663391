export const toTitleCase = (input: string): string => {
  // First version: separator is spaces sequence.
  return input.toLowerCase().replace(/([^\s]+)/gm, (match: string, $1: string) => {
    return $1.charAt(0).toUpperCase() + $1.slice(1);
  });
};

export const toSentenceCase = (input: string): string => {
  // First version: separator is spaces sequence.
  return input.toLowerCase().replace(/^([^\s]+)/gm, (match: string, $1: string) => {
    return $1.charAt(0).toUpperCase() + $1.slice(1);
  });
};

export const toNonBreakingSpaces = (input: string): string => {
  return input.replace(/(\s+)/gm, '\u00A0');
};
