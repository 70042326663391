import {
  ViewEncapsulation,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  Optional,
  Output,
  Self,
  HostBinding,
  ElementRef,
  OnDestroy,
  EventEmitter,
} from '@angular/core';
import { UntypedFormControl, NgControl } from '@angular/forms';
import { MAT_DATE_FORMATS, ErrorStateMatcher, DateAdapter, MAT_DATE_LOCALE } from '@angular/material/core';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { MatFormFieldControl } from '@angular/material/form-field';
import { FocusMonitor } from '@angular/cdk/a11y';

import { StrictMomentDateAdapter } from './strict-moment-date-adapter';
import { Subject } from 'rxjs';
import { ICalendarValueChanged } from 'app/shared/model/calendar.model';

class CalendarErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: UntypedFormControl | null): boolean {
    return !!(control?.invalid && (control.dirty || control.touched));
  }
}

const DEFAULT_DATE_FORMAT = 'DD/MM/YYYY';

export const MY_FORMATS = {
  parse: {
    dateInput: DEFAULT_DATE_FORMAT,
  },
  display: {
    dateInput: DEFAULT_DATE_FORMAT,
    dateA11yLabel: DEFAULT_DATE_FORMAT,
    monthYearLabel: 'MMMM YYYY',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'jhi-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    { provide: MatFormFieldControl, useExisting: CalendarComponent },
    { provide: DateAdapter, useClass: StrictMomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class CalendarComponent implements OnInit, OnDestroy, MatFormFieldControl<string> {
  private static readonly INITIAL_DATE = new Date();
  static nextId = 0;
  matcher = new CalendarErrorStateMatcher();

  @Input() label = 'Date';
  @Input() name: string;
  @Input() minDate: Date = moment().subtract(150, 'years').toDate();
  @Input() maxDate: Date = moment().add(50, 'years').toDate();
  @Input() readonly = false;
  @Input() minDateMessage: string = this.translateService.instant('error.date.min');
  @Input() maxDateMessage: string = this.translateService.instant('error.date.max');
  @Input() changePlaceholder = true;

  @Input()
  get value(): string {
    return this._value;
  }
  set value(value: string) {
    const date = typeof value === 'string' ? moment(value) : value;
    if (this.dateControl.value !== date) {
      this.dateControl.setValue({ value: date, disabled: this.disabled });
    }
    this._value = this.formatDate(date);
    this.stateChanges.next();
  }

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }
  set disabled(value: boolean) {
    this._disabled = value;
    if (value) {
      this.dateControl.disable();
    } else {
      this.dateControl.enable();
    }
    this.stateChanges.next();
  }

  @Input()
  get placeholder(): string {
    return `${this._placeholder} ${this.changePlaceholder ? this.translateService.instant('global.field.dateFormat') : ''}`;
  }
  set placeholder(plh: string) {
    this._placeholder = plh;
    this.stateChanges.next();
  }

  @Input()
  get required(): boolean {
    return this._required;
  }
  set required(req: boolean) {
    this._required = req;
    this.stateChanges.next();
  }
  private _required = false;

  @Input()
  get startDate(): Date {
    return this._startDate;
  }
  set startDate(value: Date) {
    this._startDate = this.initialDate = value;
  }

  @Output() valueChanged = new EventEmitter<ICalendarValueChanged>();
  get empty(): boolean {
    return !this.dateControl.value;
  }

  public dateControl = new UntypedFormControl(null);
  public initialDate: Date = CalendarComponent.INITIAL_DATE;
  public errorState = false;
  public controlType = 'jhi-calendar';
  public focused = false;
  public autofilled?: boolean;
  public stateChanges = new Subject<void>();

  private _startDate: Date = CalendarComponent.INITIAL_DATE;
  private _disabled = false;
  private _value: string;
  private _placeholder = 'JJ/MM/AAAA';

  @HostBinding() id = `date-picker-${CalendarComponent.nextId++}`;

  @HostBinding('attr.aria-describedby') describedBy = '';

  @HostBinding('class.floating')
  get shouldLabelFloat(): boolean {
    return this.focused || !this.empty;
  }

  constructor(
    @Optional() @Self() public ngControl: NgControl,
    private fm: FocusMonitor,
    private elRef: ElementRef<HTMLElement>,
    protected translateService: TranslateService,
    private adapter: DateAdapter<any>,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    fm.monitor(elRef.nativeElement, true).subscribe(origin => {
      this.focused = !!origin;
      this.stateChanges.next();
    });

    // Replace the provider from above with this.
    if (this.ngControl !== null) {
      // Setting the value accessor directly (instead of using
      // the providers) to avoid running into a circular import.
      this.ngControl.valueAccessor = this;
    }
  }

  setDescribedByIds(ids: string[]): void {
    this.describedBy = ids.join(' ');
  }

  ngOnInit(): void {
    this.adapter.setLocale(this.translateService.currentLang);
  }

  ngOnDestroy(): void {
    this.fm.stopMonitoring(this.elRef.nativeElement);
  }
  onContainerClick(event: MouseEvent): void {
    if ((event.target as Element).tagName.toLowerCase() !== 'input') {
      this.elRef.nativeElement.querySelector('input')?.focus();
    }
  }
  writeValue(value: moment.Moment | string): void {
    const date = typeof value === 'string' ? moment(value) : value;
    if (this.dateControl.value !== date) {
      this.dateControl.setValue(date);
      this.notifyChange(this.formatDate(date));
    }
  }
  onChange = (_: string): string => {
    return _;
  };
  onTouched = (): void => {};
  registerOnChange(fn: (value: string) => string): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  public setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
  public onValueChange(): void {
    this.dateControl.updateValueAndValidity();
    this.notifyChange(this.formatDate(this.dateControl.value));
  }

  private notifyChange(value: string): void {
    this.onChange(value);
    this.stateChanges.next();
    this.checkStartDate();
    this.valueChanged.emit({ value, isValid: this.dateControl.valid });
    setTimeout(() => this.changeDetectorRef.markForCheck());
  }

  private checkStartDate(): void {
    this.initialDate =
      this.dateControl.value === '' || this.dateControl.value === null || !this.dateControl.value.isValid()
        ? this.startDate
        : CalendarComponent.INITIAL_DATE;
  }

  private formatDate(date: moment.Moment): string {
    if (!date) {
      return '';
    }
    const year = date.year();
    const month = date.month() + 1;
    const day = date.date();
    return `${year}-${(month < 10 ? '0' : '') + month}-${(day < 10 ? '0' : '') + day}`;
  }
}
