import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { DATA_DISPLAY_DELETED } from 'app/shared/constants/session-storage.constants';
import { SessionStorageService } from 'ngx-webstorage';
import { Observable, ReplaySubject, of } from 'rxjs';
import { map, share } from 'rxjs/operators';

import { ContextService } from './context.service';
import { SERVER_API_URL } from 'app/app.constants';
import { IAsset } from 'app/shared/model/asset.model';
import { AssetFileType } from 'app/shared/enum/asset-file-type.enum';
import { ImageFolder } from 'app/shared/enum/image-folder.enum';
import { IImportUploadReponse } from 'app/shared/model/import-upload.model';
import { FileUtils } from 'app/shared/util/file-utils';
import { DocumentaryUnitService } from './documentary-unit.service';
import { DownloadManagerService } from './download-manager.service';

@Injectable({ providedIn: 'root' })
export class FileService {
  public resourceUrl: string;
  public documentResourceUrl = `${SERVER_API_URL}api/documents`;
  private placeholder$: Observable<IAsset>;
  private images$: { [key: string]: Observable<IAsset[]> } = {};
  private _displayDeleted: boolean = false;

  constructor(
    private http: HttpClient,
    private contextService: ContextService,
    private $sessionStorage: SessionStorageService,
    private documentaryUnitService: DocumentaryUnitService,
    private downloadManagerService: DownloadManagerService
  ) {
    this.resourceUrl = `${SERVER_API_URL}api/projects/${this.contextService.currentProjectId}/assets`;
  }

  public uploadPrepaCopyFile(file: File): Observable<IImportUploadReponse> {
    let params: HttpParams = new HttpParams();
    params = params
      .set('spinner', 'none') // Let the component use its loader
      .set('skipError', `true`); // Let the component catch issue

    const formData = new FormData();
    formData.append('file', FileUtils.sanitizeFile(file));

    return this.http.post<IImportUploadReponse>(
      `${this.documentResourceUrl}/${this.contextService.currentDocumentContext.id}/import`,
      formData,
      {
        params,
      }
    );
  }

  setDisplayDeleted(displayDeleted: boolean): void {
    this._displayDeleted = displayDeleted;
    this.$sessionStorage.store(DATA_DISPLAY_DELETED, displayDeleted);
  }

  getDisplayDeleted(): boolean {
    if (this._displayDeleted === null) {
      this._displayDeleted = this.$sessionStorage.retrieve(DATA_DISPLAY_DELETED);
      // if var is not in store
      if (typeof this._displayDeleted !== 'boolean') {
        this._displayDeleted = false;
      }
    }
    return this._displayDeleted;
  }

  public getAssets(): Observable<IAsset[]> {
    return this.http.get<IAsset[]>(`${this.resourceUrl}`).pipe(map(this.cleanChildren));
  }

  public getProjectId(): number {
    return this.contextService.currentProjectId;
  }

  public getAssetsFromParentId(assetId: number, withDeletedFiles = false): Observable<IAsset[]> {
    const params = new HttpParams().set('withDeletedFiles', `${withDeletedFiles}`);
    return this.http
      .get<IAsset[]>(`${this.resourceUrl}/${assetId}/children`, { params })
      .pipe(map(this.cleanChildren));
  }

  public getXBRLFileName(): Observable<string> {
    return this.http
      .get<IAsset[]>(`${this.resourceUrl}/types/${AssetFileType.XBRL}`)
      .pipe(map(list => (list.length > 0 ? list[0].filename ?? '' : '')));
  }

  public downloadImage(imageId: number): void {
    window.open(`${this.resourceUrl}/images/${imageId}/download`, '_blank');
  }

  public getImages(folder: string | ImageFolder, sortResults = false): Observable<IAsset[]> {
    if (!this.images$[folder]) {
      let params: HttpParams = new HttpParams();

      let folderParam = folder;
      if (this.isLanguageFolder(folder)) {
        params = params.set('language', folder);
        folderParam = ImageFolder.LANGUAGE;
      }

      params = params.set('spinner', 'none');
      params = params.set('sortResults', String(sortResults));
      const documentId = this.contextService.currentDocumentContext.id;
      this.images$[folder] = this.http
        .get<IAsset[]>(`${this.resourceUrl}/images/${documentId}/${folderParam}`, { params })
        .pipe(
          share({
            connector: () => new ReplaySubject(1),
            resetOnError: false,
            resetOnComplete: false,
            resetOnRefCountZero: false,
          })
        );
    }
    return this.images$[folder];
  }

  public getDocumentImages(): Observable<{ id: number; filename: string }[]> {
    const documentId = this.contextService.currentDocumentContext.id;
    return this.http.get<{ id: number; filename: string }[]>(`${this.resourceUrl}/images/${documentId}`);
  }

  public getImagesFolders(): Observable<string[]> {
    const documentId = this.contextService.currentDocumentContext.id;
    return this.http.get<string[]>(`${this.resourceUrl}/images/language-folders/${documentId}`);
  }

  public getPlaceholderImage(): Observable<IAsset> {
    if (!this.placeholder$) {
      let params: HttpParams = new HttpParams();
      params = params.set('spinner', 'none');
      this.placeholder$ = this.http
        .get<IAsset>(`${this.resourceUrl}/placeholder`, { params })
        .pipe(
          share({
            connector: () => new ReplaySubject(1),
            resetOnError: false,
            resetOnComplete: false,
            resetOnRefCountZero: false,
          })
        );
    }
    return this.placeholder$;
  }

  public isLanguageFolder(folder: ImageFolder | string) {
    return (folder as ImageFolder) !== ImageFolder.GENERAL && (folder as ImageFolder) !== ImageFolder.PROJECT;
  }

  public isLanguageFolderValid(filename: string, folder: ImageFolder | string, languageFolders: string[]): boolean {
    if ((folder as ImageFolder) === ImageFolder.GENERAL) {
      // general folder does not have filename language control
      return true;
    }

    // remove file extension
    filename = filename.replace(/\.[^/.]+$/, '');

    const parts = filename.toLowerCase().split('_');
    if ((folder as ImageFolder) === ImageFolder.PROJECT) {
      // valid if filename does not contain a language info (no language for project folder)
      return parts.filter((part: string) => languageFolders.includes(part)).length === 0;
    }

    // valid if the filename contains the language folder
    return parts.filter((part: string) => part === folder).length > 0;
  }

  public uploadImage(folder: ImageFolder | string, files: FileList, languageFolders: string[]): Observable<any> {
    let params: HttpParams = new HttpParams();
    params = params.set('spinner', 'none');

    const formData = new FormData();
    for (const file of Array.from(files)) {
      if (!this.isLanguageFolderValid(file.name, folder, languageFolders)) {
        return of({ errorLanguageFolder: true });
      }
      if (this.isPdf(file)) {
        formData.append('file', FileUtils.sanitizeFile(file));
      }
    }
    if (!formData.has('file')) {
      return of({ errorFileFormat: true });
    }

    let paramFolder = folder;
    if (this.isLanguageFolder(folder)) {
      params = params.set('language', folder);
      paramFolder = ImageFolder.LANGUAGE;
    }

    delete this.images$[folder];

    const documentId = this.contextService.currentDocumentContext.id;
    return this.http.post<void>(`${this.resourceUrl}/images/${documentId}/${paramFolder}`, formData, {
      reportProgress: false,
      observe: 'events',
      params,
    });
  }

  public moveImage(assetId: number, filename: string, folder: ImageFolder | string, currentFolder: ImageFolder | string) {
    const data = {
      filename,
      assetId,
    };
    let paramFolder = folder;
    if (this.isLanguageFolder(folder)) {
      data['language'] = folder;
      paramFolder = ImageFolder.LANGUAGE;
    }

    this.images$[currentFolder] = of([]);
    delete this.images$[folder];

    const documentId = this.contextService.currentDocumentContext.id;
    return this.http.put<void>(`${this.resourceUrl}/images/${documentId}/move/${paramFolder}`, data);
  }

  public deleteDataAsset(assetId: number): Observable<void> {
    return this.http.delete<void>(`${this.resourceUrl}/${assetId}`);
  }

  public deleteImageAsset(imageId: number, folder: ImageFolder | string): Observable<void> {
    let params: HttpParams = new HttpParams();
    params = params.set('spinner', 'none');
    delete this.images$[folder];
    return this.http.delete<void>(`${this.resourceUrl}/images/${imageId}`, { params });
  }

  public getRootAssetUrl(): string {
    return `${this.resourceUrl}/`;
  }

  public getAssetUrl(assetId: number): string {
    return `${this.resourceUrl}/${assetId}`;
  }

  public getAssetUrlWithRealName(assetId: number): string {
    return `${this.resourceUrl}/${assetId}?realName=true`;
  }

  private cleanChildren(assets: IAsset[]): IAsset[] {
    return assets.map((item: IAsset) => {
      // Clean children
      item.childs = [];
      item.expandable = false;
      return item;
    });
  }

  private isPdf(file: File): boolean {
    return file && file instanceof File && file['type'] === 'application/pdf';
  }

  public numberOfPagesPDF(file: File): Observable<{ nbPages: number; existingGraphicalPagesNbPages: number }> {
    let params: HttpParams = new HttpParams();
    params = params
      .set('spinner', 'none') // Let the component use its loader
      .set('skipError', `true`); // Let the component catch issue

    const formData = new FormData();
    formData.append('file', FileUtils.sanitizeFile(file));

    return this.http.post<{ nbPages: number; existingGraphicalPagesNbPages: number }>(
      `${this.documentResourceUrl}/${this.contextService.currentDocumentContext.id}/documentary-unit/${this.documentaryUnitService.currentSelectedDocumentaryUnit?.id}/graphical-pages/number-pages`,
      formData,
      {
        params,
      }
    );
  }

  public importGraphicalPages(file: File, replaceContent: boolean): Observable<number> {
    let params: HttpParams = new HttpParams();
    params = params
      .set('spinner', 'none') // Let the component use its loader
      .set('skipError', `true`) // Let the component catch issue
      .set('replaceContent', `${replaceContent}`);

    const formData = new FormData();
    formData.append('file', FileUtils.sanitizeFile(file));

    return this.http.post<number>(
      `${this.documentResourceUrl}/${this.contextService.currentDocumentContext.id}/documentary-unit/${this.documentaryUnitService.currentSelectedDocumentaryUnit?.id}/graphical-pages/import`,
      formData,
      {
        params,
      }
    );
  }

  public downloadData(projectId: number, assetId: number, exportFormat: string): void {
    this.http
      .get(`${SERVER_API_URL}/api/projects/${projectId}/assets/${assetId}/${exportFormat}`, {
        observe: 'response',
        responseType: 'blob',
      })
      .subscribe(response => this.downloadManagerService.saveExportedDocument(response, 'text/csv'));
  }

  public downloadTitlesExport(projectId: number): void {
    this.http
      .get(`${SERVER_API_URL}/api/projects/${projectId}/titles/export`, {
        observe: 'response',
        responseType: 'blob',
      })
      .subscribe(response => {
        if (response.body) {
          const contentDisposition = response.headers.get('content-disposition');
          const filename = contentDisposition?.split(';')[1].split('filename')[1].split('=')[1].trim();
          const file = new Blob([response.body], { type: 'application/zip' });
          saveAs(file, filename);
        }
      });
  }
}
