import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SERVER_API_URL } from 'app/app.constants';

import { IColumn, ILabel, IRow, ITableModelFull } from 'app/shared/model/table-model.model';

import { Observable, ReplaySubject, share } from 'rxjs';
import { ProjectService } from './project.service';

@Injectable({
  providedIn: 'root',
})
export class LabelService {
  public resourceUrl = `${SERVER_API_URL}api/labels`;

  private readonly CACHE_DURATION = 60 * 60000; // 60 min
  private labels$: Observable<ILabel[]> | null;

  constructor(protected http: HttpClient, protected projectService: ProjectService) {}

  public getLabels(): Observable<ILabel[]> {
    if (!this.labels$) {
      this.labels$ = this.http.get<ILabel[]>(this.resourceUrl).pipe(
        share({
          connector: () => new ReplaySubject(1),
          resetOnError: false,
          resetOnComplete: false,
          resetOnRefCountZero: false,
        })
      );
      setTimeout(() => this.clearCache(), this.CACHE_DURATION);
    }
    return this.labels$;
  }

  public getLabelsWithExtendedOnes(): Observable<ILabel[]> {
    return this.http.get<ILabel[]>(`${this.resourceUrl}-extended`);
  }

  public clearCache(): void {
    this.labels$ = null;
  }

  public retrieveLabelsLists(
    model: ITableModelFull,
    labels: ILabel[],
    addColumns: boolean,
    addRows: boolean
  ): { columnsLabelsList: any; rowsLabelsList: any } {
    const columnsLabelsList: any[] = [];
    const rowsLabelsList: any[] = [];

    if (addColumns && model.columns) {
      model.columns.forEach((column: IColumn) => {
        column = this.checkAndApplyDisplayedName(column, labels) as IColumn;
        columnsLabelsList.push({ id: column.label.id ?? 0, name: column.label.displayedName });
      });

      columnsLabelsList.sort((col1: any, col2: any) => (col1.id < col2.id ? -1 : 1));
    }

    if (addRows && model.rows) {
      model.rows.forEach((row: IRow) => {
        row = this.checkAndApplyDisplayedName(row, labels) as IRow;
        rowsLabelsList.push({ id: row.label.id ?? 0, name: row.label.displayedName });
      });
      rowsLabelsList.sort((row1, row2) => (row1.id < row2.id ? -1 : 1));
    }

    return { columnsLabelsList, rowsLabelsList };
  }

  public checkAndApplyDisplayedName(columnOrRow: any, labels: ILabel[]): any {
    if (columnOrRow.label?.id && !columnOrRow.label.displayedName) {
      // retrieve displayedName
      const actualLabel = labels.find(label => label.nameFr === columnOrRow.label.nameFr && label.type === columnOrRow.label.type);
      columnOrRow.label.displayedName = actualLabel?.displayedName ?? columnOrRow.label.nameFr;
    }

    return columnOrRow;
  }
}
