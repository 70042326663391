import { Component, Input, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'jhi-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoadingComponent {
  @Input() show = false;
  @Input() color: string;
}
