export enum BalanceType {
  DEBIT = 'debit',
  CREDIT = 'credit',
}

export enum MappingBalance {
  DEBIT = 'DEBIT',
  CREDIT = 'CREDIT',
}

export function convertRcsfBalanceToBalance(rcsfBalance: MappingBalance): BalanceType | null {
  if (!rcsfBalance) {
    return null;
  }
  const map = {
    [MappingBalance.DEBIT]: BalanceType.DEBIT,
    [MappingBalance.CREDIT]: BalanceType.CREDIT,
  };

  return map[rcsfBalance];
}
