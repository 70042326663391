import { NgModule } from '@angular/core';
import { SelectDUsFieldComponent } from 'app/shared/component/select-uds-field/select-d-us-field.component';
import { XbrlDataViewerDimensionComponent } from 'app/shared/component/xbrl-data-viewer/xbrl-data-viewer-dimension/xbrl-data-viewer-dimension.component';
import { XbrlDataViewerComponent } from 'app/shared/component/xbrl-data-viewer/xbrl-data-viewer.component';

import { AlphaModule } from 'ngx-color';
import { PgdpSharedLibsModule } from './shared-libs.module';

import { AlertComponent } from 'app/shared/jhipster/alert/alert.component';
import { AlertErrorComponent } from 'app/shared/jhipster/alert/alert-error.component';
import { HeaderComponent } from './component/header/header.component';
import { CalendarComponent } from './component/calendar/calendar.component';
import { ColorFieldComponent } from './component/color-field/color-field.component';
import { ColorPickerComponent } from './component/color-picker/color-picker.component';
import { DynamicDataViewerComponent } from './component/dynamic-data-viewer/dynamic-data-viewer.component';
import { InputSearchComponent } from './component/input-search/input-search.component';
import { NodeExpandComponent } from './component/node-expand/node-expand.component';
import { TaxonomyTableComponent } from './component/taxonomy-table/taxonomy-browser-table.component';
import { DeletionConfirmationComponent } from './dialog/deletion-confirmation/deletion-confirmation.component';
import { ConfirmDialogComponent } from './dialog/confirm-dialog/confirm-dialog.component';
import { DialogErrorComponent } from './dialog/error/dialog-error.component';
import { FrontErrorComponent } from './dialog/error/frontError/front-error.component';
import { ResizableLayoutComponent } from './component/resizable/resizable-layout.component';
import { ResizableComponent } from './component/resizable/resizable.component';
import { EditableSelectComponent } from './component/editable-select/editable-select.component';
import XbrlDataTaggerComponents from './component/xbrl-data-tagger';
import { AccountButtonComponent } from './component/header/account-button/account-button.component';
import { TableModelColumnWidthComponent } from './component/table-modeler/table-model-column-width/table-model-column-width.component';
import { VersionFilterFormComponent } from './component/version-filter-form/version-filter-form.component';
import { SpinnerComponent } from './component/spinner/spinner.component';
import { LoadingComponent } from './component/loading/loading.component';
import { DownloadManagerButtonComponent } from './component/header/dm-button/dm-button.component';
import { SaveButtonComponent } from './component/header/save-button/save-button.component';
import { ZoomButtonComponent } from './component/header/zoom-button/zoom-button.component';
import { VerticalMenuComponent } from './component/menu/vertical-menu.component';
import { VerticalMenuItemComponent } from './component/menu/menu-item/vertical-menu-item.component';
import { VerticalSubMenuComponent } from './component/menu/sub-menu/vertical-sub-menu.component';
import { AccordionFormGroupComponent } from './component/accordion-form-group/accordion-form-group.component';
import { ImportExportComponent } from './component/import-export/import-export.component';

import { HasAnyAuthorityDirective } from './auth/has-any-authority.directive';
import { ActiveMenuDirective } from './directive/active-menu.directive';
import { DndDirective } from './directive/dnd.directive';
import { TranslateDirective } from 'app/shared/jhipster/language/translate.directive';

import { FindLanguageFromKeyPipe } from './language/find-language-from-key.pipe';
import { AssetIconPipe } from './pipe/asset-icon.pipe';
import { DateLabel } from './pipe/date-label.pipe';
import { SafeHtmlPipe } from './pipe/safe-html.pipe';
import { SplitQnamePipe } from './pipe/split-qname.pipe';
import { SplitStringPipe } from './pipe/split-string.pipe';
import { FormatFactPipe } from './pipe/xbrl-utils.pipe';
import { CmykDisplayPipe } from './pipe/cmyk-display.pipe';
import { HslaDisplayPipe } from './pipe/hsla-display.pipe';
import { IndexToMarkerPipe } from './pipe/index-to-marker.pipe';
import { DynamicDataPipe } from './util/dynamic-data-utils';
import { PipeModule } from './pipe.module';
import { JhiFilterPipe } from 'app/shared/jhipster/pipe/filter.pipe';
import { JhiKeysPipe } from 'app/shared/jhipster/pipe/keys.pipe';
import { JhiOrderByPipe } from 'app/shared/jhipster/pipe/order-by.pipe';
import { JhiPureFilterPipe } from 'app/shared/jhipster/pipe/pure-filter.pipe';
import { MarginInputComponent } from './component/margin-input/margin-input.component';
import { InputNumberComponent } from './component/input-number/input-number.component';
import { EditorPropertyOpacitiesComponent } from './component/editor-properties-opacity/editor-properties-opacity.component';
import { MatchingScoreDirective } from './directive/matching-score.directive';
import { PropertiesPanelComponent } from './component/properties-panel/properties-panel.component';
import { PropertiesFormGroupComponent } from './component/properties-panel/properties-form-group/properties-form-group.component';
import { ColumnCountInputComponent } from './component/column-count-input/column-count-input.component';
import { PropertiesFormRowComponent } from './component/properties-panel/form-row/properties-form-row.component';
import { SanitizeHtmlPipe } from './pipe/sanitize-html.pipe';
import { StripHtmlPipe } from './pipe/strip-html.pipe';
import { InlineDialogComponent } from './component/inline-dialog/inline-dialog.component';
import { PanelDeleteConfirmationComponent } from 'app/html-editor/plugins/dynamic-data/annotate/panel-delete-confirmation/panel-delete-confirmation.component';
import { DialogErrorArrayComponent } from 'app/shared/dialog/error/export/dialog-error-array.component';
import { DialogItemStateComponent } from './dialog/item-state/dialog-item-state.component';
import { CellDataHistoryComponent } from './component/cell-data-history/cell-data-history.component';
import { MetricsDatasourceComponent } from 'app/shared/jhipster/metrics/metrics-datasource/metrics-datasource.component';
import { MetricsRequestComponent } from 'app/shared/jhipster/metrics/metrics-request/metrics-request.component';
import { MetricsSystemComponent } from 'app/shared/jhipster/metrics/metrics-system/metrics-system.component';
import { MetricsGarbageCollectorComponent } from 'app/shared/jhipster/metrics/metrics-garbagecollector/metrics-garbagecollector.component';
import { MetricsEndpointsRequestsComponent } from 'app/shared/jhipster/metrics/metrics-endpoints-requests/metrics-endpoints-requests.component';
import { MetricsCacheComponent } from 'app/shared/jhipster/metrics/metrics-cache/metrics-cache.component';
import { JvmMemoryComponent } from 'app/shared/jhipster/metrics/jvm-memory/jvm-memory.component';
import { MetricsModalThreadsComponent } from 'app/shared/jhipster/metrics/metrics-modal-threads/metrics-modal-threads.component';
import { JvmThreadsComponent } from 'app/shared/jhipster/metrics/jvm-threads/jvm-threads.component';
import { ItemCountComponent } from 'app/shared/jhipster/pagination/item-count.component';
import { SortDirective } from 'app/shared/jhipster/sort/sort.directive';
import { SortByDirective } from 'app/shared/jhipster/sort/sort-by.directive';

import { DocumentTocComponent } from '../html-editor/document-toc/document-toc.component';
import { TitlesLevelSelectionComponent } from '../html-editor/titles-level-selection/titles-level-selection.component';
import { FootnoteComponent } from '../html-editor/plugins/footnote/footnote.component';
import { PluginPanelComponent } from '../html-editor/plugins/plugin-panel.component';

const COMPONENTS = [
  // Pipes
  AssetIconPipe,
  PanelDeleteConfirmationComponent,
  FindLanguageFromKeyPipe,
  SafeHtmlPipe,
  SanitizeHtmlPipe,
  StripHtmlPipe,
  DynamicDataPipe,
  DateLabel,
  SplitStringPipe,
  SplitQnamePipe,
  FormatFactPipe,
  CmykDisplayPipe,
  HslaDisplayPipe,
  IndexToMarkerPipe,
  JhiFilterPipe,
  JhiOrderByPipe,
  JhiKeysPipe,
  JhiPureFilterPipe,
  // Directives
  HasAnyAuthorityDirective,
  ActiveMenuDirective,
  DndDirective,
  MatchingScoreDirective,
  SortDirective,
  SortByDirective,
  TranslateDirective,
  // Components
  AlertComponent,
  AlertErrorComponent,
  HeaderComponent,
  TaxonomyTableComponent,
  InputSearchComponent,
  InputNumberComponent,
  DynamicDataViewerComponent,
  CalendarComponent,
  SpinnerComponent,
  LoadingComponent,
  NodeExpandComponent,
  ColorPickerComponent,
  ColorFieldComponent,
  SelectDUsFieldComponent,
  DialogErrorComponent,
  DeletionConfirmationComponent,
  ConfirmDialogComponent,
  FrontErrorComponent,
  DialogErrorArrayComponent,
  ResizableLayoutComponent,
  ResizableComponent,
  EditableSelectComponent,
  MarginInputComponent,
  AccountButtonComponent,
  DownloadManagerButtonComponent,
  SaveButtonComponent,
  ZoomButtonComponent,
  TableModelColumnWidthComponent,
  EditorPropertyOpacitiesComponent,
  PropertiesPanelComponent,
  PropertiesFormGroupComponent,
  PropertiesFormRowComponent,
  ColumnCountInputComponent,
  VersionFilterFormComponent,
  InlineDialogComponent,
  DialogItemStateComponent,
  VerticalMenuComponent,
  VerticalMenuItemComponent,
  VerticalSubMenuComponent,
  AccordionFormGroupComponent,
  XbrlDataViewerComponent,
  CellDataHistoryComponent,
  XbrlDataViewerDimensionComponent,
  ImportExportComponent,
  ItemCountComponent,
  JvmMemoryComponent,
  JvmThreadsComponent,
  MetricsCacheComponent,
  MetricsDatasourceComponent,
  MetricsEndpointsRequestsComponent,
  MetricsGarbageCollectorComponent,
  MetricsModalThreadsComponent,
  MetricsRequestComponent,
  MetricsSystemComponent,
  // Html-editor + preview
  DocumentTocComponent,
  TitlesLevelSelectionComponent,
  FootnoteComponent,
  PluginPanelComponent,
];

@NgModule({
  imports: [PgdpSharedLibsModule, PipeModule.forRoot(), AlphaModule],
  declarations: [...COMPONENTS, ...XbrlDataTaggerComponents],
  exports: [PgdpSharedLibsModule, PipeModule, ...COMPONENTS, ...XbrlDataTaggerComponents],
})
export class PgdpSharedModule {}
