import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'jhi-panel-delete-confirmation',
  templateUrl: './panel-delete-confirmation.component.html',
  styleUrls: ['./panel-delete-confirmation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PanelDeleteConfirmationComponent {
  @Input() warningMessage: string;
  @Output() cancel = new EventEmitter<void>();
  @Output() confirm = new EventEmitter<void>();

  onConfirm(): void {
    this.confirm.emit();
  }

  onCancel($event: Event): void {
    $event.preventDefault();
    this.cancel.emit();
  }
}
