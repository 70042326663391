import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

import { SERVER_API_URL } from 'app/app.constants';
import { IChart } from 'app/shared/model/chart.model';
import { HighchartFont } from 'app/shared/model/highchart-font.model';

import { IHighchartSelected, IHighchartTemplate } from 'app/shared/model/highchart-template.model';
import { ContextService } from './context.service';
import { TranslateService } from '@ngx-translate/core';
import pubsub from 'app/pubsub';
import { EDITOR_PLUGIN_VIEWHIGHCHART } from 'app/pubsub.topics';
import { FileUtils } from 'app/shared/util/file-utils';

@Injectable({ providedIn: 'root' })
export class HighChartService {
  public activeChartType: IChart[];
  public chartTemplates = new Map();
  public activeFontType: HighchartFont[];
  public resourceUrl: string;
  public _tmpHighchart: IHighchartSelected;

  constructor(private http: HttpClient, protected contextService: ContextService, private translate: TranslateService) {
    this.resourceUrl = `${SERVER_API_URL}api/projects/${this.contextService.currentProjectId}/highcharts`;

    pubsub.on(EDITOR_PLUGIN_VIEWHIGHCHART, ({ detail }: CustomEvent) => {
      this._tmpHighchart = detail;
    });
  }

  getTemplatesByProjectId(): Observable<IHighchartTemplate[]> {
    return this.http.get<IHighchartTemplate[]>(`${this.resourceUrl}/templates`);
  }

  getTemplateById(templateId: number): Observable<IHighchartTemplate> {
    return this.http.get<IHighchartTemplate>(`${this.resourceUrl}/templates/${templateId}`);
  }

  addTemplate(template: IHighchartTemplate): Observable<IHighchartTemplate> {
    return this.http.post<IHighchartTemplate>(`${this.resourceUrl}/templates`, template);
  }

  updateTemplate(template: IHighchartTemplate): Observable<void> {
    return this.http.put<void>(`${this.resourceUrl}/templates`, template);
  }

  public duplicateModel(model: IHighchartTemplate): Observable<IHighchartTemplate> {
    const id = model.id;
    const duplicateModelName = `${model.name}_${this.translate.instant('styleEditor.highchart.placeholder.copy')}`;

    const params = new HttpParams().set('skipError', 'true');
    return this.http.post<IHighchartTemplate>(`${this.resourceUrl}/templates/${id}/duplicate`, { name: duplicateModelName }, { params });
  }

  public deleteModel(model: IHighchartTemplate): Observable<IHighchartTemplate> {
    const id = model.id;

    const params = new HttpParams().set('skipError', 'true');
    return this.http.delete<IHighchartTemplate>(`${this.resourceUrl}/templates/${id}`, { params });
  }

  public bulkDeleteModel(ids: number[]): Observable<IHighchartTemplate> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: { ids },
      params: new HttpParams().set('skipError', 'true'),
    };
    return this.http.delete<IHighchartTemplate>(`${this.resourceUrl}/templates/bulkDelete`, options);
  }

  public exportTemplates(ids: number[]): void {
    this.http
      .post(`${this.resourceUrl}/templates/exports`, ids, {
        observe: 'response',
        responseType: 'blob',
      })
      .subscribe(response => this.saveExportedDocument(response, 'application/zip'));
  }

  public importTemplates(file: File): Observable<HttpResponse<Blob>> {
    const formData = new FormData();
    formData.append('file', FileUtils.sanitizeFile(file));

    return this.http.post<HttpResponse<Blob>>(`${this.resourceUrl}/templates/imports`, formData);
  }

  public saveExportedDocument(response: HttpResponse<Blob>, contentType: string): void {
    if (!response.ok || !response.body) {
      return console.error(response);
    }
    const contentDisposition = response.headers.get('content-disposition');
    const filename = contentDisposition?.split(';')[1].split('filename')[1].split('=')[1].trim();
    const file = new Blob([response.body], { type: contentType });
    saveAs(file, filename);
  }
}
