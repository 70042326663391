import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { XbrlActionService } from 'app/core/service/xbrl-action.service';
import { IFact, ReferencedFact } from 'app/shared/model/fact.model';
import { PluginPanelService } from '../../plugin-panel.service';
import { PluginComponent } from '../../plugin.component';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ReferencedFootnote } from 'app/shared/model/xbrl-footnote.model';
import { DocumentaryUnitService } from 'app/core/service/documentary-unit.service';
import { ContextService } from 'app/core/service/context.service';
import { PeriodType } from 'app/shared/enum/period-type.enum';
import { PluginsCommand } from 'app/html-editor/plugins/plugins-commands';
import { forkJoin, Observable, Subscription } from 'rxjs';
import { CKSimpleCommandWithOptionsModel } from 'app/shared/model/ck-simple-command.model';
import { cKSimpleCommandRegistry } from 'app/shared/util/ck-simple-command-registry.utils';
import { CKSimpleCommandKeys } from 'app/shared/enum/ck-simple-command-keys.enum';
import { arraysEqual } from 'app/shared/util/array-utils';
import pubsub from 'app/pubsub';
import { EDITOR_PLUGIN_TOGGLEPANEL } from 'app/pubsub.topics';
import { PLUGIN_PANEL_COMPONENT_KEYS } from '../../plugin-panel-component-keys';

interface IFactFormControl {
  fact: IFact;
  checked: boolean;
}

@Component({
  selector: 'jhi-annotate-panel-plugin',
  templateUrl: './annotate-panel.component.html',
  styleUrls: ['./annotate-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AnnotateFactPanelComponent extends PluginComponent implements OnInit, OnDestroy {
  public editForm = this.fb.group({
    facts: new UntypedFormArray([]),
  });

  public factsList: IFact[];
  public documentLanguage: string;
  public formGroup: UntypedFormGroup;
  public readonly periodType = PeriodType;
  public searchTerm = '';
  public isSubmitButtonEnabled = false;
  public footnoteId: number;
  public displayDeleteConfirmation = false;
  public numberOfFactsSelected = 0;
  public initialListOfFacts: IFactFormControl[] = [];
  private annotation: ReferencedFootnote;

  private subscriptions = new Subscription();
  private unwrapXbrlFootnoteCommand: CKSimpleCommandWithOptionsModel;

  public get factsCtrl(): UntypedFormArray {
    return this.editForm.get('facts') as UntypedFormArray;
  }

  constructor(
    private xbrlActionService: XbrlActionService,
    public pluginPanelService: PluginPanelService,
    private changeDetectorRef: ChangeDetectorRef,
    private fb: UntypedFormBuilder,
    private documentaryUnitService: DocumentaryUnitService,
    private contextService: ContextService
  ) {
    super(pluginPanelService);
  }

  ngOnInit(): void {
    this.unwrapXbrlFootnoteCommand = cKSimpleCommandRegistry.get(this.editor.id, CKSimpleCommandKeys.UNWRAP_XBRL_FOOTNOTE);
    this.documentLanguage = this.contextService.currentDocumentContext.language.code?.substring(0, 2) ?? '';
    this.footnoteId = this.data?.footnoteId;
    this.getFacts();

    this.subscriptions.add(
      this.factsCtrl.valueChanges.subscribe(factsCtrl => {
        this.numberOfFactsSelected = factsCtrl.filter(({ checked }: IFactFormControl) => checked).length;
        this.isSubmitButtonEnabled =
          factsCtrl.length > 0 && this.numberOfFactsSelected > 0 && !arraysEqual(this.initialListOfFacts, factsCtrl);

        this.changeDetectorRef.markForCheck();
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private getFacts(): void {
    const currentDU = this.documentaryUnitService.currentSelectedDocumentaryUnit;
    if (!currentDU?.id) {
      return;
    }
    const documentUnitId = currentDU.id;
    const forkJoinSources: Observable<any>[] = [this.xbrlActionService.getListFactbyUD(documentUnitId)];

    if (this.footnoteId) {
      forkJoinSources.push(this.xbrlActionService.getXbrlFootnote(this.footnoteId));
    }

    forkJoin(forkJoinSources).subscribe(([factsList, annotation]) => {
      this.annotation = annotation;
      this.buildFactListForm(factsList, annotation);
    });
  }

  public filter(searchTerm: string): void {
    if (this.searchTerm !== searchTerm) {
      this.searchTerm = searchTerm;
    }
  }

  public trackByFactId(_index: number, item: { value: IFactFormControl }): number | null {
    return item.value.fact.id ?? null;
  }

  public onSubmitSelectedFacts(): void {
    const facts = this.factsCtrl.value;
    const currentDU = this.documentaryUnitService.currentSelectedDocumentaryUnit;
    if (!currentDU?.id) {
      return;
    }

    if (facts.length > 0) {
      const footnote: ReferencedFootnote = {
        id: this.footnoteId,
        htmlContent: '',
        active: true,
        documentaryUnitId: currentDU.id,
        lang: this.documentLanguage,
        xbrlFacts: facts
          .filter(({ checked }: IFactFormControl) => checked)
          .map(
            ({ fact }: IFactFormControl): ReferencedFact => ({
              factKey: fact.factXbrlId ?? '',
              documentaryUnitId: currentDU.id as number,
            })
          ),
      };
      if (this.footnoteId) {
        this.subscriptions.add(
          this.xbrlActionService.updateXbrlFootnote(footnote).subscribe(footnoteResponse => {
            if (footnoteResponse.id) {
              this.openDetailPanel(footnoteResponse.id);
            }
          })
        );
      } else {
        this.subscriptions.add(
          this.xbrlActionService.createXbrlFootnote(footnote).subscribe(value => {
            this.editor.execute(PluginsCommand.ADD_XBRL_FOOTNOTE, {
              footnoteId: value.id,
              footnoteLang: value.lang,
            });
            if (value.id) {
              this.openDetailPanel(value.id);
            }
          })
        );
      }
    }
  }

  public onRemoveAnnotation($event: Event): void {
    $event.stopPropagation();
    $event.preventDefault();
    this.displayDeleteConfirmation = true;
  }

  public onRemoveConfirmation(): void {
    this.removeAnnotation();
  }

  public onRemoveCancel(): void {
    this.displayDeleteConfirmation = false;
  }

  private removeAnnotation(): void {
    this.xbrlActionService.deleteXbrlFootnote(this.annotation).subscribe(() => {
      this.unwrapXbrlFootnoteCommand.execute({ footnoteId: this.annotation.id });
      this.closePanel();
    });
  }

  private buildFactListForm(factsList: IFact[], annotation: ReferencedFootnote | undefined): void {
    this.factsList = factsList;
    this.factsCtrl.clear();

    factsList.forEach(fact => {
      const checked = !!annotation?.xbrlFacts?.find(footnoteFact => footnoteFact.factKey === fact.factXbrlId);
      this.initialListOfFacts.push({ fact, checked });
      this.factsCtrl.push(
        new UntypedFormGroup({
          fact: new UntypedFormControl(fact),
          checked: new UntypedFormControl(!!checked),
        })
      );
    });

    this.changeDetectorRef.markForCheck();
  }

  private openDetailPanel(footnoteId: number): void {
    pubsub.fire(EDITOR_PLUGIN_TOGGLEPANEL, {
      component: PLUGIN_PANEL_COMPONENT_KEYS.ANNOTATE_DETAIL_PANEL,
      data: {
        footnoteId,
      },
      title: 'htmlEditor.plugins.annotate.detail-panel.headerTitle',
    });
  }
}
