import { Component, AfterViewInit, ViewChild, ElementRef, ViewEncapsulation, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import moment from 'moment';
import { CommentFilterModel, CommentFilterOrder, CommentFilterOrderBy } from 'app/shared/model/commentFilterModel';

import { PluginComponent } from '../plugin.component';
import { PluginPanelService } from '../plugin-panel.service';
import pubsub from 'app/pubsub';
import {
  EDITOR_PLUGIN_SETSIDEBAR,
  EDITOR_COMMENTS_CLOSE_COMMENTS,
  EDITOR_COMMENTS_SELECTION_CHANGED,
  EDITOR_COMMENTS_USER_LIST,
  SESSION_STORAGE_SET_STATE,
  SESSION_STORAGE_GET_STATE,
  EDITOR_COMMENTS_FILTER_SESSION_FORM_RETRIVE,
  EDITOR_COMMENTS_FOUND_RESULTS,
  EDITOR_COMMENTS_FROM_URL_PARAMS,
} from 'app/pubsub.topics';

@Component({
  selector: 'jhi-comments-panel-plugin',
  templateUrl: './comments-panel.component.html',
  styleUrls: ['./comments-panel.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CommentsPanelComponent extends PluginComponent implements OnInit, AfterViewInit, OnDestroy {
  public static initializedFromUrlParam = false;
  public static className = 'plugin-panel-comments';
  public panel: string;
  public userList: { value: string; name: string }[] = [];
  public formFilter: UntypedFormGroup;
  public commentFilterOrderByList: CommentFilterOrderBy[];
  public commentFilterOrderList: CommentFilterOrder[];
  public displayNoResultFound = false;
  private _handleUserList: Function;
  private _handleResetFilters: Function;
  private _handleFoundResults: Function;
  private _sessionStorageKey: string;

  @ViewChild('commentsContainer')
  public commentsContainer: ElementRef<HTMLElement>;

  constructor(pluginPanelService: PluginPanelService, private activatedRoute: ActivatedRoute) {
    super(pluginPanelService);
  }

  ngOnInit() {
    this.commentFilterOrderByList = [
      CommentFilterOrderBy.POSITION,
      CommentFilterOrderBy.USER_NAME,
      CommentFilterOrderBy.DATE,
      CommentFilterOrderBy.LAST_UPDATE,
    ];

    this.commentFilterOrderList = [CommentFilterOrder.ASC, CommentFilterOrder.DESC];

    this._sessionStorageKey = 'filter_comment_suggestion_' + this.getEditorTopicContext();

    this.formFilter = new UntypedFormGroup({
      displayComment: new UntypedFormControl(true),
      displaySuggestion: new UntypedFormControl(true),
      periodDateStart: new UntypedFormControl(null),
      periodDateEnd: new UntypedFormControl(null),
      periodHourStart: new UntypedFormControl({ value: null, disabled: true }),
      periodHourEnd: new UntypedFormControl({ value: null, disabled: true }),
      users: new UntypedFormControl({ value: null, disabled: true }),
      orderBy: new UntypedFormControl(CommentFilterOrderBy.POSITION),
      order: new UntypedFormControl('asc'),
    });

    this._handleUserList = this._setUserList.bind(this);
    pubsub.on(EDITOR_COMMENTS_USER_LIST, this._handleUserList);

    this._handleResetFilters = this._resetFormValues.bind(this);
    pubsub.on(SESSION_STORAGE_SET_STATE, this._handleResetFilters);

    this._handleFoundResults = this._foundResults.bind(this);
    pubsub.on(EDITOR_COMMENTS_FOUND_RESULTS, this._handleFoundResults);

    // hour enable only if date is set
    this.formFilter.controls.periodDateStart.valueChanges.subscribe(value => {
      if (!value) {
        this.formFilter.controls.periodHourStart.disable({ emitEvent: false });
      } else {
        this.formFilter.controls.periodHourStart.enable({ emitEvent: false });
      }
    });

    // hour enable only if date is set
    this.formFilter.controls.periodDateEnd.valueChanges.subscribe(value => {
      if (!value) {
        this.formFilter.controls.periodHourEnd.disable({ emitEvent: false });
      } else {
        this.formFilter.controls.periodHourEnd.enable({ emitEvent: false });
      }
    });

    this._retrieveFormValues();

    if (!CommentsPanelComponent.initializedFromUrlParam) {
      this.activatedRoute.queryParams.subscribe((params: { annotationId?: string }) => {
        if (params?.annotationId) {
          CommentsPanelComponent.initializedFromUrlParam = true;
          pubsub.fire(EDITOR_COMMENTS_FROM_URL_PARAMS, { annotationId: params.annotationId });
        }
      });
    }
  }

  ngAfterViewInit(): void {
    pubsub.fire(EDITOR_PLUGIN_SETSIDEBAR, this.commentsContainer.nativeElement, this.getEditorTopicContext());
  }

  public onClickClosePanel(): void {
    pubsub.once(EDITOR_COMMENTS_SELECTION_CHANGED, super.onClickClosePanel.bind(this));
    pubsub.fire(EDITOR_COMMENTS_CLOSE_COMMENTS, {}, this.getEditorTopicContext());
  }

  /**
   * save filter form values in session
   */
  public applyFilters() {
    const filter: CommentFilterModel = {
      displayComment: this.formFilter.controls.displayComment.value,
      displaySuggestion: this.formFilter.controls.displaySuggestion.value,
    };

    filter.periodDateStart =
      this._getUnixDateFilter(this.formFilter.controls.periodDateStart.value, this.formFilter.controls.periodHourStart.value) ?? undefined;

    filter.periodDateEnd =
      this._getUnixDateFilter(this.formFilter.controls.periodDateEnd.value, this.formFilter.controls.periodHourEnd.value ?? '23:59') ??
      undefined;

    filter.users = this.formFilter.controls.users.value;
    filter.order = this.formFilter.controls.order.value;
    filter.orderBy = this.formFilter.controls.orderBy.value;

    filter.formPeriodDateStart = this.formFilter.controls.periodDateStart.value;
    filter.formPeriodDateEnd = this.formFilter.controls.periodDateEnd.value;
    filter.formPeriodHourStart = this.formFilter.controls.periodHourStart.value;
    filter.formPeriodHourEnd = this.formFilter.controls.periodHourEnd.value;

    pubsub.fire(SESSION_STORAGE_SET_STATE, { prefix: this._sessionStorageKey, newValue: filter });
  }

  /**
   * display "no result message" if no result found
   */
  private _foundResults({ detail }: CustomEvent) {
    this.displayNoResultFound = detail?.hasResults === false;
  }

  /**
   * init form values with filter saved in session
   */
  private _retrieveFormValues() {
    pubsub.once(EDITOR_COMMENTS_FILTER_SESSION_FORM_RETRIVE, ({ detail }: CustomEvent) => {
      if (detail.value) {
        const filter: CommentFilterModel = detail.value;
        if (filter) {
          this.formFilter.patchValue({
            displayComment: filter.displayComment,
            displaySuggestion: filter.displaySuggestion,
            periodDateStart: filter.formPeriodDateStart ?? null,
            periodHourStart: filter.formPeriodHourStart ?? null,
            periodDateEnd: filter.formPeriodDateEnd ?? null,
            periodHourEnd: filter.formPeriodHourEnd ?? null,
            users: filter.users,
            orderBy: filter.orderBy,
            order: filter.order,
          });
        }
      }
    });
    pubsub.fire(SESSION_STORAGE_GET_STATE, {
      prefix: 'filter_comment_suggestion_' + this.editor.config.get('editorId'),
      callback: EDITOR_COMMENTS_FILTER_SESSION_FORM_RETRIVE,
    });
  }

  /**
   * reset form values for
   *  - a direct call (without event)
   *  - editor reset event (check "resetCommentsFilters" value)
   *
   *  don't reset values if we just apply a new filter
   */
  private _resetFormValues(event: CustomEvent | null = null) {
    if (event === null || event?.detail?.resetCommentsFilters === true) {
      this.formFilter.patchValue({
        displayComment: true,
        displaySuggestion: true,
        users: null,
        periodDateStart: null,
        periodHourStart: null,
        periodDateEnd: null,
        periodHourEnd: null,
        orderBy: CommentFilterOrderBy.POSITION,
        order: 'asc',
      });
    }
  }

  /**
   * reset form and applyFilters
   */
  public resetFormValues(event: any) {
    event.stopPropagation();
    this._resetFormValues();
    this.applyFilters();
  }

  /**
   * use moment unix date and compare date value in editor html comment content
   */
  private _getUnixDateFilter(date: string, hour: string): number | null {
    if (date) {
      let formatDate = date + 'T';
      formatDate += hour ?? '00:00';
      formatDate += ':00';
      const momentDate = moment(formatDate);
      return momentDate.unix();
    } else {
      return null;
    }
  }

  /**
   * set userList with editor data
   * values extract from html comment parsing
   */
  private _setUserList(event: CustomEvent) {
    this.userList = [];
    if (event.detail?.length) {
      event.detail.forEach((user: string) => this.userList.push({ value: user, name: user }));
    }
    this.formFilter.controls.users.enable({ emitEvent: false });
  }

  ngOnDestroy(): void {
    pubsub.off(EDITOR_COMMENTS_USER_LIST, this._handleUserList);
    pubsub.off(SESSION_STORAGE_SET_STATE, this._handleResetFilters);
    pubsub.off(EDITOR_COMMENTS_FOUND_RESULTS, this._handleFoundResults);
  }
}
