<h1 mat-dialog-title class="error-title">
  <span jhiTranslate="dialog.errors.{{ errorKey }}.title"></span>
  <button type="button" mat-icon-button class="mat-dialog-close-btn" (click)="onClose()">
    <fa-icon icon="times"></fa-icon>
  </button>
</h1>

<div mat-dialog-content>
  <span class="error-message" jhiTranslate="dialog.errors.{{ errorKey }}.message" [translateValues]="errorValues"></span>
</div>

<div mat-dialog-actions>
  <button mat-raised-button color="primary" (click)="onClose()"><span jhiTranslate="dialog.btn-understood"></span></button>
</div>
