import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

import { DownloadManagerService } from 'app/core/service/download-manager.service';

@Component({
  selector: 'jhi-dm-button',
  templateUrl: './dm-button.component.html',
  styleUrls: ['./dm-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DownloadManagerButtonComponent implements OnInit, OnDestroy {
  private _stateSubscription: Subscription = new Subscription();
  public stateIcon: string;
  public opened = false;

  constructor(private changeDetectorRef: ChangeDetectorRef, private downloadManagerService: DownloadManagerService) {}

  public ngOnInit(): void {
    this._stateSubscription.add(this.downloadManagerService.getButtonState().subscribe(state => this.manageState(state ?? '')));
    this._stateSubscription.add(
      this.downloadManagerService.getPanelState().subscribe(opened => {
        this.opened = opened;
        this.changeDetectorRef.markForCheck();
      })
    );
    this.manageState(this.downloadManagerService.getActualButtonState() ?? '');
  }

  ngOnDestroy(): void {
    this._stateSubscription.unsubscribe();
  }

  private manageState(state: string): void {
    this.stateIcon = `dm-${state ? state.toLowerCase() : ''}`;
    this.changeDetectorRef.markForCheck();
  }

  public openPanel(): void {
    this.downloadManagerService.togglePanel(!this.opened);
    this.changeDetectorRef.markForCheck();
  }
}
