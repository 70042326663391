import { PipeTransform, Pipe } from '@angular/core';
import { SanitizeService } from 'app/core/service/sanitize.service';

@Pipe({
  name: 'sanitizeHtml',
  pure: true,
})
export class SanitizeHtmlPipe implements PipeTransform {
  constructor(private sanitizeService: SanitizeService) {}

  transform(value: string): string {
    return value !== null ? this.sanitizeService.sanitizeHtml(value) : '';
  }
}
