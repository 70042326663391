export class FindReplaceConstants {
  public static FIND = 'find';
  public static REPLACE = 'replace';
}

export class FindReplaceRequest {
  public textToFind?: string;
  public textToReplace?: string;
  public isCaseSensitive?: boolean;
  public wholeWordsOnly?: boolean;
}
export type FindReplaceEvent = CustomEvent<FindReplaceRequest>;
