<h1 mat-dialog-title>
  <span><span jhiTranslate="htmlEditor.plugins.imagePicker.imageUsage"></span> {{asset.filename ?? '' | splitString }}</span>
  <button type="button" mat-icon-button class="mat-dialog-close-btn" [mat-dialog-close]="true" tabindex="-1">
    <fa-icon icon="times"></fa-icon>
  </button>
</h1>

<div mat-dialog-content>
  <jhi-loading [show]="loading"></jhi-loading>
  <ul>
    <li *ngFor="let doc of documents">
      {{ doc.documentName }}
      <ul>
        <li *ngFor="let section of doc?.sections">
          <div>{{ section.sectionNumber }}</div>
          <div>
            {{ section.sectionName }} <span>({{ section.count }})</span>
          </div>
        </li>
      </ul>
    </li>
  </ul>
</div>

<div mat-dialog-actions>
  <button type="button" mat-raised-button color="primary" [mat-dialog-close]="true">
    <span jhiTranslate="dialog.btn-close"></span>
  </button>
</div>
