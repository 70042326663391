import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { MatAccordion } from '@angular/material/expansion';

@Component({
  selector: 'jhi-accordion-form-group',
  templateUrl: './accordion-form-group.component.html',
  styleUrls: ['./accordion-form-group.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccordionFormGroupComponent implements AfterViewInit {
  @Input() expanded = false;
  @Input() title: string;
  @Input() disableMoveUpButton: boolean;
  @Input() disableMoveDownButton: boolean;
  @Input() showActionButtons: boolean;

  @Output() public deleteRule = new EventEmitter<void>();
  @Output() public moveRuleUp = new EventEmitter<void>();
  @Output() public moveRuleDown = new EventEmitter<void>();

  @ViewChild(MatAccordion) public accordion: MatAccordion;
  @ViewChildren('accordionContainerPanel') public accordionContainerPanel: QueryList<ElementRef>;

  ngAfterViewInit() {
    if (this.expanded) {
      this.scrollToSelectedTitle(true);
    }
  }

  public scrollToSelectedTitle(showFirstTitle: boolean) {
    const accordionElement = this.accordionContainerPanel.toArray()[0]?.nativeElement;
    const elements = accordionElement?.querySelectorAll('.titles-list .mat-mdc-checkbox-checked, .titles-list .mat-mdc-radio-checked');
    if (elements) {
      elements[showFirstTitle ? 0 : 1]?.scrollIntoView();
      // a double scroll bar is possible in view, so add scrollIntoView on the current accordion
      accordionElement.scrollIntoView();
    }
  }
}
