import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'jhi-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss'],
})
export class ConfirmDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      message?: string;
      translatedMessage?: string;
      continueBtn?: string;
      cancelBtn?: string;
      translatedTitle?: string;
      blueBanner?: boolean;
    }
  ) {}
}
