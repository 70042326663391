<div class="color-edition-container" *ngIf="color">
  <color-sketch #colorPicker [color]="rgbaCode" (onChange)="onChangeColor($event)"></color-sketch>
  <form role="form" (ngSubmit)="updateColor()" [formGroup]="colorForm">
    <mat-form-field>
      <mat-label>{{ 'styleEditor.color.placeholder.colorName' | translate }}</mat-label>
      <input matInput formControlName="colorName" autocomplete="off" type="text" />
      <mat-error *ngIf="colorForm.get('colorName')?.hasError('required')">
        {{ 'entity.validation.required' | translate }}
      </mat-error>
    </mat-form-field>

    <mat-form-field>
      <mat-label>{{ 'styleEditor.color.placeholder.hexValue' | translate }}</mat-label>
      <input matInput formControlName="hexCode" autocomplete="off" />
      <mat-error *ngIf="colorForm.get('hexCode')?.hasError('required')">
        {{ 'entity.validation.required' | translate }}
      </mat-error>
      <mat-error *ngIf="colorForm.get('hexCode')?.hasError('pattern')">
        {{ 'entity.validation.patternHexColor' | translate }}
      </mat-error>
    </mat-form-field>

    <div class="cmyk-code">
      <mat-form-field class="cmyk-code">
        <mat-label>{{ 'styleEditor.color.placeholder.cmykCodeC' | translate }}</mat-label>
        <input matInput formControlName="cmykCodeC" type="number" min="0" max="100" autocomplete="off" />
      </mat-form-field>
      <mat-form-field class="cmyk-code">
        <mat-label>{{ 'styleEditor.color.placeholder.cmykCodeM' | translate }}</mat-label>
        <input matInput formControlName="cmykCodeM" type="number" min="0" max="100" autocomplete="off" />
      </mat-form-field>
      <mat-form-field class="cmyk-code">
        <mat-label>{{ 'styleEditor.color.placeholder.cmykCodeY' | translate }}</mat-label>
        <input matInput formControlName="cmykCodeY" type="number" min="0" max="100" autocomplete="off" />
      </mat-form-field>
      <mat-form-field class="cmyk-code">
        <mat-label>{{ 'styleEditor.color.placeholder.cmykCodeK' | translate }}</mat-label>
        <input matInput formControlName="cmykCodeK" type="number" min="0" max="100" autocomplete="off" />
      </mat-form-field>
    </div>
    <mat-form-field>
      <mat-label>{{ 'styleEditor.color.placeholder.pantone' | translate }}</mat-label>
      <input matInput formControlName="pantoneCode" autocomplete="off" />
      <mat-error *ngIf="colorForm.get('pantoneCode')?.hasError('required')">
        {{ 'entity.validation.required' | translate }}
      </mat-error>
    </mat-form-field>

    <div class="hsla-code">
      <mat-form-field class="hsla-code">
        <mat-label>{{ 'styleEditor.color.placeholder.hslaCodeH' | translate }}</mat-label>
        <input matInput formControlName="hslaCodeH" autocomplete="off" />
      </mat-form-field>
      <mat-form-field class="hsla-code">
        <mat-label>{{ 'styleEditor.color.placeholder.hslaCodeS' | translate }}</mat-label>
        <input matInput formControlName="hslaCodeS" autocomplete="off" />
      </mat-form-field>
      <mat-form-field class="hsla-code">
        <mat-label>{{ 'styleEditor.color.placeholder.hslaCodeL' | translate }}</mat-label>
        <input matInput formControlName="hslaCodeL" autocomplete="off" />
      </mat-form-field>
      <mat-form-field class="hsla-code">
        <mat-label>{{ 'styleEditor.color.placeholder.hslaCodeA' | translate }}</mat-label>
        <input matInput formControlName="hslaCodeA" autocomplete="off" />
      </mat-form-field>
    </div>

    <div class="actions-button">
      <button
        type="button"
        mat-raised-button
        color="accent"
        jhiTranslate="adminEditor.project-configuration.cancelBtn"
        (click)="cancelUpdated()"
      ></button>
      <button
        type="submit"
        mat-raised-button
        color="primary"
        [disabled]="colorForm.invalid"
        jhiTranslate="styleEditor.color.action.save"
      ></button>
    </div>
  </form>
</div>
