import { Pipe, PipeTransform } from '@angular/core';
import { IStyle } from '../model/style.model';
import { generateMarkerLabel } from '../util/marker.utils';
@Pipe({
  name: 'indexToMarker',
})
export class IndexToMarkerPipe implements PipeTransform {
  transform(index: number, markerStyle: IStyle): string {
    return generateMarkerLabel(markerStyle, index);
  }
}
