import { IMonetaryUnit } from 'app/shared/model/monetary-unit.model';

export function getFormattedUnit(xbrlunit: string, projectUnits: IMonetaryUnit[]): string {
  const xbrlunits = xbrlunit.split('/');
  return xbrlunits
    .map(xu => {
      const unit = projectUnits.find(projectUnit => projectUnit.code === xu);
      return getUnitFromProject(unit as IMonetaryUnit);
    })
    .join('/');
}

function getUnitFromProject(unit: IMonetaryUnit): string {
  if (unit?.symbol) {
    return unit.symbol;
  }
  return unit?.name;
}
