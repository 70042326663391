import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

import { EDITOR_PLUGIN_VIEWTABLE, EDITOR_PLUGIN_VIEW_COLUMN, EDITOR_PLUGIN_VIEW_ROW } from 'app/pubsub.topics';
import pubsub from 'app/pubsub';
import { IRowOrColumnSelected, ITableSelected } from 'app/shared/model/table-model.model';

@Injectable({ providedIn: 'root' })
export class TablePanelService {
  private selectedTableSubject: Subject<ITableSelected> = new Subject();
  private selectedColumnSubject: Subject<IRowOrColumnSelected> = new Subject();
  private selectedRowSubject: Subject<IRowOrColumnSelected> = new Subject();
  private _tmpTable: ITableSelected;
  private _tmpColumn: IRowOrColumnSelected;
  private _tmpRow: IRowOrColumnSelected;

  constructor() {
    // here we are on a singleton service, .off is not necessary
    pubsub.on(EDITOR_PLUGIN_VIEWTABLE, ({ detail }: CustomEvent) => {
      this.selectTable(detail);
    });

    pubsub.on(EDITOR_PLUGIN_VIEW_COLUMN, ({ detail }: CustomEvent) => {
      this.seletColumn(detail);
    });

    pubsub.on(EDITOR_PLUGIN_VIEW_ROW, ({ detail }: CustomEvent) => {
      this.seletRow(detail);
    });
  }

  getSelectedTableObservable(): Observable<ITableSelected> {
    return this.selectedTableSubject.asObservable();
  }

  selectTable(data: ITableSelected): void {
    this._tmpTable = data;
    this.selectedTableSubject.next(data);
  }

  getActualTable(): ITableSelected {
    return this._tmpTable;
  }

  getSelectedColumnObservable(): Observable<IRowOrColumnSelected> {
    return this.selectedColumnSubject.asObservable();
  }

  seletColumn(data: IRowOrColumnSelected): void {
    this._tmpColumn = data;
    this.selectedColumnSubject.next(data);
  }

  getActualColumn(): IRowOrColumnSelected {
    return this._tmpColumn;
  }

  getSelectedRowObservable(): Observable<IRowOrColumnSelected> {
    return this.selectedRowSubject.asObservable();
  }

  seletRow(data: IRowOrColumnSelected): void {
    this._tmpRow = data;
    this.selectedRowSubject.next(data);
  }

  getActualRow(): IRowOrColumnSelected {
    return this._tmpRow;
  }
}
