import { NgModule } from '@angular/core';
import { ConceptTranslatedLabelPipe } from './pipe/concept-translated-label.pipe';
import { DisplayScalePipe } from './pipe/display-scale.pipe';
import { IsConceptLangAvailablePipe } from './pipe/is-concept-lang-available.pipe';
import { LocalizedDatePipe } from './pipe/localized-date.pipe';
import { XbrlFactValuePipe } from './pipe/xbrl-factValue.pipe';
import { DynamicDataValuePipe } from './pipe/xbrl-dynamicDataValue.pipe';
import { TextFilterPipe, TitlesFilterPipe } from './pipe/filter.pipe';

const PIPES = [
  ConceptTranslatedLabelPipe,
  IsConceptLangAvailablePipe,
  DisplayScalePipe,
  LocalizedDatePipe,
  XbrlFactValuePipe,
  DynamicDataValuePipe,
  TextFilterPipe,
  TitlesFilterPipe,
];

@NgModule({
  imports: [],
  declarations: PIPES,
  exports: PIPES,
})
export class PipeModule {
  static forRoot(): any {
    return {
      ngModule: PipeModule,
      providers: PIPES,
    };
  }
}
