import { Input, Output, EventEmitter, OnInit, Directive, OnChanges, SimpleChanges } from '@angular/core';
import { IStyle } from 'app/shared/model/style.model';
import { EditorPropertiesService } from './editor-properties-form-panel.service';
import { IStyleChildUpdateEventType, IStylePropertyUpdateEventType } from './editor-properties-form-panel.component';

export interface EditorPropertyField<T, U> {
  value: T | null;
  placeholder: U | null;
}
@Directive()
// tslint:disable-next-line: directive-class-suffix
export abstract class EditorPropertiesBase implements OnChanges, OnInit {
  @Input() styleElement: IStyle;
  @Input() changeCount: number;
  @Output() updateProperty = new EventEmitter<IStylePropertyUpdateEventType>();
  @Output() updateChild = new EventEmitter<IStyleChildUpdateEventType>();

  constructor(protected editorPropertiesService: EditorPropertiesService) {}

  protected abstract init(): void;

  protected updateStyleProperty(property: IStylePropertyUpdateEventType): void {
    this.updateProperty.emit(property);
  }

  protected updateStyleChild(childUpdate: IStyleChildUpdateEventType): void {
    this.updateChild.emit(childUpdate);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.styleElement?.currentValue) {
      this.init();
    }
  }

  ngOnInit(): void {
    this.init();
  }
}
