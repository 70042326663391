import { Component, Output, Input } from '@angular/core';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { Subject, Observable } from 'rxjs';

@Component({
  selector: 'jhi-input-search',
  templateUrl: './input-search.component.html',
  styleUrls: ['./input-search.component.scss']
})
export class InputSearchComponent {
  public inputTermChanged: Subject<string> = new Subject<string>();

  @Input() placeholder: string;
  @Input() disabled = false;

  @Output()
  get searchTermChanged(): Observable<string> {
    return this.inputTermChanged.pipe(
      map((e: any) => (e.target.value ? e.target.value.toLowerCase() : null)),
      debounceTime(500),
      distinctUntilChanged()
    );
  }
}
