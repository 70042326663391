import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DownloadManagerService } from 'app/core/service/download-manager.service';
import { AMFValidateReportResult } from 'app/shared/model/download-manager.model';
import { ValidateReportResultDTO, LogEntryDTO } from 'app/shared/model/taxonomy.model';

@Component({
  templateUrl: 'xbrl-validation-dialog-result.component.html',
  styleUrls: ['xbrl-validation-dialog-result.component.scss'],
})
export class XbrlValidationDialogResultComponent {
  public panelErrorOpenState = false;
  public panelWarningOpenState = false;
  public errorColumns: string[] = ['code', 'message'];
  public warningColumns: string[] = ['code', 'message'];
  public errors: LogEntryDTO[];
  public warnings: LogEntryDTO[];

  constructor(
    public dialogRef: MatDialogRef<XbrlValidationDialogResultComponent>,
    public dialog: MatDialog,
    public downloadManagerService: DownloadManagerService,
    @Inject(MAT_DIALOG_DATA) public xbrlValidationResult: ValidateReportResultDTO | AMFValidateReportResult
  ) {
    this.errors = xbrlValidationResult.errorsWarnings?.errors ?? [];
    this.warnings = xbrlValidationResult.errorsWarnings?.warnings ?? [];
  }

  public close(): void {
    this.dialogRef.close(this.xbrlValidationResult);
  }

  public download(): void {
    const amfValidateReportResult = this.xbrlValidationResult as AMFValidateReportResult;
    if (amfValidateReportResult.reportId) {
      window.open(this.downloadManagerService.downloadReportURL(amfValidateReportResult.reportId), '_blank');
    }
  }

  public isAMFReport(): boolean {
    const amfValidateReportResult = this.xbrlValidationResult as AMFValidateReportResult;
    return !!amfValidateReportResult?.reportId && amfValidateReportResult?.reportId > 0;
  }
}
