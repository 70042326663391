import { Pipe, PipeTransform } from '@angular/core';
import { IConcept, IEntryPointTable } from '../model/concept.model';
import { getConceptLanguages } from 'app/shared/util/xbrl-utils';
import { TranslateService } from '@ngx-translate/core';
import { JhiLanguageService } from 'app/shared/jhipster/service/language.service';

@Pipe({
  name: 'isConceptLangAvailable',
  pure: true,
})
export class IsConceptLangAvailablePipe implements PipeTransform {
  constructor(private translate: TranslateService, private languageService: JhiLanguageService) {}

  transform(concept: IConcept | IEntryPointTable, translationLanguage: string, currentLanguage = ''): boolean {
    const translations = getConceptLanguages(concept);
    if (currentLanguage === translationLanguage) {
      return false;
    }
    return translations.includes(translationLanguage) ? true : false;
  }
}
