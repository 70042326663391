import { Component, Output, Input, ViewEncapsulation, EventEmitter } from '@angular/core';
import { MatSelectChange } from '@angular/material/select';
import { StyleProperty } from 'app/shared/enum/style-property.enum';
import { IColor } from 'app/shared/model/color.model';
import { IStyle, IStyleProperty } from 'app/shared/model/style.model';
import { EditorPropertyField } from 'app/style-editor/editor/editor-properties-form-panel/editor-properties-base';
import { EditorPropertiesService } from 'app/style-editor/editor/editor-properties-form-panel/editor-properties-form-panel.service';

@Component({
  selector: 'jhi-color-field',
  templateUrl: './color-field.component.html',
  styleUrls: ['./color-field.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ColorFieldComponent {
  @Output() updateColor = new EventEmitter<{ property: string; value: number }>();
  @Output() resetDone = new EventEmitter<void>();
  @Input() fieldName: string;
  @Input() public colors: IColor[];
  @Input() propertyName: StyleProperty;
  @Input() reinit = true;
  @Input() label: string;

  @Input()
  get styleElement(): IStyle {
    return this._styleElement;
  }

  set styleElement(value: IStyle) {
    if (value) {
      this._styleElement = value;
      this.colorValue = this.getColor();
    }
  }

  @Input()
  get reset(): boolean {
    return this._reset;
  }

  set reset(value: boolean) {
    if (value) {
      this.colorValue = { value: 0, placeholder: '' } as EditorPropertyField<number, string>;
      this.resetDone.emit();
    }
  }

  private _styleElement: IStyle;
  private _reset: boolean;
  public colorValue = { value: 0, placeholder: '' } as EditorPropertyField<number, string>;

  constructor(private editorPropertiesService: EditorPropertiesService) {}

  getColor(): EditorPropertyField<number, string> {
    const colorProperty = this.editorPropertiesService.getStyleProperty(this.styleElement, this.propertyName);

    return {
      value: this.getColorId(colorProperty.styleElementProperty)?.id ?? null,
      placeholder: this.getColorId(colorProperty.parentStyle)?.colorName ?? null,
    };
  }

  private getColorId(styleElementProperty: IStyleProperty): IColor | null {
    if (!styleElementProperty) {
      return null;
    }
    return this.colors?.find((c: IColor) => c.id === +styleElementProperty?.data) ?? null;
  }

  updateColorValue($colorSelected: MatSelectChange): void {
    const colorId = $colorSelected.value;
    this.updateColor.emit({ property: this.propertyName, value: colorId });
  }
}
