import { LOCALE_ENUM } from '../enum/locale.enum';

export const DATE_FORMAT = 'YYYY-MM-DD';
export const DATE_TIME_FORMAT = 'YYYY-MM-DDTHH:mm';
export const TIME_FORMAT = 'HH:mm';

type DateFormatType = {
  [key in LOCALE_ENUM]: {
    DATE: string;
    DATE_TIME: string;
    TIME: string;
    INPUT_DATE: string[];
  };
};

export const INPUT_DATE_FORMAT: DateFormatType = {
  [LOCALE_ENUM.FR]: {
    DATE: 'DD/MM/YYYY',
    DATE_TIME: 'DD/MM/YYYY HH:mm',
    TIME: 'HH:mm',
    INPUT_DATE: ['DD/MM/YYYY', 'DD/MM/YYYY HH:mm', 'DD MMMM YYYY', 'YYYY-MM-DD', 'YYYY/MM/DD'],
  },
  [LOCALE_ENUM.EN]: {
    DATE: 'M/D/YY',
    DATE_TIME: 'M/D/YY HH:mm',
    TIME: 'HH:mm',
    INPUT_DATE: ['M/D/YY', 'M/D/YY HH:mm', 'MMMM DD YYYY', 'YYYY-MM-DD', 'YYYY/MM/DD'],
  },
};
