import { CellModel, CellStyleModel, SheetModel, Spreadsheet } from '@syncfusion/ej2-angular-spreadsheet';
import * as XLSX from 'xlsx';
import * as moment from 'moment';

import { Direction } from 'app/shared/enum/direction.enum';
import { TagAction } from 'app/shared/enum/tag-action.enum';
import { CoordSpreadSheet } from 'app/shared/model/coord-spreadsheet.model';
import { IFact, IFactValueUpdate } from 'app/shared/model/fact.model';
import { RCSFFile, IRCSFDataUpdated, RCSFCell, RCSFSheet, RCSFRow, SpreadsheetRowHeight } from 'app/shared/model/rcsf.model';
import { ISheetSelection } from 'app/shared/model/sheet-selection.model';
import {
  SPREADSHEET_FACT_COLOR,
  SPREADSHEET_FACT_DUPLICATE_COLOR,
  SPREADSHEET_FACT_NON_EDITABLE_COLOR,
} from 'app/shared/constants/styles.constants';
import { VALUE_TYPE } from 'app/shared/enum/xslx.enum';
import { DATE_FORMAT, DATE_TIME_FORMAT, INPUT_DATE_FORMAT } from '../constants/input.constants';
import { LOCALE_ENUM } from '../enum/locale.enum';
import { CellViewDataModel } from '../model/cell-view-data.model';
import { BalanceType, MappingBalance } from '../enum/balance-type.enum';
import { AssetFileType } from '../enum/asset-file-type.enum';

export class SpreadsheetUtils {
  /**
   * Get correct format to process it (format sample output 'A1')
   * @param cellRange (format sample 'A1:A1')
   */
  private static getRange(cellRange: string): string {
    return cellRange.split(':')[0];
  }

  /**
   * Get correct format to process it (format sample output CoordSpreadSheet(col:0,row:0)
   * @param range (format sample 'A1')
   */
  public static decodeCellRangeToNumber(range: string): CoordSpreadSheet {
    const rowColumn = range.split(/(\d+)/);
    return { col: XLSX.utils.decode_col(rowColumn[0]), row: XLSX.utils.decode_row(rowColumn[1]) };
  }

  /**
   * Get correct format to process it (format sample output 'A1')
   * @param coords (format sample CoordSpreadSheet(col:0,row:0))
   */
  public static encodeNumberToCellRange(coords: CoordSpreadSheet): string {
    return XLSX.utils.encode_col(coords.col as number).concat(XLSX.utils.encode_row(coords.row as number));
  }

  public static encodeNumberToColumnRange(colIndex: number): string {
    return XLSX.utils.encode_col(colIndex);
  }

  /**
   * Check if fact exist in the table value
   * @param tabValue
   * @param row
   * @param col
   */
  private static isFactExistInCellTable(tabValue: IFact[][], col: string | number, row: string | number): boolean {
    return +col >= 0 && +row >= 0 && +row < tabValue.length && tabValue[+row]?.[+col] !== undefined && tabValue[+row][+col] !== null;
  }

  /**
   * Get the cell value selected. Two case, mode edition active or not
   * @param spreadSheet
   * @param isEdition
   * @param coords
   * @param range
   */
  public static async getCellValue(spreadSheet: Spreadsheet, coords: CoordSpreadSheet, range: string): Promise<string | number> {
    const result: Map<string, CellModel> = await spreadSheet.getData(`${spreadSheet.getActiveSheet().name}!${range}`);
    return result.values().next().value?.value || null;
  }

  /**
   * Check if the selection is single or multiple and return the ISheetSelection appropriate
   * @param range
   * @param sheetName
   */
  public static setSingleOrMultipleSelection(range: string, sheetName: string): ISheetSelection {
    const selection: ISheetSelection = { sheetName, range };
    const rangeTab = range.split(':');
    selection.single = rangeTab && rangeTab.length === 2 && rangeTab[0] === rangeTab[1] ? rangeTab[0] : null;
    return selection;
  }

  /**
   * Used to add or remove a fact with the manual mapping use-case
   * @param mapData
   * @param fact
   * @param action
   */
  public static addOrRemoveFact(mapData: Map<string, IFact[][]>, fact: IFact, action: TagAction): IFact[][] {
    let tabValue: IFact[][] = [];
    const sheetName = SpreadsheetUtils.cleanSheetname(fact.selection?.sheetName as string);
    for (const name of mapData.keys()) {
      if (name === sheetName) {
        tabValue = mapData.get(name) as IFact[][];
        const coords = this.decodeCellRangeToNumber(fact.selection?.range as string);
        if (coords?.row && coords?.col) {
          tabValue[coords.row][coords.col] = TagAction.ADD_TAG === action ? fact : null;
        }
      }
    }
    return tabValue;
  }

  /**
   * Generate an empty tabValue with the good column and row number
   * @param sheets
   */
  public static generateEmptyTabValue(sheets: SheetModel[]): Map<string, IFact[][]> {
    const newTabvalue: Map<string, IFact[][]> = new Map();
    sheets.forEach(sheet => {
      const factValue: any[][] = [];
      for (let rowIndex = 0; rowIndex < (sheet.rows?.length ?? 0); rowIndex++) {
        factValue.push([]);
        for (let colIndex = 0; colIndex < (sheet.rows?.[rowIndex].cells?.length ?? 0); colIndex++) {
          factValue[rowIndex].push(null);
        }
      }
      newTabvalue.set(sheet.name as string, factValue);
    });
    return newTabvalue;
  }

  public static getFactsValueForSheet(sheet: RCSFSheet, keepIdx: boolean): IFact[][] {
    // fill the array to avoid empty cells
    const factValue: IFact[][] = new Array(sheet.rows.length).fill([]);
    for (let rowNb = 0; rowNb < sheet.rows.length; rowNb++) {
      // For RCSF : Never use nbRows but rowIdx, empty cells are not in the sheet data
      // For XBRL : we need to use the nbRows
      const rowIdx = keepIdx ? rowNb : sheet.rows[rowNb].rowIdx;

      factValue[rowIdx] = [];

      for (let colIndex = 0; colIndex < sheet.rows[rowNb].cols.length; colIndex++) {
        const currentCell = sheet.rows[rowNb].cols[colIndex];

        // On cell addition, we may have holes in spreadsheet at first position of the cell
        if (colIndex === 0 && currentCell && currentCell.colIdx !== colIndex) {
          factValue[rowIdx].push(...new Array(currentCell.colIdx - colIndex).fill(null));
        }

        factValue[rowIdx].push(currentCell.fact as IFact);

        const nextCell = sheet.rows[rowNb].cols[colIndex + 1];
        if (nextCell && nextCell.colIdx !== currentCell.colIdx + 1) {
          const arraySize = nextCell.colIdx - (currentCell.colIdx + 1);
          if (arraySize > 0) {
            factValue[rowIdx].push(...new Array(arraySize).fill(null));
          }
        }
      }
    }
    return factValue;
  }

  /**
   * Generate a tabValue with the correct column and row number
   * @param sheets
   */
  public static generateTableValue(sheets: RCSFSheet[], keepIdx = false): Map<string, IFact[][]> {
    const newTabvalue: Map<string, IFact[][]> = new Map();
    sheets.forEach(sheet => {
      newTabvalue.set(sheet.sheetName, SpreadsheetUtils.getFactsValueForSheet(sheet, keepIdx));
    });
    return newTabvalue;
  }

  public static getCellToHighlight(tabValue: IFact[][]): { coords: string; fact: IFact }[] {
    const cellToHighlight: { coords: string; fact: IFact }[] = [];

    tabValue.forEach((row, indexRow) => {
      const coord: CoordSpreadSheet = new CoordSpreadSheet();
      if (row.length) {
        coord.row = indexRow;
        row.forEach((col, indexCol) => {
          if (col) {
            coord.col = indexCol;
            cellToHighlight.push({
              coords: `${this.encodeNumberToCellRange(coord)}:${this.encodeNumberToCellRange(coord)}`,
              fact: col,
            });
          }
        });
      }
    });
    return cellToHighlight;
  }

  /**
   * Search in the table value the fact detail
   * @param selection
   * @param cellRange
   * @param mapData
   */
  public static findFactDetail(selection: ISheetSelection, cellRange: string, mapData: Map<string, IFact[][]>): IFact | null {
    let fact: IFact | null = null;
    if (mapData) {
      const range = this.getRange(cellRange);
      const selectionSheetName = SpreadsheetUtils.cleanSheetname(selection.sheetName);
      for (const sheetname of mapData.keys()) {
        const cleanSheetname = SpreadsheetUtils.cleanSheetname(sheetname);
        if (cleanSheetname === selectionSheetName) {
          const tabValue = mapData.get(cleanSheetname);
          const coord: CoordSpreadSheet = this.decodeCellRangeToNumber(range);
          if (coord.row !== null && this.isFactExistInCellTable(tabValue as IFact[][], coord.col as number, coord.row)) {
            fact = tabValue?.[coord.row][coord.col ?? 0];
            if (fact) {
              fact.selection = selection;
            }
            break;
          }
        }
      }
    }
    return fact;
  }

  /**
   * Get the previous or the next non-empty cell according to the direction param
   * @param direction
   * @param sheetName
   * @param cellRange
   * @param mapData
   */
  public static getPreviousOrNextCellRange(
    direction: Direction,
    sheetName: string,
    cellRange: string,
    mapData: Map<string, IFact[][]>
  ): string | null {
    const originRange = this.getRange(cellRange);
    let newRange: CoordSpreadSheet | null = null;
    const cleansheetName = SpreadsheetUtils.cleanSheetname(sheetName);
    for (const name of mapData.keys()) {
      const cleanName = SpreadsheetUtils.cleanSheetname(name);
      if (cleanName === cleansheetName) {
        const tabValue = mapData.get(cleanName) as IFact[][];
        const coord: CoordSpreadSheet = this.decodeCellRangeToNumber(originRange);
        newRange = direction === Direction.ASC ? this.searchNextCell(tabValue, coord) : this.searchPreviousCell(tabValue, coord);
        break;
      }
    }
    return newRange &&
      SpreadsheetUtils.isFactExistInCellTable(mapData.get(cleansheetName) as IFact[][], newRange.col as number, newRange.row as number)
      ? this.encodeNumberToCellRange(newRange)
      : null;
  }

  /**
   * Search the previous non-empty fact in the table value
   * @param tabValue
   * @param coord
   */
  private static searchPreviousCell(tabValue: IFact[][], coord: CoordSpreadSheet): CoordSpreadSheet {
    let coordsToReturn: CoordSpreadSheet = new CoordSpreadSheet(coord.col, coord.row);
    const previousColIndex: number = +(coord.col ?? 0) - 1;
    const previousRowIndex: number = +(coord.row ?? 0) - 1;
    const previousIndexColMax = tabValue[previousRowIndex];

    if (SpreadsheetUtils.isFactExistInCellTable(tabValue, previousColIndex, coord.row ?? 0)) {
      coordsToReturn = new CoordSpreadSheet(previousColIndex, coord.row);
    } else if (coord.col === 0 && (coord.row as number) > 0) {
      coordsToReturn = this.searchPreviousCell(tabValue, new CoordSpreadSheet(previousIndexColMax.length, previousRowIndex));
    } else if ((coord.col as number) > 0) {
      coordsToReturn = this.searchPreviousCell(tabValue, new CoordSpreadSheet(previousColIndex, coord.row));
    } else if (coord.col === 0 && coord.row === 0) {
      const lastRow = tabValue[tabValue.length - 1];
      coordsToReturn = this.searchPreviousCell(tabValue, new CoordSpreadSheet(lastRow.length, tabValue.length - 1));
    }
    return coordsToReturn;
  }

  /**
   * Search the next non-empty fact in the table value
   * @param tabValue
   * @param coord
   */
  private static searchNextCell(tabValue: IFact[][], coord: CoordSpreadSheet): CoordSpreadSheet {
    let coordsToReturn: CoordSpreadSheet = new CoordSpreadSheet(coord.col, coord.row);
    const nextColIndex: number = +(coord.col ?? 0) + 1;
    const nextRowIndex: number = +(coord.row ?? 0) + 1;
    const indexColMax: number = tabValue[coord.row ?? 0].length;
    const indexRowMax: number = Object.keys(tabValue).length;

    if (SpreadsheetUtils.isFactExistInCellTable(tabValue, nextColIndex, coord.row ?? 0)) {
      coordsToReturn = new CoordSpreadSheet(nextColIndex, coord.row);
    } else if (indexColMax > nextColIndex) {
      coordsToReturn = this.searchNextCell(tabValue, new CoordSpreadSheet(nextColIndex, coord.row));
    } else if (nextColIndex === indexColMax && indexRowMax > nextRowIndex) {
      coordsToReturn = this.searchNextCell(tabValue, new CoordSpreadSheet(-1, nextRowIndex));
    } else if (nextColIndex === indexColMax && indexRowMax === nextRowIndex) {
      coordsToReturn = this.searchNextCell(tabValue, new CoordSpreadSheet(-1, 0));
    }
    return coordsToReturn;
  }

  /**
   * Set the XBRL value to save
   * @param factValue
   * @param fact
   */
  public static getValueFact(factValue: string, fact: IFact): IFactValueUpdate {
    return {
      factId: fact?.id ?? null,
      factValue,
    };
  }

  /**
   * Set the RCSF value to save
   * @param viewCell
   * @param selection
   * @param rcsfFile
   * @param defaultProjectImportScale
   */
  public static getRCSFData(
    viewCell: CellViewDataModel,
    selection: ISheetSelection,
    rcsfFile: RCSFFile,
    defaultProjectImportScale: number
  ): IRCSFDataUpdated {
    const rcsfCell: RCSFCell | null = SpreadsheetUtils.findRCSFCell(selection, rcsfFile?.sheets);

    return {
      rowIndex: selection.rowIndex ?? null,
      colIndex: selection.colIndex ?? null,
      colName: XLSX.utils.encode_col(selection.colIndex as number),
      sheetName: SpreadsheetUtils.cleanSheetname(selection.sheetName),
      filename: rcsfFile.filename,
      value: viewCell.value,
      valueType: viewCell.dataType ? viewCell?.dataType : rcsfCell?.valueType ?? null,
      factId: rcsfCell?.fact?.id,
      scale:
        viewCell?.scale ?? this.getRealScale(rcsfCell?.mappingScale as number, rcsfCell?.importScale as number, defaultProjectImportScale),
      lang: rcsfCell?.language ?? null,
      balance: viewCell?.balance ?? this.getRealBalance(rcsfCell?.mappingBalance as MappingBalance, rcsfCell?.balance as BalanceType),
    };
  }

  public static getRealScale(mappingScale: number | null, importScale: number | null, defaultProjectImportScale: number | null): number {
    return mappingScale ?? importScale ?? defaultProjectImportScale ?? 0;
  }

  public static getRealBalance(mappingBalance: MappingBalance | null, importBalance: BalanceType | null): BalanceType {
    let balance;
    if (mappingBalance) {
      balance = mappingBalance.toLowerCase() as BalanceType;
    } else if (importBalance) {
      balance = importBalance.toLowerCase() as BalanceType;
    } else {
      balance = BalanceType.CREDIT;
    }
    return balance;
  }

  public static isDisplayDropdowns(fileType: AssetFileType): boolean {
    return fileType === AssetFileType.RCSF || fileType === AssetFileType.XLS || fileType === AssetFileType.XLSX;
  }

  /**
   * Get the data thanks to the coords
   * @param selection
   * @param sheets
   */
  public static findRCSFCell(selection: ISheetSelection, sheets: RCSFSheet[]): RCSFCell | null {
    let rcsfCell: RCSFCell | null = null;
    const selectionName = SpreadsheetUtils.cleanSheetname(selection.sheetName);
    for (const sheet of sheets) {
      if (SpreadsheetUtils.cleanSheetname(sheet.sheetName) === selectionName) {
        const rcsfRow = sheet.rows.find(row => row.rowIdx === selection.rowIndex);
        rcsfCell = rcsfRow?.cols.find(col => col?.colIdx === selection.colIndex) ?? null;
        break;
      }
    }
    return rcsfCell;
  }

  public static getPositionInFile(sheet: RCSFSheet, rowIdx: number, colIdx: number): { rowIndex: number; colIndex: number } {
    const rows = sheet.rows;
    const rowIndex = Number(Object.keys(rows).find(k => rows[k].rowIdx === rowIdx));
    const cols = sheet.rows[rowIndex].cols;
    const colIndex = Number(Object.keys(cols).find(k => cols[k].colIdx === colIdx));
    return { rowIndex, colIndex };
  }
  //
  // Set cell format
  // @see /pgdp/node_modules/@syncfusion/ej2-spreadsheet/src/workbook/common/enum.d.ts
  // Specifies the number format types in Spreadsheet.
  // 'General' | 'Number' | 'Currency' | 'Accounting' | 'ShortDate' | 'LongDate' |
  // 'Time' | 'Percentage' | 'Fraction' | 'Scientific' | 'Text';
  //
  public static setFormat(valueType: VALUE_TYPE | undefined): string {
    switch (valueType) {
      case VALUE_TYPE.NUMERIC:
        // '_#,##0.##;-#,##0.##' display '  0,2' instead of ',2' but add two space in value
        return '#,##0.##;-#,##0.##'; // use setCulture to localize in the correct locale, ';-#,##0.##' display zero value and keep decimal
      case VALUE_TYPE.DATE:
        return 'dd/MM/yyyy';
      default:
        return 'General';
    }
  }

  //
  // Style for cells
  //
  public static setStyle(fact: IFact | null, cellStyle: string, valueType: VALUE_TYPE | undefined, editableCell = true): CellStyleModel {
    let style: CellStyleModel = {};
    if (fact) {
      style.backgroundColor = fact.duplicateInfo ? SPREADSHEET_FACT_DUPLICATE_COLOR : SPREADSHEET_FACT_COLOR;
      if (!editableCell) {
        style.fontStyle = 'italic';
        style.color = SPREADSHEET_FACT_NON_EDITABLE_COLOR;
      }
    }
    // align every date and numbers to the right
    if (valueType === VALUE_TYPE.DATE || valueType === VALUE_TYPE.NUMERIC) {
      style.textAlign = 'right';
    }
    // align every string (even with numeric, date or boolean values) to the left
    // (By default, spreadsheat aligned numeric values on the right )
    else if (valueType === VALUE_TYPE.STRING) {
      style.textAlign = 'left';
    }
    try {
      if (cellStyle?.length) {
        style = { ...JSON.parse(cellStyle), ...style };
      }
    } catch (error) {
      console.error(`Unexpected JSON parse erreur, check style syntax : ${cellStyle}`);
    }
    return style;
  }

  //
  // Set SpreadSheet
  //
  public static setSpreadSheet(sheet: RCSFSheet, locale: LOCALE_ENUM): SheetModel {
    return {
      name: SpreadsheetUtils.getSheetname(sheet),
      rows: sheet.rows.map(({ rowIdx, cols }: RCSFRow) => ({
        index: rowIdx,
        height: SpreadsheetRowHeight,
        cells: cols.map(col => this.createCellModel(col, locale)),
      })),
    };
  }

  public static getSheetname(sheet: RCSFSheet): string {
    if (sheet.deleted) {
      return `🗑️️️️ ${sheet.sheetName}`;
    }
    const haveDuplicatedValueFact = SpreadsheetUtils.haveDuplicatedValueFact(sheet);
    return `${haveDuplicatedValueFact ? '⚠️ ' : ''}${sheet.sheetName}`;
  }

  public static haveDuplicatedValueFact(sheet: RCSFSheet): boolean {
    return !!sheet.rows?.find(r => !!r.cols?.find(c => !!c?.fact?.duplicateInfo));
  }

  public static cleanSheetname(sheetname: string): string {
    return sheetname.replace('⚠️ ', '').replace('🗑️️️️ ', '');
  }

  /**
   * Create a cell object from RCSFCell
   * @param cell: RCSFCell object
   * @returns CellModel object for spreadsheet component
   */
  public static createCellModel(cell: RCSFCell, locale: LOCALE_ENUM): CellModel {
    const { colIdx, formattedValue, fact, cellStyle, rowspan, colspan, valueType }: RCSFCell = cell;

    return {
      index: colIdx,
      style: SpreadsheetUtils.setStyle(fact as IFact, cellStyle as string, valueType as VALUE_TYPE, cell.editable),
      colSpan: colspan,
      rowSpan: rowspan,
      value: SpreadsheetUtils.manageDateISO8601(formattedValue, valueType as VALUE_TYPE, locale),
      format: SpreadsheetUtils.setFormat(valueType as VALUE_TYPE),
    };
  }

  /**
   * Converts into String ISO date
   * @example 2018-09-30T00:00:00.000+0100 to string 2018-09-30 or 2018-09-30T00:00
   *
   * @param pattern - optional -- Specify the pattern used for parsing 'value', to avoid an "Invalid Date" parsing error
   * @example value = '31-12/2018' --> pattern = 'DD-MM/YYYY'
   * @see https://momentjs.com/docs/#/displaying/format/
   */
  public static manageDateISO8601(value: string, valueType: VALUE_TYPE, locale: LOCALE_ENUM, pattern?: string): string {
    if (valueType !== VALUE_TYPE.DATE) {
      return value;
    }
    const date = moment(value, pattern); // /!\ pattern must match the real value format, otherwise Moment will return "Invalid Date"
    const hasTime = date.hours() > 0 || date.minutes() > 0;
    const localizedPattern = INPUT_DATE_FORMAT[locale][hasTime ? 'DATE_TIME' : 'DATE'];
    return date.format(localizedPattern);
  }

  public static formatInputDateToISO8601(inputDate: string, locale: LOCALE_ENUM): string {
    return SpreadsheetUtils.formatDateForServer(moment(inputDate, INPUT_DATE_FORMAT[locale].INPUT_DATE));
  }

  public static formatDateForServer(date: moment.Moment): string {
    if (date.hours() > 0 || date.minutes() > 0) {
      return date.format(DATE_TIME_FORMAT);
    } else {
      return date.format(DATE_FORMAT);
    }
  }

  //
  // Set SpreadSheets
  //
  public static setSpreadSheets(sheets: RCSFSheet[], locale: LOCALE_ENUM): SheetModel[] {
    const spreadSheets: SheetModel[] = [];
    sheets.forEach(sheet => spreadSheets.push(SpreadsheetUtils.setSpreadSheet(sheet, locale)));
    return spreadSheets;
  }
}
