<h1 mat-dialog-title>
  <span jhiTranslate="global.dm.report.warnings.title"></span>
  <button type="button" mat-icon-button (focus)="(false)" class="mat-dialog-close-btn" (click)="close()">
    <fa-icon icon="times"></fa-icon>
  </button>
</h1>

<div mat-dialog-content>
  <mat-accordion>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title class="error-text">
          {{ 'global.dm.report.warnings.errors' | translate: '{count:' + errors.length + '}' }}
        </mat-panel-title>
      </mat-expansion-panel-header>
      <jhi-pdf-report-table [dataSource]="errors" [columns]="errorColumns" [docId]="accountService.documentId"></jhi-pdf-report-table>
    </mat-expansion-panel>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title class="warning-text">
          {{ 'global.dm.report.warnings.warnings' | translate: '{count:' + warnings.length + '}' }}
        </mat-panel-title>
      </mat-expansion-panel-header>
      <jhi-pdf-report-table [dataSource]="warnings" [columns]="warningsColumns" [docId]="accountService.documentId"></jhi-pdf-report-table>
    </mat-expansion-panel>
  </mat-accordion>
</div>

<div mat-dialog-actions>
  <button
    cdkFocusInitial
    type="submit"
    mat-raised-button
    color="primary"
    class="mat-flat-button mat-button"
    jhiTranslate="dataEditor.taxonomy.dialog.validation.download"
    (click)="download()"
  ></button>
  <button
    cdkFocusInitial
    type="submit"
    mat-raised-button
    color="secondary"
    class="mat-flat-button mat-button"
    jhiTranslate="dataEditor.taxonomy.dialog.validation.close"
    (click)="close()"
  ></button>
</div>
