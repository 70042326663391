import { Inject, Injectable, Optional } from '@angular/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import * as _moment from 'moment';

const moment = _moment;

@Injectable()
export class StrictMomentDateAdapter extends MomentDateAdapter {
  constructor(@Optional() @Inject(MAT_DATE_LOCALE) dateLocale: string) {
    super(dateLocale);
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  parse(value: any, parseFormat: string | string[]): _moment.Moment | null {
    if (value && typeof value === 'string') {
      // Force the strict mode
      return this._createMoment2(value, parseFormat, this.locale, true);
    }
    return value ? this._createMoment2(value).locale(this.locale) : null;
  }

  private _createMoment2(...args: any[]): _moment.Moment {
    return moment(...args);
  }
}
