import { Component, OnInit, OnDestroy, AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { Subscription } from 'rxjs';

import { PluginPanelService } from '../plugin-panel.service';

import { DocumentaryUnitService } from 'app/core/service/documentary-unit.service';
import { SummaryType } from 'app/shared/enum/summary-type.enum';
import { SummaryService } from 'app/core/service/summary.service';

import { IDisplayableTitle, ISummary } from 'app/shared/model/summary.model';
import { IDocumentaryUnit } from 'app/shared/model/documentary-unit.model';

import pubsub from 'app/pubsub';
import { EDITOR_SUMMARY_INSERT, EDITOR_SUMMARY_REFRESH, TOC_UPDATED } from 'app/pubsub.topics';
import { PluginComponent } from '../plugin.component';

@Component({
  selector: 'jhi-summary-panel',
  templateUrl: 'summary-panel.component.html',
  styleUrls: ['summary-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SummaryPanelComponent extends PluginComponent implements OnInit, AfterViewInit, OnDestroy {
  public summaryTypes: SummaryType[] = [SummaryType.GENERAL, SummaryType.LOCAL];
  public titleLevels = Array.from(Array(10), (_, index) => index + 1); // Ten title levels

  public documentTitles: IDisplayableTitle[];
  public filteredTitles: IDisplayableTitle[];
  public allTitlesSelected = true;

  public summary: ISummary;

  public disableAnimation = true;

  private readonly _subscriptions: Subscription = new Subscription();
  private tocUpdatedRef: Function;

  constructor(
    pluginPanelService: PluginPanelService,
    private summaryService: SummaryService,
    protected documentaryUnitService: DocumentaryUnitService,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    super(pluginPanelService);
  }

  public ngOnInit(): void {
    // Default initialization
    this.summary = {
      id: null,
      type: SummaryType.GENERAL,
      includedTitleLevels: [1, 2],
      currentDocumentaryUnitId: this.documentaryUnitService.currentSelectedDocumentaryUnit?.objectId,
      excludedTitles: [],
    };
    this.loadSummaryPreviewWithTitles();

    this._subscriptions.add(
      this.summaryService.getSelectedSummary().subscribe(summary => {
        if (summary?.id) {
          this.summary = summary;
          this.allTitlesSelected = this.summary.excludedTitles.length === 0;
          this.updateFilteredTitles();
        }
      })
    );
    this.tocUpdatedRef = this.loadSummaryPreviewWithTitles.bind(this);
    pubsub.on(TOC_UPDATED, this.tocUpdatedRef);
  }

  public ngAfterViewInit(): void {
    // Workaround for angular component issue #13870
    // https://github.com/angular/components/issues/13870
    this.disableAnimation = false;
    this.changeDetectorRef.markForCheck();
  }

  public ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
    pubsub.off(TOC_UPDATED, this.tocUpdatedRef);
  }

  public onSummaryTypeChange(): void {
    // Default selected levels
    if (this.summary.type === SummaryType.GENERAL) {
      this.summary.includedTitleLevels = [1, 2];
    } else {
      this.summary.includedTitleLevels = [2, 3];
    }
    this.updateFilteredTitles();
  }

  public updateFilteredTitles(): void {
    this.filteredTitles = this.computeFilteredTitles(this.documentTitles);
    this.changeDetectorRef.markForCheck();
  }

  public excludeTitle(title: IDisplayableTitle): void {
    const idx = this.summary.excludedTitles.indexOf(title.id);
    if (idx > -1) {
      // Remove from exceluded title
      this.summary.excludedTitles.splice(idx, 1);
    } else {
      // Add excluded title
      this.summary.excludedTitles.push(title.id);
    }
    this.allTitlesSelected = this.summary.excludedTitles.length === 0;
    this.changeDetectorRef.markForCheck();
  }

  public onClickInsertBtn(): void {
    if (this.summary?.id) {
      // Update summary and refresh it in the document
      this.summaryService.updateSummary(this.summary).subscribe((summary: ISummary) => {
        pubsub.fire(EDITOR_SUMMARY_REFRESH, { summary }, this.getEditorTopicContext());
        this.onClickClosePanel();
      });
    } else {
      // Create summary and insert it in the document
      this.summaryService.createSummary(this.summary).subscribe((summary: ISummary) => {
        pubsub.fire(EDITOR_SUMMARY_INSERT, { summary }, this.getEditorTopicContext());
        this.onClickClosePanel();
      });
    }
  }

  public onClickCancel(): void {
    this.summaryService.setSelectedSummary(null);
    this.onClickClosePanel();
    this.changeDetectorRef.markForCheck();
  }

  public selectAll(): void {
    this.summary.excludedTitles = [];
    if (!this.allTitlesSelected) {
      this.allTitlesSelected = true;
    } else {
      this.allTitlesSelected = false;
      this.summary.excludedTitles = this.filteredTitles.map(title => title.id);
    }
    this.changeDetectorRef.markForCheck();
  }

  private loadSummaryPreviewWithTitles(): void {
    this.summaryService.getSummaryPreviewWithTitles().subscribe(summary => {
      // Just take titles of this preview summary object (include 1..10 levels and no excluded)
      this.documentTitles = summary.titles ?? [];
      this.updateFilteredTitles();
    });
  }

  private computeFilteredTitles(titles: IDisplayableTitle[]): IDisplayableTitle[] {
    let filteredTitles: IDisplayableTitle[] = [];
    if (titles) {
      // Filter by type
      filteredTitles = this.summary.type === SummaryType.GENERAL ? [...titles] : this.getCurrentSectionTitles(titles);
      // Filter by levels
      filteredTitles = filteredTitles.filter(title => this.summary.includedTitleLevels.includes(title.level));
    }
    return filteredTitles;
  }

  private getCurrentSectionTitles(titles: IDisplayableTitle[]): IDisplayableTitle[] {
    // Need titles of current section
    const currentSection: IDocumentaryUnit | null = this.documentaryUnitService.currentSelectedDocumentaryUnit;
    let currentTitles: IDisplayableTitle[] = [];
    let currentT1: IDisplayableTitle | null = null;
    for (const title of titles) {
      if (title.level === 1) {
        if (currentT1 && currentT1.sectionId !== title.sectionId) {
          break;
        }
        currentTitles = [];
      }
      if (title.targetUuid === currentSection?.titleId) {
        currentT1 = title;
      }
      currentTitles.push(title);
    }
    return currentT1 ? currentTitles : [];
  }
}
