<div class="input-container"
(focusout)="checkValue()"
>
  <input matInput type="text" [(ngModel)]="inputValue" [placeholder]="placeholder" [disabled]="disabled" autocomplete="off"
        (input)="handleInput()" (blur)="onBlur($event)" (paste)="onPaste($event)" (keydown)="onKeydown($event)" />
  <div class="spinner" *ngIf="!disabled && focused">
    <button (click)="increase($event)" tabindex="-1">▲</button>
    <button (click)="decrease($event)" tabindex="-1">▼</button>
  </div>
</div>
