import { StyleCategory, StyleGroup } from '../enum/style-category.enum';
import { CKEditorViewElement } from 'app/shared/enum/ckeditor-model.enum';
import { StyleProperty } from '../enum/style-property.enum';
import { ModelAttribute } from 'editor/model/attribute.enum';
import { IColor } from './color.model';

export const SUMMARY_CATEGORIES = [StyleCategory.GENERAL_SUMMARY, StyleCategory.LOCAL_SUMMARY, StyleCategory.NOTE_SUMMARY];
export const FOOTNOTE_CATEGORIES = [StyleCategory.FOOTNOTE, StyleCategory.FOOTNOTE_PARAGRAPH, StyleCategory.FOOTNOTE_CHARACTER];
export const LIST_CATEGORIES = [StyleCategory.LIST_UL, StyleCategory.LIST_OL, StyleCategory.LIST_LI];

export enum STYLE_PROP_TYPE {
  COLOR = 'COLOR',
  FONT_FAMILIY = 'FONT_FAMILY',
  TEXT = 'TEXT',
}

export interface IStyleProperty {
  name: StyleProperty;
  type: STYLE_PROP_TYPE;
  data: any;
}

export interface IPseudoClass {
  name: string;
  classToApply: string;
  nameFr?: string;
  nameEn?: string;
  properties: IStyleProperty[];
  extend?: IPseudoClass;
}

export enum SUMMARY_TYPES_CLASSES {
  GENERAL = 'eolng_somg',
  CHAPTER = 'eolng_soms',
}

export interface IStyle {
  id?: number;
  name?: string;
  nameFr?: string;
  nameEn?: string;
  indesignFolder?: string;
  indesignName?: string;
  category: StyleCategory;
  element: CKEditorViewElement;
  applicable: boolean;
  deletable: boolean;
  extend?: IStyle;
  extendFlatProperties?: IStyleProperty[];
  properties?: IStyleProperty[];
  icon?: string;
  level?: number;
  levels?: string | null;
  styleGroup: StyleGroup;
  preview?: string;
  previewLabel?: string;
  childrenStyles?: IStyle[];
  order?: number;
  pseudoClasses?: IPseudoClass[];
  classToApply?: string;
  compliant?: boolean;
  isRefChild?: boolean;
  specificStyleProperties?: Object;
}

export interface ICssImportResponse {
  createdStylesCount: number;
  updatedStylesCount: number;
  createdColorsCount: number;
  nbColors: number;
  nbStyles: number;
}

export const convertLevelsStringToArray = (levels: string): number[] => {
  if (!levels) {
    return [];
  }
  return levels?.split(',').map((level: string) => +level) ?? [];
};
export const convertLevelsArrayToString = (levels: number[]): string => levels?.join(',') ?? '';

export enum SpecificMap {
  TEXT_ALIGN = ModelAttribute.ALIGNMENT,
  COLUMN_SPAN = ModelAttribute.COLUMN_SPREAD,
  COLUMN_BREAK = ModelAttribute.COLUMN_BREAK,
  TABLE_BREAK = ModelAttribute.TABLE_BREAK,
  COUNTER_RESET = ModelAttribute.COUNTER_RESET,
}

export interface IMatrixLine {
  id?: number;
  projectId?: number;
  category?: StyleCategory;
  style?: IStyle;
  level?: number;
  cells?: IMatrixCell[];
  label?: string;
  isLastAdded?: boolean;
}

export interface IMatrixCell {
  id?: number;
  chapterNumber?: string;
  colors?: IMatrixCellColor[];
  matrixLineId?: number;
}

export enum ISubExportType {
  DOCX = 'DOCX',
  XHTML = 'XHTML',
  SIGNAL = 'SIGNAL',
  EOL_PAGE = 'EOL_PAGE',
}

export interface IMatrixCellColor {
  id?: number;
  subElement?: MatrixCellColorSubElement;
  color?: IColor;
  matrixCellId?: number;
  colorProperty?: MatrixCellColorProperty;
  isSubstitute: boolean;
  exportType?: ISubExportType;
  exportTypes?: ISubExportType[];
  show?: boolean;
}

export enum MatrixCellColorSubElement {
  NUMBERING = 'numbering',
  TEXT_CONTENT = 'text-content',
  BEFORE = 'before',
  FOLIO = 'folio',
}

export enum MatrixCellColorProperty {
  COLOR = 'color',
  BORDER_COLOR = 'border-color',
  BACKGROUND_COLOR = 'background-color',
  COLUMN_RULE_COLOR = 'column-rule-color',
}

export enum PropertiesToFormEnum {
  'color' = 'color',
  'border-color' = 'borderColor',
  'background-color' = 'backgroundColor',
  'column-rule-color' = 'columnRuleColor',
  'before' = 'bulletColor',
  'numbering' = 'numberingColor',
  'text-content' = 'textContentColor',
  'folio' = 'folioColor',
}
