<div class="xbrl-data-tagger" [ngSwitch]="step">
  <div class="selection-choice" *ngSwitchCase="TaggingStep.CHOICE">
    <button
      mat-raised-button
      (click)="updateStep(TaggingStep.CONCEPT)"
      [class.completed]="!!fact?.concept"
      [disabled]="!!fact.duplicateInfo || disabled"
    >
      <ng-container *ngIf="fact?.concept; else noConcept">
        <mat-icon svgIcon="tag-large-dot" color="primary"></mat-icon>
        {{ fact?.concept.qname }}
      </ng-container>
      <ng-template #noConcept>
        <mat-icon svgIcon="tag-search" color="primary"></mat-icon>
        <span jhiTranslate="dataEditor.tagging.select.concept"></span>
      </ng-template>
    </button>
    <button
      mat-raised-button
      (click)="updateStep(TaggingStep.DIMENSION)"
      [disabled]="!fact?.concept || dimensions?.length === 0 || !!fact.duplicateInfo || disabled"
      [class.completed]="fact?.context?.dimensions?.length > 0 && !!fact?.context?.dimensions[0]?.dimAxis?.qname"
    >
      <ng-container *ngIf="fact?.context?.dimensions?.length > 0 && fact?.context.dimensions[0]?.dimAxis?.qname; else noAxis">
        <mat-icon svgIcon="tag-large-dot" color="primary"></mat-icon>
        <span>{{ fact.context?.dimensions[0].dimAxis.qname }}</span>
      </ng-container>
      <ng-template #noAxis>
        <mat-icon svgIcon="tag-search" color="primary"></mat-icon>
        <span jhiTranslate="dataEditor.tagging.select.dimension"></span>
      </ng-template>
    </button>
    <button
      mat-raised-button
      class="shifted"
      (click)="updateStep(TaggingStep.MEMBER)"
      [disabled]="!!fact.duplicateInfo || disabled"
      *ngIf="fact?.context?.dimensions?.length > 0 && fact?.context?.dimensions[0]?.dimAxis?.qname"
      [class.completed]="!!fact?.context?.dimensions[0]?.dimMember?.qname"
    >
      <ng-container *ngIf="fact?.context?.dimensions[0]?.dimMember?.qname; else noMember">
        <mat-icon svgIcon="tag-large-dot" color="primary"></mat-icon>
        {{ fact.context?.dimensions[0].dimMember.qname }}
      </ng-container>
      <ng-template #noMember>
        <mat-icon svgIcon="tag-search" color="primary"></mat-icon>
        <span jhiTranslate="dataEditor.tagging.select.member"></span>
      </ng-template>
    </button>
    <button
      mat-raised-button
      (click)="updateStep(TaggingStep.TIME)"
      [class.completed]="fact?.context?.period?.periodType"
      [disabled]="!fact?.concept || !!fact.duplicateInfo || disabled"
    >
      <ng-container *ngIf="fact?.context?.period?.periodType; else noTime">
        <span>
          <mat-icon svgIcon="tag-large-dot" color="primary"></mat-icon>
          {{ fact.context?.period.startDate | localizedDate: 'shortDate' }}
          <span *ngIf="fact.context?.period.periodType === PeriodType.PERIOD">
            - {{ fact.context?.period.endDate | localizedDate: 'shortDate' }}</span
          >
          {{ fact.context?.period.periodName | dateLabel }}
        </span>
      </ng-container>
      <ng-template #noTime>
        <mat-icon svgIcon="tag-search" color="primary"></mat-icon>
        <ng-container *ngIf="fact?.concept; else noConceptTime">
          <ng-container [ngSwitch]="fact?.concept.periodType">
            <span jhiTranslate="dataEditor.tagging.select.timePeriod" *ngSwitchCase="PeriodType.PERIOD"></span>
            <span jhiTranslate="dataEditor.tagging.select.timeInstant" *ngSwitchCase="PeriodType.INSTANT"></span>
            <span jhiTranslate="dataEditor.tagging.select.time" *ngSwitchDefault></span>
          </ng-container>
        </ng-container>
        <ng-template #noConceptTime>
          <span jhiTranslate="dataEditor.tagging.select.time"></span>
        </ng-template>
      </ng-template>
    </button>

    <ng-container *ngIf="fact?.concept">
      <button mat-raised-button class="completed" [disabled]="true">
        <mat-icon svgIcon="tag-large-dot" color="primary"></mat-icon>
        {{ fact.concept?.type }}
      </button>
      <button
        mat-raised-button
        *ngIf="fact?.concept?.type === 'xbrli:monetaryItemType'"
        [matMenuTriggerFor]="currencyMenu"
        [class.completed]="!!fact?.oimUnit"
        [disabled]="currencies?.length < 2 || !!fact.duplicateInfo"
      >
        <ng-container *ngIf="fact?.oimUnit; else noCurrency">
          <mat-icon svgIcon="tag-large-dot" color="primary"></mat-icon>
          {{ fact.oimUnit }}
        </ng-container>
        <ng-template #noCurrency>
          <mat-icon svgIcon="fleche-down"></mat-icon>
          <span jhiTranslate="dataEditor.tagging.select.unit"></span>
        </ng-template>
      </button>
      <mat-menu #currencyMenu="matMenu">
        <button *ngFor="let currency of currencies" (click)="onCurrencySelected(currency)" mat-menu-item>{{ currency }}</button>
      </mat-menu>

      <button
        mat-raised-button
        *ngIf="fact?.concept?.type === 'num:perShareItemType' || fact?.concept?.type === 'dtr-types:perShareItemType'"
        [disabled]="!!fact.duplicateInfo"
        [matMenuTriggerFor]="perShareMenu"
        [class.completed]="!!fact?.oimUnit"
      >
        <ng-container *ngIf="fact?.oimUnit; else noPerShare">
          <mat-icon svgIcon="tag-large-dot" color="primary"></mat-icon>
          {{ fact.oimUnit }}
        </ng-container>
        <ng-template #noPerShare>
          <mat-icon svgIcon="fleche-down"></mat-icon>
          <span jhiTranslate="dataEditor.tagging.select.unit"></span>
        </ng-template>
      </button>
      <mat-menu #perShareMenu="matMenu">
        <button *ngFor="let perShare of perShareCurrencies" (click)="onCurrencySelected(perShare)" mat-menu-item>{{ perShare }}</button>
      </mat-menu>

      <button
        mat-raised-button
        *ngIf="fact?.concept?.type === 'num:sharesItemType'"
        [disabled]="!!fact.duplicateInfo"
        [matMenuTriggerFor]="shareMenu"
        [class.completed]="!!fact?.oimUnit"
      >
        <ng-container *ngIf="fact?.oimUnit; else noShare">
          <mat-icon svgIcon="tag-large-dot" color="primary"></mat-icon>
          {{ fact.oimUnit }}
        </ng-container>
        <ng-template #noShare>
          <mat-icon svgIcon="fleche-down"></mat-icon>
          <span jhiTranslate="dataEditor.tagging.select.unit"></span>
        </ng-template>
      </button>
      <mat-menu #shareMenu="matMenu">
        <button *ngFor="let share of shareCurrencies" (click)="onCurrencySelected(share)" mat-menu-item>{{ share }}</button>
      </mat-menu>

      <mat-form-field class="language" *ngIf="showLang && fact?.concept?.type === 'xbrli:stringItemType'">
        <mat-label jhiTranslate="dataEditor.tagging.select.language"></mat-label>
        <mat-select [(ngModel)]="selectedLanguage" (selectionChange)="onLanguagesUpdate()" [disabled]="!!fact.duplicateInfo">
          <mat-option *ngFor="let language of languages" [value]="language.code">{{ language.value }}</mat-option>
        </mat-select>
      </mat-form-field>
    </ng-container>
  </div>

  <div class="item-list" *ngSwitchCase="TaggingStep.CONCEPT">
    <div class="tagging-header">
      <button mat-icon-button class="header-close" (click)="updateStep(TaggingStep.CHOICE)">
        <mat-icon svgIcon="chevron-left"></mat-icon>
      </button>
      <div class="tagging-title" jhiTranslate="dataEditor.tagging.select.concept"></div>
    </div>
    <div class="tagging-content">
      <jhi-concept-browser
        [treeView]="false"
        (selected)="onConceptSelected($event)"
        [displayAbstract]="false"
        [onlyMappableConcepts]="true"
        [filteredType]="filteredType"
      >
      </jhi-concept-browser>
    </div>

    <div class="action-buttons">
      <button mat-raised-button color="primary" [disabled]="selectButtonDisabled" (click)="selectConcept()">
        <span jhiTranslate="dataEditor.taxonomy.conceptBrowser.actions.select"></span>
      </button>
    </div>
    <div
      *ngIf="isConceptDisabled && editorialTextFactDisabled"
      class="warning-editorial-text-fact"
      jhiTranslate="dataEditor.taxonomy.conceptBrowser.warningEditorialTextFact"
    ></div>
    <div
      *ngIf="isConceptDisabled && !editorialTextFactDisabled"
      class="warning-editorial-text-fact"
      jhiTranslate="dataEditor.taxonomy.conceptBrowser.warningTextFact"
    ></div>
  </div>

  <div class="item-list" *ngSwitchCase="TaggingStep.DIMENSION">
    <div class="tagging-header">
      <button mat-icon-button class="header-close" (click)="updateStep(TaggingStep.CHOICE)">
        <mat-icon svgIcon="chevron-left"></mat-icon>
      </button>
      <div class="tagging-title" jhiTranslate="dataEditor.tagging.select.dimension"></div>
    </div>
    <div class="tagging-content">
      <jhi-dimension-list [dimensions]="axisItems" (selected)="onDimensionSelected($event)"></jhi-dimension-list>
    </div>
  </div>

  <div class="item-list" *ngSwitchCase="TaggingStep.MEMBER">
    <div class="tagging-header">
      <button mat-icon-button class="header-close" (click)="updateStep(TaggingStep.CHOICE)">
        <mat-icon svgIcon="chevron-left"></mat-icon>
      </button>
      <div class="tagging-title" jhiTranslate="dataEditor.tagging.select.member"></div>
    </div>
    <div class="tagging-content">
      <jhi-dimension-list [dimensions]="memberItems" (selected)="onMemberSelected($event)"></jhi-dimension-list>
    </div>
  </div>

  <div class="item-list" *ngSwitchCase="TaggingStep.TIME">
    <div class="tagging-header">
      <button mat-icon-button class="header-close" (click)="updateStep(TaggingStep.CHOICE)">
        <mat-icon svgIcon="chevron-left"></mat-icon>
      </button>
      <div class="tagging-title" jhiTranslate="dataEditor.tagging.select.timePeriod" *ngSwitchCase="PeriodType.PERIOD"></div>
      <div class="tagging-title" jhiTranslate="dataEditor.tagging.select.timeInstant" *ngSwitchCase="PeriodType.INSTANT"></div>
      <div class="tagging-title" jhiTranslate="dataEditor.tagging.select.time" *ngSwitchDefault></div>
    </div>
    <div class="tagging-content">
      <jhi-time-list [periodType]="fact?.concept?.periodType" (selected)="onTimeSelected($event)"></jhi-time-list>
    </div>
  </div>
</div>
