<div class="taxonomy-browser-table">
  <jhi-node-expand [label]="table | conceptTranslatedLabel" [expanded]="expanded" *ngIf="treeControl.dataNodes.length > 0">
    <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
      <mat-tree-node *matTreeNodeDef="let node">
        <div class="mat-tree-node" (click)="selection(node, table)" [ngClass]="{'selected': conceptIdSelected === node.pnodeId }">
          <button mat-icon-button disabled></button>
          <div *ngIf="!node.highlight;else highlight" [title]="node | conceptTranslatedLabel">{{node | conceptTranslatedLabel}}</div>
          <ng-template #highlight>
            <div [innerHTML]="node.highlight | sanitizeHtml" [title]="node.highlight | stripHtml"></div>
          </ng-template>
        </div>
      </mat-tree-node>

      <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">
        <div class="mat-tree-node" [ngClass]="{'selected': conceptIdSelected === node.pnodeId }">
          <button mat-icon-button matTreeNodeToggle>
            <mat-icon svgIcon="{{treeControl.isExpanded(node) ? 'fleche-down' : 'fleche-right'}}"></mat-icon>
          </button>
          <div *ngIf="!node.highlight;else highlight2" (click)="selection(node, table)">{{node | conceptTranslatedLabel}}</div>
          <ng-template #highlight2>
            <div [innerHTML]="node.highlight | sanitizeHtml" (click)="selection(node, table)"></div>
          </ng-template>
        </div>
        <div [class.node-invisible]="!treeControl.isExpanded(node)">
          <ng-container matTreeNodeOutlet></ng-container>
        </div>
      </mat-nested-tree-node>
    </mat-tree>
  </jhi-node-expand>
</div>
