export class CommentFilterModel {
  public displayComment: boolean;
  public displaySuggestion: boolean;
  public periodDateStart?: number;
  public periodDateEnd?: number;
  public users?: string[];
  public order?: CommentFilterOrder;
  public orderBy?: CommentFilterOrderBy;

  public formPeriodDateStart?: string;
  public formPeriodDateEnd?: string;
  public formPeriodHourStart?: string;
  public formPeriodHourEnd?: string;
}

export enum CommentFilterOrderBy {
  POSITION = 'position',
  USER_NAME = 'user_name',
  DATE = 'date',
  LAST_UPDATE = 'last_update',
}

export enum CommentFilterOrder {
  ASC = 'asc',
  DESC = 'desc',
}
