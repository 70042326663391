import { SepXbrlMultiPart } from 'app/shared/enum/sep-xbrl-multi-part.enum';
import Big from 'big.js';

import { IFact } from 'app/shared/model/fact.model';
import { IXBRLTags } from 'app/shared/enum/dynamic-data.enum';
import { CKEditorModel } from 'app/shared/enum/ckeditor-model.enum';
import { ISheetSelection } from './sheet-selection.model';
import { BalanceType } from 'app/shared/enum/balance-type.enum';
import { XbrlFormat, NumberFormat, Format } from './format.model';
import { IDynamicData, RCSFCell } from './rcsf.model';
import { VALUE_TYPE } from 'app/shared/enum/xslx.enum';
import { DateFormat } from 'app/shared/model/format.model';
import { pgdpFormatNumber } from 'app/shared/util/format-number-utils';
import { formatDate } from './../util/format-date.utils';
import { IMonetaryUnit } from './monetary-unit.model';

const formatNumber = (
  value: Big,
  scale: number,
  balance: boolean,
  format: NumberFormat,
  defaultGroupingSeparator: string,
  defaultDecimalsSeparator: string,
  language: string,
  unit: string
): string => {
  if (scale && !isNaN(scale)) {
    const ten = new Big(10);
    value = value.mul(ten.pow(Math.round(scale)));
  }
  if (balance) {
    value.s = value.s * -1;
  }
  let shape = format?.shapes.find(sh => sh.languages.includes(language));
  if (shape === undefined) {
    shape = format.shapes[0];
  }
  return pgdpFormatNumber(value, shape, defaultGroupingSeparator, defaultDecimalsSeparator, unit, format.noBreakSpace);
};

export interface ITransformOptions {
  defaultGroupingSeparator?: string;
  defaultDecimalsSeparator?: string;
  language?: string;
}

export abstract class IXBRLTag {
  balance?: BalanceType;
  format: Format;
  scale?: number;
  factXbrlId: string;
  value: string;
  xhtmlValue?: string;
  selection?: ISheetSelection;
  tagName: IXBRLTags;
  ckModel: CKEditorModel;
  factIndex?: number;

  abstract transform(options: ITransformOptions): string;
}

export class NonNumeric implements IXBRLTag {
  format: Format;
  value: string;
  factXbrlId: string;
  factIndex: number;
  xhtmlValue?: string;
  selection?: ISheetSelection;
  ckModel: CKEditorModel;
  tagName = IXBRLTags.NON_NUMERIC;

  constructor({ factValue, factXbrlId, factIndex }: IFact, { format }: XbrlFormat) {
    this.factIndex = factIndex as number;
    this.factXbrlId = factXbrlId as string;
    this.format = format as Format;
    this.ckModel = CKEditorModel.TEXT_DATA;

    const partValue = factValue?.split(SepXbrlMultiPart.DATA);
    if ((partValue?.length ?? 0) > 1) {
      this.factIndex = typeof factIndex === 'number' && factIndex >= 0 ? factIndex : 0;
      this.value = partValue?.[this.factIndex] ?? '';
    } else {
      this.value = factValue as string;
    }
  }

  transform({ language }: ITransformOptions): string {
    if (this.format) {
      return this.value === null || this.value === undefined
        ? formatDate(new Date(), this.format as DateFormat, language as string)
        : formatDate(this.value, this.format as DateFormat, language as string);
    }
    return this.value;
  }
}

export class NonFraction implements IXBRLTag {
  scale: number;
  format: NumberFormat;
  balance: BalanceType;
  value: string;
  factBalance: BalanceType;
  factXbrlId: string;
  xhtmlValue?: string;
  selection?: ISheetSelection;
  ckModel: CKEditorModel;
  tagName = IXBRLTags.NON_FRACTION;
  unit: string;
  projectUnits: IMonetaryUnit[];

  constructor({ factValue, factXbrlId, concept }: IFact, unit: string, { scale, balance, format }: XbrlFormat) {
    this.value = factValue as string;
    this.factXbrlId = factXbrlId as string;
    this.format = format as NumberFormat;
    this.scale = Math.round(scale as number);
    this.balance = balance ?? BalanceType.CREDIT;
    this.factBalance = concept?.balance ?? BalanceType.CREDIT;
    this.unit = unit;
    this.ckModel = CKEditorModel.NUMERIC_DATA;
  }

  // TODO remove the === null test when we have a spec for invalid
  transform({ defaultGroupingSeparator, defaultDecimalsSeparator, language }: ITransformOptions): string {
    return this.value === null || this.value === undefined
      ? ''
      : formatNumber(
          new Big(this.value),
          this.scale,
          this.balance !== this.factBalance,
          this.format,
          defaultGroupingSeparator as string,
          defaultDecimalsSeparator as string,
          language as string,
          this.unit
        );
  }
}

export class DynamicDataTag implements IXBRLTag {
  balance?: BalanceType;
  format: Format;
  scale?: number;
  factXbrlId = '';
  value = '';
  selection?: ISheetSelection;
  tagName = IXBRLTags.DD_TAG;
  filename: string;
  sheetName: string;
  row: number;
  ckModel: CKEditorModel;
  cell: RCSFCell;

  constructor({ filename, sheetName, row, cell }: IDynamicData, { scale, balance, format }: XbrlFormat) {
    this.format = format as Format;
    this.scale = scale;
    this.balance = balance ?? BalanceType.CREDIT;
    this.filename = filename;
    this.row = row;
    this.sheetName = sheetName;
    this.cell = cell;
    this.ckModel = CKEditorModel.DYNAMIC_DATA;
  }

  transform({ defaultGroupingSeparator, defaultDecimalsSeparator, language }: ITransformOptions): string {
    const cellScale = this.cell?.mappingScale ?? this.cell?.importScale ?? 0;
    let cellBalance = this.cell?.mappingBalance ?? this.cell?.balance ?? BalanceType.CREDIT;
    cellBalance = cellBalance.toLowerCase() as BalanceType;
    switch (this.cell?.valueType) {
      case VALUE_TYPE.DATE:
        return formatDate(this.cell.dateValue as string, this.format as DateFormat, language as string);
      case VALUE_TYPE.NUMERIC:
        return formatNumber(
          new Big(this.cell.numericValue as number),
          cellScale + (this.scale ?? 0),
          this.balance !== cellBalance,
          this.format as NumberFormat,
          defaultGroupingSeparator as string,
          defaultDecimalsSeparator as string,
          language as string,
          ''
        );
      default:
        return this.cell?.formattedValue ?? '';
    }
  }
}
