import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';

import { SERVER_API_URL } from 'app/app.constants';

import pubsub from 'app/pubsub';
import {
  EDITOR_ASK_SUMMARY_WITH_TITLES,
  EDITOR_GOT_SUMMARY_WITH_TITLES,
  EDITOR_SUMMARY_MODIFY,
  EDITOR_SUMMARY_SELECT,
  EDITOR_SUMMARY_PASTE,
  EDITOR_SUMMARY_COPY,
} from 'app/pubsub.topics';

import { ISummary } from 'app/shared/model/summary.model';
import { PluginPanelService } from 'app/html-editor/plugins/plugin-panel.service';
import { SummaryPanelComponent } from 'app/html-editor/plugins/summary/summary-panel.component';
import { AccountService } from '../auth/account.service';
import { ContextService } from './context.service';
import { PLUGIN_PANEL_COMPONENT_KEYS } from 'app/html-editor/plugins/plugin-panel-component-keys';
import { DocumentaryUnitService } from './documentary-unit.service';

@Injectable({ providedIn: 'root' })
export class SummaryService {
  public summaryResourceUrl: string;

  private selectedSummary: Subject<ISummary | null> = new Subject();

  constructor(
    protected http: HttpClient,
    private router: Router,
    private contextService: ContextService,
    private pluginPanelService: PluginPanelService,
    private accountService: AccountService,
    protected documentaryUnitService: DocumentaryUnitService
  ) {
    this.register();
    this.summaryResourceUrl = `${SERVER_API_URL}api/documents/${this.contextService.currentDocumentContext.id}/summaries/`;
  }

  register(): void {
    pubsub.on(EDITOR_ASK_SUMMARY_WITH_TITLES, ({ detail }: CustomEvent) => {
      this.getSummaryWithTitles(detail.id).subscribe((summary: ISummary) => {
        // Give access to title navigation if user has write/read access
        const summaryTitles = summary?.titles ?? [];
        for (const title of summaryTitles) {
          title.allowToNavigate = this.accountService.hasAnyAuthority([`UD_${title.sectionId}_READ`, `UD_${title.sectionId}_WRITE`]);
        }

        pubsub.fire(`${EDITOR_GOT_SUMMARY_WITH_TITLES}/${detail.id}`, { summary }, detail.editorId);
      });
    });

    pubsub.on(EDITOR_SUMMARY_MODIFY, ({ detail }: CustomEvent) => {
      this.pluginPanelService.togglePluginPanel(
        SummaryPanelComponent,
        'htmlEditor.plugins.summary.title',
        PLUGIN_PANEL_COMPONENT_KEYS.SUMMARY
      );
      this.getSummary(detail.id).subscribe(summary => {
        this.setSelectedSummary(summary);
      });
    });

    pubsub.on(EDITOR_SUMMARY_SELECT, ({ detail }: CustomEvent) => {
      this.getSummary(detail.id).subscribe(summary => {
        this.setSelectedSummary(summary);
      });
    });

    pubsub.on(EDITOR_SUMMARY_COPY, ({ detail }: CustomEvent) => {
      this.getSummary(detail.id).subscribe(summary => {
        summary.id = null;
        summary.currentDocumentaryUnitId = this.documentaryUnitService.currentSelectedDocumentaryUnit?.objectId;

        this.createSummary(summary).subscribe((summaryToInsert: ISummary) => {
          pubsub.fire(EDITOR_SUMMARY_PASTE, { summary: detail.summary, newId: summaryToInsert.id }, detail.editorId);
        });
      });
    });
  }

  getSummaryPreviewWithTitles(): Observable<ISummary> {
    return this.http.get<ISummary>(`${this.summaryResourceUrl}preview`);
  }

  getSelectedSummary(): Observable<ISummary | null> {
    return this.selectedSummary.asObservable();
  }

  setSelectedSummary(summary: ISummary | null): void {
    this.selectedSummary.next(summary);
  }

  getSummary(id: number): Observable<ISummary> {
    return this.http.get<ISummary>(`${this.summaryResourceUrl}${id}`);
  }

  getSummaryWithTitles(id: number): Observable<ISummary> {
    return this.http.get<ISummary>(`${this.summaryResourceUrl}${id}/titles`);
  }

  createSummary(summary: ISummary): Observable<ISummary> {
    return this.http.post<ISummary>(`${this.summaryResourceUrl}`, summary);
  }

  updateSummary(summary: ISummary): Observable<ISummary> {
    return this.http.put<ISummary>(`${this.summaryResourceUrl}`, summary);
  }
}
