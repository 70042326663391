import { Pipe, PipeTransform } from '@angular/core';
import { IXBRLTag, NonFraction, NonNumeric, DynamicDataTag } from '../model/ixbrl.model';
import { IFact } from 'app/shared/model/fact.model';
import { XbrlFormat } from '../model/format.model';
import { XBRLType } from '../enum/xbrl-type.enum';
import { IDynamicData } from '../model/rcsf.model';
import { IMonetaryUnit } from '../model/monetary-unit.model';
import { getFormattedUnit } from './unit.utils';
import { CKEditorDynamicDataModels } from '../enum/ckeditor-model.enum';
import { BalanceType } from '../enum/balance-type.enum';
import { IXBRLTags } from 'app/shared/enum/dynamic-data.enum';
import { v4 as uuidv4 } from 'uuid';

export default class DynamicDataFormatter {
  static dataFactory(fact: IFact | IDynamicData, format: XbrlFormat, projectUnits: IMonetaryUnit[] = []): IXBRLTag {
    const xbrlFact = fact as IFact;
    const dynData = fact as IDynamicData;
    if (xbrlFact?.factXbrlId ?? dynData?.cell?.fact?.factXbrlId) {
      // XBRL fact
      let mXbrlFact: IFact;
      if (dynData?.cell?.fact?.factXbrlId) {
        mXbrlFact = dynData.cell.fact;
      } else {
        mXbrlFact = xbrlFact;
      }
      switch (mXbrlFact.concept?.type) {
        case XBRLType.MONETARY:
        case XBRLType.PER_SHARE:
        case XBRLType.PER_SHARE_2021:
        case XBRLType.SHARES:
        case XBRLType.DECIMAL:
          return new NonFraction(mXbrlFact, getFormattedUnit(mXbrlFact.oimUnit as string, projectUnits), format);
        default:
          return new NonNumeric(mXbrlFact, format);
      }
    } else {
      // Dynamic Data
      return new DynamicDataTag(dynData, format);
    }
  }

  static transform(
    fact: IFact | IDynamicData,
    format: XbrlFormat,
    defaultGroupingSeparator: string,
    defaultDecimalsSeparator: string,
    language: string,
    projectUnits: IMonetaryUnit[] = []
  ): string {
    // typer la donnée dynamique : Factory
    // executer la transformation adéquate : Strategy
    return this.dataFactory(fact, format, projectUnits).transform({
      defaultGroupingSeparator,
      defaultDecimalsSeparator,
      language,
    });
  }
}

@Pipe({
  name: 'dynamicData',
  pure: true,
})
export class DynamicDataPipe implements PipeTransform {
  transform(fact: IFact | IDynamicData, format: XbrlFormat, projectUnits: IMonetaryUnit[] = []): string {
    return DynamicDataFormatter.transform(
      fact,
      format,
      format.defaultGroupingSeparator as string,
      format.defaultDecimalsSeparator as string,
      format.language as string,
      projectUnits
    );
  }
}

/**
 * Check if the element is a dynamic data
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const isDynamicData = (element: any): boolean => {
  const elementName = element?.name;
  return CKEditorDynamicDataModels.includes(elementName);
};

// Return the formatted value for the RCSF cell / XBRL fact
export function _getFormattedValueForFact(detail: any, fact: any, scale: number, balance: BalanceType, language: string): string {
  return DynamicDataFormatter.transform(
    fact,
    {
      balance,
      scale: scale ?? detail.defaultPublicationScale,
      format: detail.format,
    },
    detail.defaultGroupingSeparator,
    detail.defaultDecimalsSeparator,
    language,
    detail.projectUnits
  );
}

export const CustomAttributes = {
  FACT_XBRL_ID: 'custom-factxbrlid',
  IS_MULTIPLE_FACT: 'custom-is-multiple',
  FACT_INDEX: 'custom-fact-index',
  BALANCE: 'custom-balance',
  VALUE: 'custom-value',
  SCALE: 'scale', // Use during the export
  FORMAT_ID: 'custom-formatid',
  FILENAME: 'custom-filename',
  SHEETNAME: 'custom-sheetname',
  COLNAME: 'custom-colname',
  ROW: 'custom-row',
  UUID: 'uuid',
  ANNOTATED_DATA: 'custom-annotated-data',
};

export function getValueToShow(value: string, fallback: string): string {
  let valueToShow = fallback;
  // only add the fallback if the value is empty (otherwise it won't be displayed)
  // having or not an attached fact does not have to be taken into account
  if (value) {
    valueToShow = value;
  }

  return valueToShow;
}

// Todo : Refactor this part, we don't need that much attributes for the upcast
export function _createDynamicDataContainerElement(
  editor: any,
  modelElement: any,
  downcastWriter: any,
  tag: IXBRLTags,
  tagClass: string,
  forDowncast = false
): any {
  const formatId = modelElement.getAttribute(CustomAttributes.FORMAT_ID);
  const balance = modelElement.getAttribute(CustomAttributes.BALANCE) as BalanceType;
  const scale = parseInt(modelElement.getAttribute(CustomAttributes.SCALE), 10);
  const filename = modelElement.getAttribute(CustomAttributes.FILENAME);
  const sheetName = modelElement.getAttribute(CustomAttributes.SHEETNAME);
  const row = parseInt(modelElement.getAttribute(CustomAttributes.ROW), 10);
  const colName = modelElement.getAttribute(CustomAttributes.COLNAME);
  const value = modelElement.getAttribute(CustomAttributes.VALUE);
  const factXbrlId = modelElement.getAttribute(CustomAttributes.FACT_XBRL_ID);
  const isMultipleFact = modelElement.getAttribute(CustomAttributes.IS_MULTIPLE_FACT);
  const factIndex = modelElement.getAttribute(CustomAttributes.FACT_INDEX);

  let uuid: string = modelElement.getAttribute(CustomAttributes.UUID);
  if (!modelElement.hasAttribute(CustomAttributes.UUID)) {
    uuid = uuidv4();
    // Apply also new uuid on modelElement
    editor.model.enqueueChange({ isUndoable: false }, (writer: any) => {
      writer.setAttribute(CustomAttributes.UUID, uuid, modelElement);
    });
  }

  const attributes = {
    class: `simple-dynamic-data ${tagClass}${formatId ? ' formatted-value' : ''}`,
    [CustomAttributes.VALUE]: value,
    id: uuid,
  };

  if (factXbrlId) {
    attributes[CustomAttributes.FACT_XBRL_ID] = factXbrlId;
  }

  if (isMultipleFact) {
    attributes[CustomAttributes.IS_MULTIPLE_FACT] = isMultipleFact;
  }

  if (factIndex) {
    attributes[CustomAttributes.FACT_INDEX] = factIndex;
  }

  if (forDowncast) {
    attributes[CustomAttributes.FORMAT_ID] = formatId || null;
    attributes[CustomAttributes.BALANCE] = balance || null;
    attributes[CustomAttributes.SCALE] = Number.isNaN(scale) ? null : scale;

    if (filename?.length) {
      attributes[CustomAttributes.FILENAME] = filename;
    }
    if (sheetName?.length) {
      attributes[CustomAttributes.SHEETNAME] = sheetName;
    }
    if (Number.isInteger(row)) {
      attributes[CustomAttributes.ROW] = row;
    }
    if (colName?.length) {
      attributes[CustomAttributes.COLNAME] = colName;
    }
  }

  // in downcast the inner value is '' for empty facts
  const valueToShow = getValueToShow(value, forDowncast ? '' : editor.t('incoming'));
  return downcastWriter.createContainerElement(tag, attributes, [downcastWriter.createText(valueToShow)]);
}
