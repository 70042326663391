<div class="data-history-container">
  <div class="data-history-header">
    <button mat-icon-button class="header-close" (click)="close()">
      <mat-icon svgIcon="chevron-right"></mat-icon>
    </button>
    <div class="plugin-title" jhiTranslate="dataEditor.history.panelTitle"></div>
  </div>
  <div class="data-history-content" *ngIf="historyEntries?.length > 0">
    <mat-accordion multi class="standard">
      <mat-expansion-panel hideToggle *ngFor="let dayEntries of historyEntries; first as isFirstDay" [expanded]="isFirstDay">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <ng-container *ngIf="isToday(dayEntries[0].updatedAt); else notToday">
              <span jhiTranslate="dataEditor.history.panelToday"></span>
            </ng-container>
            <ng-template #notToday>
              <span>{{ dayEntries[0].updatedAt | localizedDate: 'longDate' }}</span>
            </ng-template>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div *ngFor="let entry of dayEntries; first as isFirstEntry" class="entry">
          <div class="time" *ngIf="isFirstDay && isFirstEntry">{{ 'htmlEditor.plugins.versions.actual' | translate }}</div>
          <div>
            <label jhiTranslate="dataEditor.history.updatedAt"></label>
            <div class="value">{{ entry.updatedAt | localizedDate: 'medium' }}</div>
          </div>
          <div>
            <label jhiTranslate="dataEditor.history.author"></label>
            <div class="value">{{ entry.updatedBy }}</div>
          </div>
          <div>
            <label jhiTranslate="dataEditor.history.modificationType"></label>
            <div class="value">{{ 'dataEditor.history.modificationTypeValue.' + entry.modificationType | translate }}</div>
          </div>
          <ng-container *ngIf="entry.modificationType !== ModificationType.DELETION">
            <div>
              <label jhiTranslate="dataEditor.history.value"></label>
              <div class="value">{{ entry.value }}</div>
            </div>
            <div *ngIf="entry.cellType">
              <label jhiTranslate="dataEditor.history.cellType"></label>
              <div class="value">{{ 'dataEditor.history.cellTypeValue.' + entry.cellType | translate }}</div>
            </div>
            <div *ngIf="entry.cellType === ValueType.NUMERIC && entry.balance">
              <label jhiTranslate="dataEditor.history.balance"></label>
              <div class="value">{{ 'dataEditor.history.balanceValue.' + entry.balance | translate }}</div>
            </div>
            <div *ngIf="entry.cellType === ValueType.NUMERIC">
              <label jhiTranslate="dataEditor.history.scale"></label>
              <div class="value">{{ entry.scale }}</div>
            </div>
            <div *ngIf="entry.updatedByPropagation" class="property-legend" jhiTranslate="dataEditor.history.updatedByPropagation"></div>
          </ng-container>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
  <div class="data-history-content" *ngIf="historyEntries?.length === 0">
    <div class="data-history-empty">
      {{ (isFactHistory ? 'dataEditor.history.emptyFact' : 'dataEditor.history.empty') | translate }}
    </div>
  </div>
</div>
