import { Component, OnInit } from '@angular/core';

import { AccountService } from 'app/core/auth/account.service';

@Component({
  selector: 'jhi-redirect',
  template: ' ',
})
export class RedirectComponent implements OnInit {
  constructor(private accountService: AccountService) {}

  ngOnInit(): void {
    this.accountService.navigateToStoredUrl(true);
  }
}
