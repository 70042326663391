import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, Input, Output, EventEmitter } from '@angular/core';
import { DocumentaryUnitService, ITocConfig } from 'app/core/service/documentary-unit.service';
import { ContentType } from 'app/shared/enum/content-type.enum';
import { IDocumentaryUnit, ITitle } from 'app/shared/model/documentary-unit.model';

@Component({
  selector: 'jhi-titles-level-selection',
  templateUrl: 'titles-level-selection.component.html',
  styleUrls: ['titles-level-selection.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TitlesLevelSelectionComponent implements OnInit {
  public titleLevels = Array.from(Array(10), (_, index) => index + 1); // Ten title levels

  public documentTitles: ITitle[];
  public filteredTitles: ITitle[];
  public allTitlesSelected = true;
  public selectAllDisabled = true;

  @Input()
  public tocConfig: ITocConfig;

  @Output()
  public finishSetup: EventEmitter<any> = new EventEmitter<any>();

  constructor(protected documentaryUnitService: DocumentaryUnitService, private changeDetectorRef: ChangeDetectorRef) {}

  public ngOnInit(): void {
    this.documentaryUnitService.getDocumentUnits(false, false, null, 10).subscribe((data: IDocumentaryUnit[]) => {
      this.documentTitles = [];
      data.forEach((documentaryUnit: IDocumentaryUnit) => {
        if (documentaryUnit.type === ContentType.CHAPTER) {
          this.documentTitles = this.documentTitles.concat(documentaryUnit.titles);
        }
      });
      this.updateFilteredTitles();
    });
  }

  public updateFilteredTitles(): void {
    this.filteredTitles = this.documentTitles.filter(title => title.level && this.tocConfig.includedTitleLevels.includes(title.level));
    this.selectAllDisabled = !this.filteredTitles.find(title => title.level && title.level > 2);
    this.allTitlesSelected = this.tocConfig.excludedTitles.length === 0;
    this.changeDetectorRef.markForCheck();
  }

  public excludeTitle(title: ITitle): void {
    const idx = this.tocConfig.excludedTitles.indexOf(title.id);
    if (idx > -1) {
      // Remove from excluded title
      this.tocConfig.excludedTitles.splice(idx, 1);
    } else {
      // Add excluded title
      this.tocConfig.excludedTitles.push(title.id);
    }
    this.allTitlesSelected = this.tocConfig.excludedTitles.length === 0;
    this.changeDetectorRef.markForCheck();
  }

  public applyChanges(): void {
    this.finishSetup.emit();
  }

  public cancelChanges(): void {
    const tocConfig = this.documentaryUnitService.getTocConfiguration(true);
    this.tocConfig.excludedTitles = tocConfig.excludedTitles;
    this.tocConfig.includedTitleLevels = tocConfig.includedTitleLevels;
    this.finishSetup.emit();
  }

  public selectAll(): void {
    this.tocConfig.excludedTitles = [];
    if (!this.allTitlesSelected) {
      this.allTitlesSelected = true;
    } else {
      this.allTitlesSelected = false;
      this.tocConfig.excludedTitles = this.filteredTitles.filter(title => (title.level ?? 0) > 2).map(title => title.id);
    }
    this.changeDetectorRef.markForCheck();
  }
}
