import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'displayScale',
  pure: true,
})
export class DisplayScalePipe implements PipeTransform {
  transform(displayedValue: number, scaledValue: number): number {
    return displayedValue * Math.pow(10, scaledValue);
  }
}
