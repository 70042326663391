import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, Renderer2, ViewEncapsulation } from '@angular/core';
import { PluginComponent } from '../../plugin.component';
import { PluginPanelService } from '../../plugin-panel.service';
import { ITableModelFull, ITableSelected } from 'app/shared/model/table-model.model';
import { Subscription } from 'rxjs';
import { TablePanelService } from '../table-panel.service';
import { Direction } from 'app/shared/enum/direction.enum';
import pubsub from 'app/pubsub';
import { EDITOR_GO_TO_TABLE, EDITOR_PLUGIN_APPLY_COLUMN_HEADER, EDITOR_SET_CAPTION } from 'app/pubsub.topics';
import { DocumentaryUnitService } from 'app/core/service/documentary-unit.service';
import { ICKDocument } from 'app/core/user/ck-config.model';
import { ITitleTemplate } from 'app/shared/model/lookup.model';
import { LabelService } from 'app/core/service/label.service';

@Component({
  selector: 'jhi-table-accessibility-panel-plugin',
  templateUrl: './accessibility.component.html',
  styleUrls: ['./accessibility.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableAccessibilityPanelComponent extends PluginComponent implements OnDestroy, OnInit {
  public panel: string;
  public open: boolean[] = [];
  public direction = Direction;

  public descManualBool = true;
  public descManualValue = '';
  public DUTitles: ITitleTemplate[] = [];
  public selectedTitle: string | null = null;

  public columnsLabels: number[];
  public columnsLabelsList: { id: number; name: string }[] = [];
  public columns: Map<number, string> = new Map();
  public columnsHeader: boolean[] = [];

  private _tableSubscription: Subscription;

  constructor(
    pluginPanelService: PluginPanelService,
    private tablePanelService: TablePanelService,
    private changeDetectorRef: ChangeDetectorRef,
    private renderer: Renderer2,
    private documentaryUnitService: DocumentaryUnitService,
    private labelService: LabelService
  ) {
    super(pluginPanelService);
  }

  ngOnInit(): void {
    this.documentaryUnitService
      .getDUTitles('' + (this.editor?.config.get('document') as ICKDocument).documentaryUnit?.id)
      .subscribe((titles: ITitleTemplate[]) => {
        this.DUTitles = titles;
        this.changeDetectorRef.markForCheck();
      });

    this._tableSubscription = this.tablePanelService.getSelectedTableObservable().subscribe((element: ITableSelected) => {
      this.setData(element);
    });
    this.setData(this.tablePanelService.getActualTable());
  }

  ngOnDestroy(): void {
    this._tableSubscription.unsubscribe();
  }

  private setData(element: ITableSelected): void {
    // Set Caption values
    this.descManualBool = true;
    this.descManualValue = element.accessibility.manualValue;
    this.selectedTitle = element.accessibility.selectedTitle;
    if (this.selectedTitle) {
      this.descManualBool = false;
    }

    // Columns
    this.columnsLabels = element.columns;
    this.retrieveLabelsLists(element.model);
    this.columnsLabels.forEach(columnId => {
      const actualLabel = this.columnsLabelsList.find(label => label.id === columnId);
      this.columns.set(columnId, actualLabel?.name ?? '');
    });
    this.columnsHeader = element.accessibility.columnsHeader;

    const numberOfSelected = this.columnsHeader.filter(value => value);
    if (!numberOfSelected.length) {
      // If there is no columns selected, select by default the C1 columns
      this.selectC1Cols();
    }

    this.changeDetectorRef.markForCheck();
  }

  private selectC1Cols(): void {
    // find id of C1
    const C1 = this.columnsLabelsList.find(label => label.name === 'C1');
    if (!C1) {
      return;
    }

    this.columnsLabels.forEach((column, index) => {
      if (column === C1.id) {
        pubsub.fire(EDITOR_PLUGIN_APPLY_COLUMN_HEADER, { value: true, columnId: index }, this.getEditorTopicContext());
        this.columnsHeader[index] = true;
      }
    });

    this.changeDetectorRef.markForCheck();
  }

  private retrieveLabelsLists(model: ITableModelFull): void {
    this.labelService.getLabels().subscribe(labels => {
      const data = this.labelService.retrieveLabelsLists(model, labels, true, true);
      this.columnsLabelsList = data.columnsLabelsList ?? [];
      this.changeDetectorRef.markForCheck();
    });
  }

  public selectDirection(direction: Direction): void {
    pubsub.fire(EDITOR_GO_TO_TABLE, { direction }, this.getEditorTopicContext());
  }

  public toggle(element: HTMLElement): void {
    if (element.classList.contains('hidden')) {
      this.renderer.removeClass(element, 'hidden');
    } else {
      this.renderer.addClass(element, 'hidden');
    }
  }

  public manualDescriptionChanged(): void {
    pubsub.fire(EDITOR_SET_CAPTION, { isManual: true, value: this.descManualValue }, this.getEditorTopicContext());
  }

  public titleSelected(uuid: string): void {
    this.selectedTitle = uuid;
    pubsub.fire(EDITOR_SET_CAPTION, { isManual: false, value: uuid }, this.getEditorTopicContext());
  }

  public changeColumnSelection($event: any, columnId: number): void {
    pubsub.fire(EDITOR_PLUGIN_APPLY_COLUMN_HEADER, { value: $event.checked, columnId }, this.getEditorTopicContext());
  }
}
