import { Injectable, Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { SERVER_API_URL } from 'app/app.constants';
import { ConfigurationService } from '../service/configuration.service';
import { Logout } from 'app/core/login/logout.model';

@Injectable({ providedIn: 'root' })
export class AuthServerProvider {
  public cockpitURL: string;

  private configurationService: ConfigurationService;

  constructor(private http: HttpClient, private injector: Injector) {
    setTimeout(() => {
      this.configurationService = injector.get(ConfigurationService);
      this.configurationService.getConfiguration().subscribe(data => {
        this.cockpitURL = data.sppUri;
      });
    });
  }

  logout(): void {
    // First log out from EONLG
    this.http.post<Logout>(`${SERVER_API_URL}logout`, {}).subscribe(() => {
      // Then  log out from Cockpit
      window.location.href = `${this.cockpitURL}/logout`;
    });
  }
}
