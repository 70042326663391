<div class="button-container">
  <button
    type="button"
    mat-flat-button
    mat-button
    color="primary"
    jhiTranslate="htmlEditor.plugins.lookup.addBtn"
    (click)="addEntry.emit()"
    [disabled]="!documentTitles.length"
  ></button>
</div>
<div class="entries-container" *ngIf="documentTitles.length">
  <jhi-lookup-entry-form
    *ngFor="let entry of entries; let i = index; let first = first; let last = last"
    [documentTitles]="documentTitles"
    [lookupType]="lookupType"
    [entry]="entry"
    [entryIndex]="i + 1"
    [disableMoveUpButton]="first"
    [disableMoveDownButton]="last"
    (updateEntry)="updateEntry.emit({ entry: $event, index: i, moved: false })"
    (deleteEntry)="deleteEntry.emit(i)"
    (moveEntryUp)="moveEntryUp.emit({ entry: $event, index: i })"
    (moveEntryDown)="moveEntryDown.emit({ entry: $event, index: i })"
    [expanded]="last"
  >
  </jhi-lookup-entry-form>
</div>
<div class="plugin-title" *ngIf="!documentTitles.length" jhiTranslate="htmlEditor.plugins.lookup.noTitles"></div>
