import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { LoginService } from 'app/core/login/login.service';
import { StateStorageService } from 'app/core/auth/state-storage.service';
import { AccountService } from 'app/core/auth/account.service';

@Injectable()
export class AuthExpiredInterceptor implements HttpInterceptor {
  constructor(
    private loginService: LoginService,
    private stateStorageService: StateStorageService,
    private accountService: AccountService,
    private router: Router
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap(
        () => {
          //
        },
        (err: HttpErrorResponse) => {
          if (err.status === 401) {
            this.stateStorageService.storeUrl(this.router.routerState.snapshot.url || location.pathname + location.search);
            this.accountService.authenticate(null);
            window.location.href = `/login`;
          } else if (err.status === 403) {
            this.router.navigate(['accessdenied']);
          }
        }
      )
    );
  }
}
