import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';

import pubsub from 'app/pubsub';
import { ContextService } from './context.service';
import { PLUGIN_PANEL_COMPONENT_KEYS } from 'app/html-editor/plugins/plugin-panel-component-keys';

import { SERVER_API_URL } from 'app/app.constants';
import { ITitleTemplate, ILookup } from 'app/shared/model/lookup.model';
import { EDITOR_PLUGIN_TOGGLEPANEL, EDITOR_LOOKUP_MODIFY, EDITOR_PLUGIN_SELECTION_LOOKUP } from 'app/pubsub.topics';

@Injectable({ providedIn: 'root' })
export class LookupService {
  public baseUrl: string;
  private _lookupValue = new Subject<ILookup>();
  private _tmpLookup: ILookup;

  private _lookupTypeValue = new Subject<string>();
  public lookupTypeValues$ = this._lookupTypeValue.asObservable();

  constructor(protected http: HttpClient, private contextService: ContextService) {
    this.register();
    this.baseUrl = `${SERVER_API_URL}api/documents/${this.contextService.currentDocumentContext.id}`;

    pubsub.on(EDITOR_PLUGIN_SELECTION_LOOKUP, ({ detail }: CustomEvent) => {
      this.setSelectedLookup(detail);
    });
  }

  register(): void {
    pubsub.on(EDITOR_LOOKUP_MODIFY, ({ detail }: CustomEvent) => {
      pubsub.fire(EDITOR_PLUGIN_TOGGLEPANEL, {
        component: PLUGIN_PANEL_COMPONENT_KEYS.LOOKUP,
        title: 'htmlEditor.plugins.lookup.title',
      });
      // we need to wait till panel component is initialized/ BehaviorSubject is not right for our logic
      setTimeout(() => {
        this.setSelectedLookup(detail);
      }, 500);
    });
  }

  public setSelectedLookup(lookup: ILookup): void {
    this._tmpLookup = lookup;
    this._lookupValue.next(lookup);
  }

  public getLookupTitles(): Observable<ITitleTemplate[]> {
    return this.http.get<ITitleTemplate[]>(`${this.baseUrl}/titles`);
  }

  public changeLookupTypeValue(value: string): void {
    this._lookupTypeValue.next(value);
  }

  public getActualLookup(): ILookup {
    return this._tmpLookup;
  }

  public getSelectedLookupObservable(): Observable<ILookup> {
    return this._lookupValue.asObservable();
  }
}
