import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Moment } from 'moment';

import { SERVER_API_URL } from 'app/app.constants';
import { HttpErrorCustomResponse } from 'app/shared/model/http-error-custom-response.model';
import { CodeErrorServer } from 'app/shared/enum/code-error-server.enum';
import { IDocumentaryUnitContent } from 'app/shared/model/documentary-unit-content.model';
import { IXbrlErrors } from 'app/shared/model/xbrl.model';
import { ICommit, IFilters, IStickerModificationsDocument, UpdateTypes } from 'app/shared/model/version-history.model';

import { ContextService } from './context.service';
import { DialogService } from './dialog.service';
import { StateStorageService } from '../auth/state-storage.service';
import { IUser } from 'app/core/user/user.model';
import { IDocumentaryUnit } from 'app/shared/model/documentary-unit.model';
import { DownloadManagerService } from 'app/core/service/download-manager.service';
import pubsub from 'app/pubsub';
import { NAVIGATE_TO_EDITOR, SPLIT_DU_EXECUTION, SPLIT_DU_REMOVE_CONTENT } from 'app/pubsub.topics';
import { Router } from '@angular/router';
import { SNACKBAR_TYPE, SnackbarService } from 'app/core/service/snackbar.service';
import { ITitleTemplate } from 'app/shared/model/lookup.model';

export interface ITocConfig {
  includedTitleLevels: number[];
  excludedTitles: number[];
}

type EntityResponseType = HttpResponse<IDocumentaryUnitContent>;
@Injectable({ providedIn: 'root' })
export class DocumentaryUnitService {
  public resourceUrl: string;
  public currentSelectedDocumentaryUnit: IDocumentaryUnit | null = null;

  private readonly tocConfigurationKey = 'toc_titles_configuration';
  public tocConfig: ITocConfig;
  public canSynchronize = true;

  constructor(
    protected http: HttpClient,
    private contextService: ContextService,
    private dialogService: DialogService,
    private stateStorageService: StateStorageService,
    private downloadManagerService: DownloadManagerService,
    private router: Router,
    private snackBar: SnackbarService
  ) {
    this.resourceUrl = `${SERVER_API_URL}api/documents/${this.contextService.currentDocumentContext.id}/documentary-units`;

    pubsub.on(SPLIT_DU_EXECUTION, ({ detail }: CustomEvent) => {
      this.confirmBeforeSplitDU(detail);
    });

    pubsub.on(NAVIGATE_TO_EDITOR, ({ detail }: CustomEvent) => {
      this.navigateToNewSectionId(detail);
    });
  }

  public getDocumentUnits(
    showSpinner = false,
    fetchRealUpdateDates = false,
    documentId: number | null = null,
    level = 2
  ): Observable<IDocumentaryUnit[]> {
    let params: HttpParams = new HttpParams();
    params = params.set('level', level);
    if (!showSpinner) {
      params = params.set('spinner', 'none');
    }
    if (fetchRealUpdateDates) {
      params = params.set('fetch-real-update-dates', 'true');
    }
    if (!documentId) {
      documentId = this.contextService.currentDocumentContext.id;
    }
    return this.http.get<IDocumentaryUnit[]>(`${SERVER_API_URL}api/documents/${documentId}/documentary-units`, { params });
  }

  public getDocumentaryUnit(sectionId: number): Observable<IDocumentaryUnit> {
    return this.http.get<IDocumentaryUnit>(`${this.resourceUrl}/${sectionId}/documentary-unit`);
  }

  public synchronize(): Observable<Moment> {
    return this.http.post<Moment>(`${this.resourceUrl}/synchronize`, {});
  }

  public addAfter(id: number): Observable<void> {
    return this.http.post<void>(`${this.resourceUrl}/${id}/after`, {});
  }

  public lockDocumentUnit(id: number, locked: boolean): Observable<void> {
    return this.http.put<void>(`${this.resourceUrl}/${id}/lock`, { locked });
  }

  public activateDocumentUnit(id: number, activated: boolean): Observable<void> {
    return this.http.put<void>(`${this.resourceUrl}/${id}/activate`, { activated });
  }

  public removeGraphicalPageDocumentUnit(list: number[]): Observable<void> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: list,
    };

    return this.http.delete<void>(`${this.resourceUrl}/graphical-pages`, options);
  }

  public updateForCollaboration(sectionId: number, documentaryUnitId: number, types: UpdateTypes[]): Observable<HttpResponse<void>> {
    const params: HttpParams = new HttpParams().set('skipError', 'true').set('spinner', 'none');
    return this.http
      .put<void>(
        `${this.resourceUrl}/collaboration`,
        { sectionId, documentId: this.contextService.currentDocumentContext.id, documentaryUnitId, types: JSON.stringify(types) },
        { params, observe: 'response' }
      )
      .pipe(catchError(this.handleSaveError.bind(this)));
  }

  public getDocumentContent(sectionId: number, forCollaboration = false, checkIfDUIsOpened = false): Observable<EntityResponseType> {
    const params: HttpParams = new HttpParams()
      .set('forCollaboration', `${forCollaboration}`)
      .set('checkIfDUIsOpened', `${checkIfDUIsOpened}`);
    return this.http.get<IDocumentaryUnitContent>(`${this.resourceUrl}/${sectionId}`, {
      params,
      observe: 'response',
    });
  }

  public getNotCurrentDocumentContent(
    documentId: number,
    sectionId: number,
    forCollaboration = false,
    checkIfDUIsOpened = false
  ): Observable<EntityResponseType> {
    const url = `${SERVER_API_URL}api/documents/${documentId}/documentary-units`;
    const params: HttpParams = new HttpParams()
      .set('forCollaboration', `${forCollaboration}`)
      .set('checkIfDUIsOpened', `${checkIfDUIsOpened}`);
    return this.http.get<IDocumentaryUnitContent>(`${url}/${sectionId}`, {
      params,
      observe: 'response',
    });
  }

  public getXbrlErrorsForDU(documentaryUnitId: number, documentaryUnitContent: IDocumentaryUnitContent): Observable<IXbrlErrors[]> {
    const params: HttpParams = new HttpParams().set('spinner', 'none');
    return this.http.post<IXbrlErrors[]>(
      `${this.resourceUrl}/xbrlerrors`,
      { sectionId: documentaryUnitId, documentaryUnit: documentaryUnitContent },
      { params }
    );
  }

  public getCommitHistory(documentaryUnitId: string): Observable<ICommit[]> {
    return this.http.get<ICommit[]>(`${this.resourceUrl}/${documentaryUnitId}/history`).pipe(
      map((commits: any[]) =>
        commits.map(
          ({ sha1, author, message, date, branch }: { sha1: string; author: string; date: string; message: string; branch: string }) => {
            let mess;
            try {
              mess = JSON.parse(message);
            } catch (err) {
              mess = { users: [], types: [] };
            }
            return {
              sha1,
              author,
              date,
              branch,
              message: mess,
            };
          }
        )
      )
    );
  }

  public getVersion(documentaryUnitId: string, sha1: string): Observable<string> {
    return this.http.get(`${this.resourceUrl}/${documentaryUnitId}/version/${sha1}`, { responseType: 'text' as const });
  }

  private handleSaveError(response: HttpErrorCustomResponse): Observable<any> {
    const error = response.error;

    if (error?.title === CodeErrorServer.GIT_ERROR_SAVE) {
      if (!this.stateStorageService.hasGitError()) {
        this.dialogService.openErrorDialog(error.title ?? error);
        // Save that issue has already been displayed
        this.stateStorageService.setGitError();
      }
    }
    return throwError(response);
  }

  public getUsers(): Observable<IUser[]> {
    return this.http.get<IUser[]>(`${SERVER_API_URL}api/documents/${this.contextService.currentDocumentContext.id}/users`);
  }

  public getSections(showSpinner = true): Observable<IDocumentaryUnit[]> {
    let params: HttpParams = new HttpParams();
    if (showSpinner) {
      params = params.set('spinner', 'none');
    }
    return this.http.get<IDocumentaryUnit[]>(`${SERVER_API_URL}api/documents/${this.contextService.currentDocumentContext.id}/sections`, {
      params,
    });
  }

  public getStickerFilteredHistory(filters: IFilters): Observable<IStickerModificationsDocument> {
    const params: HttpParams = new HttpParams().set('spinner', 'none');
    return this.http.post<IStickerModificationsDocument>(
      `${SERVER_API_URL}api/documents/${this.contextService.currentDocumentContext.id}/version_tracking_stickers`,
      filters,
      { params }
    );
  }

  public getDocumentContentWithFilteredHistory(filters: IFilters): Observable<string> {
    const params: HttpParams = new HttpParams().set('spinner', 'none');
    return this.http.post(
      `${SERVER_API_URL}api/documents/${this.contextService.currentDocumentContext.id}/version_tracking_view_stickers`,
      filters,
      { params, responseType: 'text' as const }
    );
  }

  public exportVersionTrackingPdfOrHtml(filters: IFilters, isExportPdf: boolean, isPaginated: boolean): void {
    const params: HttpParams = new HttpParams().set('spinner', 'none').set('paginated', isPaginated.toString());
    const urlExport = `version_tracking${isExportPdf ? '' : '_html'}_export`;
    this.http
      .post(`${SERVER_API_URL}/api/documents/${this.contextService.currentDocumentContext.id}/${urlExport}`, filters, {
        params,
        observe: 'response',
        responseType: 'blob',
      })
      .subscribe(() => this.downloadManagerService.forceCall());
  }

  // SPLIT DU
  public async confirmBeforeSplitDU({ titleId, editorId }: { titleId: string; editorId: string }): Promise<void> {
    const result = await this.dialogService.openConfirmationDUSplit(this.canSynchronize);

    if (result) {
      // User confirmed split
      this.splitDUOnTitle(titleId).subscribe(
        (newSectionId: number) => {
          // Back end split ok, send pubsub to editor to remove content
          pubsub.fire(SPLIT_DU_REMOVE_CONTENT, { newSectionId }, editorId);
        },
        (error: HttpErrorResponse) => {
          if (error?.status === 406) {
            this.snackBar.open(error.error.errorMessage, SNACKBAR_TYPE.warning);
          } else {
            this.snackBar.open('error.internalServerError', SNACKBAR_TYPE.danger);
          }
          console.error('error split DU', error);
        }
      );
    }
  }

  public splitDUOnTitle(titleId: string): Observable<number> {
    const params: HttpParams = new HttpParams().set('skipError', 'true');
    return this.http.post<number>(
      `${SERVER_API_URL}api/documents/${this.contextService.currentDocumentContext.id}/documentary-units/${this.currentSelectedDocumentaryUnit?.id}/split`,
      titleId,
      { params }
    );
  }

  public navigateToNewSectionId({ newSectionId }: { newSectionId: number }): void {
    this.router.navigate([], { queryParams: { sectionId: newSectionId } });
  }

  public getTocConfiguration(reload = false): ITocConfig {
    if (!reload && this.tocConfig) {
      return this.tocConfig;
    }
    const tocConfig = localStorage.getItem(`${this.tocConfigurationKey}_${this.contextService.currentProjectId}`);
    if (tocConfig) {
      this.tocConfig = JSON.parse(tocConfig) as ITocConfig;
    } else {
      this.tocConfig = {
        includedTitleLevels: [1, 2],
        excludedTitles: [],
      };
    }
    return this.tocConfig;
  }

  public setTocConfiguration(tocConfig: ITocConfig): void {
    localStorage.setItem(`${this.tocConfigurationKey}_${this.contextService.currentProjectId}`, JSON.stringify(tocConfig));
    this.tocConfig = tocConfig;
  }

  public getDUTitles(documentaryUnitId: string): Observable<ITitleTemplate[]> {
    return this.http.get<ITitleTemplate[]>(`${this.resourceUrl}/${documentaryUnitId}/titles`);
  }
}
