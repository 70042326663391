<div class="annotate-detail-panel">
  <ng-container>
    <div class="panel-name-wrapper">
      <div class="first-col">
        <mat-icon svgIcon="tag-indigo" color="primary"></mat-icon>
        <span jhiTranslate="htmlEditor.plugins.annotate.detail-panel.headerName"></span>
      </div>
      <div class="second-col">
        <span>#{{xbrlFootnoteId}}</span>
      </div>
    </div>
    <div class="detail-header">
      <div class="language-wrapper">
        <div class="first-col" jhiTranslate="htmlEditor.plugins.annotate.detail-panel.language"></div>
        <div class="second-col" *ngIf="langKey">{{langKey | findLanguageFromKey}}</div>
      </div>
      <div class="title-wrapper">
        <div class="title" jhiTranslate="htmlEditor.plugins.annotate.detail-panel.contentTitle"></div>
        <div class="subtitle">
          {{numberOfFactsSelected}}
          <span jhiTranslate="htmlEditor.plugins.annotate.detail-panel.contentSubTitle"></span>
        </div>
      </div>
    </div>
  </ng-container>

  <div class="detail-content facts-list-wrapper">
    <ng-container *ngIf="xbrlFootnoteFactList != null;else noFacts">
      <div class="fact-cnt" *ngFor="let fact of xbrlFootnoteFactList">
        <div class="first-col">
          <div class="fact-name">
            {{fact.concept | conceptTranslatedLabel: 'label':documentLanguage}}
          </div>
          <div class="fact-id">{{fact.factValue | xbrlFactValue:fact.concept.type }}</div>
        </div>
        <div class="second-col" *ngIf="fact.context?.period">
          {{ fact.context.period.startDate | localizedDate: 'shortDate' }}
          <span *ngIf="fact.context.period.periodType === periodType.PERIOD">- {{ fact.context.period.endDate | localizedDate: 'shortDate' }}</span>
        </div>
      </div>
    </ng-container>
  </div>

  <div class="detail-action-btn">
    <div class="button-cta" *ngIf="displayEditBtn" (click)="openEditPanel()"
      jhiTranslate="htmlEditor.plugins.annotate.detail-panel.confirmBtn"></div>
  </div>
</div>

<ng-template #noFacts>
  <div class="annotate-panel__no-facts" jhiTranslate="htmlEditor.plugins.annotate.noFacts"></div>
</ng-template>
