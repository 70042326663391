<div class="plugin-container row-panel-plugin-container">
  <div class="rowHeaderSection">
    <mat-slide-toggle color="primary" [checked]="header" (change)="changeRowHeader($event.checked)">
      {{ 'htmlEditor.plugins.tableEdition.rowHeader' | translate }}
    </mat-slide-toggle>
  </div>
  <div class="rowLabelSection">
    <mat-form-field>
      <mat-label jhiTranslate="htmlEditor.plugins.tableEdition.rowLabel" [translateValues]="{ index: actualRow.index + 1 }"></mat-label>
      <mat-select (selectionChange)="changeRowLabel($event.value)" [value]="actualRow.labelID">
        <mat-option *ngFor="let label of rowsLabelsList" [value]="label.id">{{ label.name }}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="rowHeaderSection">
    <mat-slide-toggle color="primary" [checked]="disablePyjama" (change)="changePyjamaValue($event.checked)">
      {{ 'htmlEditor.plugins.tableEdition.disablePyjama' | translate }}
    </mat-slide-toggle>
  </div>
  <div class="rowHeaderSection">
    <mat-slide-toggle color="primary" [checked]="reversePyjama" (change)="changeReverseValue($event.checked)">
      {{ 'htmlEditor.plugins.tableEdition.reversePyjama' | translate }}
    </mat-slide-toggle>
  </div>
  <div class="rowLabelSection">
    <mat-form-field>
      <mat-label jhiTranslate="htmlEditor.plugins.tableEdition.rowHeight"></mat-label>
      <input matInput type="number" [(ngModel)]="actualRow.height" (change)="changeRowHeight()" />
      <span matSuffix>px</span>
    </mat-form-field>
  </div>
</div>
