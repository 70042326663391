import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'cmykFormat',
  pure: true,
})
export class CmykDisplayPipe implements PipeTransform {
  constructor(private translate: TranslateService) {}

  transform(str: string): any {
    const cmykArr = str.split(',');
    return `
     ${this.translate.instant('styleEditor.color.placeholder.cmykCodeC')}${cmykArr[0]}
     ${this.translate.instant('styleEditor.color.placeholder.cmykCodeM')}${cmykArr[1]}
     ${this.translate.instant('styleEditor.color.placeholder.cmykCodeY')}${cmykArr[2]}
     ${this.translate.instant('styleEditor.color.placeholder.cmykCodeK')}${cmykArr[3]}`;
  }
}
