import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { XBRLType } from 'app/shared/enum/xbrl-type.enum';
import { PluginPanelService } from '../../plugin-panel.service';
import { PluginComponent } from '../../plugin.component';
import { Subscription } from 'rxjs';
import { DynamicDataService } from '../dynamic-data.service';
import { ITextBlock, TextBlockType, DeletionType } from 'app/shared/model/xbrl.model';
import { UntypedFormBuilder } from '@angular/forms';
import { INSERT_TEXT_BLOCK, DELETE_TEXT_BLOCK } from 'app/pubsub.topics';
import pubsub from 'app/pubsub';
import { IFact } from 'app/shared/model/fact.model';
import { TaggingStep } from 'app/shared/enum/tagging-step.enum';
import { ArevioService } from 'app/core/service/arevio.service';
import { PluginsCommand } from '../../plugins-commands';

const EditionType = {
  CREATE: 'create',
  RESTRICTIVE: 'restrictive',
};

@Component({
  selector: 'jhi-macro-tagging-panel-plugin',
  templateUrl: './macro-tagging-panel.component.html',
  styleUrls: ['./macro-tagging-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MacroTaggingPanelComponent extends PluginComponent implements OnInit, OnDestroy {
  private _textBlockSubscription: Subscription;
  public disableSave: boolean;
  public isSelection = false;
  public displayActionButton = true;
  public textBlockId: string | null = null;
  public inEdition = false;
  public textBlock: string | null = null;
  public textBlockXBRLType = XBRLType.TEXT_BLOCK_2021;
  private closeSubscription: Subscription;
  public EditionType = EditionType;
  public DeletionType = DeletionType;
  public isDeletion = false;

  public deletionValue = DeletionType.ONE;

  public textBlockForm = this.fb.group({
    closingType: this.fb.control(null),
  });

  public fact: IFact;
  public translationRoot = 'textBlock';

  public textBlockTypeRef = XBRLType.TEXT_BLOCK;

  constructor(
    private panelService: PluginPanelService,
    private changeDetectorRef: ChangeDetectorRef,
    private dynamicDataService: DynamicDataService,
    private arevioService: ArevioService,
    private fb: UntypedFormBuilder
  ) {
    super(panelService);
  }

  ngOnInit(): void {
    if (this.data?.isTextFact) {
      this.switchToTextFact();
    }

    // Get editor's selection
    this._textBlockSubscription = this.dynamicDataService.getSelectedMacroTaggingObservable().subscribe((element: ITextBlock) => {
      this.setData(element);
    });

    this.setData(this.dynamicDataService.getActualMicroTagging());

    this.closeSubscription = this.textBlockForm.controls.closingType.valueChanges.subscribe((value: boolean) => {
      // Reinit textBlock value to have the choice back when you click on opeining
      this.textBlock = null;
      if (value) {
        // Force to restrictive on closing to only have the list of existing facts
        this.textBlock = EditionType.RESTRICTIVE;
      }
      this.changeDetectorRef.markForCheck();
    });
  }

  private switchToTextFact(): void {
    this.translationRoot = 'textFact';
    this.textBlockXBRLType = XBRLType.STRING;
    this.panelService.panelTitle = 'htmlEditor.plugins.textFact.title';
    this.changeDetectorRef.markForCheck();
  }

  private switchToTextBlock(): void {
    this.translationRoot = 'textBlock';
    this.textBlockXBRLType = XBRLType.TEXT_BLOCK_2021;
    this.panelService.panelTitle = 'htmlEditor.plugins.textBlock.title';
    this.changeDetectorRef.markForCheck();
  }

  private initFact(): void {
    this.fact = {
      context: {
        dimensions: [],
        period: null,
        entityScheme: '',
        entityIdentifier: null,
      },
    };
  }

  private setData(element: ITextBlock): void {
    this.updateStep(TaggingStep.CHOICE);
    this.disableSave = !element.insertionIsAllowed;
    this.isSelection = element.isSelection;
    this.textBlockId = element.id;
    this.isDeletion = false;
    this.textBlock = null;
    this.initFact();

    if (('isTextFact' in element && element.isTextFact) || (!('isTextFact' in element) && this.data?.isTextFact)) {
      this.switchToTextFact();
    } else {
      this.switchToTextBlock();
    }

    this.textBlockForm.setValue(
      {
        closingType: element.type === TextBlockType.CLOSE,
      },
      {
        emitEvent: false,
      }
    );

    if (element.factId) {
      if ('isTextFact' in element && element.isTextFact) {
        this.getTextFact(element);
      } else {
        this.getTextBlock(element);
      }
    } else {
      this.inEdition = true;
    }

    this.changeDetectorRef.markForCheck();
  }

  getTextBlock(element: ITextBlock): void {
    this.inEdition = false;
    // TODO quick fix, review this
    this.arevioService.getTextBlockById(element.factId, false).subscribe((fact: IFact) => {
      if (fact?.id) {
        this.fact = fact;
      } else {
        this.arevioService.getTextBlockById(element.factId, true).subscribe((fact: IFact) => {
          if (fact?.id) {
            this.fact = fact;
          }
          this.changeDetectorRef.markForCheck();
        });
      }
      this.changeDetectorRef.markForCheck();
    });
  }

  getTextFact(element: ITextBlock): void {
    this.inEdition = false;
    this.arevioService.getTextFactById(element.factId).subscribe((fact: IFact) => {
      if (fact?.id) {
        this.fact = fact;
      }
      this.changeDetectorRef.markForCheck();
    });
  }

  ngOnDestroy(): void {
    if (this._textBlockSubscription) {
      this._textBlockSubscription.unsubscribe();
    }
    if (this.closeSubscription) {
      this.closeSubscription.unsubscribe();
    }
  }

  save(): void {
    if (!this.fact.concept || !this.fact.context?.period) {
      return;
    }
    const type = this.textBlockForm.controls.closingType.value ? TextBlockType.CLOSE : TextBlockType.OPEN;

    if (this.fact.context && !this.fact.context.dimensions) {
      this.fact.context.dimensions = [];
    }
    const factId = this.arevioService.getFactXbrlId(this.fact);

    pubsub.fire(
      INSERT_TEXT_BLOCK,
      {
        id: this.textBlockId,
        type,
        isSelection: this.isSelection,
        factId,
        qname: this.fact.concept.qname,
        startDate: this.fact.context.period.startDate,
        periodType: this.fact.context.period.periodType,
        endDate: this.fact.context.period.endDate,
        axisQname: this.fact.context.dimensions[0]?.dimAxis.qname,
        memberQname: this.fact.context.dimensions[0]?.dimMember.qname,
        isTextFact: this.data?.isTextFact,
      },
      this.getEditorTopicContext()
    );

    if (this.textBlock === EditionType.CREATE) {
      this.arevioService.clearTextBlocks();
    }

    this.closePanel();
  }

  updateStep(step: TaggingStep): void {
    this.panelService.toggleHeaderDisplay(step !== TaggingStep.CHOICE);
    this.displayActionButton = step !== TaggingStep.CONCEPT;
  }

  updateFact(fact: IFact): void {
    this.fact = fact;
  }

  openingTagsChoice(choice: string): void {
    this.textBlock = choice;
    this.changeDetectorRef.markForCheck();
  }

  update(choice: string): void {
    this.inEdition = true;
    this.textBlock = choice;
    this.changeDetectorRef.markForCheck();
  }

  insertRestrictive(event: { command: PluginsCommand; params: any; toFormat: boolean } | null): void {
    if (!event) {
      // Go back to first screen selection
      this.textBlock = null;
    } else {
      this.fact = event.params.data;
      this.save();
    }
  }

  openDeleteConfirmation(): void {
    this.isDeletion = true;
  }

  cancelDeletion(): void {
    this.isDeletion = false;
  }

  radioChange(value: DeletionType): void {
    this.deletionValue = value;
  }
  delete(): void {
    pubsub.fire(
      DELETE_TEXT_BLOCK,
      {
        type: this.deletionValue,
      },
      this.getEditorTopicContext()
    );

    this.closePanel();
  }
}
