<div class="analysis">
  <h2 jhiTranslate="htmlEditor.plugins.import.importContent"></h2>

  <div *ngIf="importResponse" class="container">
    <div class="row">
      <div class="col">
        <div class="row">
          <span jhiTranslate="htmlEditor.plugins.import.paragraphsCount"></span>
        </div>
        <div class="row">
          <span jhiTranslate="htmlEditor.plugins.import.tablesCount"></span>
        </div>
        <div class="row">
          <span jhiTranslate="htmlEditor.plugins.import.matchingScore"></span>
        </div>
      </div>
      <div class="col">
        <div class="row result">
          {{ importResponse.paragraphsCount }}
        </div>
        <div class="row result">
          {{ importResponse.tablesCount }}
        </div>
        <div class="row result">
          <span [jhiMatchingScore]="importResponse.matchingScore">{{ importResponse.matchingScore | number: '1.0-0' }}%</span>
        </div>
      </div>
    </div>

    <ng-container *ngIf="importResponse.totalTabName>0 || importResponse.totalSheetName>0 || importResponse.tabAndSheetNotFound.length>0 || importResponse.tabAndSheetDifferentFromData.length>0">
      <div class="row row-border">
        <div class="col">
          <div class="row">
            <span jhiTranslate="htmlEditor.plugins.import.tabnames"></span>
          </div>
          <div class="row">
            <span jhiTranslate="htmlEditor.plugins.import.sheetnames"></span>
          </div>
        </div>
        <div class="col">
          <div class="row result" [class.errorValues]="importResponse.matchedTabName !== importResponse.totalTabName">
            {{ importResponse.matchedTabName }} / {{ importResponse.totalTabName }}
          </div>
          <div class="row result" [class.errorValues]="importResponse.matchedSheetName !== importResponse.totalSheetName">
            {{ importResponse.matchedSheetName }} / {{ importResponse.totalSheetName }}
          </div>
        </div>
      </div>
      <ng-container *ngIf="importResponse.tabAndSheetNotFound.length>0 || importResponse.tabAndSheetDifferentFromData.length>0">
        <div class="row rowErrors">
          <span jhiTranslate="htmlEditor.plugins.import.detailEolData.title"></span>
        </div>
        <div class="row accordionsContainer" >
          <mat-accordion>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <span class="errorTitle" jhiTranslate="htmlEditor.plugins.import.detailEolData.errors" [translateValues]="{count: importResponse.tabAndSheetNotFound.length+importResponse.tabAndSheetDifferentFromData.length}"></span>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="errorValues" *ngFor="let error of importResponse.tabAndSheetNotFound">
                <b>{{error.tabname}}, {{error.sheetname}}</b> <span  jhiTranslate="htmlEditor.plugins.import.detailEolData.notMatched"></span>
              </div>
              <div class="errorValues" *ngFor="let error of importResponse.tabAndSheetDifferentFromData">
                <b>{{error.tabname}}, {{error.sheetname}}</b> <span jhiTranslate="htmlEditor.plugins.import.detailEolData.structure"></span>
              </div>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
      </ng-container>
    </ng-container>

    <div class="action-buttons">
      <button mat-raised-button color="accent" jhiTranslate="htmlEditor.plugins.import.cancelBtn" (click)="onClickClosePanel()"></button>
      <button mat-raised-button color="primary" jhiTranslate="htmlEditor.plugins.import.importBtn" (click)="onClickImportBtn()"></button>
    </div>
  </div>

  <span *ngIf="loading" jhiTranslate="htmlEditor.plugins.import.analyzing"></span>
  <span *ngIf="hasError" jhiTranslate="htmlEditor.plugins.import.importFailed"></span>
  <hr />
</div>

<div class="loading-or-error" *ngIf="loading || hasError">
  <div class="wrapper">
    <jhi-loading [show]="loading"></jhi-loading>

    <span jhiTranslate="htmlEditor.plugins.import.loadingAndAnalyzing" *ngIf="loading"></span>

    <div *ngIf="hasError" class="error-container">
      <mat-icon svgIcon="error"></mat-icon>
      <span jhiTranslate="htmlEditor.plugins.import.importFailedTryAgain"></span>
      <button
        class="import-btn"
        mat-raised-button
        color="accent"
        jhiTranslate="htmlEditor.plugins.import.importAgainBtn"
        (click)="onClickNewImportFilteBtn()"
      ></button>
    </div>
  </div>
</div>
