export enum IXBRLTags {
  NON_FRACTION = 'ix:non-fraction',
  NON_NUMERIC = 'ix:non-numeric',
  DD_TAG = 'dd:tag',
}

export enum DynamicDataFilter {
  ALL,
  XBRL,
  DD,
}
