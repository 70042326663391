import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { MatSelectChange } from '@angular/material/select';

@Component({
  selector: 'jhi-column-count-input',
  templateUrl: './column-count-input.component.html',
  styleUrls: ['./column-count-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ColumnCountInputComponent {
  @Input() label = '';
  @Input() value = 1;
  @Output() change = new EventEmitter<number>();

  updateColumnCount($columnCount: MatSelectChange): void {
    this.change.emit(+$columnCount.value);
  }
}
