import { HttpClient } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { ErrorStateMatcher, ShowOnDirtyErrorStateMatcher } from '@angular/material/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router } from '@angular/router';
import { PgdpCoreModule } from 'app/core/core.module';
import { LoaderComponent } from 'app/shared/component/loader/loader.component';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';

import { PgdpSharedModule } from 'app/shared/shared.module';
import { PgdpAppRoutingModule } from './app-routing.module';
import { InitialisationService } from './core/service/initialisation.service';
import { ErrorComponent } from './layouts/error/error.component';
// jhipster-needle-angular-add-module-import JHipster will add new module here
import { MainComponent } from './layouts/main/main.component';
import { NavbarComponent } from './layouts/navbar/navbar.component';
import { PageRibbonComponent } from './layouts/profiles/page-ribbon.component';
import { DownloadManagerComponent } from './layouts/download-manager/download-manager.component';
import { PDFReportDialogResultComponent } from './layouts/download-manager/pdf-report/pdf-report-dialog-result.component';
import { PDFReportTableComponent } from './layouts/download-manager/pdf-report/pdf-report-table/pdf-report-tablecomponent';
import './vendor';
import { ImportProjectDetailComponent } from './layouts/import-project-detail/import-project-detail.component';

import { TraceService, createErrorHandler } from '@sentry/angular-ivy';

export function appInit(initialisationService: InitialisationService): any {
  return () => initialisationService.initializedApp();
}

@NgModule({
  imports: [
    BrowserModule,
    CKEditorModule,
    BrowserAnimationsModule,
    PgdpCoreModule,
    PgdpSharedModule,
    // jhipster-needle-angular-add-module JHipster will add new module here
    PgdpAppRoutingModule,
  ],
  declarations: [
    MainComponent,
    NavbarComponent,
    ErrorComponent,
    PageRibbonComponent,
    LoaderComponent,
    DownloadManagerComponent,
    PDFReportDialogResultComponent,
    PDFReportTableComponent,
    ImportProjectDetailComponent,
  ],
  bootstrap: [MainComponent],
  providers: [
    InitialisationService,
    { provide: APP_INITIALIZER, useFactory: appInit, deps: [InitialisationService, HttpClient, TraceService], multi: true },
    { provide: ErrorStateMatcher, useClass: ShowOnDirtyErrorStateMatcher },
    {
      provide: ErrorHandler,
      useValue: createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: TraceService,
      deps: [Router],
    },
  ],
})
export class PgdpAppModule {}
