<mat-form-field class="calendar">
  <mat-label>{{ placeholder }}</mat-label>
  <input
    matInput
    autocomplete="off"
    [formControl]="dateControl"
    (input)="onValueChange()"
    (dateChange)="onValueChange()"
    [min]="minDate"
    [max]="maxDate"
    [matDatepicker]="picker"
    [required]="required"
    [errorStateMatcher]="matcher"
    [readonly]="readonly"
  />
  <mat-datepicker-toggle *ngIf="disabled === false && readonly === false" matSuffix [for]="picker"> </mat-datepicker-toggle>
  <mat-datepicker #picker color="primary" [startAt]="initialDate"></mat-datepicker>
  <mat-error *ngIf="dateControl.hasError('required') && !dateControl.hasError('matDatepickerParse')">{{
    'entity.validation.required' | translate
  }}</mat-error>
  <mat-error *ngIf="dateControl.hasError('matDatepickerParse')">{{ 'entity.validation.dateFormat' | translate }}</mat-error>
  <mat-error *ngIf="dateControl.hasError('matDatepickerMin')">{{ minDateMessage }}</mat-error>
  <mat-error *ngIf="dateControl.hasError('matDatepickerMax')">{{ maxDateMessage }}</mat-error>
</mat-form-field>
