<div class="editable-select-container" matAutocompleteOrigin #origin="matAutocompleteOrigin">
    <input [formControl]="inputControl" [placeholder]="placeholder ?  placeholder : ''" [matAutocomplete]="auto" [matAutocompleteConnectedTo]="origin" class="mat-input-element"
        (input)="_handleInput()" (ngModelChange)="showSelectedInDropdown()">
    <div class="mat-select-arrow-wrapper">
        <div class="mat-select-arrow"></div>
    </div>
</div>
<mat-autocomplete #auto="matAutocomplete" (opened)="showSelectedInDropdown()"
    (optionSelected)="_handleInput()" class="{{id}} editable-select-dropdown">
    <ng-container *ngFor="let option of normalizedOptions">
        <mat-option *ngIf="option.labelLocKey" [value]="option.value" [jhiTranslate]="option.labelLocKey" [class]="option.classNames"></mat-option>
        <mat-option *ngIf="!option.labelLocKey" [value]="option.value" [class]="option.classNames">{{ option.label }}</mat-option>
    </ng-container>
</mat-autocomplete>
