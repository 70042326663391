<div class="modal-header">
  <h4 class="modal-title" jhiTranslate="metrics.jvm.threads.dump.title">Threads dump</h4>

  <button type="button" class="btn-close" (click)="dismiss()">&times;</button>
</div>

<div class="modal-body">
  <div class="mb-3">
    <span class="badge bg-primary hand" (click)="threadStateFilter = undefined">
      <fa-icon *ngIf="threadStateFilter === undefined" icon="check"></fa-icon>
      All&nbsp;<span class="badge rounded-pill bg-default">{{ threadDumpAll }}</span>
    </span>

    <span class="badge bg-success hand" (click)="threadStateFilter = ThreadState.Runnable">
      <fa-icon *ngIf="threadStateFilter === ThreadState.Runnable" icon="check"></fa-icon>
      Runnable&nbsp;<span class="badge rounded-pill bg-default">{{ threadDumpRunnable }}</span>
    </span>

    <span class="badge bg-info hand" (click)="threadStateFilter = ThreadState.Waiting">
      <fa-icon *ngIf="threadStateFilter === ThreadState.Waiting" icon="check"></fa-icon>
      Waiting&nbsp;<span class="badge rounded-pill bg-default">{{ threadDumpWaiting }}</span>
    </span>

    <span class="badge bg-warning hand" (click)="threadStateFilter = ThreadState.TimedWaiting">
      <fa-icon *ngIf="threadStateFilter === ThreadState.TimedWaiting" icon="check"></fa-icon>
      Timed Waiting&nbsp;<span class="badge rounded-pill bg-default">{{ threadDumpTimedWaiting }}</span>
    </span>

    <span class="badge bg-danger hand" (click)="threadStateFilter = ThreadState.Blocked">
      <fa-icon *ngIf="threadStateFilter === ThreadState.Blocked" icon="check"></fa-icon>
      Blocked&nbsp;<span class="badge rounded-pill bg-default">{{ threadDumpBlocked }}</span>
    </span>
  </div>

  <div class="pad" *ngFor="let thread of getThreads()">
    <h6>
      <span class="badge" [ngClass]="getBadgeClass(thread.threadState)">{{ thread.threadState }}</span>

      &nbsp;{{ thread.threadName }} (ID {{ thread.threadId }})

      <a (click)="thread.showThreadDump = !thread.showThreadDump" href="javascript:void(0);">
        <span [hidden]="thread.showThreadDump" jhiTranslate="metrics.jvm.threads.dump.show">Show Stacktrace</span>
        <span [hidden]="!thread.showThreadDump" jhiTranslate="metrics.jvm.threads.dump.hide">Hide Stacktrace</span>
      </a>
    </h6>

    <div class="card" [hidden]="!thread.showThreadDump">
      <div class="card-body">
        <div *ngFor="let st of thread.stackTrace" class="break">
          <samp
            >{{ st.className }}.{{ st.methodName }}(<code>{{ st.fileName }}:{{ st.lineNumber }}</code
            >)</samp
          >
          <span class="mt-1"></span>
        </div>
      </div>
    </div>
    <table class="table table-sm table-responsive">
      <caption>
        Threads dump:
        {{
          thread.threadName
        }}
      </caption>
      <thead>
        <tr>
          <th scope="col" jhiTranslate="metrics.jvm.threads.dump.blockedtime">Blocked Time</th>
          <th scope="col" jhiTranslate="metrics.jvm.threads.dump.blockedcount">Blocked Count</th>
          <th scope="col" jhiTranslate="metrics.jvm.threads.dump.waitedtime">Waited Time</th>
          <th scope="col" jhiTranslate="metrics.jvm.threads.dump.waitedcount">Waited Count</th>
          <th scope="col" jhiTranslate="metrics.jvm.threads.dump.lockname">Lock name</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{{ thread.blockedTime }}</td>
          <td>{{ thread.blockedCount }}</td>
          <td>{{ thread.waitedTime }}</td>
          <td>{{ thread.waitedCount }}</td>
          <td class="thread-dump-modal-lock" title="{{ thread.lockName }}">
            <code>{{ thread.lockName }}</code>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-secondary float-start" data-dismiss="modal" (click)="dismiss()">Done</button>
</div>
