<h4 jhiTranslate="metrics.jvm.memory.title">Memory</h4>

<div *ngIf="!updating && jvmMemoryMetrics">
  <div *ngFor="let entry of jvmMemoryMetrics | keyvalue">
    <span *ngIf="entry.value.max !== -1; else other">
      <span>{{ entry.key }}</span>
      ({{ entry.value.used / 1048576 | number: '1.0-0' }}M / {{ entry.value.max / 1048576 | number: '1.0-0' }}M)
    </span>

    <div>Committed : {{ entry.value.committed / 1048576 | number: '1.0-0' }}M</div>

    <ng-template #other
      ><span
        ><span>{{ entry.key }}</span> {{ entry.value.used / 1048576 | number: '1.0-0' }}M</span
      ></ng-template
    >

    <ngb-progressbar
      *ngIf="entry.value.max !== -1"
      type="success"
      [value]="(100 * entry.value.used) / entry.value.max"
      [striped]="true"
      [animated]="false"
    >
      <span>{{ (entry.value.used * 100) / entry.value.max | number: '1.0-0' }}%</span>
    </ngb-progressbar>
  </div>
</div>
