import { CompatClient, Stomp } from '@stomp/stompjs';
import * as SockJS from 'sockjs-client';
import pubsub from 'app/pubsub';
import { WEBSOCKET_CONNECTED } from 'app/pubsub.topics';
import { ConfigurationService } from '../configuration.service';
import { Injectable } from '@angular/core';

/**
 * A WebSocket service allowing subscription to a broker.
 */
@Injectable({
  providedIn: 'root',
})
export class WebSocketNewService {
  disabled = true;
  name: string;
  private stompClient: CompatClient | null = null;
  private login: string;
  private password: string;
  private prefix: string;
  private websocketsHost: string;

  constructor(private configurationService: ConfigurationService) {
    this.configurationService.getConfiguration().subscribe(data => {
      this.login = data.brokerLogin;
      this.password = data.brokerPassword;
      this.prefix = data.brokerPrefix;
      this.websocketsHost = data.websocketsHost;
      this.connect();
    });
  }

  setConnected(connected: boolean): void {
    this.disabled = !connected;
  }

  isConnected(): boolean {
    return !this.disabled;
  }

  getConnection(): CompatClient | null {
    return this.stompClient;
  }

  getPrefix(): string {
    return this.prefix;
  }

  connect(): void {
    const host = this.websocketsHost;
    // use SockJS implementation instead of the browser's native implementation
    this.stompClient = Stomp.over(function (): WebSocket {
      return new SockJS(host + '/api/ws/stomp');
    });
    this.stompClient.reconnectDelay = 5000;

    this.stompClient.connect(this.login, this.password, (): void => {
      this.setConnected(true);
      pubsub.fire(WEBSOCKET_CONNECTED);
    });
  }

  disconnect(): void {
    if (this.stompClient != null) {
      this.stompClient.disconnect();
    }
    this.setConnected(false);
  }
}
