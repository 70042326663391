<div class="dynamic-data-viewer xbrl-data-viewer" [class.missing]="data.missing">
  <div class="tagging-header">
    <ng-container>
      <div class="tagging-actions">
        <div class="button-bar">
          <button mat-icon-button (click)="selectDirection(direction.DESC)">
            <mat-icon svgIcon="arrow-left"></mat-icon>
          </button>
          <mat-select required *ngIf="showFilter" [(ngModel)]="navigationFilter">
            <mat-option [value]="filter.ALL">
              {{ 'dataEditor.navigation.filter.all' | translate }}
            </mat-option>
            <mat-option [value]="filter.DD">
              {{ 'dataEditor.navigation.filter.dd' | translate }}
            </mat-option>
          </mat-select>
          <button mat-icon-button (click)="selectDirection(direction.ASC)">
            <mat-icon svgIcon="arrow-right"></mat-icon>
          </button>
        </div>
      </div>
      <div class="tagging-title">{{ 'entity.data.rcsf.detail.title' | translate }}</div>
      <div class="tagging-type">
        <mat-icon svgIcon="tag-indigo" color="primary"></mat-icon>
        <span jhiTranslate="entity.data.rcsf.detail.tagTitle"></span>
      </div>
    </ng-container>
  </div>
  <div class="tagging-content">
    <div class="concept-desc">
      <div>
        <label jhiTranslate="entity.data.rcsf.detail.origin" *ngIf="!data.missing"></label>
        <label jhiTranslate="entity.data.rcsf.detail.origin-missing" *ngIf="data.missing"></label>
        <div class="right">
          <span [matTooltip]="data.filename" matTooltipClass="sheet-name-tooltip" matTooltipPosition="left">{{ data.filename }}</span>
        </div>
        <div class="right">
          <span [matTooltip]="data.sheetName" matTooltipClass="sheet-name-tooltip" matTooltipPosition="left">{{ data.sheetName }}</span>
        </div>
        <div class="right">
          <span> {{ 'entity.data.rcsf.detail.cell' | translate }}{{ xlsxCell }} </span>
        </div>
      </div>
    </div>

    <div class="properties">
      <div class="property">
        <label jhiTranslate="entity.data.rcsf.detail.dataValue"></label>
        <div class="value" *ngIf="data.cell.numericValue || data.cell.dateValue || data.cell.formattedValue?.length">
          {{ data.cell | dynamicDataValue }}
        </div>
        <label
          jhiTranslate="htmlEditor.plugins.dynamicData.formating.empty"
          class="emptyValue"
          *ngIf="!data.cell.numericValue && !data.cell.dateValue && !data.cell.formattedValue?.length"
        ></label>
      </div>
      <div class="property" *ngIf="formatAttributes?.scale != null && data.cell?.numericValue">
        <label jhiTranslate="htmlEditor.plugins.dynamicData.formating.displayScale"></label>
        <div class="value">{{ formatAttributes.scale }}</div>
      </div>
      <div class="property" *ngIf="formatAttributes?.balance && data.cell?.numericValue">
        <label jhiTranslate="htmlEditor.plugins.dynamicData.formating.balance"></label>
        <div class="value">{{ formatAttributes.balance }}</div>
      </div>
      <div class="property" *ngIf="data.cell?.language?.length > 0">
        <label jhiTranslate="entity.data.language"></label>
        <div class="value">{{ data.cell.language }}</div>
      </div>
      <div class="property" *ngIf="data.cell?.oimUnit?.length > 0">
        <label jhiTranslate="entity.data.unit"></label>
        <div class="value">{{ data.cell.oimUnit }}</div>
      </div>
    </div>
  </div>
</div>
