import {
  ChangeDetectionStrategy,
  Component,
  Input,
  Output,
  EventEmitter,
  AfterViewInit,
  ChangeDetectorRef,
  ViewChildren,
  QueryList,
} from '@angular/core';
import { MatExpansionPanel } from '@angular/material/expansion';

@Component({
  selector: 'jhi-properties-form-group',
  templateUrl: './properties-form-group.component.html',
  styleUrls: ['./properties-form-group.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PropertiesFormGroupComponent implements AfterViewInit {
  @Input() id: string;
  @Input() title: string;
  @Input() isExpandable = false;

  @Output() afterCollapse = new EventEmitter<void>();
  @Output() afterExpand = new EventEmitter<void>();

  @ViewChildren(MatExpansionPanel) matExpansionPanelElements: QueryList<MatExpansionPanel>;

  public disableAnimation = true;

  constructor(private changeDetectorRef: ChangeDetectorRef) {}

  public ngAfterViewInit(): void {
    // Workaround for angular component issue #13870
    // https://github.com/angular/components/issues/13870
    this.disableAnimation = false;
    this.changeDetectorRef.markForCheck();
  }

  public getId(): string {
    return this.id;
  }

  public open(): void {
    if (this.matExpansionPanelElements?.first) {
      this.matExpansionPanelElements.first.open();
    }
  }

  public close(): void {
    if (this.matExpansionPanelElements?.first) {
      this.matExpansionPanelElements.first.close();
    }
  }

  onAfterExpand(): void {
    this.afterExpand.emit();
  }

  onAfterCollapse(): void {
    this.afterCollapse.emit();
  }
}
