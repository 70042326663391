import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { ArevioService } from 'app/core/service/arevio.service';
import { SearchType } from 'app/shared/enum/search-type.enum';
import { XBRLType } from 'app/shared/enum/xbrl-type.enum';
import { IEntryPointTable } from 'app/shared/model/concept.model';
import { ITaxonomyConceptSelected } from 'app/shared/model/taxonomy.model';

@Component({
  selector: 'jhi-concept-browser',
  templateUrl: './concept-browser.component.html',
  styleUrls: ['./concept-browser.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConceptBrowserComponent implements OnInit {
  @Input() public treeView = false;
  @Input() public displayAbstract = true;
  @Input() public onlyMappableConcepts = false;
  @Input() public filteredType: XBRLType | null = null;

  @Output() public selected = new EventEmitter<ITaxonomyConceptSelected>();

  public tablesAndConcepts: IEntryPointTable[];
  public filterText: string;
  public readonly SearchType = SearchType;
  public conceptIdSelected: number | null;
  public conceptIdParentIDSelected: { pnodeId?: number; parentId: number };

  constructor(private changeDetectorRef: ChangeDetectorRef, private arevioService: ArevioService) {}

  ngOnInit(): void {
    if (this.filteredType) {
      this.arevioService.getFilteredConcepts(this.filteredType).subscribe((data: IEntryPointTable[]) => {
        this.tablesAndConcepts = data;
        this.changeDetectorRef.markForCheck();
      });
    } else {
      this.arevioService.getTablesFromEntryPoint(this.onlyMappableConcepts).subscribe((data: IEntryPointTable[]) => {
        this.tablesAndConcepts = data;
        this.changeDetectorRef.markForCheck();
      });
    }
  }

  public filterTable(text: string): void {
    this.filterText = text;
    setTimeout(() => this.changeDetectorRef.markForCheck());
  }

  public onSelected(conceptSelected: ITaxonomyConceptSelected): void {
    this.conceptIdParentIDSelected = { pnodeId: conceptSelected?.concept?.pnodeId, parentId: conceptSelected?.concept?.parentId };
    this.conceptIdSelected = conceptSelected?.concept?.pnodeId ?? null;
    this.selected.emit(conceptSelected);
  }

  public viewConceptChange(): void {
    this.treeView = !this.treeView;
    this.filterTable('');
  }
}
