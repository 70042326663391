import { FocusMonitor } from '@angular/cdk/a11y';
import {
  Component,
  Input,
  forwardRef,
  HostBinding,
  OnDestroy,
  Self,
  Optional,
  ElementRef,
  Inject,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
} from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { MatFormField, MatFormFieldControl, MAT_FORM_FIELD } from '@angular/material/form-field';
import { Subject } from 'rxjs';

@Component({
  selector: 'jhi-input-number',
  templateUrl: './input-number.component.html',
  styleUrls: ['./input-number.component.scss'],
  providers: [{ provide: MatFormFieldControl, useExisting: forwardRef(() => InputNumberComponent) }],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputNumberComponent implements OnDestroy, ControlValueAccessor, MatFormFieldControl<number> {
  private static nextId = 0;
  private static readonly FLOAT_PATTERN = /^-?\d*\.?\d*$/; // nosonar
  private static readonly INT_PATTERN = /^-?\d*$/;
  private readonly specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home', 'ArrowLeft', 'ArrowRight', 'Del', 'Delete'];

  @Input()
  get placeholder(): string {
    return this._placeholder;
  }
  set placeholder(placeholder: string) {
    this._placeholder = placeholder;
    this.stateChanges.next();
  }

  @Input()
  get required(): boolean {
    return this._required;
  }
  set required(required: boolean) {
    this._required = required;
    this.stateChanges.next();
  }

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }
  set disabled(value: boolean) {
    this._disabled = value;
    this.stateChanges.next();
  }

  @Input()
  get decimal(): number {
    return this._decimal;
  }
  set decimal(value: number) {
    this._decimal = value;
    if (value) {
      this.validPattern = value > 0 ? new RegExp(`^-?\\d*\\.?\\d{0,${value}}$`) : InputNumberComponent.INT_PATTERN;
    } else {
      this.validPattern = InputNumberComponent.FLOAT_PATTERN;
    }
    this.stateChanges.next();
  }

  @Input()
  get step(): number {
    return this._step;
  }
  set step(value: number) {
    this._step = value ?? 1;
    this.stateChanges.next();
  }

  @Input() min: number;
  @Input() max: number;
  @Input() revertNullToZero: boolean;

  @Output() valueChange = new EventEmitter<{ value: number | null }>();

  get value(): number | null {
    return this._value;
  }
  set value(value: number | null) {
    this._value = value;
    this._inputValue = value != null ? `${value}` : null;
    this.stateChanges.next();
  }

  get inputValue(): string | null {
    return this._inputValue;
  }
  set inputValue(value: string | null) {
    this._inputValue = value === '' ? null : value;
    this._value = this._inputValue == null || isNaN(Number(this._inputValue)) ? null : Number(this._inputValue);
    this.stateChanges.next();
  }

  public focused = false;
  public errorState = false;
  public controlType = 'input-number';
  public autofilled?: boolean;
  public userAriaDescribedBy?: string;
  public stateChanges = new Subject<void>();

  private _disabled = false;
  private _required = false;
  private _placeholder: string;
  private _inputValue: string | null;
  private _value: number | null;
  private _decimal: number;
  private _step = 1;
  private validPattern = InputNumberComponent.FLOAT_PATTERN;

  @HostBinding() id = `input-number-${InputNumberComponent.nextId++}`; // nosonar

  constructor(
    @Optional() @Self() public ngControl: NgControl,
    @Optional() @Inject(MAT_FORM_FIELD) public _formField: MatFormField,
    private elRef: ElementRef<HTMLElement>,
    private _focusMonitor: FocusMonitor,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    _focusMonitor.monitor(elRef, true).subscribe(origin => {
      if (this.focused && !origin) {
        this.onTouched();
      }
      this.focused = !!origin;
      this.stateChanges.next();
      this.changeDetectorRef.markForCheck();
    });

    // Replace the provider from above with this.
    if (this.ngControl != null) {
      // Setting the value accessor directly (instead of using, the providers) to avoid running into a circular import.
      this.ngControl.valueAccessor = this;
    }
  }

  ngOnDestroy(): void {
    this.stateChanges.complete();
    this._focusMonitor.stopMonitoring(this.elRef);
  }

  @HostBinding('class.floating')
  get shouldLabelFloat(): boolean {
    return this.focused || !this.empty;
  }

  get empty(): boolean {
    return this.inputValue === null || this.inputValue === undefined || this.inputValue === '';
  }

  setDescribedByIds(ids: string[]): void {
    const controlElement = this.elRef.nativeElement.querySelector('.input-container');
    if (controlElement) {
      controlElement.setAttribute('aria-describedby', ids.join(' '));
    }
  }

  onContainerClick(event: MouseEvent): void {
    const tagName = (event.target as Element).tagName.toLowerCase();
    if (tagName !== 'input' && tagName !== 'button') {
      this.elRef.nativeElement.querySelector('input')?.focus();
    }
  }

  increase(event: Event): void {
    event.preventDefault();
    if (!this.value) {
      this.value = 0;
    }
    if (this.max == null) {
      this.value += this.step;
      if (this.decimal > 0) {
        this.value = this.roundDecimal(this.value);
      }
      this.handleInput();
    } else {
      if (this.value < this.max) {
        if (this.value + this.step > this.max) {
          this.value = this.max;
        } else {
          this.value += this.step;
        }
        if (this.decimal > 0) {
          this.value = this.roundDecimal(this.value);
        }
        this.handleInput();
      }
    }
  }

  decrease(event: Event): void {
    event.preventDefault();
    if (!this.value) {
      this.value = 0;
    }
    if (this.min == null) {
      this.value -= this.step;
      if (this.decimal > 0) {
        this.value = this.roundDecimal(this.value);
      }
      this.handleInput();
    } else {
      if (this.value > this.min) {
        if (this.value - this.step < this.min) {
          this.value = this.min;
        } else {
          this.value -= this.step;
        }
        if (this.decimal > 0) {
          this.value = this.roundDecimal(this.value);
        }
        this.handleInput();
      }
    }
  }

  roundDecimal(value: number): number {
    const pow = Math.pow(10, this.decimal);
    return Math.round(value * pow) / pow;
  }

  handleInput(): void {
    this.onChange(this.value);
    this.valueChange.emit({ value: this.value });
    this.changeDetectorRef.markForCheck();
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  onChange: any = () => {};
  onTouched: any = () => {};

  writeValue(value: string): void {
    this.value = value === null || isNaN(Number(value)) ? null : Number(value);
    this.changeDetectorRef.markForCheck();
  }

  registerOnChange(fn: (value: string) => string): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  //
  // Input validation
  //
  private getTextAfterInsertNewValue(
    currentVal: string,
    positionStart: number | null,
    positioneEnd: number | null,
    addedValue: string
  ): string {
    return currentVal.substring(0, positionStart ?? 0) + addedValue + currentVal.substring(positioneEnd ?? 0, currentVal.length);
  }

  private isValid(currentVal: string): boolean {
    return this.validPattern.test(currentVal);
  }

  public onKeydown(event: KeyboardEvent): void {
    if (!event.ctrlKey && event.key) {
      // If not a ctrl key.
      if (event.key === 'ArrowUp') {
        this.increase(event);
        return;
      }
      if (event.key === 'ArrowDown') {
        this.decrease(event);
        return;
      }
      if (event.key.length !== 1 || this.specialKeys.indexOf(event.key) !== -1) {
        return;
      }

      const target = event.target as HTMLInputElement;
      const value = this.getTextAfterInsertNewValue(target.value, target.selectionStart, target.selectionEnd, event.key);
      if (!this.isValid(value) || (this.min != null && Number(value) < this.min) || (this.max != null && Number(value) > this.max)) {
        event.preventDefault();
        return;
      }
    }
  }

  public onBlur(event: Event): void {
    const target = event.currentTarget as HTMLInputElement;
    const elemVal = target.value;
    if (elemVal?.endsWith('.')) {
      this.inputValue = elemVal.replace(/\./g, ''); // Remove point from the end of the float Field
    }
  }

  public onPaste(event: ClipboardEvent): void {
    const text = event.clipboardData?.getData('text/plain');
    const target = event.target as HTMLInputElement;
    const value = this.getTextAfterInsertNewValue(target.value, target.selectionStart, target.selectionEnd, text ?? '');
    if (!this.isValid(value)) {
      // If not matched pattern.
      event.preventDefault();
    }
  }

  public checkValue(): void {
    if (this.revertNullToZero && !this.value) {
      this.value = 0;
      this.handleInput();
    }
  }
}
