// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const arraysEqual = (array1: any, array2: any): boolean => {
  return array1.length === array2.length && array1.every((item: any, index: any) => objectsEqual(item, array2[index]));
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const objectsEqual = (object1: any, object2: any): boolean => {
  const keysObject1 = Object.keys(object1);
  return keysObject1.length === Object.keys(object2).length && keysObject1.every(p => object1[p] === object2[p]);
};
