<div class="table-model-form-group" #accordionContainerPanel>
  <mat-accordion multi>
    <mat-expansion-panel hideToggle [expanded]="expanded" (afterExpand)="scrollToSelectedTitle(true)">
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{ title }}
          <div class="buttons-container">
            <button
              mat-icon-button
              *ngIf="showActionButtons"
              [matTooltip]="'styleEditor.tableBorderRules.form.deleteToolTipMessage' | translate"
              (click)="deleteRule.emit()"
            >
              <mat-icon svgIcon="icon-trash"></mat-icon>
            </button>
            <button
              class="iconarrow-up"
              mat-icon-button
              *ngIf="showActionButtons"
              [disabled]="disableMoveUpButton"
              [matTooltip]="'styleEditor.tableBorderRules.form.moveUpToolTipMessage' | translate"
              (click)="moveRuleUp.emit(); $event.stopPropagation()"
            >
              <mat-icon svgIcon="icon-arrow-up"></mat-icon>
            </button>
            <button
              class="iconarrow-down"
              mat-icon-button
              *ngIf="showActionButtons"
              [disabled]="disableMoveDownButton"
              [matTooltip]="'styleEditor.tableBorderRules.form.moveDownToolTipMessage' | translate"
              (click)="moveRuleDown.emit(); $event.stopPropagation()"
            >
              <mat-icon svgIcon="icon-arrow-down"></mat-icon>
            </button>
          </div>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <ng-content></ng-content>
    </mat-expansion-panel>
  </mat-accordion>
</div>
