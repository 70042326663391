import { format as fnsFormatDate, parse as fnsParseDate } from 'date-fns';
import { AREVIO_DATE_PATTERN, TEXT_CASES } from 'app/shared/constants/patterns.constants';
import { getDateFnsLocaleByCode } from 'app/shared/util/date-fns.locale.utils';
import { DateFormat, DateShaping } from 'app/shared/model/format.model';
import { toTitleCase, toSentenceCase, toNonBreakingSpaces } from 'app/shared/util/format-commons.utils';

/**
 * Unicode Locale Data Markup Language (LDML)
 * Part 4: Dates
 * [https://www.unicode.org/reports/tr35/tr35-dates.html#Date_Field_Symbol_Table]
 * Characters from unicode standard taken into account:
 * - y : Calendar year (numeric)
 * - M : Month number/name
 * - d : Day of month (numeric)
 * Additionnal character from date-fns library:
 * - o : Ordinal number modifier
 * Les autres caractères du standard (GYuUrQqLlwWDFgEecabBhHKkjJCmsSAzZOvVXx) sont traités comme suit :
 * - Les caractères (GYuUrQqLlwWDFgEecabBhHKkjJCmsSAzZOvVXx) sont autorisés entre apostrophes ''.
 * - Les caractères () sont autorisés mais pas interprétés.
 * Reserved chars []#{} (See https://docs.oracle.com/en/java/javase/11/docs/api/java.base/java/time/format/DateTimeFormatter.html)
 */

export type FormatDateErrorType = {
  no: number;
  message: string;
};

type FormatDateErrors = {
  NOT_PAIRWISE_QUOTES: FormatDateErrorType;
  NOT_QUOTED_LATIN_CHARS: FormatDateErrorType;
  INVALID_DAY_PATTERN: FormatDateErrorType;
  INVALID_MONTH_PATTERN: FormatDateErrorType;
  INVALID_YEAR_PATTERN: FormatDateErrorType;
  NOT_QUOTED_ORPHAN_ORDINAL_CHAR: FormatDateErrorType;
  RESERVED_CHAR: FormatDateErrorType;
};

export const FORMAT_DATE_ERRORS: FormatDateErrors = {
  NOT_PAIRWISE_QUOTES: {
    no: 1,
    message: 'adminEditor.project-configuration.format.dates.patternErrors.notPairwiseQuotes',
  },
  NOT_QUOTED_LATIN_CHARS: {
    no: 2,
    message: 'adminEditor.project-configuration.format.dates.patternErrors.notQuotedLatinChars',
  },
  INVALID_DAY_PATTERN: {
    no: 3,
    message: 'adminEditor.project-configuration.format.dates.patternErrors.invalidDayPattern',
  },
  INVALID_MONTH_PATTERN: {
    no: 4,
    message: 'adminEditor.project-configuration.format.dates.patternErrors.invalidMonthPattern',
  },
  INVALID_YEAR_PATTERN: {
    no: 5,
    message: 'adminEditor.project-configuration.format.dates.patternErrors.invalidYearPattern',
  },
  NOT_QUOTED_ORPHAN_ORDINAL_CHAR: {
    no: 6,
    message: 'adminEditor.project-configuration.format.dates.patternErrors.notQuotedOrphanChar',
  },
  RESERVED_CHAR: {
    no: 7,
    message: 'adminEditor.project-configuration.format.dates.patternErrors.reservedChar',
  },
};

const reservedCharsPattern = '[\\[\\]\\#\\{\\}]+';
const notQuotedLatinCharsPattern = '[a-ce-np-xzA-LN-Z]+';
const notQuotedOrphanOrdinalCharPattern = '(?<![dMy])(?:o+)';
const candidateDayPattern = 'd+o*';
const validDayPattern = '^(?:d|do|dd)$';
const candidateMonthPattern = 'M+o*';
const validMonthPattern = '^(?:M|Mo|MM|MMM|MMMM|MMMMM)$';
const candidateYearPattern = 'y+o*';
const validYearPattern = '^(?:y+|yo)$';
const LDML_APOS = "'";

const containsOddNumberOfApos = (expression: string): boolean => {
  return expression.split(LDML_APOS).length % 2 === 0;
};

const notQuotedSegments = (expression: string): string[] => {
  return expression.split(LDML_APOS).flatMap((value, index) => {
    if (index % 2 === 0) {
      return !value ? [] : [value];
    } else {
      return [];
    }
  });
};

const getAllMatches = (input: string, regex: RegExp): string[] => {
  const matches: string[] = [];
  let match = regex.exec(input);
  while (match !== null) {
    matches.push(match[0]);
    match = regex.exec(input);
  }
  return matches;
};

const checkPatterns = (segments: string[], candidatePattern: string, validPattern: string): { pattern: string; isValid: boolean }[] => {
  return segments.flatMap(segment => {
    const matches: string[] = getAllMatches(segment, new RegExp(candidatePattern, 'g'));
    return matches.map((match: string) => {
      return {
        pattern: match,
        isValid: new RegExp(validPattern, 'g').test(match),
      };
    });
  });
};

const checkDayPatterns = (segments: string[]): { pattern: string; isValid: boolean }[] => {
  return checkPatterns(segments, candidateDayPattern, validDayPattern);
};

const checkMonthPatterns = (segments: string[]): { pattern: string; isValid: boolean }[] => {
  return checkPatterns(segments, candidateMonthPattern, validMonthPattern);
};

const checkYearPatterns = (segments: string[]): { pattern: string; isValid: boolean }[] => {
  return checkPatterns(segments, candidateYearPattern, validYearPattern);
};

export const checkDatePattern = (expression: string): { isValid: boolean; error?: FormatDateErrorType } => {
  // Check quotes.
  if (containsOddNumberOfApos(expression)) {
    return { isValid: false, error: FORMAT_DATE_ERRORS.NOT_PAIRWISE_QUOTES };
  }

  // Extract non quoted segments from the date pattern.
  const segments = notQuotedSegments(expression);

  // Check reserved chars.
  if (0 < segments.filter(segment => new RegExp(reservedCharsPattern, 'g').test(segment)).length) {
    return { isValid: false, error: FORMAT_DATE_ERRORS.RESERVED_CHAR };
  }

  // Check latin chars (other than dMyo).
  if (0 < segments.filter(segment => new RegExp(notQuotedLatinCharsPattern, 'g').test(segment)).length) {
    return { isValid: false, error: FORMAT_DATE_ERRORS.NOT_QUOTED_LATIN_CHARS };
  }

  // Check day patterns.
  const invalidDayPatterns = checkDayPatterns(segments).filter(pattern => !pattern.isValid);
  if (0 < invalidDayPatterns.length) {
    return { isValid: false, error: FORMAT_DATE_ERRORS.INVALID_DAY_PATTERN };
  }

  // Check month patterns.
  const invalidMonthPatterns = checkMonthPatterns(segments).filter(pattern => !pattern.isValid);
  if (0 < invalidMonthPatterns.length) {
    return { isValid: false, error: FORMAT_DATE_ERRORS.INVALID_MONTH_PATTERN };
  }

  // Check year patterns.
  const invalidYearPatterns = checkYearPatterns(segments).filter(pattern => !pattern.isValid);
  if (0 < invalidYearPatterns.length) {
    return { isValid: false, error: FORMAT_DATE_ERRORS.INVALID_YEAR_PATTERN };
  }

  // Check orphan number ordinal modifier (o).
  if (0 < segments.filter(segment => new RegExp(notQuotedOrphanOrdinalCharPattern, 'g').test(segment)).length) {
    return { isValid: false, error: FORMAT_DATE_ERRORS.NOT_QUOTED_ORPHAN_ORDINAL_CHAR };
  }

  return { isValid: true };
};

const applyTextCase = (value: string, textCase: string): string => {
  switch (TEXT_CASES[textCase]) {
    case TEXT_CASES.LOWER_CASE:
      return value.toLowerCase();
    case TEXT_CASES.UPPER_CASE:
      return value.toUpperCase();
    case TEXT_CASES.TITLE_CASE:
      return toTitleCase(value);
    case TEXT_CASES.SENTENCE_CASE:
      return toSentenceCase(value);
    default:
      return value;
  }
};

export const formatDateWithShape = (value: string | Date, shape: DateShaping, language: string, noBreakSpace = false): string => {
  const check = checkDatePattern(shape.datePattern);
  if (check.isValid) {
    const locale: Locale = getDateFnsLocaleByCode(language);
    const date: Date = value instanceof Date ? value : fnsParseDate(value, AREVIO_DATE_PATTERN, new Date());
    let formatted = fnsFormatDate(date, shape.datePattern, { locale });
    formatted = applyTextCase(formatted, shape.textCase);
    return noBreakSpace ? toNonBreakingSpaces(formatted) : formatted;
  }
  return '';
};

export const formatDate = (value: string | Date, format: DateFormat, language: string): string => {
  const shape: DateShaping = format.shapes.find(sh => sh.languages.includes(language)) ?? format.shapes[0];
  return formatDateWithShape(value, shape, language, format.noBreakSpace);
};
