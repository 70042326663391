// ANGULAR
import { Component, ChangeDetectionStrategy, OnInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import pubsub from 'app/pubsub';
import { EDITOR_LOOKUP_INSERT } from 'app/pubsub.topics';
import { ModalWidth } from '../../../shared/enum/modal-width.enum';
// SERVICES
import { PluginPanelService } from '../plugin-panel.service';
import { LookupService } from 'app/core/service/lookup.service';
// COMPONENTS
import { PluginComponent } from '../plugin.component';
// ENUMS, MODELS,
import { LookupType, LinkTypes, DisplayTypes, TypePageRef } from 'app/shared/enum/lookup-type.enum';
import { ILookup, ILookupEntry } from 'app/shared/model/lookup.model';
import { LookupInfosDialogComponent } from './lookup-infos-dialog/lookup-infos-dialog.component';

@Component({
  selector: 'jhi-lookup-panel',
  templateUrl: 'lookup-panel.component.html',
  styleUrls: ['lookup-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LookupPanelComponent extends PluginComponent implements OnInit, OnDestroy {
  public insertOrUpdateBtn = 'insertBtn';
  public lookupTypes: LookupType[] = [LookupType.PAGE, LookupType.TITLE_REF];
  public entries: ILookupEntry[] = [];
  public lookupType = new UntypedFormControl(LookupType.PAGE);
  public disableSave = true;

  private readonly subscriptions: Subscription = new Subscription();
  private _lookupSubscription: Subscription;

  constructor(
    pluginPanelService: PluginPanelService,
    private dialog: MatDialog,
    private changeDetectorRef: ChangeDetectorRef,
    private lookupService: LookupService
  ) {
    super(pluginPanelService);
  }

  ngOnInit(): void {
    // Get editor's selection
    this._lookupSubscription = this.lookupService.getSelectedLookupObservable().subscribe((element: ILookup) => {
      this.updateLookupFormValues(element);
    });

    this.updateLookupFormValues(this.lookupService.getActualLookup());
    this.subscriptions.add(this.lookupType.valueChanges.subscribe(() => this.updateType()));
  }

  private updateLookupFormValues(lookup: ILookup): void {
    this.lookupType.patchValue(lookup?.type ?? LookupType.PAGE);
    this.entries = [];
    if (lookup?.entries?.length) {
      lookup.entries.forEach(entry => this.addEntry(entry));
    } else {
      // Create empty entry if creation
      this.addEntry();
    }
  }

  private updateType(): void {
    this.entries.forEach((entry: ILookupEntry) => {
      entry.lookupType = this.lookupType.value;
      entry.displayType = '';
    });
    this.lookupService.changeLookupTypeValue(this.lookupType.value);
    this.changeDetectorRef.markForCheck();
  }

  public addEntry(entry?: ILookupEntry): void {
    this.entries.push({
      order: this.entries.length + 1,
      lookupType: this.lookupType.value,
      linkType: entry?.linkType ?? LinkTypes.DISJOINT,
      titles: entry?.titles ?? '',
      displayType: entry?.displayType ?? (this.lookupType.value === LookupType.PAGE ? DisplayTypes.PAGES : TypePageRef.FULL),
      addNewLine: entry?.addNewLine ?? false,
    });
    this.checkDisableState();
  }

  public deleteEntry(entryId: number): void {
    this.entries.splice(entryId, 1);
    this.entries = [...this.entries];
    this.checkDisableState();
  }

  public moveEntryUp({ entry, index }: { entry: ILookupEntry; index: number }): void {
    if (index > 0 && entry.order) {
      this.entries[index - 1].order = entry.order;
      this.updateEntry({ entry: this.entries[index - 1], index, moved: true });
      entry.order = entry.order - 1;
      this.updateEntry({ entry, index: index - 1, moved: true });
    }
  }

  public moveEntryDown({ entry, index }: { entry: ILookupEntry; index: number }): void {
    if (index < this.entries.length - 1 && entry.order) {
      this.entries[index + 1].order = entry.order;
      this.updateEntry({ entry: this.entries[index + 1], index, moved: true });
      entry.order = entry.order + 1;
      this.updateEntry({ entry, index: index + 1, moved: true });
    }
  }

  public updateEntry({ entry, index, moved }: { entry: ILookupEntry; index: number; moved: boolean }): void {
    if (moved) {
      this.entries.splice(index, 1, entry);
      this.entries = [...this.entries];
      this.changeDetectorRef.markForCheck();
    } else if (!moved) {
      this.entries[index].displayType = entry.displayType;
      this.entries[index].linkType = entry.linkType;
      this.entries[index].lookupType = entry.lookupType;
      this.entries[index].order = entry.order;
      this.entries[index].titles = entry.titles;
      this.entries[index].addNewLine = entry.addNewLine;
      this.changeDetectorRef.markForCheck();
    }

    this.checkDisableState();
  }

  public onClickCancel(): void {
    this.onClickClosePanel();
    this.changeDetectorRef.markForCheck();
  }

  checkDisableState(): void {
    // Disable save if an entry don't have selected titles
    const emptyEntries = this.entries.filter(entrie => !entrie.titles?.length);
    this.disableSave = !!emptyEntries.length;
    this.changeDetectorRef.markForCheck();
  }

  public onSaveBtn(): void {
    const lookup: ILookup = {
      type: this.lookupType.value,
      entries: this.entries,
    };
    pubsub.fire(EDITOR_LOOKUP_INSERT, { lookup }, this.getEditorTopicContext());
  }

  public displayHelpDialog(): void {
    this.dialog.open(LookupInfosDialogComponent, {
      width: ModalWidth.MEDIUM,
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    this._lookupSubscription.unsubscribe();
  }
}
