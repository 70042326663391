import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { SERVER_API_URL } from 'app/app.constants';
import { ILanguage } from 'app/shared/model/language.model';
import { JhiLanguageService } from 'app/shared/jhipster/service/language.service';
import { Observable } from 'rxjs';
import { ContextService } from './context.service';

@Injectable({ providedIn: 'root' })
export class LanguageService {
  private resourceUrl = `${SERVER_API_URL}/api/languages`;

  constructor(private httpClient: HttpClient, private contextService: ContextService, private languageService: JhiLanguageService) {}

  public getAllLanguages(): Observable<ILanguage[]> {
    const currentLanguage = this.languageService.getCurrentLanguage();
    return this.httpClient.get<ILanguage[]>(`${this.resourceUrl}/${currentLanguage}`);
  }

  public getProjectLanguages(): Observable<ILanguage[]> {
    const id = this.contextService.currentProjectId;
    const currentLanguage = this.languageService.getCurrentLanguage();
    return this.httpClient.get<ILanguage[]>(`${SERVER_API_URL}/api/${id}/languages/${currentLanguage}`);
  }
}
