<div class="main-block">
    <div class="header-block">
        <jhi-input-search [disabled]="!all?.length" [placeholder]="placeholder" (searchTermChanged)="filter($event)"></jhi-input-search>
    </div>
    <div class="content-block">
        <ng-container>
            <mat-tab-group>

                <ng-template #instantTmpl let-item>
                    <div class="period" (click)="onSelected(item)">
                        <div class="period-type">
                            <mat-icon [svgIcon]="item.periodType===PeriodType.INSTANT?'clock':'calendar'"></mat-icon>
                        </div>
                        <div class="period-date">
                            {{item.startDate|localizedDate:'shortDate'}}
                            <span *ngIf="item.periodType===PeriodType.PERIOD"> - {{item.endDate|localizedDate:'shortDate'}}</span>
                        </div>
                        <div class="period-name">{{item.periodName | dateLabel}}</div>
                    </div>
                </ng-template>

                <mat-tab [disabled]="!all.length" label="{{'dataEditor.date.tab.all'|translate:'{count:'+all.length+'}'}}" *ngIf="periodType!==PeriodType.PERIOD && periodType!==PeriodType.INSTANT">
                    <div class="table-wrapper">
                        <ng-container *ngFor="let item of all" [ngTemplateOutlet]="instantTmpl" [ngTemplateOutletContext]="{$implicit:item}"></ng-container>
                    </div>
                </mat-tab>
                <mat-tab [disabled]="!periods.length" label="{{'dataEditor.date.tab.period'|translate:'{count:'+periods.length+'}'}}" *ngIf="periodType==PeriodType.PERIOD">
                    <div class="table-wrapper">
                        <ng-container *ngFor="let item of periods" [ngTemplateOutlet]="instantTmpl" [ngTemplateOutletContext]="{$implicit:item}"></ng-container>
                    </div>
                </mat-tab>
                <mat-tab [disabled]="!instants.length" label="{{'dataEditor.date.tab.instant'|translate:'{count:'+instants.length+'}'}}" *ngIf="periodType==PeriodType.INSTANT">
                    <div class="table-wrapper">
                        <ng-container *ngFor="let item of instants" [ngTemplateOutlet]="instantTmpl" [ngTemplateOutletContext]="{$implicit:item}"></ng-container>
                    </div>
                </mat-tab>
            </mat-tab-group>
        </ng-container>
    </div>
</div>
