import { Component, ViewEncapsulation } from '@angular/core';
import { PluginComponent } from '../plugin.component';
import { PluginPanelService } from '../plugin-panel.service';
import { DocumentService } from 'app/core/service/document.service';
import { ContextService } from 'app/core/service/context.service';

import pubsub from 'app/pubsub';
import { EDITOR_PLUGIN_TOGGLEPANEL } from 'app/pubsub.topics';
import { PLUGIN_PANEL_COMPONENT_KEYS } from '../plugin-panel-component-keys';
import { Authority } from 'app/shared/enum/authority.enum';
import { MatDialog } from '@angular/material/dialog';
import { DialogErrorArrayComponent } from 'app/shared/dialog/error/export/dialog-error-array.component';
import { Observable } from 'rxjs';

@Component({
  selector: 'jhi-document-export',
  templateUrl: './document-export.component.html',
  styleUrls: ['./document-export.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DocumentExportComponent extends PluginComponent {
  public hasTaxonomyImported = this.contextService.hasTaxonomyImported;
  public hasRegulator = this.contextService.hasRegulator;
  public readonly Authority = Authority;

  constructor(
    pluginPanelService: PluginPanelService,
    private documentService: DocumentService,
    private contextService: ContextService,
    private dialog: MatDialog
  ) {
    super(pluginPanelService);
    // add class "export-plugin-panel" to the panel
    DocumentExportComponent.className = 'export-plugin-panel';
  }

  private factVerificationBeforeExport(): Observable<boolean> {
    return new Observable(observer => {
      this.documentService.verifyFactLink().subscribe(
        factVerif => {
          if (factVerif.length > 0) {
            this.dialog.open(DialogErrorArrayComponent, {
              data: {
                factVerif,
              },
            });
            observer.next(false);
          } else {
            observer.next(true);
          }
          observer.complete();
        },
        error => {
          observer.next(error);
          observer.complete();
        }
      );
    });
  }

  exportDocumentXHTML(): void {
    this.factVerificationBeforeExport().subscribe(result => {
      if (result) {
        this.documentService.downloadXHTMLExport();
      }
    });
  }

  exportDocumentXHTMLWithViewer(): void {
    this.factVerificationBeforeExport().subscribe(result => {
      if (result) {
        this.documentService.downloadXHTMLExportWithViewer();
      }
    });
  }

  exportDocumentESEF(): void {
    this.factVerificationBeforeExport().subscribe(result => {
      if (result) {
        pubsub.fire(EDITOR_PLUGIN_TOGGLEPANEL, {
          component: PLUGIN_PANEL_COMPONENT_KEYS.CONFIGURATION_EXPORT_ESEF,
          title: 'htmlEditor.plugins.configurationExport.title',
        });
      }
    });
  }

  // Below methods don't use factVerificationBeforeExport because
  // these formats don't need to generate XBRL tags, unlike those above.

  exportDocumentPDF(): void {
    pubsub.fire(EDITOR_PLUGIN_TOGGLEPANEL, {
      component: PLUGIN_PANEL_COMPONENT_KEYS.CONFIGURATION_EXPORT_PDF,
      title: 'htmlEditor.plugins.configurationExport.title',
    });
  }

  exportDocumentCleanedHTML(): void {
    this.documentService.downloadCleanedHTMLExport();
  }

  exportDocumentLore(): void {
    pubsub.fire(EDITOR_PLUGIN_TOGGLEPANEL, {
      component: PLUGIN_PANEL_COMPONENT_KEYS.CONFIGURATION_EXPORT_LORE,
      title: 'htmlEditor.plugins.configurationExport.title',
    });
  }

  exportDocumentWord(): void {
    pubsub.fire(EDITOR_PLUGIN_TOGGLEPANEL, {
      component: PLUGIN_PANEL_COMPONENT_KEYS.CONFIGURATION_EXPORT_WORD,
      title: 'htmlEditor.plugins.configurationExport.title',
    });
  }

  exportDocumentXLIFF(): void {
    pubsub.fire(EDITOR_PLUGIN_TOGGLEPANEL, {
      component: PLUGIN_PANEL_COMPONENT_KEYS.CONFIGURATION_EXPORT_XLIFF,
      title: 'htmlEditor.plugins.configurationExport.title',
    });
  }

  // exportDocumentSite(): void {
  //   alert('Not implemented');
  // }
}
