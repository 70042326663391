<div
  class="footnotes_container"
  *ngIf="footnote || footnotes?.length > 0"
  [ngStyle]="{ width: 'calc(21.02cm * ' + currentZoom / 100 + ')', 'margin-left': 'calc(-10.65cm * ' + currentZoom / 100 + ')' }"
>
  <div class="header">
    <h1 [jhiTranslate]="editionMode ? 'htmlEditor.plugins.footnote.title.editionMode' : 'htmlEditor.plugins.footnote.title.displayMode'">
      Note
    </h1>
    <div #footnoteEditorToolbarElement class="editor-toolbar" *ngIf="editionMode && isDisplayToolbar"></div>
  </div>

  <div class="edition_view custom-styles-container" *ngIf="editionMode">
    <div class="footnote_body eolng_footnote_body">
      <a class="footnote_marker eolng_footnote_marker">{{ (footnote?.index??0) + chapterOffset | indexToMarker:
        markerStyle }}</a>
      <div #footnoteEditorElement class="footnote_element eolng_footnote_element"></div>
    </div>

    <div class="actions-btn">
      <button type="button" mat-raised-button color="accent" (click)="cancel()">
        {{ 'htmlEditor.plugins.footnote.cancel' | translate }}
      </button>
      <button type="button" mat-raised-button color="primary" (click)="save()" [disabled]="!hasFootnoteEditorDataChanged">
        {{ 'htmlEditor.plugins.footnote.validate' | translate }}
      </button>
    </div>
  </div>

  <div class="display_view custom-styles-container" *ngIf="!editionMode">
    <div *ngFor="let footnote of footnotes; trackBy: trackFootnoteById; let footnoteIdx = index" class="footnote_item">
      <hr *ngIf="footnoteIdx > 0" />

      <div
        class="footnote_body_wrapper"
        [ngClass]="{ hovered: footnote.id === hoveredFootnote?.id }"
        (click)="!isReadOnlyMode && edit(footnote)"
        (mouseenter)="hoveredFootnote = footnote"
        (mouseleave)="hoveredFootnote = {}"
      >
        <div [hidden]="isReadOnlyMode || footnote.id !== hoveredFootnote?.id">
          <button class="remove-btn" type="button" mat-icon-button color="accent" (click)="remove(footnote, $event)">
            <mat-icon svgIcon="icon-trash"></mat-icon>
          </button>
        </div>
        <div class="footnote_body eolng_footnote_body">
          <span
            class="footnote_marker eolng_footnote_marker"
            [ngStyle]="{ transform: 'scale(' + currentZoom / 100 + ')', '-moz-transform': 'scale(' + currentZoom / 100 + ')' }"
            >{{ footnote.index + this.chapterOffset | indexToMarker: markerStyle }}</span
          >
          <div
            contenteditable="true"
            spellcheck="false"
            #displayedFootnotes
            class="footnote_element eolng_footnote_element"
            [innerHTML]="footnote.content | safeHtml"
            (focus)="disableEditableContent(displayedFootnotes)"
          ></div>
        </div>
      </div>
    </div>
  </div>
</div>
