<div id="import_container">
  <div class="titleName">
    <span jhiTranslate="adminEditor.{{ type }}.importExport.title"></span>
    <div class="buttons-container">
      <div>
        <button
          id="import_button"
          *ngIf="!importedFile && showInput == true"
          mat-flat-button
          color="primary"
          (click)="clickOnInput(fileInput)"
        >
          + <span jhiTranslate="adminEditor.upload.importFileButton"></span>
        </button>
      </div>

      <div [matTooltip]="disableExport ? tooltipOnDisabledExport : null">
        <button
          id="export_button"
          *ngIf="showExport == true"
          mat-flat-button
          color="primary"
          [disabled]="disableExport"
          (click)="clickOnExport()"
        >
          ↓ <span jhiTranslate="adminEditor.download.exportFileButton"></span>
        </button>
      </div>
    </div>
  </div>
  <hr />

  <div class="file-container">
    <input #fileInput type="file" (change)="onFileChanged($event)" [accept]="formats" />

    <div class="file_row" *ngIf="importedFile">
      <mat-icon svgIcon="document" color="primary"></mat-icon>
      <div class="file_name">{{ importedFile.name }}</div>

      <button *ngIf="importedFile" mat-icon-button class="delete_file" color="primary" (click)="reset()">
        <mat-icon svgIcon="big-cross"></mat-icon>
      </button>
    </div>

    <div class="button_container" *ngIf="importedFile && !openDocumentaryUnits?.length">
      <button (click)="import(false)" class="action_button" color="primary" mat-flat-button>
        <span jhiTranslate="adminEditor.upload.apply"></span>
      </button>
      <div *ngIf="type == 'xliff'">
        <button (click)="import(true)" *jhiHasAnyAuthority="[Authority.SUPER_ADMIN]" class="action_button" color="primary" mat-flat-button>
          <span jhiTranslate="adminEditor.upload.applyWithFixes"></span>
        </button>
      </div>
    </div>
  </div>
</div>

<div id="import_result" *ngIf="importResponse">
  <hr />
  <div *ngIf="!importResponse.errors">
    <div class="important">
      <mat-icon svgIcon="valid-30"></mat-icon>
      <p>{{ importResponse.message }}</p>
    </div>
  </div>
  <div *ngIf="importResponse.errors">
    <div class="important">
      <mat-icon svgIcon="error-30"></mat-icon>
      <p>{{ importResponse.message }}</p>
    </div>
    <ul>
      <li *ngFor="let error of importResponse.errors">{{ error }}</li>
    </ul>
  </div>
</div>

<div id="open_UD" *ngIf="openDocumentaryUnits?.length">
  <hr />
  <div class="important">
    <mat-icon svgIcon="warning"></mat-icon>
    <p jhiTranslate="adminEditor.xliff.importExport.openUD"></p>

    <button (click)="importXliff(false)" class="action_button" color="primary" mat-flat-button>
      <span jhiTranslate="adminEditor.xliff.importExport.import"></span>
    </button>

    <button (click)="importXliff(true)" *jhiHasAnyAuthority="[Authority.SUPER_ADMIN]" class="action_button" color="primary" mat-flat-button>
      <span jhiTranslate="adminEditor.xliff.importExport.importWithFixes"></span>
    </button>
  </div>
  <ul>
    <li *ngFor="let openUD of openDocumentaryUnits">
      <a target="_blank" href="/document/{{ openUD.documentId }}/html-editor?sectionId={{ openUD.documentaryUnitId }}">
        <p
          jhiTranslate="adminEditor.xliff.importExport.openUDDetail"
          [translateValues]="{
            docId: openUD.documentId,
            docName: openUD.documentName,
            udId: openUD.documentaryUnitId,
            udName: openUD.documentaryUnitName
          }"
        ></p>
      </a>
    </li>
  </ul>
</div>
