import { Component, ViewEncapsulation, OnInit, Input } from '@angular/core';

import { PluginComponent } from '../../plugin.component';
import { PluginPanelService } from '../../plugin-panel.service';
import { DocumentService } from 'app/core/service/document.service';
import { LanguageService } from 'app/core/service/language.service';
import { ContextService } from 'app/core/service/context.service';

import { ILanguage } from 'app/shared/model/language.model';
import { IFact } from 'app/shared/model/fact.model';
import pubsub from 'app/pubsub';
import { EDITOR_PLUGIN_TOGGLEPANEL } from 'app/pubsub.topics';
import { PLUGIN_PANEL_COMPONENT_KEYS } from '../../plugin-panel-component-keys';
import { Authority } from 'app/shared/enum/authority.enum';
import { ExportType } from 'app/shared/model/download-manager.model';

import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'jhi-configuration-export-xliff',
  templateUrl: './configuration-export-xliff.component.html',
  styleUrls: ['./configuration-export-xliff.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ConfigurationExportXliffComponent extends PluginComponent implements OnInit {
  public quality: string;
  public export = ExportType.DOCUMENTARY_UNIT;
  public readonly Authority = Authority;
  public languages: ILanguage[] = [];
  public sourceLanguage: string;
  public selectedLanguage: string;
  private _fact: IFact;
  public readonly ExportType = ExportType;
  public marksToggle = true;

  constructor(
    private contextService: ContextService,
    public pluginPanelService: PluginPanelService,
    private documentService: DocumentService,
    private languageService: LanguageService,
    protected activatedRoute: ActivatedRoute
  ) {
    super(pluginPanelService);
  }

  @Input()
  get fact(): IFact {
    return this._fact;
  }

  ngOnInit(): void {
    this.languageService.getProjectLanguages().subscribe((list: ILanguage[]) => {
      // Since the file should have the translation of the current's document language,
      // we remove it (the current document language) out of the export options dropdown
      this.sourceLanguage = list
        .filter(language => language.code === this.contextService.currentDocumentContext.language.code)
        .map(item => item.code)
        .join();
      this.languages = list.filter(language => language.code !== this.contextService.currentDocumentContext.language.code);
    });

    this.activatedRoute.data.subscribe((data: any) => {
      this.marksToggle = data.clientDomains?.length ? true : false;
    });
  }

  goBack(): void {
    pubsub.fire(EDITOR_PLUGIN_TOGGLEPANEL, {
      component: PLUGIN_PANEL_COMPONENT_KEYS.DOCUMENT_EXPORT,
      title: 'htmlEditor.plugins.documentExport.title',
    });
  }

  setExport(exportType: ExportType): void {
    this.export = exportType;
  }

  exportDocumentXliff(): void {
    const language = {
      sourceLanguage: this.sourceLanguage,
      targetLanguage: this.selectedLanguage,
    };
    this.documentService.downloadXLIFFExport(language, this.export, this.marksToggle);
    this.pluginPanelService.closePanel();
  }
}
