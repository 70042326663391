<h1 mat-dialog-title>
  <span jhiTranslate="htmlEditor.plugins.lookup.helpDialog.title"></span>
  <button type="button" mat-icon-button class="mat-dialog-close-btn" (click)="close()">
    <fa-icon icon="times"></fa-icon>
  </button>
</h1>

<div mat-dialog-content class="lookup-infos">
  <p class="lookup-detail" jhiTranslate="htmlEditor.plugins.lookup.helpDialog.detail"></p>
  <p class="lookup-detail-example" jhiTranslate="htmlEditor.plugins.lookup.helpDialog.detailExample"></p>
  <div>
    <div jhiTranslate="htmlEditor.plugins.lookup.helpDialog.examples"></div>
    <ul>
      <li *ngFor="let numExample of ['1', '2', '3', '4']">
        <span class="lookup-example" jhiTranslate="htmlEditor.plugins.lookup.helpDialog.example{{ numExample }}"></span>
      </li>
    </ul>
  </div>
</div>
