import { Component, EventEmitter, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '../../../dialog/confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'jhi-save-button',
  templateUrl: './save-button.component.html',
  styleUrls: ['./save-button.component.scss'],
})
export class SaveButtonComponent {
  @Output() private saveCKDocument = new EventEmitter<boolean>();

  constructor(private dialog: MatDialog) {}

  public save(): void {
    const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
      data: {
        message: 'dialog.confirmation.save.message',
      },
    });
    confirmDialog.afterClosed().subscribe(result => {
      if (result) {
        this.saveCKDocument.emit(true);
      }
    });
  }
}
