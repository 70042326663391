import { Component, ViewEncapsulation, OnInit, OnDestroy, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { PluginComponent } from '../../plugin.component';
import { IStyle } from 'app/shared/model/style.model';
import pubsub from 'app/pubsub';
import { ASK_APPLIED_STYLE, APPLIED_STYLE, EDITOR_PLUGIN_APPLYSTYLE } from 'app/pubsub.topics';
import { ExtraCharactersFolders, StyleCategory, StyleCategoryAllowed } from 'app/shared/enum/style-category.enum';
import { PluginPanelService } from '../../plugin-panel.service';
import { PluginsCommand } from '../../plugins-commands';
import { isContainingBorder, isInXbrlFootnote, isContainingXbrlFootnote, isInTable } from 'editor/plugins/styling/styling-utils';
import { ICKStyles } from 'app/core/user/ck-config.model';

@Component({
  selector: 'jhi-styling-panel-plugin',
  templateUrl: './styling-panel.component.html',
  styleUrls: ['./styling-panel.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StylingPanelComponent extends PluginComponent implements OnInit, OnDestroy {
  public panel: string;
  public styles: IStyle[];
  public open: boolean[] = [];
  public activated: string[] = [];
  public stylecategory = StyleCategory;
  public disableBorder = false;
  public disableTitle = false;
  public allowCategory: string[] = [];

  private _hightlightAppliedStyleSubscription: Function;

  constructor(pluginPanelService: PluginPanelService, private changeDetectorRef: ChangeDetectorRef) {
    super(pluginPanelService);
  }

  ngOnInit(): void {
    this.styles = this._orderList((this.editor?.config.get('styles') as ICKStyles).list);
    this._hightlightAppliedStyleSubscription = this.hightlightAppliedStyle.bind(this);
    pubsub.on(APPLIED_STYLE, this._hightlightAppliedStyleSubscription);
    pubsub.fire(ASK_APPLIED_STYLE, this.getEditorTopicContext());
    const {
      model: {
        document: { selection },
      },
    } = this.editor;
    const containingBorder = isContainingBorder(selection.getSelectedBlocks());
    const inXbrlFootnote = isInXbrlFootnote(selection.getSelectedBlocks());
    const containingXbrlFootnote = isContainingXbrlFootnote(selection.getSelectedBlocks());
    const inTable = isInTable(selection.getSelectedBlocks());

    this.disableBorder = this.isDisableBorder(containingBorder);
    this.disableTitle = this.isDisableTitle(inXbrlFootnote, containingXbrlFootnote, inTable);
  }

  public ngOnDestroy(): void {
    pubsub.off(APPLIED_STYLE, this._hightlightAppliedStyleSubscription);
  }

  public removeFormat(): void {
    this.editor?.execute(PluginsCommand.REMOVE_STYLE);
  }

  _orderList(styleList: IStyle[]): IStyle[] {
    Object.values(StyleCategoryAllowed).forEach((category: string) => {
      this.allowCategory.push(category);
    });

    return styleList.reduce((a: IStyle[], b: IStyle) => {
      if (b.applicable === true && this.allowCategory.includes(b.category)) {
        if (b.category === StyleCategory.CHARACTERS && ExtraCharactersFolders.includes(b.indesignFolder ?? '')) {
          a[StyleCategoryAllowed.EXTRA_CHARACTERS] = a[StyleCategoryAllowed.EXTRA_CHARACTERS] || [];
          a[StyleCategoryAllowed.EXTRA_CHARACTERS].push(b);
        } else {
          a[b.category] = a[b.category] || [];
          a[b.category].push(b);
        }
      }
      return a;
    }, []);
  }

  applyStyle(style: IStyle, disabled = false): void {
    if (!disabled) {
      pubsub.fire(EDITOR_PLUGIN_APPLYSTYLE, { style }, this.getEditorTopicContext());
    }
  }

  hightlightAppliedStyle({ detail }: CustomEvent): void {
    this.activated = [];
    const styleApplied = (this.editor?.config.get('styles') as ICKStyles).list.find((e: IStyle) => e.classToApply === detail.classToApply);

    if (styleApplied?.classToApply) {
      this.activated[styleApplied.classToApply] = 'activated';
      this.activated[styleApplied.category] = 'activated';
    }
    this.disableBorder = this.isDisableBorder(detail.containingBorder);
    this.disableTitle = this.isDisableTitle(detail.inXbrlFootnote, detail.containingXbrlFootnote, detail.inTable);
    this.changeDetectorRef.markForCheck();
  }

  public getTooltip(element: HTMLElement, name: string): string | null {
    const truncated = element ? element.scrollWidth > element.clientWidth : false;
    return truncated ? name : null;
  }

  private isDisableBorder(containingBorder: boolean): boolean {
    return containingBorder;
  }

  private isDisableTitle(inXbrlFootnote: boolean, containingXbrlFootnote: boolean, inTable: boolean): boolean {
    return inXbrlFootnote || containingXbrlFootnote || inTable;
  }
}
