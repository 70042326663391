<div class="plugin-container version-tracking-plugin-container">
  <div>
    <jhi-version-filter-form (filterHistory)="filterHistory($event)" [sections]="sections"></jhi-version-filter-form>
  </div>
  <div class="track-changes" [class.hidden]="!isFiltered">
    <div class="viewer-bar">
      <button mat-icon-button class="plugin-close-btn" (click)="hideTrackChanges()">
        <mat-icon svgIcon="chevron-right"></mat-icon>
      </button>
      <span *ngIf="firstDate && lastDate"
        >{{ 'htmlEditor.plugins.versions-tracking.from' | translate }}{{ firstDate | localizedDate: 'mediumDate' }}
        {{ 'htmlEditor.plugins.versions-tracking.to' | translate }}{{ lastDate | localizedDate: 'mediumDate' }}</span
      >
      <button (click)="exportChanges(true, true)" [disabled]="!lastDate" class="btn-export" color="primary" mat-raised-button>
        <span jhiTranslate="htmlEditor.plugins.versions-tracking.export_pdf_paginated"></span>
      </button>
      <button (click)="exportChanges(true, false)" [disabled]="!lastDate" class="btn-export" color="primary" mat-raised-button>
        <span jhiTranslate="htmlEditor.plugins.versions-tracking.export_pdf"></span>
      </button>
      <button (click)="exportChanges(false, false)" [disabled]="!lastDate" class="btn-export" color="primary" mat-raised-button>
        <span jhiTranslate="htmlEditor.plugins.versions-tracking.export_html"></span>
      </button>
      <button [disabled]="filterLoading" mat-raised-button color="primary" class="btn-display-sidenav" (click)="displaySideNav()">
        <span jhiTranslate="htmlEditor.plugins.versions-tracking.{{ documentStylesheet ? 'show' : 'hide' }}_filters"></span>
      </button>
      <ng-template #lastSavedVersion>
        <span class="label-version" jhiTranslate="htmlEditor.plugins.versions-tracking.actual"></span>
      </ng-template>
    </div>
    <jhi-loading [show]="filterLoading"></jhi-loading>
    <div class="sticker-container">
      <jhi-resizable-layout *ngIf="this.stickerModificationsDocument">
        <jhi-resizable
          class="side-by-side__editor"
          [directions]="['right']"
          minSize="20%"
          (resizeStart)="onResizeVerticalPanelStart()"
          (resizeEnd)="onResizeVerticalPanelEnd()"
        >
          <div id="stickerDocument" class="track-changes-content">
            <div class="html-document" *ngIf="stickerHtmlDocument?.loaded && stickerHtmlDocument?.display" style="transform: scale(1)">
              <div [innerHTML]="stickerHtmlDocument?.content | safeHtml"></div>
            </div>
          </div>
        </jhi-resizable>
        <jhi-resizable class="side-by-side__pages-preview" minSize="10%">
          <div id="stickerModifications" class="track-changes-modifications">
            <div class="no_result" *ngIf="!filterLoading && versionsDates?.length === 0" jhiTranslate="htmlEditor.plugins.versions-tracking.no_result"></div>
            <mat-accordion multi *ngIf="versionsDates?.length" class="standard">
              <mat-expansion-panel
                hideToggle
                *ngFor="let date of versionsDates; let dateIndex = index"
                [expanded]="modificationsPanelExpanded[dateIndex]"
                (opened)="setModificationsPanelExpanded(dateIndex)"
                (closed)="setModificationsPanelExpanded(dateIndex, true)"
              >
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    <ng-container *ngIf="isToday(date); else notToday">
                      <span jhiTranslate="htmlEditor.plugins.versions.today"></span>
                    </ng-container>
                    <ng-template #notToday>
                      <span>{{ date | localizedDate: 'longDate' }}</span>
                    </ng-template>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <div
                  [id]="stickerModification.sha1 + '_highlight'"
                  class="modifications-container"
                  *ngFor="
                    let stickerModification of stickerModificationsDocument.stickerModificationByDates[date];
                    let stickerIndex = index
                  "
                  (click)="setTrackChanges(stickerModification)"
                >
                  <div class="sticker-information-content">
                    <div class="information-content commit-card">
                      <div>
                        <div class="datetime">{{ stickerModification.modificationDate | localizedDate: 'medium' }}</div>
                        <div *ngIf="dateIndex === 0 && stickerIndex === 0" jhiTranslate="htmlEditor.plugins.versions.actual"></div>
                        <div class="user">{{ stickerModification.user }}</div>
                        <div>{{ stickerModification.section }}</div>
                        <div
                          *ngIf="stickerModification.commitIsImportWord"
                          jhiTranslate="htmlEditor.plugins.versions-tracking.importWord"
                        ></div>
                      </div>
                    </div>
                    <div class="sticker-content">
                      <button class="sticker">
                        <a [id]="stickerModification.sha1 + '_modifs'">{{ stickerModification.number }}</a>
                      </button>
                    </div>
                  </div>
                  <div class="diff-container" *ngIf="stickerModification.diff" [innerHTML]="stickerModification.diff | safeHtml"></div>
                </div>
              </mat-expansion-panel>
            </mat-accordion>
          </div>
        </jhi-resizable>
      </jhi-resizable-layout>
    </div>
  </div>
</div>
