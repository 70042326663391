import { Component, ChangeDetectionStrategy, ChangeDetectorRef, Output, EventEmitter, Input } from '@angular/core';
import { Subject } from 'rxjs';

import { IAxisMemberItem } from 'app/shared/model/dimension.model';
import { ConceptTranslatedLabelPipe } from 'app/shared/pipe/concept-translated-label.pipe';

@Component({
  selector: 'jhi-dimension-list',
  templateUrl: './dimension-list.component.html',
  styleUrls: ['./dimension-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DimensionListComponent {
  @Input()
  set dimensions(dimensions: IAxisMemberItem[]) {
    this.filteredDimensions = dimensions;
    this._dimensions = dimensions;
  }

  get dimensions(): IAxisMemberItem[] {
    return this.filteredDimensions;
  }

  @Output() public selected = new EventEmitter();

  public filtered = false;

  private filteredDimensions: IAxisMemberItem[];
  private _dimensions: IAxisMemberItem[];

  public searchTermChanged: Subject<string> = new Subject<string>();

  constructor(private changeDetectorRef: ChangeDetectorRef, private conceptTranslatedLabelPipe: ConceptTranslatedLabelPipe) {}

  public filter(filterText: string): void {
    this.filtered = !!filterText;
    if (filterText) {
      const search = filterText.toLowerCase();
      this.filteredDimensions = this._dimensions.filter(
        dimension =>
          dimension.qname?.toLowerCase().includes(search) ||
          this.conceptTranslatedLabelPipe.transform(dimension).toLowerCase().includes(search)
      );
    } else {
      this.filteredDimensions = this._dimensions;
    }
    this.changeDetectorRef.markForCheck();
  }

  public onSelected(index: number): void {
    this.selected.emit(index);
  }
}
