<div class="special-characters-plugin-container">
    <mat-select class="symbolCategory" [(ngModel)]="currentSelectedOption" (ngModelChange)="changeCategory()" panelClass="force-panel-under-field">
        <mat-option *ngFor="let symbolCategory of symbolCategoryList" [value]="symbolCategory">
            {{symbolCategory}}
        </mat-option>
    </mat-select>
    <div #topCharPanel class="spclchar-scroll">
        <mat-divider></mat-divider>
        <mat-grid-list cols="7" rowHeight="1:1">
            <ng-container *ngFor="let symbol of selectedSymbols">
                <mat-grid-tile (click)="insertSymbol(symbol)" [style]="currentFontFamily"
                    (mouseover)="onMouseover(symbol)" class="symbol" (mouseout)="onMouseleave()">
                    {{symbol.character}}
                </mat-grid-tile>
            </ng-container>
        </mat-grid-list>
    </div>
    <div *ngIf="symbolTitle || unicode" class="highlighted-symbol">
        <span *ngIf="symbolTitle" class="highlighted-symbol-text">{{symbolTitle}}</span>
        <span *ngIf="unicode" class="highlighted-symbol-text">{{unicode}}</span>
    </div>
</div>