import {
  Component,
  OnInit,
  Input,
  OnDestroy,
  Output,
  ElementRef,
  EventEmitter,
  ViewChild,
  QueryList,
  ViewChildren,
  Renderer2,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
} from '@angular/core';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';

import CKEditorInspector from '@ckeditor/ckeditor5-inspector';

import pubsub from 'app/pubsub';
import {
  EDITOR_FOOTNOTE_REMOVE,
  EDITOR_FOOTNOTE_INSERT,
  EDITOR_FOOTNOTE_TOGGLE,
  CONTEXT_WSC_INIT_INSTANCE,
  EDITOR_FOOTNOTE_UPDATE,
  EDITOR_XBLR_ERRORS_TOGGLE,
} from 'app/pubsub.topics';

import { DeletionConfirmationComponent } from 'app/shared/dialog/deletion-confirmation/deletion-confirmation.component';

import { IFootnote } from 'app/shared/model/footnote.model';
import { IStyle } from 'app/shared/model/style.model';
import { StyleCategory } from 'app/shared/enum/style-category.enum';
import { generateMarkerLabel } from 'app/shared/util/marker.utils';
import { ProfileService } from 'app/layouts/profiles/profile.service';
import { ProfileInfo } from 'app/layouts/profiles/profile-info.model';
import { FOOTNOTE_EDITOR_TOOLBAR_AUTH } from './footnote-authorities.util';
import { AccountService } from 'app/core/auth/account.service';

@Component({
  selector: 'jhi-footnote',
  templateUrl: 'footnote.component.html',
  styleUrls: ['footnote.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FootnoteComponent implements OnInit, OnDestroy {
  @Input()
  public contextConfiguration: any;
  @Input()
  public editor: any;
  @Input()
  public documentId: number;
  @Input()
  public sectionId: number;
  @Input()
  public currentZoom = 100;
  @Input()
  public chapterOffset = 0;

  @Input()
  set documentStyles(documentStyles: IStyle[]) {
    this._documentStyles = documentStyles;
    const footnoteStyle = this._documentStyles?.find(style => style.category === StyleCategory.FOOTNOTE);
    this.markerStyle =
      footnoteStyle?.childrenStyles?.find(childStyle => childStyle.category === StyleCategory.FOOTNOTE_MARKER) ?? ({} as IStyle);
  }
  public markerStyle: IStyle;

  public isDisplayToolbar = true;

  @Output()
  public onPanelHeightChange: EventEmitter<number> = new EventEmitter<number>();

  @ViewChild('footnoteEditorToolbarElement', { static: false }) footnoteEditorToolbarElement: ElementRef<HTMLElement>;
  @ViewChild('footnoteEditorElement', { static: false }) footnoteEditorElement: ElementRef<HTMLElement>;
  @ViewChildren('displayedFootnotes')
  set displayedFootnotes(footnotesList: QueryList<ElementRef<HTMLElement>>) {
    footnotesList.forEach(displayedFootnote => {
      // Create WSC instance
      pubsub.fire(CONTEXT_WSC_INIT_INSTANCE, { container: displayedFootnote.nativeElement, enableReplaceText: false });
    });
  }

  public footnotes: IFootnote[];
  public footnote: IFootnote | null;
  public hoveredFootnote: IFootnote;
  public footnoteContentBackup: string;

  public editionMode = false;
  public footnoteEditorInstance: any;
  public hasFootnoteEditorDataChanged = false;
  public isReadOnlyMode = false;
  public hasXbrlErrorsPannelDisplayed = false;

  private _toggleSubscription: Function;
  private _toggleXbrlErrorsSubscription: Function;
  private _subscriptions: Subscription = new Subscription();
  private _developmentMode = false;
  private _documentStyles: IStyle[] = [];

  private previousPaddingValue = 0;

  constructor(
    public dialog: MatDialog,
    private element: ElementRef,
    private renderer: Renderer2,
    private changeDetectorRef: ChangeDetectorRef,
    private profileService: ProfileService,
    private accountService: AccountService
  ) {}

  public ngOnInit(): void {
    this._toggleSubscription = this._togglePanel.bind(this);
    pubsub.on(EDITOR_FOOTNOTE_TOGGLE, this._toggleSubscription);
    this._subscriptions.add(
      this.profileService.getProfileInfo().subscribe((profileInfo: ProfileInfo) => (this._developmentMode = !profileInfo.inProduction))
    );
    this._toggleXbrlErrorsSubscription = this._toggleXbrlErrorsPanel.bind(this);
    pubsub.on(EDITOR_XBLR_ERRORS_TOGGLE, this._toggleXbrlErrorsSubscription);
  }

  public trackFootnoteById(footnote: IFootnote): string {
    return footnote.id ?? '';
  }

  public edit(footnote: IFootnote): void {
    this.editionMode = true;
    this.hasFootnoteEditorDataChanged = false;
    this.footnote = footnote;
    // In case of edition and cancel
    this.footnoteContentBackup = footnote.content ?? '';

    this.changeDetectorRef.detectChanges();
    this._createFootnoteEditor(this.footnoteEditorElement.nativeElement, this.footnote);
  }

  public remove(footnote: IFootnote, event: Event): void {
    event.stopPropagation();
    const dialogRef: MatDialogRef<DeletionConfirmationComponent> = this.dialog.open(DeletionConfirmationComponent, {
      data: {
        type: 'footnote',
        name: `${generateMarkerLabel(this.markerStyle, footnote.index)}`,
      },
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result && footnote.id) {
        const id: string = footnote.id;

        pubsub.fire(EDITOR_FOOTNOTE_REMOVE, { id }, this.editor.config.get('editorId'));
        this.footnotes = this.footnotes.filter((fn: IFootnote) => fn.id !== id);
        this.changeDetectorRef.markForCheck();
      }
    });
  }

  public save(): void {
    if (!this.footnote) {
      return;
    }
    this.footnote.content = this.footnoteEditorInstance.getData();

    pubsub.fire(this.footnote.id ? EDITOR_FOOTNOTE_UPDATE : EDITOR_FOOTNOTE_INSERT, this.footnote, this.editor.config.get('editorId'));
    this.editionMode = false;

    this.hoveredFootnote = {};
    this.changeDetectorRef.markForCheck();
  }

  public cancel(): void {
    this.editionMode = false;
    if (this.footnote?.id) {
      this.footnote.content = this.footnoteContentBackup;
    } else {
      this.footnote = null;
    }
    this.hoveredFootnote = {};
    this.changeDetectorRef.markForCheck();
  }

  public ngOnDestroy(): void {
    pubsub.off(EDITOR_FOOTNOTE_TOGGLE, this._toggleSubscription);
    pubsub.off(EDITOR_XBLR_ERRORS_TOGGLE, this._toggleXbrlErrorsSubscription);
    this._subscriptions.unsubscribe();
  }

  public disableEditableContent(displayedFootnote: HTMLElement): void {
    // ContentEditable attribute was add to allow WSC to apply on container
    // But we don't want the user to be able to edit in displayed mode
    displayedFootnote.setAttribute('contenteditable', 'false');
  }

  private _toggleXbrlErrorsPanel({ detail }: CustomEvent): void {
    this.hasXbrlErrorsPannelDisplayed = detail.enabled;
  }
  // Map<id, index> of footnotes

  private _togglePanel({ detail }: CustomEvent): void {
    if (!this.hasXbrlErrorsPannelDisplayed) {
      // Map<id, index> of footnotes
      const footnotesMap: Map<string | null, IFootnote> = detail?.footnotesMap;
      this.isReadOnlyMode = detail?.isReadOnlyMode ?? false;
      if (footnotesMap?.size === 1 && footnotesMap.has(null)) {
        // ID null means footnote creation request
        // Maybe to refactor with dedicated event
        const footnoteIndex = footnotesMap.get(null)?.index;
        // Creation
        const footnote: IFootnote = {
          index: footnoteIndex,
          content: '',
        };

        this.edit(footnote);
      } else if (footnotesMap?.size > 0) {
        this.footnotes = Array.from(footnotesMap.entries()).map((item: any) => {
          return { id: item[0], index: item[1].index, content: item[1].content };
        });
        this.changeDetectorRef.markForCheck();

        // Apply change and wait for the view to refresh before sending height to eolPage
        setTimeout(() => {
          const newValue = this.element.nativeElement?.firstElementChild?.clientHeight ?? 0;
          if (this.previousPaddingValue !== newValue) {
            this.onPanelHeightChange.emit(newValue);
            this.previousPaddingValue = newValue;
          }
        });
      } else {
        if (!this.editionMode) {
          this._resetPanel();
        }
      }
    }
  }

  private _resetPanel(): void {
    // Close panel
    this.footnote = null;
    this.footnotes = [];
    this.editionMode = false;
    this.onPanelHeightChange.emit(0);
    this.previousPaddingValue = 0;
    this.changeDetectorRef.markForCheck();
  }

  private _createFootnoteEditor(editorElement: HTMLElement, footnote: IFootnote): void {
    CKEditor.FootnoteEditor.create(editorElement, {
      ...this.contextConfiguration,
      initialData: footnote.content,
      styles: { list: this._documentStyles },
      toolbar: this.buildToolbar(),
      link: { defaultProtocol: 'https://' },
    }).then((editor: any) => {
      this.footnoteEditorInstance = editor;
      // Add toolbar to view
      this.renderer.appendChild(this.footnoteEditorToolbarElement.nativeElement, this.footnoteEditorInstance.ui.view.toolbar.element);
      // Detect change to enable validate btn
      this.footnoteEditorInstance.model.document.on('change:data', () => {
        this.hasFootnoteEditorDataChanged = true;
        this.changeDetectorRef.markForCheck();
      });
      // Disable browser spellcheck and create WSC instance
      this.footnoteEditorInstance.editing.view.change((writer: any) => {
        writer.setAttribute('spellcheck', 'false', this.footnoteEditorInstance.editing.view.document.getRoot());
      });

      this.footnoteEditorInstance.editing.view.focus();
      if (this._developmentMode) {
        CKEditorInspector.attach(this.footnoteEditorInstance);
      }

      editor.on('destroy', () => {
        if (this.footnoteEditorInstance && this.footnoteEditorInstance.state !== 'destroyed') {
          this.footnoteEditorInstance.destroy().then(() => (this.footnoteEditorInstance = null));
        }
      });
    });
  }

  private buildToolbar(): string[] {
    const result = FOOTNOTE_EDITOR_TOOLBAR_AUTH.filter(
      (toolbarItem: any) => !toolbarItem.hasAnyAuthorities || this.accountService.hasAnyAuthority(toolbarItem.hasAnyAuthorities)
    ).map((toolbarItem: any) => toolbarItem.value);
    if (!result.filter(item => item !== '|')?.length) {
      this.isDisplayToolbar = false;
      this.changeDetectorRef.markForCheck();
    }
    return result;
  }
}
