import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { importProjectRoute } from 'app/layouts/import-project-detail/import-project-detail.route';
import { DocumentResolver } from './core/resolver/document.resolver';
import { errorRoute } from './layouts/error/error.route';
import { RedirectComponent } from './shared/component/redirect/redirect.component';

const LAYOUT_ROUTES = [...importProjectRoute, ...errorRoute];

@NgModule({
  imports: [
    RouterModule.forRoot(
      [
        { path: 'admin', loadChildren: () => import('./admin/admin-routing.module').then(m => m.AdminRoutingModule) },
        {
          path: 'document/:documentId',
          resolve: {
            documentContext: DocumentResolver,
          },
          children: [
            {
              path: 'html-editor',
              loadChildren: () => import('./html-editor-page/html-editor-page.module').then(m => m.PgdpHtmlEditorPageModule),
            },
            {
              path: 'style-editor',
              loadChildren: () => import('./style-editor/style-editor.module').then(m => m.PgdpStyleEditorModule),
            },
            {
              path: 'data-editor',
              loadChildren: () => import('./data-editor/data-editor.module').then(m => m.PgdpDataEditorModule),
            },
            { path: 'ged', loadChildren: () => import('./ged/ged.module').then(m => m.PgdpGedModule) },
            { path: 'preview', loadChildren: () => import('./preview/preview.module').then(m => m.PgdpPreviewModule) },
            { path: 'admin', loadChildren: () => import('./admin-editor/admin-editor.module').then(m => m.PgdpAdminEditorModule) },
            { path: '', pathMatch: 'full', redirectTo: 'html-editor' },
          ],
        },
        { path: '', pathMatch: 'full', component: RedirectComponent },
        ...LAYOUT_ROUTES,
      ],
      { enableTracing: false, paramsInheritanceStrategy: 'always' }
    ),
  ],
  exports: [RouterModule],
})
export class PgdpAppRoutingModule {}
