import { CKSimpleCommandKeys } from '../enum/ck-simple-command-keys.enum';
import { CKSimpleCommandModel } from '../model/ck-simple-command.model';

class CKSimpleCommandRegistry {
  private registry = new Map<string, CKSimpleCommandModel>();

  public add(editorId: string, key: CKSimpleCommandKeys, command: CKSimpleCommandModel): void {
    const registryKey = this.getKey(editorId, key);
    if (this.registry.has(registryKey)) {
      throw new Error(`[CKSimpleCommandRegistry: add command] Command '${key}' for the editor already exists`);
    }
    this.registry.set(registryKey, command);
  }

  public get(editorId: string, key: CKSimpleCommandKeys): CKSimpleCommandModel {
    const registryKey = this.getKey(editorId, key);
    if (!this.registry.has(registryKey)) {
      throw new Error(`[CKSimpleCommandRegistry: get command] Command '${key}' for the editor doesn't exist`);
    }
    const result = this.registry.get(registryKey);
    if (!result) {
      throw new Error(`[CKSimpleCommandRegistry: get command] Command '${key}' for the editor doesn't exist`);
    }
    return result;
  }

  public remove(editorId: string, key: CKSimpleCommandKeys): void {
    const registryKey = this.getKey(editorId, key);
    if (!this.registry.has(registryKey)) {
      throw new Error(`[CKSimpleCommandRegistry: remove command] Command '${key}' doesn't exist`);
    }
    this.registry.delete(registryKey);
  }

  public clear(): void {
    this.registry.clear();
  }

  private getKey(editorId: string, key: CKSimpleCommandKeys): string {
    return `${editorId}_${key}`;
  }
}

const cKSimpleCommandRegistry = window['cKSimpleCommandRegistry'] || new CKSimpleCommandRegistry();

window['cKSimpleCommandRegistry'] = cKSimpleCommandRegistry;
export { cKSimpleCommandRegistry };
