<div class="plugin-container styling-plugin-container">
  <p class="styling_panel_title" jhiTranslate="htmlEditor.plugins.styling.panel.choose"></p>
  <div class="srollableAreaStyle">
    <div class="category_container" *ngFor="let category of allowCategory; index as i">
      <div class="category_row" (click)="open[i] = !open[i]; editor?.editing.view.focus()">
        <mat-icon class="blue_dot" *ngIf="activated[category]" svgIcon="oval"></mat-icon>
        <p
          class="styling_panel_action {{ activated[category] }}"
          jhiTranslate="htmlEditor.plugins.styling.panel.category.{{ category }}"
        ></p>
        <mat-icon *ngIf="!open[i]" svgIcon="arrow-right"></mat-icon>
        <mat-icon *ngIf="open[i]" svgIcon="arrow-down"></mat-icon>
      </div>
      <div class="style_container" *ngIf="open[i]">
        <div *ngIf="category === stylecategory.CHARACTERS || category === 'EXTRA_CHARACTERS'" class="style_row" (click)="removeFormat()">
          <p jhiTranslate="ckeditor.styling.none"></p>
        </div>
        <div
          class="style_row {{ activated[style.classToApply] }}"
          *ngFor="let style of styles[category]"
          (click)="
            applyStyle(style, (disableTitle && category === stylecategory.TITLE) || (disableBorder && category === stylecategory.BORDER))
          "
          (mouseover)="getTooltip(styleName, style?.name)"
          [matTooltip]="getTooltip(styleName, style?.name)"
          matTooltipPosition="right"
          matTooltipClass="sheet-name-tooltip"
          [ngClass]="{
            disabled: (disableTitle && category === stylecategory.TITLE) || (disableBorder && category === stylecategory.BORDER)
          }"
        >
          <p #styleName>{{ style.name }}</p>
        </div>
      </div>
    </div>
  </div>
</div>
