import { IConcept, IEntryPointTable } from '../model/concept.model';
import { SearchType } from '../enum/search-type.enum';
import { DEFAULT_ROLE, DEFAULT_XBRL_LANGUAGE } from '../constants/xbrl.constants';

export function matchConcept(concept: IConcept, translatedLabel: string, match: string, searchType: SearchType): IConcept | null {
  let result: IConcept | null = null;
  switch (searchType) {
    case SearchType.QNAME:
      result = concept.qname === match ? concept : null;
      break;
    case SearchType.LABEL:
      result = translatedLabel.toLowerCase().includes(match) ? concept : null;
      break;
    case SearchType.ALL:
      result = concept?.qname?.toLowerCase()?.includes(match) || translatedLabel?.toLowerCase()?.includes(match) ? concept : null;
      break;
  }
  return result;
}

export function highlightConcept(concept: IConcept, filterText: string, highlight: boolean, translatedLabel: string): IConcept {
  let highlightLabel: any = null;
  if (highlight) {
    // Always show translatedLabel (Mantis 180964)
    const regEx = new RegExp(filterText, 'gi');
    highlightLabel = translatedLabel.replace(regEx, `<span class='highlight'>$&</span>`);
  }
  return highlightLabel ? { ...concept, highlight: highlightLabel } : concept;
}

export function filterConcept(
  concept: IConcept,
  currentLanguage: string,
  filterText: string,
  searchType: SearchType,
  highlight = true
): IConcept | null {
  const conceptTranslatedLabel = getConceptValue(concept, getConceptLanguages(concept), currentLanguage);
  if ((concept?.children?.length ?? 0) > 0) {
    const conceptMatch = matchConcept(concept, conceptTranslatedLabel, filterText, searchType);
    if (conceptMatch) {
      concept = highlightConcept(concept, filterText, highlight, conceptTranslatedLabel);
    }
    const list: IConcept[] = [];
    concept.children?.forEach((child: IConcept) => {
      const subchild = filterConcept(child, currentLanguage, filterText, searchType);
      if (subchild) {
        list.push(subchild);
      }
    });
    if (list.length > 0) {
      return { ...concept, children: list };
    }
  } else {
    const conceptMatch = matchConcept(concept, conceptTranslatedLabel, filterText, searchType);
    if (conceptMatch) {
      return highlightConcept(concept, filterText, highlight, conceptTranslatedLabel);
    }
  }
  return null;
}

/**
 * Return the label in current interface language
 * If label is not translated in current language, return it in english
 * else take the first label
 * @param concept
 * @param language
 * @returns label
 */

export function getConceptLanguages(concept: IConcept | IEntryPointTable): string[] {
  const preferredLabelRole = getPreferredLabelRole(concept);
  let conceptLanguages: string[] = [];
  if ('labelsByRole' in concept.labels && concept.labels.labelsByRole[preferredLabelRole]) {
    const labelByRole = Object.values(concept.labels.labelsByRole[preferredLabelRole]);
    conceptLanguages = Object.keys(labelByRole[0]);
  } else if ('labelByLang' in concept.labels) {
    conceptLanguages = Object.keys(concept.labels.labelByLang);
  } else {
    conceptLanguages = Object.keys(concept.labels);
  }
  return conceptLanguages;
}

export function getConceptValue(concept: IConcept | IEntryPointTable, conceptLanguages: string[], language: string): string {
  let conceptValue: string = '';
  const preferredLabelRole = getPreferredLabelRole(concept);

  if ('labelsByRole' in concept.labels && concept.labels.labelsByRole[preferredLabelRole]) {
    const labelByLang = Object.values(concept.labels.labelsByRole[preferredLabelRole]);
    if (conceptLanguages.includes(language)) {
      const lang = labelByLang.find(item => item[language]);
      conceptValue = lang ? lang[language] : null;
    } else if (conceptLanguages.includes(DEFAULT_XBRL_LANGUAGE)) {
      const lang = labelByLang.find(item => item[DEFAULT_XBRL_LANGUAGE]);
      conceptValue = lang ? lang[DEFAULT_XBRL_LANGUAGE] : null;
    } else {
      conceptValue = labelByLang[0][Object.keys(labelByLang[0])[0]] || 'label not found';
    }
  } else if ('labelByLang' in concept.labels) {
    const labelByLang = concept.labels.labelByLang;
    if (conceptLanguages.includes(language) && labelByLang[language]) {
      conceptValue = labelByLang[language];
    } else if (conceptLanguages.includes(DEFAULT_XBRL_LANGUAGE) && labelByLang[DEFAULT_XBRL_LANGUAGE]) {
      conceptValue = labelByLang[DEFAULT_XBRL_LANGUAGE];
    } else {
      conceptValue = labelByLang[Object.keys(labelByLang)[0]] || 'label not found';
    }
  }
  return conceptValue;
}

export function isLangAvailable(concept: IConcept, language: string): boolean {
  const translations = getConceptLanguages(concept);
  return translations.includes(language);
}

export function getPreferredLabelRole(concept: IConcept | IEntryPointTable): string {
  let preferredLabelRole = DEFAULT_ROLE;
  if (concept.preferredLabelRole) {
    preferredLabelRole = concept.preferredLabelRole;
  }
  return preferredLabelRole;
}
