<header class="header">
  <a class="logo" (click)="toggleNavbar()" [matTooltip]="'global.menu.open' | translate" matTooltipPosition="right">
    <span class="logo-img"></span>
  </a>
  <div class="header-content">
    <ng-content></ng-content>
  </div>
  <jhi-zoom-button (zoom)="updateZoom($event)"></jhi-zoom-button>

  <div *ngIf="displaySaveButton && isToolbarVisible">
    <jhi-save-button *jhiHasAnyAuthority="[Authority.MANUAL_SAVE]" (saveCKDocument)="saveCKDocument($event)"></jhi-save-button>
  </div>
  <jhi-dm-button></jhi-dm-button>
  <jhi-account-button></jhi-account-button>
</header>
