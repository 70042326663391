export enum PluginsCommand {
  BOLD = 'customBold',
  ITALIC = 'customItalic',
  UNDERLINE = 'customUnderline',
  UPPERCASE = 'customUppercase',
  LOWERCASE = 'lowercase',
  CAPITALIZE = 'capitalize',
  LETTER_SPACING = 'letterSpacing',
  LOCALIZE_NUMBERS = 'localizeNumbers',
  STRIKE = 'customStrikethrough',
  SUBSCRIPT = 'customSubscript',
  SUPSCRIPT = 'customSuperscript',
  INSERT_IMAGE = 'customInsertImage',
  UPDATE_PLACEHOLDER = 'updatePlaceholder',
  INSERT_SUMMARY = 'insertSummary',
  REFRESH_SELECTED_SUMMARY = 'refreshSelectedSummary',
  APPLY_SUMMARY_STYLE = 'applySummaryStyle',
  CREATE_FOOTNOTE = 'createFootnote',
  INSERT_FOOTNOTE = 'insertFootnote',
  UPDATE_FOOTNOTE = 'updateFootnote',
  REMOVE_FOOTNOTE = 'removeFootnote',
  INSERT_SPE_CHAR = 'insertSpeChar',
  INSERT_SINGULAR = 'insertSimpleDynamicData',
  INSERT_XBRL = 'insertXbrlData',
  INSERT_TABULAR = 'insertTabularDynamicDatas',
  TAG_DYNAMIC_DATA = 'tagDynamicData',
  TAG_TEXT_BLOCK = 'tagTextBlock',
  ADD_XBRL_FOOTNOTE = 'addXbrlFootnote',
  UPDATE_STATE_DD = 'updateStateDD',
  COPY_FORMAT = 'copyFormat',
  PASTE_FORMAT = 'pasteFormat',
  INSERT_LIST = 'insertList',
  APPLY_CLASS = 'applyClass',
  REMOVE_STYLE = 'removeStyle',
  INSERT_GRAPH = 'insertGraph',
  TOGGLE_WEBSPELLCHECKER = 'toggleWebSpellChecker',
  APPLY_TITLE = 'applyTitle',
  ADD_TITLE_NUMBERING = 'addTitleNumbering',
  REMOVE_TITLE_NUMBERING_BACKWARD = 'removeTitleNumberingBackward',
  REMOVE_TITLE_NUMBERING_FORWARD = 'removeTitleNumberingForward',
  TABLE_MOVE_ROW = 'moveTableRow',
  TABLE_MOVE_COLUMN = 'moveTableColumn',
  APPLY_TABLE_MODEL = 'applyTableModel',
  APPLY_PAGE_TEMPLATE = 'applyPageTemplate',
  GET_COLUMN_LABEL = 'getColumnLabel',
  GET_ROW_LABEL = 'getRowLabel',
  IMPORT_FILE = 'importFile',
  ALIGNMENT = 'customAlignment',
  COLUMN_SPREAD = 'columnSpread',
  SELECT_COLUMN_SPREAD = 'selectColumnSpread',
  CELL_STYLE = 'cell_style',
  COLUMN_BREAK = 'columnBreak',
  SELECT_COLUMN_BREAK = 'selectColumnBreak',
  FRAME = 'applyFrame',
  WARNING_ZONE = 'applyWarningZone',
  HIGHLIGHT = 'customHighlight',
  TABLE_BREAK = 'tableBreak',
  SELECT_TABLE_BREAK = 'selectTableBreak',
  INSERT_LOOKUP = 'insertLookup',
  INSERT_GRAPHICAL_PAGES = 'insertGraphicalPages',
  TABLE_DISABLE_PYJAMA_ROW = 'disablePyjamaRow',
  TABLE_DISABLE_PYJAMA_COLUMN = 'disablePyjamaColumn',
  TABLE_REVERSE_PYJAMA_ROW = 'reversePyjamaRow',
  TOGGLE_COPY_PASTE_STYLES = 'toggleCopyPasteStyles',
  TABLE_SPLIT_DOUBLE_PAGE = 'splitDoublePagesTable',
  TITLE_SPLIT_CONTENT = 'titleSplitContent',
  CELL_ROTATION = 'cellRotation',
  COUNTER_RESET = 'counterReset',
  APPLY_ZOOM = 'applyZoom',
  TABLE_ACCESSIBILITY = 'tableAccessibility',
}
