import { Pipe, PipeTransform } from '@angular/core';
import { IAsset } from 'app/shared/model/asset.model';

@Pipe({
  name: 'imageFilter',
  pure: false,
})
export class ImageFilterPipe implements PipeTransform {
  transform(items: IAsset[], filter: string): any {
    if (!items || !filter) {
      return items;
    }
    // filter the images by their filename
    // the image name and the filter text are both compared in their lower case (so the filter is not case sensitive)
    return items.filter(item => item.filename?.toLowerCase().includes(filter.toLowerCase()));
  }
}
