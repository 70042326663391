import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ProjectService } from 'app/core/service/project.service';
import { PeriodType } from 'app/shared/enum/period-type.enum';
import { IDate } from 'app/shared/model/date.model';
import { Subject } from 'rxjs';

@Component({
  selector: 'jhi-time-list',
  templateUrl: './time-list.component.html',
  styleUrls: ['./time-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimeListComponent implements OnInit {
  public PeriodType = PeriodType;

  @Input()
  public get periodType(): PeriodType | null {
    return this._periodType;
  }

  public set periodType(value: PeriodType | null) {
    this._periodType = value;
    switch (value) {
      case PeriodType.PERIOD:
        this.placeholder = 'dataEditor.date.search.placeholderPeriod';
        break;
      case PeriodType.INSTANT:
        this.placeholder = 'dataEditor.date.search.placeholderInstant';
        break;
      default:
        this.placeholder = 'dataEditor.date.search.placeholder';
        break;
    }
  }

  @Output() public selected = new EventEmitter<IDate>();

  public placeholder: string;
  public filtered = false;

  public instants: IDate[] = [];
  public periods: IDate[] = [];
  public all: IDate[] = [];
  private data: IDate[];

  public searchTermChanged: Subject<string> = new Subject<string>();
  private _periodType: PeriodType | null;

  constructor(private changeDetectorRef: ChangeDetectorRef, private projectService: ProjectService) {}

  ngOnInit(): void {
    this.projectService.getDatesConfiguration().subscribe(
      data => {
        this.data = data.periods;
        this.updateList();
        this.changeDetectorRef.markForCheck();
      },
      () => {
        this.data = this.all = this.instants = this.periods = [];
        this.changeDetectorRef.markForCheck();
      }
    );
  }

  public filter(filterText: string): void {
    this.filtered = !!filterText;
    this.updateList(this.filtered ? filterText.toLowerCase() : undefined);
    this.changeDetectorRef.markForCheck();
  }

  public onSelected(date: IDate): void {
    this.selected.emit(date);
  }

  private updateList(search: string = ''): void {
    if (search.length) {
      this.all = this.data.filter(date => date.periodName.toLowerCase().includes(search));
      this.periods = this.data.filter(date => date.periodType === PeriodType.PERIOD && date.periodName.toLowerCase().includes(search));
      this.instants = this.data.filter(date => date.periodType === PeriodType.INSTANT && date.periodName.toLowerCase().includes(search));
    } else {
      this.all = this.data;
      this.periods = this.data.filter(date => date.periodType === PeriodType.PERIOD);
      this.instants = this.data.filter(date => date.periodType === PeriodType.INSTANT);
    }
  }
}
