import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { PluginComponent } from '../../plugin.component';
import { PluginPanelService } from '../../plugin-panel.service';
import { DocumentService } from 'app/core/service/document.service';

import pubsub from 'app/pubsub';
import { EDITOR_PLUGIN_TOGGLEPANEL, TOC_UPDATED } from 'app/pubsub.topics';
import { PLUGIN_PANEL_COMPONENT_KEYS } from '../../plugin-panel-component-keys';
import { ExportType } from 'app/shared/model/download-manager.model';
import { DocumentaryUnitService } from 'app/core/service/documentary-unit.service';
import { IDocumentaryUnit } from 'app/shared/model/documentary-unit.model';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'jhi-configuration-export-word',
  templateUrl: './configuration-export-word.component.html',
  styleUrls: ['./configuration-export-word.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ConfigurationExportWordComponent extends PluginComponent implements OnInit, OnDestroy {
  public readonly ExportType = ExportType;
  private tocUpdatedRef: Function;
  public sections: IDocumentaryUnit[];

  public exportForm: UntypedFormGroup;

  constructor(
    public pluginPanelService: PluginPanelService,
    private documentService: DocumentService,
    private documentaryUnitService: DocumentaryUnitService
  ) {
    super(pluginPanelService);
  }

  ngOnInit(): void {
    this.loadSections(true);

    // to listen add/remove chapter/section on TOC
    this.tocUpdatedRef = this.loadSections.bind(this);
    pubsub.on(TOC_UPDATED, this.tocUpdatedRef);

    this.exportForm = new UntypedFormGroup({
      typeExportSelected: new UntypedFormControl(ExportType.CHAPTER),
      sectionsSelected: new UntypedFormControl({ value: null, disabled: true }),
    });

    this.exportForm.controls.typeExportSelected.valueChanges.subscribe(value => {
      if (value !== ExportType.CHAPTERS) {
        this.exportForm.controls.sectionsSelected.disable({ emitEvent: false });
        this.exportForm.controls.sectionsSelected.clearValidators();
      } else {
        this.exportForm.controls.sectionsSelected.enable({ emitEvent: false });
        this.exportForm.controls.sectionsSelected.setValidators([Validators.required]);
      }
      this.exportForm.controls.sectionsSelected.updateValueAndValidity();
    });
  }

  ngOnDestroy(): void {
    pubsub.off(TOC_UPDATED, this.tocUpdatedRef);
  }

  private loadSections(showSpinner = false): void {
    this.documentaryUnitService.getSections(showSpinner).subscribe(sections => (this.sections = sections));
  }

  goBack(): void {
    pubsub.fire(EDITOR_PLUGIN_TOGGLEPANEL, {
      component: PLUGIN_PANEL_COMPONENT_KEYS.DOCUMENT_EXPORT,
      title: 'htmlEditor.plugins.documentExport.title',
    });
  }

  exportDocumentWord(): void {
    const currentDU = this.documentaryUnitService.currentSelectedDocumentaryUnit;
    if (!currentDU?.id) {
      return;
    }
    const exportType: ExportType = this.exportForm.get('typeExportSelected')?.value;
    let sections: number[] | null;
    if (exportType === ExportType.DOCUMENT) {
      sections = null;
    } else if (exportType === ExportType.CHAPTER) {
      const selectedSection: number[] = [currentDU.id];
      sections = selectedSection;
    } else {
      sections = this.exportForm.get('sectionsSelected')?.value;
    }
    this.documentService.downloadWordExport(sections);
    this.pluginPanelService.closePanel();
  }
}
