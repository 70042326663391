import { ExportWarningDTO } from 'app/shared/model/taxonomy.model';
import { AssetFileType } from 'app/shared/enum/asset-file-type.enum';
import { IAssetResponse } from './asset.model';
import { IDate } from './date.model';

export interface XbrlImportData {
  dicoErrorsWarnings?: ExportWarningDTO;
  dimensionMembersAxesDelta?: number;
  dimensionMembersDelta?: number;
  numFactsDeleted?: number;
  numFactsInserted?: number;
  numFactsUpdated?: number;
  numRcsfDeleted?: number;
  numRcsfInserted?: number;
  numRcsfUpdated?: number;
  numSheets?: number;
  taxoErrorsWarnings?: ExportWarningDTO;
  unitsDelta?: number;
  xbrlImportsInfo?: XbrlInfos;
  numFacts?: number;
  periodList?: IDate[];
}

export interface CheckXbrlResult {
  response?: XbrlImportData;
  assetResponse?: IAssetResponse;
  // Unfotunally nothing exist in ImportResponseDTO to distinguish iXBRL to XBRL import,
  // so asseType is never in the reponse, we'll fill it later
  assetType?: AssetFileType;
}

export interface XbrlInfos {
  projectEntrypoint: ProjectEntryPoint;
  xbrlImport: XbrlImport[];
}

export interface XbrlImport {
  createAt: string;
  id: number;
  importType: AssetFileType;
  importfilename: string;
}

export interface ProjectEntryPoint {
  createdAt: string;
  customerId: number;
  dicoName: string;
  id: number;
  packageEntry: PackageEntry;
  projectId: number;
}

export interface PackageEntry {
  id: number;
  langs: string;
  name: string;
}

export enum TextBlockType {
  OPEN = 'open',
  CLOSE = 'close',
}

export enum DeletionType {
  ONE = 'one',
  PAIR = 'pair',
  ALL = 'all',
}

export interface ITextBlock {
  type: TextBlockType;
  id: string;
  insertionIsAllowed: boolean;
  isSelection: boolean;
  qname: string;
  startDate: string;
  periodType: string;
  factId: string;
  endDate?: string;
  axisQname?: string;
  memberQname?: string;
  isTextFact: boolean;
}

export interface ITextBlockPosition {
  top: number;
  bottom: number;
}

export interface IXbrlErrors {
  errorMessage: string;
  elementId: string;
}
