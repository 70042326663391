import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { LogEntryDTO } from 'app/shared/model/taxonomy.model';

@Component({
  selector: 'jhi-logs-report-table',
  templateUrl: './logs-report-table.component.html',
  styleUrls: ['./logs-report-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LogsReportTableComponent {
  @Input() public logs: LogEntryDTO[] = [];
  public logsColumns: string[] = ['code', 'message'];
}
