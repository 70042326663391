<div class="main-block" *ngIf="entryPointTables">
  <div class="header-block">
    <jhi-input-search placeholder="dataEditor.concept.search.placeholder" [disabled]="!data?.length" (searchTermChanged)="filter($event)">
    </jhi-input-search>
  </div>
  <div class="content-block">
    <ng-container>
      <mat-tab-group>
        <mat-tab
          [disabled]="!entryPointTables?.length"
          label="{{
            filtered
              ? ('dataEditor.concept.tab.filter' | translate: '{count:' + concepts.length + '}')
              : ('dataEditor.concept.tab.search' | translate)
          }}"
        >
          <div class="table-wrapper">
            <div *ngFor="let table of entryPointTables; trackBy: trackTable">
              <jhi-node-expand [label]="table | conceptTranslatedLabel" [expanded]="expanded">
                <div *ngFor="let concept of table.concepts">
                  <jhi-concept-detailled
                    [concept]="concept"
                    [conceptIdSelected]="conceptIdSelected"
                    (selected)="onSelected($event, table)"
                  ></jhi-concept-detailled>
                </div>
              </jhi-node-expand>
            </div>
          </div>
        </mat-tab>
        <!-- <mat-tab label="{{ 'dataEditor.concept.tab.presentation' | translate }}" [disabled]="true"></mat-tab>
        <mat-tab label="{{ 'dataEditor.concept.tab.calcul' | translate }}" [disabled]="true"></mat-tab> -->
      </mat-tab-group>
    </ng-container>
  </div>
</div>
