<div *ngIf="concept" class="concept" (click)="onSelected(concept)"
     [ngClass]="{'selected': conceptIdSelected && conceptIdSelected?.pnodeId === concept.pnodeId && conceptIdSelected?.parentId === concept.parentId}">
    <div class="concept-label">{{ concept | conceptTranslatedLabel }}</div>
    <div class="concept-type">
        <div class="concept-type-square"></div>
        <div class="concept-type-label">{{ concept.type }}</div>
    </div>
    <div class="concept-header">
        <div class="concept-header-name" [innerHTML]="concept.highlight||concept.qname | splitQname: 'name'"></div>
        <div class="concept-header-type">{{ concept.qname | splitQname }}</div>
        <!-- <div class="concept-header-year">2020</div>  TODO: add year if available from Arevio -->
    </div>
</div>
