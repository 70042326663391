<div class="export-configuration-word-container">
  <div class="back-container">
    <button mat-icon-button class="header-close" (click)="goBack()">
      <mat-icon svgIcon="arrow-back" color="primary"></mat-icon>
    </button>
    <span jhiTranslate="htmlEditor.plugins.configurationExport.goBack"></span>
  </div>
  <hr />
  <div class="configuration-content">
    <div class="type-reminder">
      <mat-icon class="export-svg" svgIcon="export-word"></mat-icon>
      <span jhiTranslate="htmlEditor.plugins.documentExport.wordExport"></span>
    </div>

    <label id="export-content-label" jhiTranslate="htmlEditor.plugins.configurationExport.export"></label>

    <form class="export-filter-form-container" role="form" [formGroup]="exportForm">
      <mat-radio-group aria-labelledby="export-content-label" color="primary" formControlName="typeExportSelected">
        <mat-radio-button [value]="ExportType.CHAPTER">
          {{ 'htmlEditor.plugins.configurationExport.du_only' | translate }}
        </mat-radio-button>
        <mat-radio-button class="export-chapters-button" [value]="ExportType.CHAPTERS">
          {{ 'htmlEditor.plugins.configurationExport.dus' | translate }}
        </mat-radio-button>
        <jhi-select-d-us-field [sections]="sections" formControlName="sectionsSelected"></jhi-select-d-us-field>
        <mat-radio-button [value]="ExportType.DOCUMENT">
          {{ 'htmlEditor.plugins.configurationExport.document' | translate }}
        </mat-radio-button>
      </mat-radio-group>
    </form>

    <div class="button-container">
      <button
        [disabled]="!exportForm.valid"
        type="button"
        mat-flat-button
        mat-button
        color="primary"
        (click)="exportDocumentWord()"
        jhiTranslate="htmlEditor.plugins.configurationExport.button"
      ></button>
      <span
        *ngIf="!exportForm.valid && !exportForm.pristine"
        jhiTranslate="htmlEditor.plugins.configurationExport.WORD.disabled"
      ></span>
    </div>
  </div>
</div>
