export const CUSTOM_STYLES_CONTAINER = 'custom-styles-container';
export const SPREADSHEET_FACT_COLOR = '#e3e4f1';
export const SPREADSHEET_FACT_DUPLICATE_COLOR = '#e1b7c5';
export const SPREADSHEET_FACT_NON_EDITABLE_COLOR = '#a5a5a5';
export const SPREADSHEET_MIN_COL_WIDTH = 100;
export const SPREADSHEET_MAX_COL_WIDTH = 500;
export const SPREADSHEET_MIN_ROW_HEIGHT = 23;
export const SPREADSHEET_MAX_ROW_HEIGHT = 69;

export const SUMMARY_CONTAINER_CLASS = 'summary-container';
