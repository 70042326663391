import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { IDataAndFormat } from 'app/shared/model/formatted.model';
import {
  EDITOR_PLUGIN_VIEWFACT,
  EDITOR_PLUGIN_VIEWDYNAMICDATA,
  EDITOR_PLUGIN_SELECTION_TEXT_BLOCK,
  EDITOR_PLUGIN_TEXT_BLOCK_ELEMENT,
} from 'app/pubsub.topics';
import pubsub from 'app/pubsub';
import { ITextBlock, ITextBlockPosition } from 'app/shared/model/xbrl.model';

@Injectable({ providedIn: 'root' })
export class DynamicDataService {
  private selectedFactSubject: Subject<IDataAndFormat> = new Subject();
  private selectedDynamicDataSubject: Subject<IDataAndFormat> = new Subject();
  private selectedMacroTaggingSubject: Subject<ITextBlock> = new Subject();
  private _tmpMacroTagging: ITextBlock;
  private textBlocks: ITextBlockPosition[][] = [];
  constructor() {
    // here we are on a singleton service, .off is not necessary
    pubsub.on(EDITOR_PLUGIN_VIEWFACT, ({ detail }: CustomEvent) => {
      this.selectFact(detail);
    });
    pubsub.on(EDITOR_PLUGIN_VIEWDYNAMICDATA, ({ detail }: CustomEvent) => {
      this.selectDynamicData(detail);
    });

    pubsub.on(EDITOR_PLUGIN_SELECTION_TEXT_BLOCK, ({ detail }: CustomEvent) => {
      this.selectMacroTagging(detail);
    });

    pubsub.on(EDITOR_PLUGIN_TEXT_BLOCK_ELEMENT, ({ detail }: CustomEvent) => {
      this.retrieveHtmlElement(detail);
    });
  }

  getSelectedFactObservable(): Observable<IDataAndFormat> {
    return this.selectedFactSubject.asObservable();
  }

  getSelectedDataObservable(): Observable<IDataAndFormat> {
    return this.selectedDynamicDataSubject.asObservable();
  }

  getSelectedMacroTaggingObservable(): Observable<ITextBlock> {
    return this.selectedMacroTaggingSubject.asObservable();
  }

  selectFact(data: IDataAndFormat): void {
    const newData = {
      ...data,
      format: { ...data.format, scale: isNaN(data.format.scale) ? 0 : data.format.scale },
    };
    this.selectedFactSubject.next(newData);
  }

  selectDynamicData(data: IDataAndFormat): void {
    this.selectedDynamicDataSubject.next(data);
  }

  selectMacroTagging(textBlock: ITextBlock): void {
    this._tmpMacroTagging = textBlock;
    this.selectedMacroTaggingSubject.next(textBlock);
  }

  getActualMicroTagging(): ITextBlock {
    return this._tmpMacroTagging;
  }

  retrieveHtmlElement(detail: CustomEvent): void {
    setTimeout(() => {
      const openTag = document.getElementById(detail['marker-uuid']) as HTMLElement;
      const marker: any = document.querySelector("[uuid='" + detail['marker-uuid'] + "']");
      const editor: any = document.querySelector('.editable:not([hidden])');
      let calculatedHorizontalPosition = 0;
      if (detail['close-tag']) {
        const distance = this.distanceBetweenElements(openTag, document.getElementById(detail['close-tag']) as HTMLElement);
        const space = 5;
        marker.style.height = distance + space + 'px';
      }

      const closeTag = detail['close-tag'] ? document.getElementById(detail['close-tag']) : null;
      calculatedHorizontalPosition = this.calculateHorizontalPosition(openTag, closeTag);

      const nbColors = 10;
      marker.style.left =
        editor.offsetWidth +
        calculatedHorizontalPosition * 20 -
        openTag.getBoundingClientRect().left +
        editor.getBoundingClientRect().left +
        'px';
      marker.parentElement.setAttribute('data_marker_position', 'marker_position_' + (calculatedHorizontalPosition % nbColors));
      marker.parentElement.setAttribute('data_marker_color', detail['close-tag'] ? 'green' : 'red');

      if (closeTag) {
        const closeTagContainer: any = closeTag.querySelector('div');
        closeTagContainer.setAttribute('data_marker_position', 'marker_position_' + (calculatedHorizontalPosition % nbColors));
        closeTagContainer.setAttribute('data_marker_color', detail['close-tag'] ? 'green' : 'red');
      }
    }, 1000);
  }

  distanceBetweenElements(elementOne: HTMLElement, elementTwo: HTMLElement): number {
    let distance = -1;

    const y1 = elementOne.getBoundingClientRect().top;
    const y2 = elementTwo.getBoundingClientRect().top;

    const yDistance = y1 - y2;

    distance = Math.abs(yDistance);

    return distance;
  }

  calculateHorizontalPosition(elementOne: HTMLElement, elementTwo: HTMLElement | null, position = 0): number {
    let positionAvailable = true;

    const margin = 5;

    const top = elementOne.getBoundingClientRect().top - margin;
    const bottom = elementTwo ? elementTwo.getBoundingClientRect().top + margin : 0;
    if (!this.textBlocks[position]) {
      this.textBlocks[position] = [];
    }

    this.textBlocks[position].every(element => {
      if (element.top === top || (element.top < top && element.bottom >= top)) {
        positionAvailable = false;
        return false;
      }
      return true;
    });

    if (positionAvailable) {
      this.textBlocks[position].push({ top, bottom });
      return position;
    } else {
      return this.calculateHorizontalPosition(elementOne, elementTwo, ++position);
    }
  }
}
