<h1 mat-dialog-title>
  <span>{{ 'htmlEditor.plugins.imagePicker.imageMoveFolder.title' | translate: { img: currentFilename } }}</span>
  <button type="button" mat-icon-button class="mat-dialog-close-btn" [mat-dialog-close]="true" tabindex="-1">
    <fa-icon icon="times"></fa-icon>
  </button>
</h1>

<div mat-dialog-content>
  <div [formGroup]="form" class="form-content">
    <div class="introduction">
      {{
        (currentFolder === ImageFolder.PROJECT
          ? 'htmlEditor.plugins.imagePicker.imageMoveFolder.moveProjectIntroduction'
          : 'htmlEditor.plugins.imagePicker.imageMoveFolder.moveIntroduction'
        ) | translate: { folder: currentFolder }
      }}
    </div>

    <mat-form-field>
      <mat-label jhiTranslate="htmlEditor.plugins.imagePicker.imageMoveFolder.destinationFolder"></mat-label>
      <mat-select class="imageSelect" formControlName="folder" panelClass="force-panel-under-field">
        <mat-option *ngIf="currentFolder !== ImageFolder.PROJECT" [value]="ImageFolder.PROJECT"
          >{{ 'htmlEditor.plugins.imagePicker.' + ImageFolder.PROJECT | translate }}
        </mat-option>
        <ng-container *ngIf="currentFolder === ImageFolder.PROJECT">
          <mat-option *ngFor="let language of languageFolders" [value]="language">
            {{ 'htmlEditor.plugins.imagePicker.LANGUAGE' | translate: { lang: language } }}
          </mat-option>
        </ng-container>
      </mat-select>
    </mat-form-field>

    <div class="language-in-filename">
      {{
        (currentFolder === ImageFolder.PROJECT
          ? 'htmlEditor.plugins.imagePicker.imageMoveFolder.addLanguageInFilename'
          : 'htmlEditor.plugins.imagePicker.imageMoveFolder.removeLanguageInFilename'
        ) | translate
      }}
    </div>
    <mat-form-field>
      <mat-label jhiTranslate="htmlEditor.plugins.imagePicker.imageMoveFolder.newFilename"></mat-label>
      <input type="text" matInput formControlName="filename" />

      <mat-error *ngIf="form.get('filename')?.hasError('pattern')">{{
        'htmlEditor.plugins.imagePicker.imageMoveFolder.filenameLanguagePattern' | translate
      }}</mat-error>
      <mat-error *ngIf="form.get('filename')?.hasError('isFilenameLanguage')">
        {{ 'htmlEditor.plugins.imagePicker.imageMoveFolder.filenameLanguage' | translate }}
      </mat-error>
      <mat-error *ngIf="form.get('filename')?.hasError('isFilenameLanguageProject')">{{
        'htmlEditor.plugins.imagePicker.imageMoveFolder.filenameLanguageProject' | translate
      }}</mat-error>
      <mat-error *ngIf="form.get('filename')?.hasError('isFilenameExist')">{{
        'htmlEditor.plugins.imagePicker.imageMoveFolder.filenameExist' | translate
      }}</mat-error>
      <mat-error *ngIf="form.get('filename')?.hasError('required')">{{ 'entity.validation.required' | translate }}</mat-error>
    </mat-form-field>
  </div>
</div>

<div mat-dialog-actions>
  <button type="button" mat-raised-button [mat-dialog-close]="false">
    <span jhiTranslate="htmlEditor.plugins.imagePicker.imageMoveFolder.cancel"></span>
  </button>
  <button type="button" mat-raised-button color="primary" (click)="save()" [disabled]="!form.valid || form.pristine">
    <span jhiTranslate="htmlEditor.plugins.imagePicker.imageMoveFolder.save"></span>
  </button>
</div>
