export enum CKEditorModel {
  // Font Transformation
  BOLD = 'bold',
  ITALIC = 'italic',
  UNDERLINE = 'underline',
  STRIKE = 'strikethrough',
  SUBSCRIPT = 'subscript',
  SUPSCRIPT = 'superscript',
  CAPITALIZE = 'capitalize',
  UPPERCASE = 'uppercase',
  LOWERCASE = 'lowercase',
  LETTER_SPACING = 'letterSpacing',
  HORIZONTAL_LINE = 'horizontalLine',

  // Dynamic data
  NUMERIC_DATA = 'simpleNumericData',
  TEXT_DATA = 'simpleTextData',
  DYNAMIC_DATA = 'dynamicData',
  // Foonote
  FOOTNOTE = 'footnote',
  FOOTNOTE_CLASS = 'footnote', // Used as techical class in export

  // Image
  IMAGE = 'imageBlock',
  IMAGE_INLINE = 'imageInline',
  IMAGE_VIEW_ELEMENT = 'img',
  IMAGE_DAMID_ATTRIBUT = 'data-damid',
  IMAGE_CUSTOM_DAMID_ATTRIBUT = 'custom-data-damid',
  IMAGE_SRC = 'src',
  IMAGE_ALT = 'alt',
  IMAGE_PLACEHOLDER = 'placeholder',
  IMAGE_STYLE = 'imageStyle',
  IMAGE_WIDTH = 'custom-width',
  IMAGE_HEIGHT = 'custom-height',
  IMAGE_MARGIN_TOP = 'margin-top',
  IMAGE_MARGIN_RIGHT = 'margin-right',
  IMAGE_MARGIN_BOTTOM = 'margin-bottom',
  IMAGE_MARGIN_LEFT = 'margin-left',
  IMAGE_NAME = 'custom-title',
  VIEW_IMAGE_NAME = 'title',
  IMAGE_RESIZED_WIDTH = 'resizedWidth',
  IMAGE_RESIZED_HEIGHT = 'resizedHeight',

  // Special Characters
  SPE_CHAR = 'speChar',
  SPE_CHAR_VIEW_ELEMENT = 'span',
  SPE_CHAR_CLASS = 'special-character',

  // Title
  TITLE = 'title',
  T1 = 'title',
  T2 = 'title',
  T3 = 'title',
  T4 = 'title',
  T5 = 'title',
  T6 = 'title',
  T7 = 'title',
  T8 = 'title',
  T9 = 'title',
  T10 = 'title',
  TITLE_CLASS = 'title',
  TITLE_NUMBERING = 'titleNumbering',
  TITLE_CONTENT = 'titleContent',

  // Summary
  SUMMARY = 'summary',
  SUMMARY_ITEM = 'summary-item',
  SUMMARY_NUMBERING_ITEM = 'summary-numbering-item',
  SUMMARY_EDITABLE_ITEM = 'summary-editable-item',
  SUMMARY_ELEMENT = 'div',
  SUMMARY_CLASS = 'summary',
  SUMMARY_ITEM_CLASS = 'summary-item',
  SUMMARY_EDITABLE_ITEM_CLASS = 'summary-editable-item',

  // Lookup
  LOOKUP = 'lookup',
  LOOKUP_ENTRY = 'lookup-entry',
  LOOKUP_ELEMENT = 'div',
  LOOKUP_CLASS = 'lookup',
  LOOKUP_ENTRY_CLASS = 'lookup-entry',

  // CKEditor
  PARAGRAPH = 'paragraph',
  TABLE = 'table',
  TABLE_ROW = 'tableRow',
  TABLE_CELL = 'tableCell',
  PAGE_BREAK = 'pageBreak',

  // HightChart
  HIGHCHART = 'highchart',
  HIGHCHART_CONTAINER_ELEMENT = 'figure',
  HIGHCHART_VIEW_ELEMENT = 'svg',
  HIGHCHART_CLASS = 'graph',

  // Track changes
  TRACK_CHANGES_MENTION = 'mention',
  TRACK_CHANGES_MENTION_VIEW_ELEMENT = 'span',
  TRACK_CHANGES_MENTION_VIEW_ELEMENT_CLASS = 'mention',

  // Page templates
  PAGE_TEMPLATES = 'pageTemplates',

  // Frames
  FRAME = 'frame',
  FRAME_EDITABLE = 'frame-content',
  FRAME_CLASS = 'frame',
  FRAME_EDITABLE_CLASS = 'frame-content',

  // Warning zones
  WARNING_ZONE = 'warning-zone',
  WARNING_ZONE_EDITABLE = 'warning-zone-content',
  WARNING_ZONE_CLASS = 'warning-zone',
  WARNING_ZONE_EDITABLE_CLASS = 'warning-zone-content',

  // xbrl footnotes
  XBRL_FOOTNOTE = 'xbrlFootnote',

  // xbrl text block
  XBRL_TEXT_BLOCK = 'xbrlTextBlock',

  // ListItem
  LIST_ITEM = 'listItem',
}

export enum CKEditorViewElement {
  //
  DEFAULT = 'div',
  DIV = 'div',

  // Dynamic data
  NUMERIC_DATA = 'ix:non-fraction',
  TEXT_DATA = 'ix:non-numeric',
  DYNAMIC_DATA = 'dd:tag',
  // footnote
  FOOTNOTE = 'span',
  // Letter spacing
  LETTER_SPACING_ELEMENT = 'span',
  // Tables
  TABLE = 'table',
  TD = 'td',
  TH = 'th',
  TR = 'tr',
  COLGROUP = 'colgroup',
  COL = 'col',
  CAPTION = 'caption',
  // CKEditor
  PARAGRAPH = 'p',
  SPAN = 'span',
  LINK = 'a',
  // Titres
  T1 = 'h1',
  T2 = 'h2',
  T3 = 'h3',
  T4 = 'h4',
  T5 = 'h5',
  T6 = 'h6',
  T7 = 'h6',
  T8 = 'h6',
  T9 = 'h6',
  T10 = 'h6',
  TITLE_NUMBERING = 'span',
  TITLE_CONTENT = 'span',

  // Summary
  SUMMARY = 'div',
  SUMMARY_ITEM = 'div',
  SUMMARY_EDITABLE_ITEM = 'span',
  SUMMARY_NUMBERING_ITEM = 'span',

  // Lookup
  LOOKUP = 'span',
  LOOKUP_ENTRY = 'span',

  // image
  FIGURE = 'figure',
  PLACEHOLDER = 'data-image-placeholder',
  IMG = 'img',

  // LISTES
  OL = 'ol',
  UL = 'ul',
  LI = 'li',

  // Page break
  PAGE_BREAK_CLASS = 'page-break',
  PAGE_BREAK_CONTAINER_ELEMENT = 'div',
  PAGE_BREAK_LABEL_CONTAINER_ELEMENT = 'span',
  PAGE_BREAK_LABEL_ELEMENT = 'span',
  PAGE_BREAK_LABEL_CLASS = 'page-break__label',

  PAGE_TEMPLATE_DROPDOWN_ELEMENT = 'span',
  PAGE_TEMPLATE_DROPDOWN_CLASS = 'page-break__dropdown',

  // Frames
  FRAME = 'div',
  FRAME_EDITABLE = 'div',

  // Warning zones
  WARNING_ZONE = 'div',
  WARNING_ZONE_EDITABLE = 'div',

  // XBRL footnote
  XBRL_FOOTNOTE = 'ix:footnote',
  XBRL_FOOTNOTE_WIDGET = 'xbrlFootnoteWidget',

  // xbrl text block
  XBRL_TEXT_BLOCK = 'span',
}

export const CKEditorDynamicDataModels = [CKEditorModel.NUMERIC_DATA, CKEditorModel.TEXT_DATA, CKEditorModel.DYNAMIC_DATA];

export const CKEditorDynamicViewElements = [
  CKEditorViewElement.NUMERIC_DATA,
  CKEditorViewElement.TEXT_DATA,
  CKEditorViewElement.DYNAMIC_DATA,
  CKEditorViewElement.XBRL_FOOTNOTE,
];
