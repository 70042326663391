import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IMenu, ISubMenu } from 'app/shared/enum/vertical-menu.enum';

@Component({
  selector: 'jhi-vertical-sub-menu',
  templateUrl: './vertical-sub-menu.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VerticalSubMenuComponent implements OnInit {
  @Input() parentItem: IMenu;
  @Input() itemSelected: string;
  @Input() public type: string;
  @Output() clickEvent = new EventEmitter<ISubMenu>();
  items: ISubMenu[];

  ngOnInit(): void {
    const children = this.parentItem.children;

    this.items = Object.values(children);
  }

  onClick(item: ISubMenu): void {
    this.clickEvent.emit(item);
  }
}
