<nav class="sidebar" [ngClass]="{ active: isNavbarOpened$ | async }" *ngIf="isAuthenticated()">
  <ul class="list-unstyled">
    <li [matTooltip]="'global.menu.close' | translate" matTooltipPosition="below">
      <a class="nav-link close-menu" (click)="collapseNavbar()">
        <mat-icon svgIcon="menu-close"></mat-icon>
      </a>
    </li>
    <li routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" *jhiHasAnyAuthority="[Authority.ACCESS_EOL_PAGE]">
      <a
        class="nav-link html-editor"
        [matTooltip]="'global.menu.htmlEditor' | translate"
        matTooltipPosition="below"
        [routerLink]="['/document', contextService.currentDocumentContext?.id, 'html-editor']"
        (click)="collapseNavbar()"
      >
        <mat-icon svgIcon="eol-item"></mat-icon>
      </a>
    </li>
    <ng-container *ngIf="contextService.currentDocumentContext?.dynamicDataAccess">
      <li routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" *jhiHasAnyAuthority="[Authority.ACCESS_EOL_DATA]">
        <a
          class="nav-link data-editor"
          [matTooltip]="'global.menu.dataEditor' | translate"
          matTooltipPosition="below"
          [routerLink]="['/document', contextService.currentDocumentContext?.id, 'data-editor']"
          (click)="collapseNavbar()"
        >
          <mat-icon svgIcon="eol-item"></mat-icon>
        </a>
      </li>
    </ng-container>
    <li routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" *jhiHasAnyAuthority="[Authority.ACCESS_EOL_STYLE]">
      <a
        class="nav-link style-editor"
        [matTooltip]="'global.menu.styleEditor' | translate"
        matTooltipPosition="below"
        [routerLink]="['/document', contextService.currentDocumentContext?.id, 'style-editor']"
        (click)="collapseNavbar()"
      >
        <mat-icon svgIcon="eol-item"></mat-icon>
      </a>
    </li>
    <li routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" *jhiHasAnyAuthority="[Authority.ACCESS_EOL_PREVIEW]">
      <a
        class="nav-link preview"
        [matTooltip]="'global.menu.preview' | translate"
        matTooltipPosition="below"
        [routerLink]="['/document', contextService.currentDocumentContext?.id, 'preview']"
        (click)="collapseNavbar()"
      >
        <mat-icon svgIcon="eol-item"></mat-icon>
      </a>
    </li>
    <li routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" *jhiHasAnyAuthority="[Authority.ACCESS_ADMIN]">
      <a
        class="nav-link admin"
        [matTooltip]="'global.menu.adminEditor' | translate"
        matTooltipPosition="below"
        [routerLink]="['/document', contextService.currentDocumentContext?.id, 'admin']"
        (click)="collapseNavbar()"
      >
        <mat-icon svgIcon="eol-item"></mat-icon>
      </a>
    </li>
    <li *ngIf="cockpitURL">
      <a
        class="nav-link cockpit"
        [matTooltip]="'global.menu.cockpit' | translate"
        matTooltipPosition="below"
        [href]="cockpitURL"
        target="_blank"
        rel="noopener noreferrer"
      >
        <mat-icon svgIcon="cockpit"></mat-icon>
      </a>
    </li>
  </ul>
</nav>
