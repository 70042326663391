<div class="root-node">
    <div class="root-node-label" (click)="expanded=!expanded">
        <div class="expender"><mat-icon svgIcon="{{expanded ? 'arrow-down' : 'arrow-right'}}"></mat-icon></div>
        <p class="label">{{label}}</p>
    </div>

    <div *ngIf="expanded">
        <ng-content></ng-content>
    </div>
</div>
