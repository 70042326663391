<div class="main-block">
  <ng-container>
    <div class="header-block">
      <div class="title-annotate" jhiTranslate="htmlEditor.plugins.annotate.search.title"></div>
      <div class="factsSelected">{{ numberOfFactsSelected }} <span jhiTranslate="htmlEditor.plugins.annotate.selectedLabel"></span></div>
      <jhi-input-search placeholder="htmlEditor.plugins.annotate.search.placeholder" (input)="filter($event.target.value)">
      </jhi-input-search>
    </div>
  </ng-container>
  <hr />
  <div class="content-block" [ngClass]="{ 'content-block--edition': footnoteId }">
    <form [formGroup]="editForm">
      <ng-container formArrayName="facts" *ngIf="factsCtrl.controls.length; else noFacts">
        <div *ngFor="let ctrl of factsCtrl.controls | annotateItemsFilter: searchTerm; trackBy: trackByFactId; index as i">
          <div class="annotate-item" [formGroup]="ctrl" [class.annotate-item-checked]="ctrl.value.checked">
            <div class="flex-1">
              <div class="annotate-item__head">
                <div class="annotate-item__concept-label">
                  {{ ctrl.value.fact?.concept | conceptTranslatedLabel: 'label':documentLanguage }}
                </div>
                <div class="property annotate-item__period-label" *ngIf="ctrl.value.fact.context?.period">
                  {{ ctrl.value.fact.context.period.startDate | localizedDate: 'shortDate' }}
                  <span *ngIf="ctrl.value.fact.context.period.periodType === periodType.PERIOD">
                    - {{ ctrl.value.fact.context.period.endDate | localizedDate: 'shortDate' }}</span
                  >
                </div>
              </div>
              <div class="annotate-item__fact-value">
                {{ ctrl.value.fact.factValue | xbrlFactValue: ctrl.value.fact.concept.type }}
              </div>
            </div>
            <div class="annotate-checkbox">
              <mat-checkbox formControlName="checked"></mat-checkbox>
            </div>
          </div>
        </div>
      </ng-container>

      <ng-template #noFacts>
        <div class="annotate-panel__no-facts" jhiTranslate="htmlEditor.plugins.annotate.noFacts"></div>
      </ng-template>
    </form>
  </div>
  <div class="annotate__footer">
    <div class="action-buttons">
      <button
        type="button"
        mat-raised-button
        color="accent"
        jhiTranslate="htmlEditor.plugins.annotate.button.cancelBtn"
        (click)="onClickClosePanel()"
      ></button>

      <button
        class="action-buttons__save"
        type="button"
        mat-raised-button
        color="primary"
        jhiTranslate="htmlEditor.plugins.annotate.button.saveBtn"
        (click)="onSubmitSelectedFacts()"
        [disabled]="!isSubmitButtonEnabled"
      ></button>
    </div>
    <div *ngIf="footnoteId" class="annotate-remove">
      <a class="annotate-remove-link" (click)="onRemoveAnnotation($event)">
        <mat-icon svgIcon="icon-trash"></mat-icon>
        <span class="annotate-remove-link__label" jhiTranslate="htmlEditor.plugins.annotate.button.removeBtn"></span>
      </a>
    </div>
    <jhi-inline-dialog *ngIf="displayDeleteConfirmation">
      <jhi-panel-delete-confirmation
        warningMessage="htmlEditor.plugins.annotate.delete.confirmDialog.deleteFootnoteContent"
        (confirm)="onRemoveConfirmation()"
        (cancel)="onRemoveCancel()"
      ></jhi-panel-delete-confirmation>
    </jhi-inline-dialog>
  </div>
</div>
