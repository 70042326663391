import { Component, Input } from '@angular/core';
import { PDFReportResult } from 'app/shared/model/download-manager.model';

@Component({
  selector: 'jhi-pdf-report-table',
  templateUrl: 'pdf-report-table.component.html',
  styleUrls: ['pdf-report-table.component.scss'],
})
export class PDFReportTableComponent {
  @Input() public columns: string[] = ['chapterTitle', 'documentaryUnitId', 'stepName', 'shortMessages'];
  @Input() public dataSource: PDFReportResult[];
  @Input() public docId: number;
}
