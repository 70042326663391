export const DEFAULT_AVATAR = './content/images/avatar/avatar-cockpit_genre2_20191209-17.svg';

export const AVATARS: string[] = [
  './content/images/avatar/avatar-cockpit_genre2_20191209-01.svg',
  './content/images/avatar/avatar-cockpit_genre2_20191209-02.svg',
  './content/images/avatar/avatar-cockpit_genre2_20191209-03.svg',
  './content/images/avatar/avatar-cockpit_genre2_20191209-04.svg',
  './content/images/avatar/avatar-cockpit_genre2_20191209-05.svg',
  './content/images/avatar/avatar-cockpit_genre2_20191209-06.svg',
  './content/images/avatar/avatar-cockpit_genre2_20191209-07.svg',
  './content/images/avatar/avatar-cockpit_genre2_20191209-08.svg',
  './content/images/avatar/avatar-cockpit_genre2_20191209-09.svg',
  './content/images/avatar/avatar-cockpit_genre2_20191209-10.svg',
  './content/images/avatar/avatar-cockpit_genre2_20191209-11.svg',
  './content/images/avatar/avatar-cockpit_genre2_20191209-12.svg',
  './content/images/avatar/avatar-cockpit_genre2_20191209-13.svg',
  './content/images/avatar/avatar-cockpit_genre2_20191209-14.svg',
  './content/images/avatar/avatar-cockpit_genre2_20191209-15.svg',
  './content/images/avatar/avatar-cockpit_genre2_20191209-16.svg',
  DEFAULT_AVATAR,
  './content/images/avatar/avatar-cockpit_genre2_20191209-18.svg',
  './content/images/avatar/avatar-cockpit_genre2_20191209-19.svg',
  './content/images/avatar/avatar-cockpit_genre2_20191209-20.svg'
];
