<div class="panel-block-right properties-panel">
  <div class="panel-header-block">
    <button mat-icon-button class="header-close" (click)="onClosePanel()">
      <mat-icon svgIcon="chevron-right"></mat-icon>
    </button>
    <div class="header-title" [jhiTranslate]="titleI18nKey"></div>
  </div>
  <div class="panel-content-block scrollbar">
    <ng-content></ng-content>
  </div>
</div>
