import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { PluginComponent } from '../../plugin.component';
import { PluginPanelService } from '../../plugin-panel.service';
import { DocumentService } from 'app/core/service/document.service';

import pubsub from 'app/pubsub';
import { EDITOR_PLUGIN_TOGGLEPANEL } from 'app/pubsub.topics';
import { PLUGIN_PANEL_COMPONENT_KEYS } from '../../plugin-panel-component-keys';
import { ExportType } from 'app/shared/model/download-manager.model';
import { IDocumentaryUnit } from 'app/shared/model/documentary-unit.model';
import { UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { ContentType } from 'app/shared/enum/content-type.enum';
import { ContextService } from 'app/core/service/context.service';

@Component({
  selector: 'jhi-configuration-export-lore',
  templateUrl: './configuration-export-lore.component.html',
  styleUrls: ['./configuration-export-lore.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ConfigurationExportLoreComponent extends PluginComponent implements OnInit {
  public export = ExportType.CHAPTER;
  public readonly ExportType = ExportType;
  public sections: IDocumentaryUnit[];
  public readonly ContentType = ContentType;
  public syncLore = false;
  public syncLoreDisabled = true;

  public exportForm = this.fb.group({
    typeExportSelected: new UntypedFormControl(this.export),
    sectionsSelected: new UntypedFormControl(null),
  });

  constructor(
    private fb: UntypedFormBuilder,
    public pluginPanelService: PluginPanelService,
    private documentService: DocumentService,
    private contextService: ContextService
  ) {
    super(pluginPanelService);
  }

  ngOnInit(): void {
    this.syncLore = this.contextService.canSyncLore;
    this.syncLoreDisabled = !this.syncLore;
  }

  goBack(): void {
    pubsub.fire(EDITOR_PLUGIN_TOGGLEPANEL, {
      component: PLUGIN_PANEL_COMPONENT_KEYS.DOCUMENT_EXPORT,
      title: 'htmlEditor.plugins.documentExport.title',
    });
  }

  downloadLoreExport(): void {
    this.documentService.downloadLoreExport(this.syncLore);
    this.pluginPanelService.closePanel();
  }
}
