<h3 id="garbageCollectorMetrics" jhiTranslate="metrics.jvm.gc.title">Garbage collections</h3>

<div class="row">
  <div class="col-md-4">
    <div *ngIf="garbageCollectorMetrics">
      <span>
        GC Live Data Size/GC Max Data Size ({{ garbageCollectorMetrics['jvm.gc.live.data.size'] / 1048576 | number: '1.0-0' }}M /
        {{ garbageCollectorMetrics['jvm.gc.max.data.size'] / 1048576 | number: '1.0-0' }}M)
      </span>

      <ngb-progressbar
        [max]="garbageCollectorMetrics['jvm.gc.max.data.size']"
        [value]="garbageCollectorMetrics['jvm.gc.live.data.size']"
        [striped]="true"
        [animated]="false"
        type="success"
      >
        <span>
          {{
            (100 * garbageCollectorMetrics['jvm.gc.live.data.size']) / garbageCollectorMetrics['jvm.gc.max.data.size'] | number: '1.0-2'
          }}%
        </span>
      </ngb-progressbar>
    </div>
  </div>

  <div class="col-md-4">
    <div *ngIf="garbageCollectorMetrics">
      <span>
        GC Memory Promoted/GC Memory Allocated ({{ garbageCollectorMetrics['jvm.gc.memory.promoted'] / 1048576 | number: '1.0-0' }}M /
        {{ garbageCollectorMetrics['jvm.gc.memory.allocated'] / 1048576 | number: '1.0-0' }}M)
      </span>

      <ngb-progressbar
        [max]="garbageCollectorMetrics['jvm.gc.memory.allocated']"
        [value]="garbageCollectorMetrics['jvm.gc.memory.promoted']"
        [striped]="true"
        [animated]="false"
        type="success"
      >
        <span>
          {{
            (100 * garbageCollectorMetrics['jvm.gc.memory.promoted']) / garbageCollectorMetrics['jvm.gc.memory.allocated']
              | number: '1.0-2'
          }}%
        </span>
      </ngb-progressbar>
    </div>
  </div>

  <div id="garbageCollectorMetrics" class="col-md-4">
    <div class="row" *ngIf="garbageCollectorMetrics">
      <div class="col-md-9">Classes loaded</div>
      <div class="col-md-3 text-end">{{ garbageCollectorMetrics.classesLoaded }}</div>
    </div>
    <div class="row" *ngIf="garbageCollectorMetrics">
      <div class="col-md-9">Classes unloaded</div>
      <div class="col-md-3 text-end">{{ garbageCollectorMetrics.classesUnloaded }}</div>
    </div>
  </div>

  <div class="table-responsive" *ngIf="!updating && garbageCollectorMetrics">
    <table class="table table-striped" aria-describedby="garbageCollectorMetrics">
      <thead>
        <tr>
          <th scope="col"></th>
          <th scope="col" class="text-end" jhiTranslate="metrics.servicesstats.table.count">Count</th>
          <th scope="col" class="text-end" jhiTranslate="metrics.servicesstats.table.mean">Mean</th>
          <th scope="col" class="text-end" jhiTranslate="metrics.servicesstats.table.min">Min</th>
          <th scope="col" class="text-end" jhiTranslate="metrics.servicesstats.table.p50">p50</th>
          <th scope="col" class="text-end" jhiTranslate="metrics.servicesstats.table.p75">p75</th>
          <th scope="col" class="text-end" jhiTranslate="metrics.servicesstats.table.p95">p95</th>
          <th scope="col" class="text-end" jhiTranslate="metrics.servicesstats.table.p99">p99</th>
          <th scope="col" class="text-end" jhiTranslate="metrics.servicesstats.table.max">Max</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>jvm.gc.pause</td>
          <td class="text-end">{{ garbageCollectorMetrics['jvm.gc.pause'].count }}</td>
          <td class="text-end">{{ garbageCollectorMetrics['jvm.gc.pause'].mean | number: '1.0-3' }}</td>
          <td class="text-end">{{ garbageCollectorMetrics['jvm.gc.pause']['0.0'] | number: '1.0-3' }}</td>
          <td class="text-end">{{ garbageCollectorMetrics['jvm.gc.pause']['0.5'] | number: '1.0-3' }}</td>
          <td class="text-end">{{ garbageCollectorMetrics['jvm.gc.pause']['0.75'] | number: '1.0-3' }}</td>
          <td class="text-end">{{ garbageCollectorMetrics['jvm.gc.pause']['0.95'] | number: '1.0-3' }}</td>
          <td class="text-end">{{ garbageCollectorMetrics['jvm.gc.pause']['0.99'] | number: '1.0-3' }}</td>
          <td class="text-end">{{ garbageCollectorMetrics['jvm.gc.pause'].max | number: '1.0-3' }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
