import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class NavbarService {
  private navbarOpened: BehaviorSubject<any> = new BehaviorSubject(false);

  isNavbarOpened(): Observable<boolean> {
    return this.navbarOpened.asObservable();
  }

  toggleNavbar(): void {
    this.navbarOpened.next(!this.navbarOpened.value);
  }

  collapseNavbar(): void {
    this.navbarOpened.next(false);
  }
}
