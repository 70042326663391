<h1 mat-dialog-title class="error-title">
  <span jhiTranslate="dialog.errors.xhtml-export-error.title"></span>
  <button type="button" mat-icon-button class="mat-dialog-close-btn" (click)="onClose()">
    <fa-icon icon="times"></fa-icon>
  </button>
</h1>

<div mat-dialog-content id="accordion-dialog">
  <table class="table table-bordered table-responsive d-table" mat-table [dataSource]="errors" aria-describedby="error-history-table">
    <ng-container matColumnDef="message">
      <th *matHeaderCellDef jhiTranslate="dataEditor.xbrl.dialog.xhtmlExportError.message" scope="col"></th>
      <td class="message-td" *matCellDef="let error" jhiTranslate="dialog.errors.{{ error.message }}.message"></td>
    </ng-container>
    <ng-container matColumnDef="footnote">
      <th *matHeaderCellDef jhiTranslate="dataEditor.xbrl.dialog.xhtmlExportError.footnote" scope="col"></th>
      <td class="footnote-td" *matCellDef="let error">{{ error.footnote }}</td>
    </ng-container>
    <ng-container matColumnDef="fact">
      <th *matHeaderCellDef jhiTranslate="dataEditor.xbrl.dialog.xhtmlExportError.fact" scope="col"></th>
      <td class="fact-td" *matCellDef="let error">{{ error?.fact?.factXbrlId }}</td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="errorColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: errorColumns"></tr>
  </table>
</div>

<div mat-dialog-actions>
  <button mat-raised-button color="primary" (click)="onClose()"><span jhiTranslate="dialog.btn-understood"></span></button>
</div>
