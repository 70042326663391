<div class="properties-form-group">
  <ng-container *ngIf="!isExpandable; else expandableTemplate">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </ng-container>
</div>
<ng-template #content>
  <ng-content></ng-content>
</ng-template>

<ng-template #expandableTemplate>
    <mat-accordion class="properties-form-accordion" [@.disabled]="disableAnimation">
      <mat-expansion-panel class="mat-elevation-z0" hideToggle (afterCollapse)="onAfterCollapse()" (afterExpand)="onAfterExpand()">
        <mat-expansion-panel-header>
          <mat-panel-title  class="properties-form-group-title">
            {{title}}
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="properties-form-accordion-content">
          <ng-container *ngTemplateOutlet="content"></ng-container>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
</ng-template>
