import { UntypedFormGroup } from '@angular/forms';
import { IColor, ICMYK } from 'app/shared/model/color.model';
import { RGBA, HSLA } from 'ngx-color';
import * as simpleColorConverter from 'simple-color-converter';

export class ColorUtils {
  public static rgbaToPantone(rgbaCode: RGBA): string {
    return new simpleColorConverter({
      rgba: rgbaCode,
      to: 'pantone',
    }).color;
  }

  public static rgbaToCmyk(rgbaCode: RGBA): ICMYK {
    return new simpleColorConverter({
      rgba: rgbaCode,
      to: 'cmyk',
    }).color;
  }

  public static hexToRgba(hexCode: string): RGBA {
    return new simpleColorConverter({
      hex: hexCode,
      to: 'rgba',
    }).color;
  }

  public static setNewColor(form: UntypedFormGroup, color: IColor): IColor {
    color.parentId = 746;
    color.colorName = form.get('colorName')?.value;
    color.key = `Pantone ${color.colorName}`;
    color.className = 'LabradorColor';
    color.hexCode = form.get('hexCode')?.value;
    color.rgbCode = form.get('rgbCode')?.value;
    color.cmykCode = this.convertCmykToString(form);
    color.pantoneCode = form.get('pantoneCode')?.value;
    color.hslaCode = this.convertHslaToString(form);
    return color;
  }

  public static convertToPercent(data: number): string {
    return (data * 100).toFixed(0);
  }

  public static concatNameColor(colors: IColor[]): string {
    return colors
      .sort((a, b) => ((a.colorName ?? '') > (b.colorName ?? '') ? 1 : (b.colorName ?? '') > (a.colorName ?? '') ? -1 : 0))
      .map((color: IColor) => color.colorName)
      .join(', ');
  }

  public static concatIdColor(colors: IColor[]): number[] {
    const colorsIds: number[] = [];
    colors.forEach(color => {
      colorsIds.push(color.id as number);
    });
    return colorsIds;
  }

  public static isNameAlreadyExist(colorName: string, colorId: number, colors: IColor[]): boolean {
    return colors.some(c => c.colorName && c.colorName.toLowerCase() === colorName.toLowerCase() && c.id !== colorId);
  }

  public static convertRgbaToString(rgbaCode: RGBA): string {
    return Object.keys(rgbaCode)
      .map(key => rgbaCode[key])
      .join(',');
  }

  private static convertCmykToString(form: UntypedFormGroup): string {
    return `${form.get('cmykCodeC')?.value},${form.get('cmykCodeM')?.value},${form.get('cmykCodeY')?.value},${
      form.get('cmykCodeK')?.value
    }`;
  }

  private static convertHslaToString(form: UntypedFormGroup): string {
    // User can unfilled a HSLA input, for this case there is no required control on the field but we save default values instead of null.
    const hslaCodeH = isNaN(form.get('hslaCodeH')?.value) || !form.get('hslaCodeH')?.value ? 0 : form.get('hslaCodeH')?.value;
    const hslaCodeS = isNaN(form.get('hslaCodeS')?.value) || !form.get('hslaCodeS')?.value ? 0 : form.get('hslaCodeS')?.value;
    const hslaCodeL = isNaN(form.get('hslaCodeL')?.value) || !form.get('hslaCodeL')?.value ? 0 : form.get('hslaCodeL')?.value;
    const hslaCodeA = isNaN(form.get('hslaCodeA')?.value) || !form.get('hslaCodeA')?.value ? 1 : form.get('hslaCodeA')?.value;

    return `${hslaCodeH},${hslaCodeS},${hslaCodeL},${hslaCodeA}`;
  }

  public static convertStringToHsla(hslaCode: string | undefined): HSLA | null {
    if (!hslaCode) {
      return null;
    }
    const hslaArray = hslaCode.split(',');
    return {
      h: +hslaArray[0],
      s: +hslaArray[1],
      l: +hslaArray[2],
      a: +hslaArray[3],
    };
  }

  public static convertStringToCmyk(cmykCode: string | undefined): ICMYK | null {
    if (!cmykCode) {
      return null;
    }
    const cmykArray = cmykCode.split(',');
    return {
      c: cmykArray[0],
      m: cmykArray[1],
      y: cmykArray[2],
      k: cmykArray[3],
    };
  }

  public static convertStringToRgba(rgbaCode: string): RGBA {
    if (!rgbaCode) {
      rgbaCode = '0,0,0,0';
    }
    const rgbaArray = rgbaCode.split(',');
    return {
      r: +rgbaArray[0],
      g: +rgbaArray[1],
      b: +rgbaArray[2],
      a: +rgbaArray[3],
    };
  }
}
