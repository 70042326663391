<div class="lookup-panel-container">
  <button
    type="button"
    class="lookup-infos"
    [matTooltip]="'htmlEditor.plugins.lookup.helpDialogButton' | translate"
    matTooltipPosition="left"
    (click)="displayHelpDialog()"
  >
    i
  </button>

  <mat-form-field>
    <mat-label jhiTranslate="htmlEditor.plugins.lookup.type.label"></mat-label>
    <mat-select [formControl]="lookupType">
      <mat-option *ngFor="let type of lookupTypes" [value]="type">{{ 'htmlEditor.plugins.lookup.type.' + type | translate }} </mat-option>
    </mat-select>
  </mat-form-field>

  <jhi-lookup-entries-form
    [lookupType]="lookupType"
    [entries]="entries"
    (addEntry)="addEntry()"
    (moveEntryUp)="moveEntryUp($event)"
    (moveEntryDown)="moveEntryDown($event)"
    (deleteEntry)="deleteEntry($event)"
    (updateEntry)="updateEntry($event)"
  ></jhi-lookup-entries-form>

  <div class="buttons">
    <button
      type="button"
      mat-raised-button
      color="accent"
      jhiTranslate="htmlEditor.plugins.lookup.cancelBtn"
      (click)="onClickCancel()"
    ></button>
    <button
      type="button"
      mat-raised-button
      color="primary"
      jhiTranslate="htmlEditor.plugins.lookup.{{ insertOrUpdateBtn }}"
      (click)="onSaveBtn()"
      [disabled]="disableSave"
    ></button>
  </div>
</div>
