import { Big } from 'big.js';
import { NumberShaping } from '../model/format.model';
import { UNIT_POSITIONS } from 'app/shared/constants/patterns.constants';
import { toNonBreakingSpaces } from './format-commons.utils';

/**
 * Unicode Locale Data Markup Language (LDML)
 * Part 3: Numbers
 * [https://www.unicode.org/reports/tr35/tr35-numbers.html#Number_Format_Patterns]
 * Prise en compte d'une partie du standard :
 * - 0 : Digit
 * - # : Digit, omitting leading/trailing zeros
 * - . : Decimal separator or monetary decimal separator
 * - , : Grouping separator. May occur in both the integer part and the fractional part. The position determines the grouping.
 * - ' : Used to quote special characters in a prefix or suffix, for example, "'#'#" formats 123 to "#123". To create a single quote itself, use two in a row: "# o''clock".
 * Les autres caractères du standard (123456789@+-E%‰;¤*) sont traités comme suit :
 * - Les caractères (123456789@;¤*) sont autorisés entre apostrophes ''.
 * - Les caractères (+-E%‰) sont autorisés mais pas interprétés.
 */

/**
 * Zero number is :
 * 1. with DOT to separate units/decimals :  000.00 / 000,000.00 / 000 000.00 ...
 * 2. with COMMA to separate units/decimals :  000,00 / 000.000,00 / 000 000,00 ...
 * 3. with only units part :  000 / 000.000 / 000 000 ...
 * Can be prefixed by MINUS sign (-).
 */
const zeroNumberPattern =
  '^\\s*[-]?\\s*((?:(?:(?:0[,]?)*|(?:0\\s?)*)[.]0+)|(?:(?:(?:0[.]?)*|(?:0\\s?)*)[,]0+)|(?:(?:(?:0[,]?)+|(?:0[.]?)+|(?:0\\s?)+)))\\s*$';

const numberPattern = '(?<![,.#0])(?:(?:(?:,?#)*(?:,?0)*[.](?:0)*(?:#)*)|(?:,?#)*(?:,?0)+|(?:,?#)+)(?![,.#0])';
const matchAllNumberPatterns = `(${numberPattern})`;
const matchFirstNumberPattern = `(?<=^[^.,#0]*)(${numberPattern})(?=.*$)`;
const matchStrictNumberPattern = `^(${numberPattern})$`;
const candidateNumberPattern = '([.,#0]+)';
const LDML_APOS = "'";
const LDML_COMMA = ',';
const LDML_DOT = '.';
const LDML_ZERO = '0';
const forbiddenChars = '(?:[123456789@;¤*]+)';

type CompiledNumberPattern = {
  prefix: string;
  pattern: string;
  suffix: string;
  unitsPattern: string;
  decimalsPattern: string;
  groupSize1: number;
  groupSize2: number;
  unitsZeroFill: number;
  decimalsZeroFill: number;
  decimalsPrecision: number;
};

export const isZeroNumber = (value: string): boolean => {
  return new RegExp(zeroNumberPattern, 'gm').test(value);
};

const containsOddNumberOfApos = (expression: string): boolean => {
  return expression.split(LDML_APOS).length % 2 === 0;
};

const candidatesNumberPatterns = (expression: string): { pattern: string; isValid: boolean }[] => {
  return expression.split(LDML_APOS).flatMap((value, index) => {
    if (index % 2 === 0) {
      const matches: RegExpMatchArray | null = value.match(new RegExp(candidateNumberPattern, 'g'));
      return matches === null
        ? []
        : matches.map((match: string) => {
            return {
              pattern: match,
              isValid: new RegExp(matchStrictNumberPattern, 'g').test(match),
            };
          });
    } else {
      return [];
    }
  });
};

const containsForbidenChars = (expression: string): boolean => {
  return (
    0 <
    expression.split(LDML_APOS).filter((value, index) => {
      if (index % 2 === 0) {
        const matches: RegExpMatchArray | null = value.match(new RegExp(forbiddenChars, 'g'));
        return matches !== null && 0 < matches.length;
      } else {
        return false;
      }
    }).length
  );
};

export const isValidNumberPattern = (expression: string): boolean => {
  if (containsOddNumberOfApos(expression)) {
    return false;
  }
  const candidates = candidatesNumberPatterns(expression);
  const invalidCandidates = candidates.filter(candidate => !candidate.isValid);
  if (0 < invalidCandidates.length) {
    return false;
  }
  const validCandidates = candidates.filter(candidate => candidate.isValid);
  if (1 < validCandidates.length) {
    return false;
  }
  if (containsForbidenChars(expression)) {
    return false;
  }
  return true;
};

const compileNumberPatternReducer = (accumulator: any, value: string, index: number, array: Array<string>) => {
  if (index % 2 === 0 && new RegExp(matchAllNumberPatterns, 'g').test(value)) {
    accumulator.pattern += value;
    const [prefix, pattern, suffix] = value.split(new RegExp(matchFirstNumberPattern, 'g'));
    accumulator.prefix += prefix;
    accumulator.pattern = pattern || '';
    accumulator.suffix += suffix || '';
  } else {
    const defaultValue = 0 < index && index < array.length - 1 ? LDML_APOS : '';
    if (accumulator.pattern === '') {
      accumulator.prefix += value || defaultValue;
    } else {
      accumulator.suffix += value || defaultValue;
    }
  }
  return accumulator;
};

const compileNumberPattern = (expression: string): CompiledNumberPattern => {
  if (!isValidNumberPattern(expression)) {
    throw new SyntaxError(`Invalid number pattern [${expression}]`);
  }
  const compiled = expression.split(LDML_APOS).reduce(compileNumberPatternReducer, { prefix: '', pattern: '', suffix: '' });
  const [unitsPattern = '', decimalsPattern = ''] = compiled.pattern.split('.');
  let groups = unitsPattern.split(LDML_COMMA);
  groups.shift();
  groups = groups.reverse();
  const [groupSize1 = 0, groupSize2 = 0] = groups.map((group: Array<string>) => group.length);
  return {
    prefix: compiled.prefix,
    pattern: compiled.pattern,
    suffix: compiled.suffix,
    unitsPattern,
    decimalsPattern,
    groupSize1,
    groupSize2,
    unitsZeroFill: unitsPattern.split(LDML_ZERO).length - 1,
    decimalsZeroFill: decimalsPattern.split(LDML_ZERO).length - 1,
    decimalsPrecision: decimalsPattern.length,
  };
};

const unitPrefixSuffix = (shaping: NumberShaping, unit: string): { unitPrefix: string; unitSuffix: string } => {
  if (unit) {
    switch (shaping.unitDisplay) {
      case UNIT_POSITIONS.AHEAD.value:
        return { unitPrefix: unit, unitSuffix: '' };
      case UNIT_POSITIONS.AHEAD_WITH_SPACE.value:
        return { unitPrefix: `${unit} `, unitSuffix: '' };
      case UNIT_POSITIONS.BEHIND.value:
        return { unitPrefix: '', unitSuffix: unit };
      case UNIT_POSITIONS.BEHIND_WITH_SPACE.value:
        return { unitPrefix: '', unitSuffix: ` ${unit}` };
    }
  }
  return { unitPrefix: '', unitSuffix: '' };
};

const separators = (
  shaping: NumberShaping,
  defaultDecimalsSeparator: string,
  defaultGroupingSeparator: string
): { decimalsSeparator: string; groupingSeparator: string } => {
  return {
    decimalsSeparator: shaping.decimalsSeparator ?? defaultDecimalsSeparator,
    groupingSeparator: shaping.groupingSeparator ?? defaultGroupingSeparator,
  };
};

const formatZeroNumber = (zeroString: string, unitPrefix: string, unitSuffix: string, noBreakSpaces = false) => {
  const formatted = isZeroNumber(zeroString) ? `${unitPrefix}${zeroString}${unitSuffix}` : zeroString;
  return noBreakSpaces ? toNonBreakingSpaces(formatted) : formatted;
};

const formatNumber = (
  number: Big,
  pattern: CompiledNumberPattern,
  options: {
    decimalsSeparator: string;
    groupingSeparator: string;
    unitPrefix: string;
    unitSuffix: string;
    noBreakSpaces: boolean;
  }
): string => {
  const groupSize1 = pattern.groupSize1;
  const groupSize2 = 0 < pattern.groupSize2 ? pattern.groupSize2 : groupSize1;
  const groupingPattern = 0 < groupSize1 ? `((?:\\d{1,${groupSize2}}))(?=(?:\\d{${groupSize2}})*(?:\\d{${groupSize1}})$)` : undefined;
  const rounded = number.abs().round(pattern.decimalsPrecision);
  const [units, decimals = ''] = rounded.toString().split(LDML_DOT);
  const unitsFilled = new Big(units).toString().padStart(pattern.unitsZeroFill, LDML_ZERO);
  const decimalsFilled = decimals.padEnd(pattern.decimalsZeroFill, LDML_ZERO);
  const unitsGrouped =
    groupingPattern !== undefined ? unitsFilled.replace(new RegExp(groupingPattern, 'g'), `$1${options.groupingSeparator}`) : unitsFilled;
  const formatted = `${options.unitPrefix}${pattern.prefix}${unitsGrouped}${
    decimalsFilled ? options.decimalsSeparator : ''
  }${decimalsFilled}${pattern.suffix}${options.unitSuffix}`;
  return options.noBreakSpaces ? toNonBreakingSpaces(formatted) : formatted;
};

export const pgdpFormatNumber = (
  number: Big,
  shaping: NumberShaping,
  defaultGroupingSeparator: string,
  defaultDecimalsSeparator: string,
  unit: string,
  noBreakSpaces = false
): string => {
  const { unitPrefix, unitSuffix } = unitPrefixSuffix(shaping, unit);
  if (number.eq(0)) {
    const zeroString = shaping.negativeRoundedToZero && number.s === -1 ? shaping.negativeRoundedToZeroString : shaping.zeroString;
    return formatZeroNumber(zeroString, unitPrefix, unitSuffix, noBreakSpaces);
  } else if (number.lt(0)) {
    const pattern = compileNumberPattern(shaping.negativePattern);
    const rounded = number.round(pattern.decimalsPrecision);
    if (rounded.eq(0) && shaping.negativeRoundedToZero) {
      return formatZeroNumber(shaping.negativeRoundedToZeroString, unitPrefix, unitSuffix, noBreakSpaces);
    } else if (rounded.eq(0)) {
      return formatZeroNumber(shaping.zeroString, unitPrefix, unitSuffix, noBreakSpaces);
    } else {
      return formatNumber(number, pattern, {
        ...separators(shaping, defaultDecimalsSeparator, defaultGroupingSeparator),
        unitPrefix,
        unitSuffix,
        noBreakSpaces,
      });
    }
  } else {
    const pattern = compileNumberPattern(shaping.positivePattern);
    const rounded = number.round(pattern.decimalsPrecision);
    if (rounded.eq(0)) {
      return formatZeroNumber(shaping.zeroString, unitPrefix, unitSuffix, noBreakSpaces);
    } else {
      return formatNumber(number, pattern, {
        ...separators(shaping, defaultDecimalsSeparator, defaultGroupingSeparator),
        unitPrefix,
        unitSuffix,
        noBreakSpaces,
      });
    }
  }
};
