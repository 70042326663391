<ng-content></ng-content>
<div
  *ngFor='let direction of directions'
  [ngClass]="['bar', 'bar--' + direction, isDragging ? 'bar--dragging' : '']"
  (mousedown)="onMousedown($event, direction);"
>
  <div [ngClass]="['handle', 'handle--'+direction]">
    <div class="deco"></div><div class="deco"></div><div class="deco"></div>
  </div>
</div>
<div class="overlay"></div>
