export const DATE_PATTERNS = [
  'dd/MM/yyyy',
  'dd-MM/yyyy',
  'dd MMM yyyy',
  'dd MMMM yyyy',
  'dd/MM/yy',
  'dd-MM-yy',
  'dd.MM.yy',
  'MMM dd yyyy',
  'MMM yyyy',
  'MMMM dd yyyy',
  'MMMM yyyy',
  'do MMMM',
  'yyyy/MM/dd ',
  'yyyy-MM/dd',
  'yy-MM-dd',
  'yy.MM.dd',
  'yy/MM/dd',
];

export const AREVIO_DATE_PATTERN = 'yyyy-MM-dd';

export const NUMBER_SAMPLE_PATTERNS = [
  '#,###.00 / - #,###.00 / 0',
  '#,###.00 / (#,###.00) / 0',
  '#,### / - #,### / -',
  '#,### / (#,###) / -',
  '#,### % / - #,### % / 0 %',
  '#,### % / - #,### % / -',
  '#,###.00 % / - #,###.00 % / -',
  '#,###.00 % / - #,###.00 % / 0 %',
];

export const concatToNumberSamplePattern = (positive: string, negative: string, zero: string): string => {
  return [positive, negative, zero].join(' / ');
};

export const splitFromNumberSamplePattern = (samplePattern: string): [string, string, string] => {
  return samplePattern.split('/').map((value: string) => value.trim()) as [string, string, string];
};

export const isNumberSamplePattern = (positive: string, negative: string, zero: string): boolean => {
  return !!NUMBER_SAMPLE_PATTERNS.find((samplePattern: string) => {
    const [samplePositive, sampleNegative, sampleZero] = splitFromNumberSamplePattern(samplePattern);
    return positive === samplePositive && negative === sampleNegative && zero === sampleZero;
  });
};

export type FormatValueLabelType = {
  value: string;
  label: string;
};

type DecimalsSeperators = {
  DEFAULT: FormatValueLabelType;
  COMMA: FormatValueLabelType;
  DOT: FormatValueLabelType;
};

export const DECIMAL_SEPARATORS: DecimalsSeperators = {
  DEFAULT: {
    value: 'default',
    label: 'adminEditor.project-configuration.format.numbers.defaultLabel',
  },
  COMMA: {
    value: ',',
    label: 'adminEditor.project-configuration.format.numbers.commaLabel',
  },
  DOT: {
    value: '.',
    label: 'adminEditor.project-configuration.format.numbers.dotLabel',
  },
};

type GroupingSeperators = {
  DEFAULT: FormatValueLabelType;
  NONE: FormatValueLabelType;
  COMMA: FormatValueLabelType;
  SPACE: FormatValueLabelType;
  DOT: FormatValueLabelType;
};

export const GROUPING_SEPARATORS: GroupingSeperators = {
  DEFAULT: {
    value: 'default',
    label: 'adminEditor.project-configuration.format.numbers.defaultLabel',
  },
  NONE: {
    value: '',
    label: 'adminEditor.project-configuration.format.numbers.noneLabel',
  },
  COMMA: {
    value: ',',
    label: 'adminEditor.project-configuration.format.numbers.commaLabel',
  },
  SPACE: {
    value: ' ',
    label: 'adminEditor.project-configuration.format.numbers.spaceLabel',
  },
  DOT: {
    value: '.',
    label: 'adminEditor.project-configuration.format.numbers.dotLabel',
  },
};

type UnitPositions = {
  NONE: FormatValueLabelType;
  AHEAD: FormatValueLabelType;
  AHEAD_WITH_SPACE: FormatValueLabelType;
  BEHIND: FormatValueLabelType;
  BEHIND_WITH_SPACE: FormatValueLabelType;
};

export const UNIT_POSITIONS: UnitPositions = {
  NONE: {
    value: 'NONE',
    label: 'adminEditor.project-configuration.format.numbers.unitPositions.noneLabel',
  },
  AHEAD: {
    value: 'AHEAD',
    label: 'adminEditor.project-configuration.format.numbers.unitPositions.aheadLabel',
  },
  AHEAD_WITH_SPACE: {
    value: 'AHEAD_WITH_SPACE',
    label: 'adminEditor.project-configuration.format.numbers.unitPositions.aheadWithSpaceLabel',
  },
  BEHIND: {
    value: 'BEHIND',
    label: 'adminEditor.project-configuration.format.numbers.unitPositions.behindLabel',
  },
  BEHIND_WITH_SPACE: {
    value: 'BEHIND_WITH_SPACE',
    label: 'adminEditor.project-configuration.format.numbers.unitPositions.behindWithSpaceLabel',
  },
};

type TextCases = {
  NONE: FormatValueLabelType;
  UPPER_CASE: FormatValueLabelType;
  LOWER_CASE: FormatValueLabelType;
  TITLE_CASE: FormatValueLabelType;
  SENTENCE_CASE: FormatValueLabelType;
};

export const TEXT_CASES: TextCases = {
  NONE: {
    value: 'NONE',
    label: 'adminEditor.project-configuration.format.textCases.none',
  },
  UPPER_CASE: {
    value: 'UPPER_CASE',
    label: 'adminEditor.project-configuration.format.textCases.upperCase',
  },
  LOWER_CASE: {
    value: 'LOWER_CASE',
    label: 'adminEditor.project-configuration.format.textCases.lowerCase',
  },
  TITLE_CASE: {
    value: 'TITLE_CASE',
    label: 'adminEditor.project-configuration.format.textCases.titleCase',
  },
  SENTENCE_CASE: {
    value: 'SENTENCE_CASE',
    label: 'adminEditor.project-configuration.format.textCases.sentenceCase',
  },
};
