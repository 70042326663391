import { DecimalPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AccountService } from 'app/core/auth/account.service';
import { VALUE_TYPE } from '../enum/xslx.enum';
import { RCSFCell } from '../model/rcsf.model';
import { SpreadsheetUtils } from '../util/spreadsheet-utils';

@Pipe({
  name: 'dynamicDataValue',
  pure: true,
})
export class DynamicDataValuePipe implements PipeTransform {
  constructor(private translateService: TranslateService, private accountService: AccountService) {}

  transform(cell: RCSFCell): string {
    if (cell.numericValue) {
      return (
        new DecimalPipe(this.translateService.currentLang).transform(cell.scaledValue ?? cell.formattedValue, '1.0-2') ??
        cell.formattedValue
      );
    }
    if (cell.dateValue) {
      return SpreadsheetUtils.manageDateISO8601(cell.formattedValue, VALUE_TYPE.DATE, this.accountService.getLocale());
    }
    return cell.formattedValue;
  }
}
