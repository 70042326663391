import { Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import pubsub from 'app/pubsub';
import { EDITOR_PLUGIN_CLICK_IMPORT_BTN, INSERT_BREAK_PAGES } from 'app/pubsub.topics';
import { SERVER_API_URL } from 'app/app.constants';

import { PluginPanelService } from '../plugin-panel.service';
import { DocumentaryUnitService } from 'app/core/service/documentary-unit.service';
import { ContextService } from 'app/core/service/context.service';
import { PluginComponent } from '../plugin.component';
import { IDocumentaryUnitContent } from 'app/shared/model/documentary-unit-content.model';

import { FileService } from 'app/core/service/file.service';

import { LoaderService } from 'app/core/service/loader.service';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { ContentType } from 'app/shared/enum/content-type.enum';
import { StyleService } from '../../../core/service/style.service';

@Component({
  selector: 'jhi-plugin-import-graphical-pages',
  templateUrl: './graphical-pages.component.html',
  styleUrls: ['./graphical-pages.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GraphicalPagesComponent extends PluginComponent implements OnInit {
  public fileToUpload: File | null;
  public nbPages: number;
  public nbExistingPages: number;
  public loading = true;
  public hasError = false;
  public isCovers = false;
  public replaceContent = true;
  public isGraphicalPage = false;
  public documentaryUnitContent: IDocumentaryUnitContent;
  public resourceUrl: string;

  constructor(
    public pluginPanelService: PluginPanelService,
    public fileService: FileService,
    public documentaryUnitService: DocumentaryUnitService,
    private changeDetectorRef: ChangeDetectorRef,
    private contextService: ContextService,
    private loadingService: LoaderService,
    private styleService: StyleService
  ) {
    super(pluginPanelService);
    this.resourceUrl = `${SERVER_API_URL}api/documents/${this.contextService.currentDocumentContext.id}/documentary-units`;
  }

  public ngOnInit(): void {
    this.fileToUpload = this.data.fileToUpload;
    const currentDU = this.documentaryUnitService.currentSelectedDocumentaryUnit;
    const type = currentDU?.type;

    // Can't import graphical pages on covers
    if (type && [ContentType.COVER_I_II, ContentType.COVER_III_IV].includes(type)) {
      this.isCovers = true;
      this.loading = false;
    } else if (this.fileToUpload) {
      this.isGraphicalPage = !!currentDU?.taggedAsGraphicalPage;
      this.fileService
        .numberOfPagesPDF(this.fileToUpload)
        .pipe(
          catchError((error: any) => {
            this.loading = false;
            this.hasError = true;
            this.changeDetectorRef.markForCheck();
            return throwError(error);
          })
        )
        .subscribe((response: { nbPages: number; existingGraphicalPagesNbPages: number }) => {
          this.loading = false;
          this.nbPages = response.nbPages;
          this.nbExistingPages = response.existingGraphicalPagesNbPages;
          this.styleService.cleanPreviewCache();
          this.changeDetectorRef.markForCheck();
        });

      if (currentDU) {
        pubsub.once(
          INSERT_BREAK_PAGES,
          () => {
            currentDU.taggedAsGraphicalPage = true;
            this.loadingService.hide();
            this.closePanel();
          },
          this.editor.config.get('editorId')
        );
      }
    }
  }

  public onClickImportBtn(): void {
    this.loadingService.show();
    if (!this.fileToUpload) {
      return;
    }

    this.fileService
      .importGraphicalPages(this.fileToUpload, this.replaceContent)
      .pipe(
        catchError((error: any) => {
          this.hasError = true;
          this.changeDetectorRef.markForCheck();
          this.loadingService.hide();
          return throwError(error);
        })
      )
      .subscribe(() => {
        let numberOfBreakPage = 0;
        if (this.replaceContent) {
          numberOfBreakPage = +this.nbPages - 1;
        } else {
          numberOfBreakPage = +this.nbPages - 1 + this.nbExistingPages;
        }

        pubsub.fire(
          INSERT_BREAK_PAGES,
          {
            numberOfBreakPage,
          },
          this.getEditorTopicContext()
        );
      });
  }

  public onClickNewImportFileBtn(): void {
    pubsub.fire(EDITOR_PLUGIN_CLICK_IMPORT_BTN, {}, this.getEditorTopicContext());

    this.onClickClosePanel();
  }

  public setReplaceContent(replaceContent: boolean): void {
    this.replaceContent = replaceContent;
  }
}
