import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'hslaFormat',
  pure: true,
})
export class HslaDisplayPipe implements PipeTransform {
  constructor(private translate: TranslateService) {}

  transform(str: string): any {
    const hslaArr = str.split(',');
    return `
     ${this.translate.instant('styleEditor.color.placeholder.hslaCodeH')}${hslaArr[0]}
     ${this.translate.instant('styleEditor.color.placeholder.hslaCodeS')}${hslaArr[1]}%
     ${this.translate.instant('styleEditor.color.placeholder.hslaCodeL')}${hslaArr[2]}%
     ${this.translate.instant('styleEditor.color.placeholder.hslaCodeA')}${hslaArr[3]}`;
  }
}
