<h1 mat-dialog-title class="error-title">
  <span jhiTranslate="dialog.confirmation.{{ key }}.title"></span>
  <button type="button" mat-icon-button class="mat-dialog-close-btn" (click)="onClose(false)">
    <fa-icon icon="times"></fa-icon>
  </button>
</h1>

<div mat-dialog-content>
  <span class="error-message" jhiTranslate="dialog.confirmation.{{ key }}.message" [translateValues]="{ name: name }"></span>
</div>

<div mat-dialog-actions>
  <button mat-raised-button color="primary" (click)="onClose(false)">
    <span jhiTranslate="dialog.btn-cancel"></span>
  </button>
  <button mat-raised-button color="warn" (click)="onClose(true)">
    <span [jhiTranslate]="deleteMessage"></span>
  </button>
</div>
