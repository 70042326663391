import { Component, Input } from '@angular/core';

@Component({
  selector: 'jhi-node-expand',
  templateUrl: './node-expand.component.html',
  styleUrls: ['./node-expand.component.scss'],
})
export class NodeExpandComponent {
  @Input() public label: string;
  @Input() public expanded = false;
}
