import { NestedTreeControl } from '@angular/cdk/tree';
import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { SearchType } from 'app/shared/enum/search-type.enum';

import { IConcept, IEntryPointTable } from 'app/shared/model/concept.model';
import { ITaxonomyConceptSelected } from 'app/shared/model/taxonomy.model';
import { filterConcept } from 'app/shared/util/xbrl-utils';
import { JhiLanguageService } from 'app/shared/jhipster/service/language.service';

@Component({
  selector: 'jhi-taxonomy-browser-table',
  templateUrl: './taxonomy-browser-table.component.html',
  styleUrls: ['./taxonomy-browser-table.component.scss'],
})
export class TaxonomyTableComponent implements OnInit {
  @Input()
  get search(): string {
    return this._search;
  }

  set search(value: string) {
    this._search = value;
    this.filter(this._search);
  }

  @Input() searchType: SearchType = SearchType.QNAME;
  @Input() highlight = true;
  @Input() conceptIdSelected: number;

  @Input()
  get table(): IEntryPointTable {
    return this._table;
  }

  set table(table: IEntryPointTable) {
    this._table = table;
    this.dataSource.data = table.concepts;
  }

  @Output() public selected = new EventEmitter<ITaxonomyConceptSelected>();

  public dataSource: MatTreeNestedDataSource<IConcept>;
  public treeControl: NestedTreeControl<IConcept>;
  public expanded = false;

  private _table: IEntryPointTable;
  private _search: string;

  constructor(private languageService: JhiLanguageService) {
    this.dataSource = new MatTreeNestedDataSource<IConcept>();
    this.treeControl = new NestedTreeControl<IConcept>((node: IConcept) => node.children);
  }

  public hasChild = (_: number, node: IConcept): boolean => !!node.children && node.children.length > 0;

  public selection(concept: IConcept, table: IEntryPointTable): void {
    if (concept) {
      const entryPointTable: IEntryPointTable = {
        id: table.id,
        labels: table.labels,
        orderNumber: table.orderNumber,
      } as IEntryPointTable;
      this.selected.emit({ entryPointTable, concept });
    }
  }

  private filter(filterText: string): void {
    if (this.table?.concepts) {
      if (filterText) {
        const data: IConcept[] = [];
        this._table.concepts.forEach((concept: IConcept) => {
          const filtered = filterConcept(concept, this.languageService.getCurrentLanguage(), filterText, this.searchType, this.highlight);
          if (filtered) {
            data.push(filtered);
          }
        });
        this.dataSource.data = data;
        this.treeControl.dataNodes = this.dataSource.data;
        this.treeControl.expandAll();
        this.expanded = true;
      } else {
        // Reset tree data
        this.dataSource.data = this.table.concepts;
        this.treeControl.dataNodes = this.dataSource.data;
        this.treeControl.collapseAll();
        this.expanded = false;
      }
    }
  }

  ngOnInit(): void {
    if (this.search) {
      this.filter(this.search);
    }
  }
}
