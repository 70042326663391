<div class="xbrl-data-viewer">
  <div class="tagging-header">
    <ng-container>
      <div class="tagging-actions">
        <div class="button-bar">
          <button mat-icon-button (click)="selectDirection(direction.DESC)">
            <mat-icon svgIcon="arrow-left"></mat-icon>
          </button>
          <mat-select required *ngIf="showFilter" [(ngModel)]="navigationFilter">
            <mat-option [value]="filter.ALL">
              {{ 'dataEditor.navigation.filter.all' | translate }}
            </mat-option>
            <mat-option [value]="filter.XBRL">
              {{ 'dataEditor.navigation.filter.xbrl' | translate }}
            </mat-option>
          </mat-select>
          <button mat-icon-button (click)="selectDirection(direction.ASC)">
            <mat-icon svgIcon="arrow-right"></mat-icon>
          </button>
        </div>
      </div>
      <div class="tagging-title">{{ 'entity.data.xbrl.detail.title' | translate }}</div>
      <div class="tagging-type" *ngIf="!showNavigationMultiFact">
        <mat-icon svgIcon="tag-indigo" color="primary"></mat-icon>
        <span jhiTranslate="entity.data.xbrl.detail.tagTitle"></span>
      </div>
      <div class="tagging-multi-index" *ngIf="showNavigationMultiFact">
        <div class="button-bar">
          <button mat-icon-button (click)="selectPrevFact()">
            <mat-icon svgIcon="arrow-left"></mat-icon>
          </button>
          <div class="tagging-type">
            <mat-icon svgIcon="tag-indigo" color="primary"></mat-icon>
            <span jhiTranslate="entity.data.xbrl.detail.tagTitle"></span>
            <span> ({{ currentIndex + 1 }}/{{ factIdList.length }})</span>
          </div>
          <button mat-icon-button (click)="selectNextFact()">
            <mat-icon svgIcon="arrow-right"></mat-icon>
          </button>
        </div>
      </div>
      <div
        class="tagging-selection"
        jhiTranslate="dataEditor.tagging.selection"
        *ngIf="fact?.selection?.single"
        [translateValues]="{ cell: fact?.selection?.single || '' }"
      ></div>
    </ng-container>
  </div>
  <div class="tagging-content">
    <div class="concept-desc missing" *ngIf="factValueMultiPart.length && fact?.factIndex >= factValueMultiPart.length">
      <label jhiTranslate="entity.data.xbrl.detail.concept-index-unknown"></label>
    </div>
    <div class="concept-desc" *ngIf="fact?.concept">
      <label jhiTranslate="entity.data.xbrl.detail.concept"></label>
      <div class="property">
        <mat-icon class="chevron" svgIcon="tag-large-dot" color="primary"></mat-icon>
        <span>{{ fact.concept?.qname }}</span>
      </div>
      <div class="property property-label">
        <mat-icon class="chevron"></mat-icon>
        <span>{{ fact.concept | conceptTranslatedLabel }}</span>
      </div>
    </div>
    <div class="concept-desc missing" *ngIf="fact?.conceptName">
      <label jhiTranslate="entity.data.xbrl.detail.concept-unknown"></label>
      <div class="property">
        <mat-icon class="chevron" svgIcon="tag-large-dot" color="primary"></mat-icon>
        <span>{{ fact.conceptName }}</span>
      </div>
    </div>

    <div class="dimension-desc" *ngIf="fact?.context?.dimensions">
      <label jhiTranslate="entity.data.xbrl.detail.dimensions"></label>
      <jhi-xbrl-data-viewer-dimension *ngFor="let dimension of fact.context?.dimensions" [dimension]="dimension">
      </jhi-xbrl-data-viewer-dimension>
    </div>

    <div class="properties">
      <div class="property" *ngIf="fact?.context?.period">
        <label jhiTranslate="entity.data.xbrl.detail.date"></label>
        <div class="value">
          {{ fact.context?.period.startDate | localizedDate: 'shortDate' }}
          <ng-container *ngIf="fact.context?.period.periodType === periodType.PERIOD"
            >- {{ fact.context?.period.endDate | localizedDate: 'shortDate' }}</ng-container
          >
        </div>
      </div>
      <div class="property" *ngIf="!fact?.context && fact?.startDate">
        <label jhiTranslate="entity.data.xbrl.detail.date"></label>
        <div class="value">
          {{ fact.startDate | localizedDate: 'shortDate' }}
          <ng-container *ngIf="fact?.endDate">- {{ fact.endDate | localizedDate: 'shortDate' }}</ng-container>
        </div>
      </div>
      <div class="property" *ngIf="fact?.factValue">
        <label jhiTranslate="entity.data.xbrl.detail.factValue"></label>
        <div class="value" *ngIf="fact?.concept && !factValueMultiPart.length">
          {{ fact.factValue | xbrlFactValue: fact.concept?.type }}
        </div>
        <div class="value" *ngIf="fact?.conceptName">{{ fact.factValue }}</div>

        <div class="value multi-part-value" *ngIf="fact?.concept && factValueMultiPart.length">
          <ng-container *ngFor="let value of factValueMultiPart; let index = index">
            <span [class]="{ selected: index === fact.factIndex }">{{ value }}</span>
          </ng-container>
        </div>
      </div>
      <div class="property" *ngIf="fact?.concept?.balance">
        <label jhiTranslate="entity.data.xbrl.detail.conceptBalance"></label>
        <div class="value">{{ fact.concept?.balance }}</div>
      </div>
      <div class="property" *ngIf="fact?.language">
        <label jhiTranslate="entity.data.language"></label>
        <div class="value">{{ fact.language }}</div>
      </div>
      <div class="property" *ngIf="hasOimUnit(fact)">
        <label jhiTranslate="entity.data.unit"></label>
        <div class="value">{{ fact.oimUnit }}</div>
      </div>
      <div class="property" *ngIf="formatAttributes?.scale != null && isNumber(fact)">
        <label jhiTranslate="htmlEditor.plugins.dynamicData.formating.displayScale"></label>
        <div class="value">{{ formatAttributes.scale }}</div>
      </div>
      <div class="property" *ngIf="formatAttributes?.balance && isNumber(fact)">
        <label jhiTranslate="htmlEditor.plugins.dynamicData.formating.balance"></label>
        <div class="value">{{ formatAttributes.balance }}</div>
      </div>

      <div class="duplicateValues" *ngIf="fact?.duplicateInfo">
        <div class="label" jhiTranslate="dataEditor.xbrl.duplicate.title"></div>

        <ng-container *ngIf="eolData; else eolPage">
          <div jhiTranslate="dataEditor.xbrl.duplicate.message"></div>
          <hr />
          <div class="label" jhiTranslate="dataEditor.xbrl.duplicate.selectValue"></div>
          <mat-radio-group aria-labelledby="duplicate-value" name="duplicateValue">
            <mat-radio-button
              *ngFor="let dupValue of fact?.duplicateInfo?.dupValues"
              color="primary"
              [value]="dupValue.value"
              (change)="setSelectedValue(dupValue.value)"
            >
              {{ dupValue.value | xbrlFactValue: fact.concept?.type }}
            </mat-radio-button>
          </mat-radio-group>
          <hr />
          <button
            mat-raised-button
            [disabled]="!selectedValue"
            (click)="emitValueSelection()"
            jhiTranslate="dataEditor.xbrl.duplicate.selectButton"
          ></button>
        </ng-container>

        <ng-template #eolPage>
          <div jhiTranslate="dataEditor.xbrl.duplicate.messageData"></div>
          <hr />
          <ul>
            <li *ngFor="let dupValue of fact?.duplicateInfo?.dupValues">{{ dupValue.value | xbrlFactValue: fact.concept?.type }}</li>
          </ul>
        </ng-template>
      </div>
    </div>
  </div>
  <div class="action-buttons" *ngIf="isEditable">
    <button mat-raised-button color="primary" [disabled]="!!fact?.duplicateInfo" (click)="goToEditFormat.emit()">
      <span jhiTranslate="htmlEditor.plugins.dynamicData.viewer.edit"></span>
    </button>
  </div>
  <div class="action-buttons" *ngIf="eolDataXbrlView">
    <button mat-raised-button color="primary" [disabled]="isEditorialFact || isTextBlock" (click)="deleteFact()">
      <span jhiTranslate="htmlEditor.plugins.dynamicData.viewer.delete"></span>
    </button>
  </div>
  <div jhiTranslate="htmlEditor.plugins.dynamicData.viewer.deleteEditorialInfo" class="delete-info" *ngIf="isEditorialFact"></div>
  <div jhiTranslate="htmlEditor.plugins.dynamicData.viewer.deleteTextBlockInfo" class="delete-info" *ngIf="isTextBlock"></div>
  <div class="action-buttons" *ngIf="isMultipleMode">
    <div *ngIf="factIdList.length >= maxFactID">
      <span jhiTranslate="htmlEditor.plugins.dynamicData.viewer.warning.max"></span>
    </div>
    <button mat-raised-button color="accent" [disabled]="factIdList.length <= 1" (click)="detach()">
      <span jhiTranslate="htmlEditor.plugins.dynamicData.viewer.detach"></span>
    </button>
    <button mat-raised-button color="primary" [disabled]="factIdList.length >= maxFactID" (click)="goToAttachXbrlFact.emit()">
      <span jhiTranslate="htmlEditor.plugins.dynamicData.viewer.attach"></span>
    </button>
  </div>

  <div class="annotations" *ngIf="hasAnnotationsList">
    <div class="annotation-delete" *ngIf="displayDeleteConfirmation">
      <jhi-inline-dialog>
        <jhi-panel-delete-confirmation
          warningMessage="htmlEditor.plugins.annotate.delete.confirmDialog.deleteLinkContent"
          (confirm)="onRemoveConfirmation()"
          (cancel)="onRemoveCancel()"
        ></jhi-panel-delete-confirmation>
      </jhi-inline-dialog>
    </div>
    <div *ngIf="!displayDeleteConfirmation">
      <div class="annotation" *ngFor="let item of annotations">
        <mat-expansion-panel hideToggle expanded>
          <mat-expansion-panel-header class="annotation-content">
            <div class="left">
              <span jhiTranslate="dataEditor.annotation.annotation" class="title"></span>
              <div class="annotation-data">
                <span class="annotation-data-lang">{{ item.lang }}</span>
                <span class="annotation-id">#{{ item.id }}</span>
              </div>
            </div>
            <button mat-icon-button [matMenuTriggerFor]="menu" (click)="$event.stopPropagation()">
              <mat-icon svgIcon="icon-menu-dots"></mat-icon>
            </button>
          </mat-expansion-panel-header>
          <div *ngIf="item.htmlContent" class="annotation-html">{{ item.htmlContent }}</div>
        </mat-expansion-panel>
        <mat-menu #menu="matMenu" yPosition="above" class="xbrl-data-viewer__annotation-overlay-menu">
          <button mat-menu-item class="overlay-button" (click)="displayAnnotationInSection(item.id)">
            <span jhiTranslate="dataEditor.annotation.scrollToAnnotation"></span>
          </button>
          <button mat-menu-item class="overlay-button" (click)="deleteLinkBetweenFactAndFootnote(item.id)">
            <span jhiTranslate="dataEditor.annotation.removeLinkWithFact"></span>
          </button>
          <div mat-menu-item class="overlay-cancel">
            <span jhiTranslate="adminEditor.project-configuration.cancelBtn"></span>
          </div>
        </mat-menu>
      </div>
    </div>
  </div>
</div>
