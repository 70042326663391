import { ChangeDetectionStrategy, Component, forwardRef, ChangeDetectorRef, OnDestroy, Input } from '@angular/core';
import { AbstractControl, ControlValueAccessor, UntypedFormBuilder, UntypedFormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { IColWidthFormValue } from 'app/shared/model/table-model.model';
import { Subscription } from 'rxjs';

@Component({
  selector: 'jhi-table-model-column-width',
  templateUrl: './table-model-column-width.component.html',
  styleUrls: ['./table-model-column-width.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TableModelColumnWidthComponent),
      multi: true,
    },
  ],
})
export class TableModelColumnWidthComponent implements OnDestroy, ControlValueAccessor {
  @Input() public isSplitColumn: boolean;

  public columnsWidthForm = this.fb.group({
    colName: new UntypedFormControl(null, []),
    width: new UntypedFormControl(null, []),
    isRelative: new UntypedFormControl(true, []),
  });

  get colNameValue(): AbstractControl {
    return this.columnsWidthForm.get('colName')?.value;
  }

  get widthFormControl(): AbstractControl {
    return this.columnsWidthForm.get('width') as AbstractControl<any, any>;
  }

  get isRelativeFormControl(): AbstractControl {
    return this.columnsWidthForm.get('isRelative') as AbstractControl<any, any>;
  }

  private readonly subscriptions: Subscription = new Subscription();

  constructor(private fb: UntypedFormBuilder, private changeDetectorRef: ChangeDetectorRef) {}

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public onTouched: () => void = () => {};
  public onChanged: () => void = () => {};

  writeValue(val: IColWidthFormValue): void {
    if (val) {
      this.columnsWidthForm.setValue(
        {
          colName: val.colName ?? this.colNameValue,
          width: val.width ?? this.widthFormControl.value,
          isRelative: val.isRelative ?? this.isRelativeFormControl.value,
        },
        { emitEvent: false }
      );
      this.changeDetectorRef.markForCheck();
    }
  }
  registerOnChange(fn: (value: IColWidthFormValue) => void): void {
    this.subscriptions.add(this.columnsWidthForm.valueChanges.subscribe(fn));
  }
  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    isDisabled ? this.columnsWidthForm.disable() : this.columnsWidthForm.enable();
  }
}
