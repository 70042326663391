<div class="margin-input">
  <div class="margin-input-top">
    <mat-label>{{ label }}</mat-label>

    <button mat-icon-button (click)="reset()">
      <mat-icon title="{{ 'styleEditor.editor.properties.reset' | translate }}" svgIcon="reset"></mat-icon>
    </button>
  </div>
  <div class="margin-input-form">
    <div class="margin-input-row">
      <mat-form-field floatLabel="never">
        <jhi-input-number
          autocomplete="off"
          [decimal]="1"
          [(ngModel)]="margin.top"
          [placeholder]="margin.topPlaceHolder"
          [revertNullToZero]="revertNullToZero"
          [disabled]="marginDisabled.top"
          (valueChange)="onUpdateMargin($event.value, 'top')"
        ></jhi-input-number>
      </mat-form-field>
      <span class="margin-input-caption" jhiTranslate="styleEditor.editor.marginInput.top"></span>
    </div>
    <div class="margin-input-row">
      <mat-form-field floatLabel="never">
        <jhi-input-number
          autocomplete="off"
          [decimal]="1"
          [(ngModel)]="margin.right"
          [placeholder]="margin.rightPlaceHolder"
          [revertNullToZero]="revertNullToZero"
          [disabled]="marginDisabled.right"
          (valueChange)="onUpdateMargin($event.value, 'right')"
        ></jhi-input-number>
      </mat-form-field>
      <span class="margin-input-caption" jhiTranslate="styleEditor.editor.marginInput.right"></span>
    </div>
    <div class="margin-input-row">
      <mat-form-field floatLabel="never">
        <jhi-input-number
          autocomplete="off"
          [decimal]="1"
          [(ngModel)]="margin.left"
          [placeholder]="margin.leftPlaceHolder"
          [revertNullToZero]="revertNullToZero"
          [disabled]="marginDisabled.left"
          (valueChange)="onUpdateMargin($event.value, 'left')"
        ></jhi-input-number>
      </mat-form-field>
      <span class="margin-input-caption" jhiTranslate="styleEditor.editor.marginInput.left"></span>
    </div>
    <div class="margin-input-row">
      <mat-form-field floatLabel="never">
        <jhi-input-number
          autocomplete="off"
          [decimal]="1"
          [(ngModel)]="margin.bottom"
          [placeholder]="margin.bottomPlaceHolder"
          [revertNullToZero]="revertNullToZero"
          [disabled]="marginDisabled.bottom"
          (valueChange)="onUpdateMargin($event.value, 'bottom')"
        ></jhi-input-number>
      </mat-form-field>
      <span class="margin-input-caption" jhiTranslate="styleEditor.editor.marginInput.bottom"></span>
    </div>
  </div>
</div>
