import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { LogEntryFactValidationDTO } from 'app/shared/model/fact.model';

@Component({
  selector: 'jhi-dialog-error-array',
  templateUrl: 'dialog-error-array.component.html',
  styleUrls: ['dialog-error-array.component.scss'],
})
export class DialogErrorArrayComponent {
  public errors: LogEntryFactValidationDTO[];
  public errorColumns: string[] = ['message', 'footnote', 'fact'];

  constructor(
    public dialogRef: MatDialogRef<DialogErrorArrayComponent>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: { factVerif: LogEntryFactValidationDTO[] }
  ) {
    this.errors = data.factVerif;
  }

  onClose(): void {
    this.dialogRef.close();
  }
}
