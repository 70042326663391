<div class="plugin-container table-panel-plugin-container">
  <mat-form-field class="table-model-input">
    <mat-label jhiTranslate="htmlEditor.plugins.tableEdition.tableModel"></mat-label>
    <mat-select (selectionChange)="changeModel($event)" [value]="actualModel?.id">
      <mat-option *ngFor="let model of tableModels" [value]="model.id">{{ model.modelName }}</mat-option>
    </mat-select>
  </mat-form-field>
  <hr />
  <div>
    <div
      class="containerTitle"
      jhiTranslate="htmlEditor.plugins.tableEdition.columnsLabelsTitle"
      (click)="toggle(columnsLabelsFoldingPart)"
    ></div>
    <div #columnsLabelsFoldingPart>
      <div *ngFor="let column of columnsLabels; let i = index; trackBy: trackByIndex">
        <mat-form-field>
          <mat-label jhiTranslate="htmlEditor.plugins.tableEdition.columnLabel" [translateValues]="{ index: encodeColName(i) }"></mat-label>
          <mat-select (selectionChange)="changeColumnLabel($event.value, i)" [value]="column">
            <mat-option *ngFor="let label of columnsLabelsList" [value]="label.id">{{ label.name }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </div>
  <hr />
  <div>
    <div
      class="containerTitle"
      jhiTranslate="htmlEditor.plugins.tableEdition.rowsLabelsTitle"
      (click)="toggle(rowsLabelsFoldingPart)"
    ></div>
    <div #rowsLabelsFoldingPart>
      <div *ngFor="let row of rowsLabels; let i = index; trackBy: trackByIndex">
        <mat-form-field>
          <mat-label jhiTranslate="htmlEditor.plugins.tableEdition.rowLabel" [translateValues]="{ index: i + 1 }"></mat-label>
          <mat-select (selectionChange)="changeRowLabel($event.value, i)" [value]="row">
            <mat-option *ngFor="let label of rowsLabelsList" [value]="label.id">{{ label.name }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </div>
  <hr />
  <div class="columnWidthContainer">
    <div class="containerTitle" jhiTranslate="htmlEditor.plugins.tableEdition.columnsWidthTitle" (click)="toggle(columnFoldingPart)"></div>
    <div #columnFoldingPart>
      <div *ngFor="let colWidth of columnsWidth; let i = index; trackBy: trackByIndex">
        <jhi-table-model-column-width
          [ngModel]="colWidth"
          [isSplitColumn]="i === dblPageSplitColumn"
          (ngModelChange)="columnWidthChange($event, i)"
        ></jhi-table-model-column-width>
      </div>
      <div class="restoreLink" jhiTranslate="htmlEditor.plugins.tableEdition.columnsWidthReset" (click)="restoreModelColumnsWidth()"></div>
    </div>
  </div>
</div>
