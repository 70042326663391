import { Component, OnInit, ViewEncapsulation, OnDestroy, HostListener } from '@angular/core';
import pubsub from 'app/pubsub';
import {
  EDITOR_FINDREPLACE_FIND,
  EDITOR_FINDREPLACE_NEXT,
  EDITOR_FINDREPLACE_PREV,
  EDITOR_FINDREPLACE_REPLACE,
  EDITOR_FINDREPLACE_REPLACE_ALL,
  EDITOR_FINDREPLACE_RESET,
  EDITOR_FINDREPLACE_RESPONSE,
} from 'app/pubsub.topics';
import { FindReplaceConstants } from 'editor/model/find-replace';
import { PluginPanelService } from '../plugin-panel.service';
import { PluginComponent } from '../plugin.component';

export class FindReplaceResponse {
  public total?: number;
  public index?: number;
  public removed?: number;
}
export type FindReplaceResponseEvent = CustomEvent<FindReplaceResponse>;

@Component({
  selector: 'jhi-plugin-find-replace',
  templateUrl: './find-replace.component.html',
  styleUrls: ['./find-replace.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class FindReplaceComponent extends PluginComponent implements OnInit, OnDestroy {
  public FindReplaceConstants = FindReplaceConstants;
  public textToFind = '';
  public textToReplace = '';
  public isCaseSensitive = false;
  public wholeWordsOnly = false;
  public activeButton = '';
  public selectedTab = FindReplaceConstants.FIND;
  public total = -1;
  public index = -1;
  public removed = 0;

  private _responseSubscription: Function;

  private clickInside = false;

  /**
   * function to get all the clicks inside the panel
   *
   * for an inside 'click', we need to click inside the content of the panel, not the full panel container
   * we had a 500px min height for the find-replace-container class to expend the content
   */
  @HostListener('click')
  clickIn() {
    this.clickInside = true;
  }

  // Function to get all the clicks in the document
  @HostListener('document:click')
  generalClick() {
    // If the click is not inside the panel ...
    if (!this.clickInside) {
      this.searchChange();
    }
    // Reset the variable
    this.clickInside = false;
  }

  constructor(public pluginPanelService: PluginPanelService) {
    super(pluginPanelService);
  }

  public ngOnInit(): void {
    this._responseSubscription = this._response.bind(this);
    pubsub.on(EDITOR_FINDREPLACE_RESPONSE, this._responseSubscription);
  }

  public ngOnDestroy(): void {
    pubsub.off(EDITOR_FINDREPLACE_RESPONSE, this._responseSubscription);
  }

  public find(): void {
    this._fireSearchEvent(EDITOR_FINDREPLACE_FIND);
  }

  public next(): void {
    this._fireSearchEvent(EDITOR_FINDREPLACE_NEXT);
  }

  public prev(): void {
    this._fireSearchEvent(EDITOR_FINDREPLACE_PREV);
  }

  public replace(): void {
    this._fireSearchEvent(EDITOR_FINDREPLACE_REPLACE);
  }

  public replaceAll(): void {
    this._fireSearchEvent(EDITOR_FINDREPLACE_REPLACE_ALL);
  }

  private _response({ detail }: FindReplaceResponseEvent): void {
    this.total = detail.total ?? -1;
    this.index = detail.index ?? -1;
    this.removed = detail.removed ?? 0;
  }

  /**
   * Method called when the Find and Find&Replace Tabs are clicked
   * @param selectedTab
   */
  public onTabClick(selectedTab: string): void {
    this.selectedTab = selectedTab;
  }

  /**
   * Close the panel
   */
  public onClickClosePanel(): void {
    this.closePanel();
  }

  /**
   * reset search
   */
  public searchChange() {
    if (this.total > -1) {
      this._fireSearchEvent(EDITOR_FINDREPLACE_RESET);

      this.total = -1;
      this.index = -1;
      this.removed = 0;
    }
  }

  private _getFindAndReplaceParams() {
    return {
      textToFind: this.textToFind,
      textToReplace: this.textToReplace,
      isCaseSensitive: this.isCaseSensitive,
      wholeWordsOnly: this.wholeWordsOnly,
    };
  }

  private _fireSearchEvent(searchConst: string) {
    pubsub.fire(searchConst, this._getFindAndReplaceParams(), this.getEditorTopicContext());
  }
}
