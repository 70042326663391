import { Component, OnInit, OnDestroy, Output, EventEmitter, ChangeDetectionStrategy, ChangeDetectorRef, Input } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, UrlSegment } from '@angular/router';
import { Subscription } from 'rxjs';
import { getMenuItems, ISubMenu, IMenu } from 'app/shared/enum/vertical-menu.enum';

@Component({
  selector: 'jhi-vertical-menu',
  templateUrl: './vertical-menu.component.html',
  styleUrls: ['./vertical-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VerticalMenuComponent implements OnInit, OnDestroy {
  @Input() public type: string;
  @Input() public appVersion: string;
  @Output() public itemToDislay = new EventEmitter<{ item: string; isChild: ISubMenu | null }>();
  @Output() public closed = new EventEmitter<void>();
  public itemSelected: string;
  public menuItems: IMenu[] = [];
  private subscription = new Subscription();

  constructor(private route: ActivatedRoute, private router: Router, private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.menuItems = getMenuItems(this.type);
    const lastChildRoute = this.getLastChildRoute(this.route?.firstChild);
    this.subscription.add(
      lastChildRoute?.url.subscribe((urlSegments: UrlSegment[]) => {
        if (urlSegments?.length) {
          this.itemSelected = urlSegments[urlSegments.length - 1].path;
          this.cdr.markForCheck();
        }
      })
    );

    this.subscription.add(
      this.router.events.subscribe(routerEvent => {
        if (routerEvent instanceof NavigationEnd) {
          this.itemSelected = routerEvent.url.split('/').pop() ?? '';
          this.cdr.markForCheck();
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public clickHandler(parent: IMenu, child: ISubMenu | null, parentDiv: HTMLElement): void {
    if (!child && parent.toggleChildren) {
      parentDiv.querySelector(`#menuSubMenuDiv-${parent.name}`)?.classList.toggle('hidden');
    } else {
      const name = parent.name;
      this.itemToDislay.emit({ item: name, isChild: child });
    }
  }

  public close(): void {
    this.closed.emit();
  }

  private getLastChildRoute(currentRouteChild: ActivatedRoute | null): ActivatedRoute | null {
    let result: ActivatedRoute | null = null;
    if (currentRouteChild?.children.length) {
      result = this.getLastChildRoute(currentRouteChild.firstChild);
    } else {
      result = currentRouteChild;
    }
    return result;
  }
}
