import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Direction } from 'app/shared/enum/direction.enum';
import { IDynamicData } from 'app/shared/model/rcsf.model';
import { DynamicDataFilter } from 'app/shared/enum/dynamic-data.enum';
import { SpreadsheetUtils } from 'app/shared/util/spreadsheet-utils';
import { IFormatAttributes } from 'app/shared/model/formatted.model';

@Component({
  selector: 'jhi-dynamic-data-viewer',
  templateUrl: './dynamic-data-viewer.component.html',
  styleUrls: ['../xbrl-data-viewer/xbrl-data-viewer.component.scss', './dynamic-data-viewer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DynamicDataViewerComponent {
  @Input() public showFilter = false;
  @Input() public data: IDynamicData;
  @Input() public formatAttributes: IFormatAttributes;
  @Output() public closed = new EventEmitter<void>();
  @Output() public selectDataDirection = new EventEmitter<Direction | { dir: Direction; filter: DynamicDataFilter }>();
  public readonly direction = Direction;
  public readonly filter = DynamicDataFilter;
  public navigationFilter = DynamicDataFilter.ALL;

  public get xlsxCell(): string {
    if (this.data.cell) {
      return SpreadsheetUtils.encodeNumberToCellRange({ col: this.data.cell.colIdx, row: this.data.row });
    }
    return '';
  }

  public selectDirection(dir: Direction): void {
    if (this.showFilter) {
      this.selectDataDirection.emit({ dir, filter: this.navigationFilter });
    } else {
      this.selectDataDirection.emit(dir);
    }
  }
}
