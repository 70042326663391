import { StyleProperty } from 'app/shared/enum/style-property.enum';
import { MarkerType } from '../enum/marker-type.enum';
import { IStyle, IStyleProperty } from '../model/style.model';

/**
 * Allow to generate letters string from number
 * e.g. 1 => a, 26 => z, 27 => aa etc ...
 * @param number
 */
const numberToLetters = (number: number): string =>
  (number - 1 >= 26 ? numberToLetters(Math.floor((number - 1) / 26)) : '') + 'abcdefghijklmnopqrstuvwxyz'[(number - 1) % 26];

/**
 * Convert the an index to string according to MarkerType parameter
 * markerType = DECIMAL: 1 => 1, etc
 * markerType = ALPHA: 1 => a, 26 => z, 27 => aa etc ...
 *
 */
export const convertIndexToMarker = (index: number, markerType: MarkerType = MarkerType.DECIMAL): string => {
  let markerContent: string;

  if (markerType === MarkerType.LOWER_ALPHA) {
    markerContent = numberToLetters(index);
  } else {
    markerContent = `${index}`;
  }

  return markerContent;
};

export const generateMarkerLabel = (markerStyle: IStyle, index = 1): string => {
  const markerProps = new Map();

  if (markerStyle?.properties) {
    markerStyle.properties
      .filter(prop => prop.name.indexOf(StyleProperty.MARKER) > -1)
      .forEach((prop: IStyleProperty) => {
        markerProps.set(prop.name, prop);
      });
  }

  let label = '';
  if (markerProps.size > 0) {
    label += markerProps.get(StyleProperty.MARKER_PREFIX)?.data || '';

    label += convertIndexToMarker(index, markerProps.get(StyleProperty.MARKER_TYPE)?.data);

    label += markerProps.get(StyleProperty.MARKER_SUFFIX)?.data || '';
  } else {
    label = `${index})`;
  }

  return label;
};
