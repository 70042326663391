import { Input, Component } from '@angular/core';
import { PluginPanelService } from './plugin-panel.service';

@Component({
  template: '',
})
export abstract class PluginComponent {
  public static className = '';
  @Input()
  public editor: any;

  @Input()
  public title: string;

  @Input()
  public isReadOnlyMode = false;

  @Input()
  public zoom = 100;

  @Input()
  public data: any = null;

  @Input()
  public componentKey: string;

  constructor(public pluginPanelService: PluginPanelService) {}

  public onClickClosePanel(): void {
    this.closePanel();
  }

  public getEditorTopicContext(): string {
    return this.editor?.config.get('editorId') as string;
  }

  protected closePanel(): void {
    this.pluginPanelService.collapsePluginPanel();
  }
}
