import { Component, OnInit, ViewEncapsulation, ViewChild, ElementRef, Renderer2, OnDestroy } from '@angular/core';
import { OverlayContainer } from '@angular/cdk/overlay';
import { TranslateService } from '@ngx-translate/core';

import { PluginComponent } from 'app/html-editor/plugins/plugin.component';
import { PluginPanelService } from 'app/html-editor/plugins/plugin-panel.service';

import { ISpecialChar } from 'app/shared/model/special-char.model';

import pubsub from 'app/pubsub';
import { EDITOR_PLUGIN_INSERTSPECIALCHARACTER } from 'app/pubsub.topics';

import { StyleService } from 'app/core/service/style.service';
import { SpecialCharacters } from '@ckeditor/ckeditor5-special-characters';

@Component({
  selector: 'jhi-plugin-special-characters',
  templateUrl: './special-characters.component.html',
  styleUrls: ['./special-characters.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SpecialCharactersComponent extends PluginComponent implements OnInit, OnDestroy {
  selectedSymbols: any = [];
  symbolTitle: string;
  symbolCategoryList: string[];
  specialCharactersMap = new Map();
  currentSelectedOption: string;
  unicode: string;
  fontFamilies: string[];

  @ViewChild('topCharPanel') topCharPanel: ElementRef;

  constructor(
    pluginPanelService: PluginPanelService,
    private styleService: StyleService,
    private translateService: TranslateService,
    private overlayContainer: OverlayContainer,
    private renderer: Renderer2
  ) {
    super(pluginPanelService);
  }

  ngOnInit(): void {
    this.styleService.getSpecialChar().subscribe((result: Map<string, ISpecialChar[]>) => {
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
      const specialCharPluginData = this.editor.plugins.get('SpecialCharacters') as SpecialCharacters;

      this.specialCharactersMap = result;
      this.symbolCategoryList = Object.keys(this.specialCharactersMap);
      this.fontFamilies = Object.keys(this.specialCharactersMap);

      // Get Ckeditor categories
      Array.from(specialCharPluginData.getGroups().keys()).forEach((entry: any) => {
        this.translateService.get(`htmlEditor.plugins.specialCharacters.${entry}`).subscribe(msg => {
          this.symbolCategoryList.push(msg);

          const charKeys = specialCharPluginData.getCharactersForGroup(entry) ?? new Set();
          const ckChars: any[] = [];

          if (entry === 'Latin') {
            ckChars.push({ title: this.translateService.instant('htmlEditor.plugins.specialCharacters.aGraveAccent'), character: 'À' });
            ckChars.push({
              title: this.translateService.instant('htmlEditor.plugins.specialCharacters.aCircumflexAccent'),
              character: 'Â',
            });
            ckChars.push({ title: this.translateService.instant('htmlEditor.plugins.specialCharacters.aUmlaut'), character: 'Ä' });
            ckChars.push({
              title: this.translateService.instant('htmlEditor.plugins.specialCharacters.iCircumflexAccent'),
              character: 'Î',
            });
            ckChars.push({
              title: this.translateService.instant('htmlEditor.plugins.specialCharacters.oCircumflexAccent'),
              character: 'Ô',
            });
            ckChars.push({ title: this.translateService.instant('htmlEditor.plugins.specialCharacters.uGraveAccent'), character: 'Ù' });
            ckChars.push({
              title: this.translateService.instant('htmlEditor.plugins.specialCharacters.uCircumflexAccent'),
              character: 'Û',
            });
            ckChars.push({ title: this.translateService.instant('htmlEditor.plugins.specialCharacters.eAcuteAccent'), character: 'É' });
            ckChars.push({ title: this.translateService.instant('htmlEditor.plugins.specialCharacters.eGraveAccent'), character: 'È' });
            ckChars.push({
              title: this.translateService.instant('htmlEditor.plugins.specialCharacters.eCircumflexAccent'),
              character: 'Ê',
            });
            ckChars.push({ title: this.translateService.instant('htmlEditor.plugins.specialCharacters.eUmlaut'), character: 'Ë' });
            ckChars.push({ title: this.translateService.instant('htmlEditor.plugins.specialCharacters.cCedilla'), character: 'Ç' });
          }

          for (const key of charKeys) {
            ckChars.push({ character: specialCharPluginData.getCharacter(key), title: key });
          }
          this.specialCharactersMap[msg] = ckChars;
        });
      });

      this.symbolCategoryList.sort((a, b) => {
        return a.toLowerCase() > b.toLowerCase() ? 1 : -1;
      });

      this.currentSelectedOption = this.symbolCategoryList[0];
      this.changeCategory();
    });
    // disable animation on overlayContainer to prevent animation on mat-select panel that is not
    // ok on all browsers because of overriding css we made to force the panel having the same width as
    // the mat-select field
    this.renderer.setProperty(this.overlayContainer.getContainerElement(), '@.disabled', true);
  }

  ngOnDestroy(): void {
    this.renderer.setProperty(this.overlayContainer.getContainerElement(), '@.disabled', false);
  }

  public insertSymbol(symbol: ISpecialChar): void {
    pubsub.fire(
      EDITOR_PLUGIN_INSERTSPECIALCHARACTER,
      {
        symbol: symbol.characterEntity ? symbol.characterEntity : symbol.character,
        fontFamily: this.fontFamilies.includes(this.currentSelectedOption) ? `'${this.currentSelectedOption}'` : null,
      },
      this.getEditorTopicContext()
    );
  }

  changeCategory(): void {
    this.selectedSymbols = this.specialCharactersMap[this.currentSelectedOption];
    this.topCharPanel.nativeElement.scrollTo(0, 0);
  }

  public onMouseover(symbol: ISpecialChar): void {
    this.symbolTitle = symbol.title;
    const hexCode = symbol.character.codePointAt(0)?.toString(16);
    const unicode = `0000${hexCode}`.slice(-4);
    this.unicode = `U+${unicode}`;
  }

  public onMouseleave(): void {
    this.symbolTitle = '';
    this.unicode = '';
  }

  public get currentFontFamily(): string {
    return `font-family: '${this.currentSelectedOption}';`;
  }
}
