import { Injectable } from '@angular/core';
import { GIT_ERROR_ALREADY_RAISED } from 'app/shared/constants/session-storage.constants';
import { SessionStorageService } from 'ngx-webstorage';

@Injectable({ providedIn: 'root' })
export class StateStorageService {
  private previousUrlKey = 'previousUrl';

  constructor(private $sessionStorage: SessionStorageService) {}

  storeUrl(url: string, key = this.previousUrlKey): void {
    this.$sessionStorage.store(key, url);
  }

  getUrl(key = this.previousUrlKey): string | null | undefined {
    return this.$sessionStorage.retrieve(key);
  }

  clearUrl(key = this.previousUrlKey): void {
    this.$sessionStorage.clear(key);
  }

  hasGitError(): boolean {
    return this.$sessionStorage.retrieve(GIT_ERROR_ALREADY_RAISED) ?? false;
  }

  setGitError(): void {
    this.$sessionStorage.store(GIT_ERROR_ALREADY_RAISED, true);
  }
}
