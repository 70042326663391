import { AfterViewInit, ChangeDetectionStrategy, Component, ContentChildren, ElementRef, HostBinding, QueryList } from '@angular/core';
import { ResizableComponent } from './resizable.component';

/**
 * The jhi-resizable-layout component must contain only children that are jhi-resizable components.
 * Each jhi-resizable child component is a resizable panel.
 * In a same layout, you must only have vertical resizable panels or horizontal panels, not horizontal and vertical at the same time.
 * In a same layout, one and only one child must be not directly resizable (no "directions" attriubte). It will occupy the remaining size.
 * TODO: not finished, the only usage fully tested is :
 * <jhi-resizable-layout>
 *   <jhi-resizable directions=['right']>Some content in a left panel. This panel will be resizable by a right handle.</jhi-resizable>
 *   <jhi-resizable>Some content in a right panel. This panel will occupy the remaining size in the layout.</jhi-resizable>
 * </jhi-resizable-layout>
 */
@Component({
  selector: 'jhi-resizable-layout',
  template: '<ng-content></ng-content>',
  styles: [':host {display: flex; flex-wrap: nowrap;}', ':host.vertical {flex-direction: column;}'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResizableLayoutComponent implements AfterViewInit {
  @HostBinding('class.dragging') dragging = false;
  @HostBinding('class.vertical') vertical = false;

  @ContentChildren(ResizableComponent)
  panelList: QueryList<ResizableComponent>;

  size: number;

  private panels: ResizableComponent[];
  private remainingPanel: ResizableComponent;
  private remainingPanelMin: number;

  constructor(private elt: ElementRef) {}

  ngAfterViewInit(): void {
    this.panels = this.panelList.toArray();
    let vertical = false;
    let horizontal = false;
    const remainingPanels: ResizableComponent[] = [];
    this.panels.forEach((p: ResizableComponent) => {
      p.layout = this;
      vertical = vertical || p.directions.includes('top') || p.directions.includes('bottom');
      horizontal = horizontal || p.directions.includes('left') || p.directions.includes('right');
      if (!p.directions.length) {
        remainingPanels.push(p);
      }
    });
    // check that the layout is consistant
    if (vertical && horizontal) {
      throw new Error(
        'A resizable layout cannot contain both vertical (top, right) and horizontal (left, right) resizable children. Use layout inside layout to solve this.'
      );
    }
    if (remainingPanels.length !== 1) {
      throw new Error('A layout must contain one (and only one) child without resizing direction, in order to occupy the remaining room.');
    }
    this.remainingPanel = remainingPanels[0];
    this.refreshValues();
  }

  /**
   * Return the current total size of the layout, ie. width for horizontal resizing layout, height for vertical one.
   */
  liveSize(): number {
    return this.vertical ? this.elt.nativeElement.offsetHeight : this.elt.nativeElement.offsetWidth;
  }

  /**
   * For a panel that is currently resized in the layout and a new size wanted for this panel,
   * this method will return the size that this resized panel can occupy according to the minimal size
   * set for the panel that occupy the remaining room in the layout.
   * Example:
   * <jhi-resizable-layout style="width: 100px">
   *   <jhi-resizable #1 directions=['right']>...</jhi-resizable>
   *   <jhi-resizable #2 minSize="10%">...</jhi-resizable>
   * </jhi-resizable-layout>
   * #1 is resizing. It want to occupy 92px. As #2 has a minSize of 10px (10% of layout width), this method
   * will return 90px, which is in this example the max size that #1 can occupy to let #2 occupying 10% of layout size.
   * @param resizingPanel The panel that is currently resizing
   * @param newSize The new size wanted to be occupied by the panel currently resizing
   */
  getNewSizeAccordingMinRemainingSize(resizingPanel: ResizableComponent, newSize: number): number {
    const layoutSize = this.size;
    let remainingSize = layoutSize;
    for (const panel of this.panels) {
      remainingSize -= panel === resizingPanel ? newSize : panel.remaining ? 0 : panel.size;
    }
    const diff = this.remainingPanelMin - remainingSize;
    if (diff > 0) {
      return newSize - diff;
    }
    return newSize;
  }

  refreshValues(): void {
    this.size = this.liveSize();
    this.remainingPanelMin = this.remainingPanel.getMin();
  }
}
