// Toutes les langues ltr (cf CDC)
import {
  uk, // Ukrainien
  sv, // Suedois
  sr, // Serbe
  sl, // Slovène
  sk, // Slovaque
  ru, // Russe
  ro, // Roumain
  ptBR, // Brésilien
  pt, // Portugais
  pl, // Polonais
  nn, // Norvégien
  nl, // Néerlandais/Flamand
  nb, // Norvégien Bokmal
  lv, // letton
  lt, // Lithuanien
  it, // Italien
  hu, // Hongrois
  hr, // Croate
  frCA, // Francais Canadien
  fr, // Francais
  fi, // Finnois
  eu, // Basque
  et, // Estonien
  es, // Espagnol
  enUS, // locale par défaut
  enGB, // Anglais
  el, // Grec
  de, // Allemand
  da, // Danois
  cs, // Tchèque
  bg, // Bulgare
  hi, // Indi
} from 'date-fns/locale';

function getDateFnsLocaleByCode(code: string): Locale {
  // toutes les locales de cockpit et UI
  switch (code) {
    case 'uk_UA':
      return uk;
    case 'sv_SE':
      return sv;
    case 'sr_Latn':
    case 'sr_Cyrl':
      return sr;
    case 'sl_SI':
      return sl;
    case 'sk_SK':
      return sk;
    case 'ru_RU':
      return ru;
    case 'ro_RO':
      return ro;
    case 'pt_PT':
      return pt;
    case 'pt_BR':
      return ptBR;
    case 'pl_PL':
      return pl;
    case 'nn_NO':
      return nn;
    case 'nl_NL':
    case 'nl_BE':
      return nl;
    case 'nb_NO':
      return nb;
    case 'lv_LV':
      return lv;
    case 'lt_LT':
      return lt;
    case 'it_IT':
    case 'it_CH':
      return it;
    case 'hu_HU':
      return hu;
    case 'hr':
    case 'hr_HR':
      return hr;
    case 'fr_CA':
      return frCA;
    case 'fr':
    case 'fr_BE':
    case 'fr_FR':
    case 'fr_LU':
    case 'fr_CH':
      return fr;
    case 'fi_FI':
      return fi;
    case 'eu':
      return eu;
    case 'et_EE':
      return et;
    case 'es_AR':
    case 'es_BO':
    case 'es_CL':
    case 'es_CO':
    case 'es_CR':
    case 'es_DO':
    case 'es_EC':
    case 'es_SV':
    case 'es_GT':
    case 'es_HN':
    case 'es_MX':
    case 'es_NI':
    case 'es_PA':
    case 'es_PY':
    case 'es_PE':
    case 'es_PR':
    case 'es_ES':
    case 'es_US':
    case 'es_UY':
    case 'es_VE':
    case 'es_419':
      return es;
    case 'en_GB':
      return enGB;
    case 'el_GR':
    case 'el_CY':
      return el;
    case 'de_LU':
    case 'de_DE':
    case 'de_CH':
    case 'de_AT':
      return de;
    case 'da_DK':
      return da;
    case 'cs_CZ':
      return cs;
    case 'bg':
    case 'bg_BG':
      return bg;
    case 'hi':
    case 'hi_IN':
      return hi;
    case 'zh_Hans':
    case 'vi_VN':
    case 'tr_TR':
    case 'tl':
    case 'th_TH':
    case 'te_IN':
    case 'sq_AL':
    case 'rm_CH':
    case 'ms_MY':
    case 'mn':
    case 'lo':
    case 'ko_KR':
    case 'kn_IN':
    case 'km_KH':
    case 'kk_KZ':
    case 'ja_JP':
    case 'id_ID':
    case 'he':
    case 'fa':
    case 'en_US':
    case 'ca':
    case 'ar':
    case 'en':
    case 'en_DE':
    case 'en_FR':
    case 'en_419':
    default:
      return enUS;
  }
}

export { getDateFnsLocaleByCode };
