import { Injectable } from '@angular/core';
import { AccountService } from 'app/core/auth/account.service';
import { AuthServerProvider } from 'app/core/auth/auth-session.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable({ providedIn: 'root' })
export class LoginService {
  constructor(
    private accountService: AccountService,
    private authServerProvider: AuthServerProvider,
    private translateService: TranslateService
  ) {}

  logoutInClient(): void {
    this.accountService.authenticate(null);
  }

  logout(): void {
    this._logout();
  }

  loginViaSpp(): void {
    window.location.href = `/login`;
  }

  private _logout(): void {
    this.authServerProvider.logout();
  }
}
