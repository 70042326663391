export enum CodeErrorServer {
  ONLY_ONE_FACT_RELATED_TO_FOOTNOTE = 'ONLY_ONE_FACT_RELATED_TO_FOOTNOTE',
  NOT_NUMERIC_VALUE = 'NOT_NUMERIC_VALUE',
  NOT_DATE_VALUE = 'NOT_DATE_VALUE',
  NOT_BOOLEAN_VALUE = 'NOT_BOOLEAN_VALUE',
  ERR_CONV_TYPE_FACT = 'ERR_CONV_TYPE_FACT',
  TAXO_IMPORT_ERROR = 'TAXO_IMPORT_ERROR',
  PROJECT_NOT_FOUND = 'PROJECT_NOT_FOUND',
  DATE_NAME_ALREADY_EXISTS = 'date-name-already-exists',
  DATE_VALUE_ALREADY_EXISTS = 'date-value-already-exists',
  TEMPLATE_NAME_ALREADY_EXISTS = 'TEMPLATE_NAME_ALREADY_EXISTS',
  TEMPLATE_IS_IN_USE = 'TEMPLATE_IS_IN_USE',
  HIGHCHART_TEMPLATE_IS_IN_USE = 'HIGHCHART_TEMPLATE_IS_IN_USE',
  LEI_NOT_CONFIGURED = 'LEI_NOT_CONFIGURED',
  COMMAND_NOT_ALLOWED = 'COMMAND_NOT_ALLOWED',
  RCSF_BAD_IDENTIFIER = 'RCSF_BAD_IDENTIFIER',
  NONE_TAXONOMY = 'NONE_TAXONOMY',
  NONE_FACT = 'NONE_FACT',
  GIT_ERROR_SAVE = 'GIT_ERROR_SAVE',
  TABLE_MODEL_ALREADY_EXIST = 'TABLE_MODEL_ALREADY_EXIST',
  TITLE_STYLE_NAME_ALREADY_EXISTS = 'TITLE_STYLE_NAME_ALREADY_EXISTS',
  BAD_FONT_FILE = 'BAD_FONT_FILE',
  INCORRECT_TYPE_VALUE = 'INCORRECT_TYPE_VALUE',
  CSS_IMPORT_IN_PROGRESS = 'CSS_IMPORT_IN_PROGRESS',
  PAGE_BACKGROUND_IMPORT_IN_PROGRESS = 'PAGE_BACKGROUND_IMPORT_IN_PROGRESS',
  PAGE_MODEL_IMPORT_IN_PROGRESS = 'PAGE_MODEL_IMPORT_IN_PROGRESS',
  FONT_IMPORT_IN_PROGRESS = 'FONT_IMPORT_IN_PROGRESS',
  FONT_DUPLICATE_FILE = 'FONT_DUPLICATE_FILE',
  CREATE_EMPTY_FACT = 'CREATE_EMPTY_FACT',
}
