import { Component, EventEmitter, Output, OnInit, OnDestroy } from '@angular/core';
import pubsub from 'app/pubsub';
import { EDITOR_PLUGIN_ZOOM_UPDATE, EDITOR_PLUGIN_ZOOM_INIT, EDITOR_PLUGIN_ZOOM_SESSION_CHANGE } from 'app/pubsub.topics';

@Component({
  selector: 'jhi-zoom-button',
  template: '',
})
export class ZoomButtonComponent implements OnInit, OnDestroy {
  @Output() zoom = new EventEmitter<number>();

  public currentZoom = 100;

  private handleApplyZoom: Function;
  private handleChangeZoom: Function;

  ngOnInit(): void {
    const zoomOnSessionStorage = localStorage.getItem('zoom');
    if (zoomOnSessionStorage) {
      this.currentZoom = parseFloat(zoomOnSessionStorage);
      this.zoom.emit(this.currentZoom);
    }

    this.handleApplyZoom = this.applyZoom.bind(this);
    pubsub.on(EDITOR_PLUGIN_ZOOM_UPDATE, this.handleApplyZoom);

    this.handleChangeZoom = this.changeZoom.bind(this);
    pubsub.on(EDITOR_PLUGIN_ZOOM_INIT, this.handleChangeZoom);
  }

  private applyZoom({ detail }: any) {
    this.currentZoom = detail.zoom;
    this.zoom.emit(this.currentZoom);
    localStorage.setItem('zoom', this.currentZoom.toString());
    this.changeZoom();
  }

  private changeZoom() {
    pubsub.fire(EDITOR_PLUGIN_ZOOM_SESSION_CHANGE, { zoom: this.currentZoom });
  }

  ngOnDestroy(): void {
    pubsub.off(EDITOR_PLUGIN_ZOOM_UPDATE, this.handleApplyZoom);
    pubsub.off(EDITOR_PLUGIN_ZOOM_INIT, this.handleChangeZoom);
  }
}
