// ANGULAR
import {
  Component,
  ChangeDetectionStrategy,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ChangeDetectorRef,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { LookupService } from 'app/core/service/lookup.service';
import { AccordionFormGroupComponent } from 'app/shared/component/accordion-form-group/accordion-form-group.component';

// ENUMS, MODELS, ETC...
import { DisplayTypes, LinkTypes, LookupType, TypePageRef } from 'app/shared/enum/lookup-type.enum';
import { ILookupEntry, ITitleTemplate, ITitleTemplateFormGroup } from 'app/shared/model/lookup.model';
import { Subscription } from 'rxjs';

@Component({
  selector: 'jhi-lookup-entry-form',
  templateUrl: 'lookup-entry.component.html',
  styleUrls: ['lookup-entry.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LookupEntryComponent implements OnInit, OnDestroy {
  @Input() public lookupType: UntypedFormControl;
  @Input() public entry: ILookupEntry;
  @Input() public entryIndex: number;
  @Input() public documentTitles: ITitleTemplate[];
  @Input() public disableMoveDownButton: boolean;
  @Input() public disableMoveUpButton: boolean;
  @Input() public expanded: boolean;

  @Output() public deleteEntry = new EventEmitter<void>();
  @Output() public moveEntryUp = new EventEmitter<ILookupEntry>();
  @Output() public moveEntryDown = new EventEmitter<ILookupEntry>();
  @Output() public updateEntry = new EventEmitter<ILookupEntry>();

  @ViewChild(AccordionFormGroupComponent) public accordionFormGroup: AccordionFormGroupComponent;

  public linkTypes: LinkTypes[] = [LinkTypes.DISJOINT, LinkTypes.JOINT];
  public displayTypes: DisplayTypes[] = [DisplayTypes.PAGES, DisplayTypes.RANGES];
  public TypePageRef = Object.values(TypePageRef);
  public entryForm: UntypedFormGroup;
  public isMoreThanTwoSelected = false;

  get searchedTitles(): AbstractControl {
    return this.entryForm.get('searchedTitles') as AbstractControl<any, any>;
  }
  get linkType(): AbstractControl {
    return this.entryForm.get('linkType') as AbstractControl<any, any>;
  }
  get displayType(): AbstractControl {
    return this.entryForm.get('displayType') as AbstractControl<any, any>;
  }
  get jointTitles(): UntypedFormArray {
    return this.entryForm.get('jointTitles') as UntypedFormArray;
  }
  get disjointTitle(): AbstractControl {
    return this.entryForm.get('disjointTitle') as AbstractControl<any, any>;
  }
  get addNewLine(): AbstractControl {
    return this.entryForm.get('addNewLine') as AbstractControl<any, any>;
  }

  private readonly subscriptions: Subscription = new Subscription();

  constructor(private fb: UntypedFormBuilder, private changeDetectorRef: ChangeDetectorRef, private lookupService: LookupService) {}

  ngOnInit(): void {
    this.entryForm = this.fb.group({
      linkType: [this.entry.linkType],
      searchedTitles: [this.entry.searchedTitles ?? ''],
      jointTitles: this.fb.array(this.calculateJointTitlesFormValue(this.entry)),
      disjointTitle: [this.calculateDisjointTitlesFormValue(this.entry)],
      displayType: [this.entry.displayType ?? (this.lookupType.value === LookupType.PAGE ? DisplayTypes.PAGES : TypePageRef.FULL)],
      addNewLine: [this.entry.addNewLine ?? null],
    });
    this.subscriptions.add(this.entryForm.valueChanges.subscribe((selectedValue: ILookupEntry) => this.saveEntry(selectedValue)));
    this.subscriptions.add(
      this.lookupService.lookupTypeValues$.subscribe(res => {
        this.entryForm.patchValue({ displayType: (res as LookupType) === LookupType.PAGE ? DisplayTypes.PAGES : TypePageRef.FULL });
        this.changeDetectorRef.markForCheck();
      })
    );
    this.changeDetectorRef.markForCheck();
  }

  public isChecked(uuid: string): boolean {
    const isCheck = this.jointTitles.value.filter((jointTitle: ITitleTemplateFormGroup) => {
      return jointTitle.title.uuid === uuid;
    });
    return isCheck.length > 0;
  }

  private contructTitleTemplates(entry: ILookupEntry): ITitleTemplateFormGroup[] {
    const titlesUuidArray = entry.titles?.split(';') ?? [];
    return this.documentTitles
      .map((title: ITitleTemplate) => {
        return { title };
      })
      .filter((titleTemplate: ITitleTemplateFormGroup) => {
        return titlesUuidArray.includes(titleTemplate.title.uuid);
      });
  }

  private calculateDisjointTitlesFormValue(entry: ILookupEntry): ITitleTemplate | string {
    const titleTemplates = this.contructTitleTemplates(entry);
    if ((entry.linkType as LinkTypes) === LinkTypes.DISJOINT && titleTemplates.length > 0) {
      return titleTemplates[0].title;
    } else {
      return '';
    }
  }

  private calculateJointTitlesFormValue(entry: ILookupEntry): UntypedFormGroup[] | [] {
    const titleTemplates = this.contructTitleTemplates(entry);
    const isJoint = (entry.linkType as LinkTypes) === LinkTypes.JOINT;
    if (isJoint && entry.jointTitles?.length) {
      return entry.jointTitles.map(jointTitle => {
        return this.fb.group({
          title: this.fb.group({
            uuid: new UntypedFormControl(jointTitle.title.uuid),
            content: new UntypedFormControl(jointTitle.title.content),
            firstPage: new UntypedFormControl(jointTitle.title.firstPage),
            lastPage: new UntypedFormControl(jointTitle.title.lastPage),
            numbering: new UntypedFormControl(jointTitle.title.numbering),
          }),
        });
      });
    } else if (isJoint && !entry.jointTitles?.length && entry.titles) {
      return titleTemplates.map(titleTemplate => {
        return this.fb.group({
          title: this.fb.group({
            uuid: new UntypedFormControl(titleTemplate.title.uuid),
            content: new UntypedFormControl(titleTemplate.title.content),
            firstPage: new UntypedFormControl(titleTemplate.title.firstPage),
            lastPage: new UntypedFormControl(titleTemplate.title.lastPage),
            numbering: new UntypedFormControl(titleTemplate.title.numbering),
          }),
        });
      });
    } else {
      return [];
    }
  }

  public changeCheckboxValue(event: MatCheckboxChange, title: ITitleTemplate): void {
    if (!event.checked) {
      // remove at index in form
      let removeIndex: number | null = null;
      this.jointTitles.value.forEach((titleForm: ITitleTemplateFormGroup, index: number) => {
        if (titleForm.title.uuid === title.uuid) {
          removeIndex = index;
        }
      });

      if (removeIndex) {
        this.jointTitles.removeAt(removeIndex);
      } else if (!removeIndex) {
        // remove indivdual so we don't breaks subscription references
        this.jointTitles.removeAt(0);
        this.jointTitles.removeAt(1);
      }
    } else if (event.checked) {
      // insert in form
      const selectedNumber = this.entryForm.controls.jointTitles.value.length;
      switch (selectedNumber) {
        case 0: {
          this.jointTitles.push(
            this.fb.group({
              title,
            })
          );
          break;
        }
        case 1: {
          // we need to have a reference to the documentTitles items order
          const insertAtIndex = this.calculateFormArrayIndex(this.jointTitles.value[0].title.uuid, title.uuid);
          this.jointTitles.insert(
            insertAtIndex,
            this.fb.group({
              title,
            })
          );
          break;
        }
        default:
          // the default case is covered in the parent if (!event.checked). When we get in the switch we can only have this 2 cases
          break;
      }
    }
  }

  /**
   * check if the current selected title is before or after the title already checked in the list
   * @param uuidJointTitles : uuid of the title already checked
   * @param uuidSelected : uuid of the current selected title
   * @private
   */
  private calculateFormArrayIndex(uuidJointTitles: string, uuidSelected: string): number {
    const firstTitle = this.documentTitles.find((title: ITitleTemplate) => title.uuid === uuidJointTitles || title.uuid === uuidSelected);
    return firstTitle?.uuid === uuidJointTitles ? 1 : 0;
  }

  saveEntry(selectedValue: ILookupEntry): void {
    this.entry = {
      order: this.entry.order,
      lookupType: this.lookupType.value,
      linkType: selectedValue.linkType,
      titles: this.isJointType() ? this.getFormatedJointTitles() : this.disjointTitle.value.uuid,
      searchedTitles: selectedValue.searchedTitles,
      displayType: selectedValue.displayType,
      addNewLine: selectedValue.addNewLine,
      jointTitles: selectedValue.jointTitles,
      disjointTitle: selectedValue.disjointTitle,
    };
    this.updateEntry.emit(this.entry);
  }

  private calculateDisplayTypeValue(displayType: string): string {
    // in joint scenario display type is removed from the form. We need to pass a default value
    if (this.isJointType() && this.lookupType.value === LookupType.PAGE) {
      return DisplayTypes.RANGES;
    } else if (this.isJointType() && this.lookupType.value === LookupType.TITLE_REF) {
      return TypePageRef.FULL;
    } else {
      return displayType;
    }
  }

  // check which type of link we have selected
  public isJointType(): boolean {
    return this.linkType.value === LinkTypes.JOINT;
  }

  // check which type of lookup we have selected
  public isPageNumberType(): boolean {
    return this.lookupType.value === LookupType.PAGE;
  }

  public clearSearchField(): void {
    this.entryForm.patchValue({ searchedTitles: '' });
    this.changeDetectorRef.markForCheck();
  }

  public disabledScrollToLeftTitleButton() {
    return this.entryForm.get('searchedTitles')?.value || !this.jointTitles.value.length;
  }

  public disabledScrollToTitleRightButton() {
    return this.entryForm.get('searchedTitles')?.value || !(this.jointTitles.value.length === 2 || this.disjointTitle.value);
  }

  /**
   *
   * @returns the selected range title, in the right string format to feed ckeditor model
   */
  public getFormatedJointTitles(): string {
    if (this.jointTitles.value.length) {
      const formatedTitle = this.jointTitles.value.map((titleForm: ITitleTemplateFormGroup) => titleForm.title.uuid).join(';');
      return formatedTitle ?? '';
    } else {
      return '';
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
