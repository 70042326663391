<ng-container *ngIf="!selectedFactsList; else singleFactChooser">
  <jhi-input-search placeholder="dataEditor.concept.search.placeholder" (searchTermChanged)="searchTables($event)"> </jhi-input-search>
  <div class="taxonomy-list-content scrollbar">
    <ng-container *ngIf="treeView; else listView">
      <div *ngFor="let table of tablesAndConcepts">
        <jhi-taxonomy-browser-table
          [table]="table"
          [search]="filterText"
          [searchType]="SearchType.ALL"
          [conceptIdSelected]="this.selectedConcept?.pnodeId"
          (selected)="onSelected($event)"
        >
        </jhi-taxonomy-browser-table>
      </div>
    </ng-container>
    <ng-template #listView>
      <div *ngFor="let item of filteredListAndConcepts">
        <jhi-node-expand [label]="item | conceptTranslatedLabel" *ngIf="item.concepts?.length" [expanded]="expanded">
          <div
            class="concept"
            *ngFor="let concept of item.concepts"
            (click)="onConceptSelected(concept)"
            [ngClass]="{ selected: concept === selectedConcept }"
          >
            <div class="concept-label">{{ concept | conceptTranslatedLabel }}</div>
            <div class="concept-type">
              <div class="concept-type-square"></div>
              <div class="concept-type-label">{{ concept.type }}</div>
            </div>
            <div class="concept-header">
              <div class="concept-header-name">{{ concept.qname | splitQname: 'name' }}</div>
              <div class="concept-header-type">{{ concept.qname | splitQname }}</div>
              <!-- <div class="concept-header-year">2020</div>  TODO: add year if available from Arevio -->
            </div>
          </div>
        </jhi-node-expand>
      </div>
    </ng-template>
  </div>
  <div class="taxonomy-toggle">
    <mat-slide-toggle color="primary" [checked]="treeView" (toggleChange)="swapView()">
      {{ 'dataEditor.taxonomy.viewToggle' | translate }}
    </mat-slide-toggle>
  </div>
</ng-container>
<ng-template #singleFactChooser>
  <p class="factPiker">{{ 'htmlEditor.plugins.dynamicData.picking.factChooser' | translate: '{label: "' + conceptLabel + '"}' }}</p>
  <div class="taxonomy-list-content scrollbar">
    <div
      class="concept"
      *ngFor="let fact of selectedFactsList"
      (click)="onFactSelected(fact)"
      [ngClass]="{ selected: selectedFact?.id === fact.id, disabledFact: !!fact?.duplicateInfo }"
    >
      <div class="concept-header">
        <div class="concept-header-name">{{ conceptLabel }}</div>
        <div *ngIf="fact.context.period.startDate !== fact.context.period.endDate; else instant" class="concept-header-year">
          {{ fact.context.period.startDate | localizedDate: 'shortDate' }} - {{ fact.context.period.endDate | localizedDate: 'shortDate' }}
        </div>
        <ng-template #instant>
          <div class="concept-header-year">{{ fact.context.period.endDate | localizedDate: 'shortDate' }}</div>
        </ng-template>
      </div>

      <div class="concept-type-dim" *ngFor="let dim of fact.context.dimensions">
        <div class="concept-type-dim-axis">{{ dim.dimAxis.qname }}</div>
        <div class="concept-type-dim-label">{{ dim.dimMember.qname }}</div>
      </div>
      <br *ngIf="!fact.context.dimensions" />
      <div class="concept-label preserve-newline">{{ fact.factValue | xbrlFactValue: conceptType }}</div>

      <div class="duplicateValues" *ngIf="fact?.duplicateInfo">
        <div class="label" jhiTranslate="dataEditor.xbrl.duplicate.title"></div>
        <div jhiTranslate="dataEditor.xbrl.duplicate.messageData"></div>
        <hr />
        <div *ngFor="let dupValue of fact?.duplicateInfo?.dupValues">{{ dupValue.value | xbrlFactValue: conceptType }}</div>
      </div>
    </div>
  </div>
</ng-template>
<div class="action-buttons">
  <button mat-raised-button color="accent" (click)="selected.emit()">
    <span jhiTranslate="htmlEditor.plugins.dynamicData.picking.cancel"></span>
  </button>
  <button mat-raised-button color="primary" [disabled]="!nextEnabled || isDisabledConcept" (click)="pickFact()" [ngSwitch]="true">
    <span jhiTranslate="htmlEditor.plugins.dynamicData.picking.insert" *ngSwitchCase="noFormat"></span>
    <span jhiTranslate="htmlEditor.plugins.dynamicData.picking.format" *ngSwitchCase="isFormatLabel"></span>
    <span jhiTranslate="htmlEditor.plugins.dynamicData.picking.next" *ngSwitchDefault></span>
  </button>
</div>
<div
  *ngIf="nextEnabled && isDisabledConcept && isTextFact"
  class="warning-editorial-text-fact"
  jhiTranslate="htmlEditor.plugins.dynamicData.picking.warningEditorialTextFact"
></div>
<div
  *ngIf="nextEnabled && isDisabledConcept && !isTextFact"
  class="warning-editorial-text-fact"
  jhiTranslate="htmlEditor.plugins.dynamicData.picking.warningNoEditorialTextFact"
></div>
