<div class="plugin-container dynamic-data-tagging">
  <ng-container *ngIf="displayWarning; else taggingTemplate">
    <div class="warning">
      <div class="title-container">
        <span class="title" jhiTranslate="htmlEditor.plugins.dynamicData.tagging.warningTitle"></span>
      </div>
      <p [innerHTML]="'htmlEditor.plugins.dynamicData.tagging.warning' | translate"></p>
    </div>
    <div class="action-buttons">
      <button mat-raised-button color="accent" (click)="onClickClosePanel()">
        <span jhiTranslate="htmlEditor.plugins.dynamicData.tagging.cancel"></span>
      </button>
      <button mat-raised-button color="primary" (click)="displayWarning = false">
        <span jhiTranslate="htmlEditor.plugins.dynamicData.tagging.understood"></span>
      </button>
    </div>
  </ng-container>
  <ng-template #taggingTemplate>
    <ng-container *ngIf="!format; else formatTemplate">
      <div class="xbrl-data-tagger">
        <jhi-xbrl-data-tagger
          [fact]="fact"
          (factUpdated)="updateFact($event)"
          (stepUpdated)="updateTaggingStep($event)"
        ></jhi-xbrl-data-tagger>
      </div>
      <div class="alert alert-warning" *ngIf="isMultiIndexed">
        <span jhiTranslate="htmlEditor.plugins.dynamicData.tagging.factMultiIndexedWarning"></span>
      </div>
      <div class="tagging-value" *ngIf="isFactReady$ | async">
        <div class="xbrl-data-tagger" [ngSwitch]="templateType$ | async">
          <div *ngSwitchCase="'NUMBER'">
            <form
              role="form"
              [formGroup]="monetaryForm"
              class="fact-value"
              [attr.aria-label]="'htmlEditor.plugins.dynamicData.tagging.formForNumericFact' | translate"
            >
              <mat-form-field class="fact-value">
                <mat-label jhiTranslate="htmlEditor.plugins.dynamicData.tagging.factValue"></mat-label>
                <input formControlName="factValue" matInput type="number" [readonly]="isPicking" />
              </mat-form-field>
              <mat-form-field class="import-scale">
                <div class="import-scale-left">
                  <mat-label jhiTranslate="htmlEditor.plugins.dynamicData.tagging.importScale"></mat-label>
                  <input formControlName="scale" matInput type="number" min="-2" max="12" [readonly]="isPicking" />
                </div>
                <span matSuffix>&emsp;×{{ multiplier }}</span>
              </mat-form-field>
            </form>
          </div>
          <div *ngSwitchCase="'DATE'">
            <form
              role="form"
              [formGroup]="dateForm"
              class="fact-value"
              [attr.aria-label]="'htmlEditor.plugins.dynamicData.tagging.formForDateFact' | translate"
            >
              <jhi-calendar
                placeholder="{{ 'htmlEditor.plugins.dynamicData.tagging.factValue' | translate }}"
                formControlName="factValue"
                required
                [disabled]="isPicking"
              >
              </jhi-calendar>
            </form>
          </div>
          <div class="text-value" *ngSwitchDefault>
            <div>{{ 'htmlEditor.plugins.dynamicData.tagging.factValue' | translate }} :</div>
            <span class="preserve-newline">{{ valueToDisplay }}</span>
            <div class="choice-action-container" [formGroup]="formPickingOption" *ngIf="isPicking">
              <mat-radio-group color="primary" formControlName="saveOption">
                <mat-radio-button [value]="DynamicDataTaggingOption.REPLACE">
                  {{ 'htmlEditor.plugins.dynamicData.tagging.replaceOption' | translate }}
                </mat-radio-button>
                <mat-radio-button [value]="DynamicDataTaggingOption.ADD">
                  {{ 'htmlEditor.plugins.dynamicData.tagging.addOption' | translate }}
                </mat-radio-button>
              </mat-radio-group>
            </div>
            <div
              *ngIf="addOptionMandatory"
              class="choice-action-container-info"
              jhiTranslate="htmlEditor.plugins.dynamicData.tagging.addOptionMandatory"
            ></div>
          </div>
        </div>
      </div>
      <div class="action-buttons" *ngIf="isActionsButtonsDisplayed">
        <button mat-raised-button color="accent" (click)="onClickClosePanel()">
          <span jhiTranslate="htmlEditor.plugins.dynamicData.tagging.cancel"></span>
        </button>
        <button mat-raised-button color="primary" [disabled]="!(isReadyToSave$ | async)" (click)="save()">
          <span jhiTranslate="htmlEditor.plugins.dynamicData.tagging.save"></span>
        </button>
      </div>
    </ng-container>
    <ng-template #formatTemplate>
      <jhi-dynamic-data-formater
        [editor]="editor"
        (resetPanel)="onClickClosePanel()"
        [dynamicData]="factForPicking"
      ></jhi-dynamic-data-formater>
    </ng-template>
  </ng-template>
</div>
