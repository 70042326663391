import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import * as moment from 'moment';
import { DateUtils } from '../util/date-utils';

@Pipe({
  name: 'localizedDate',
  pure: false,
})
export class LocalizedDatePipe implements PipeTransform {
  private value: string | null;
  private lastDate: string;
  private lastLang: string;

  constructor(private translateService: TranslateService) {}

  //
  // https://angular.io/api/common/DatePipe
  //
  // 'short': equivalent to 'M/d/yy, h:mm a' (e.g. 6/15/15, 9:03 AM)
  // 'medium': equivalent to 'MMM d, y, h:mm:ss a' (e.g. Jun 15, 2015, 9:03:01 AM)
  // 'long': equivalent to 'MMMM d, y, h:mm:ss a z' (e.g. June 15, 2015 at 9:03:01 AM GMT+1)
  // 'full': equivalent to 'EEEE, MMMM d, y, h:mm:ss a zzzz' (e.g. Monday, June 15, 2015 at 9:03:01 AM GMT+01:00)
  // 'shortDate': equivalent to 'M/d/yy' (e.g. 6/15/15)
  // 'mediumDate': equivalent to 'MMM d, y' (e.g. Jun 15, 2015)
  // 'longDate': equivalent to 'MMMM d, y' (e.g. June 15, 2015)
  // 'fullDate': equivalent to 'EEEE, MMMM d, y' (e.g. Monday, June 15, 2015)
  // 'shortTime': equivalent to 'h:mm a' (e.g. 9:03 AM)
  // 'mediumTime': equivalent to 'h:mm:ss a' (e.g. 9:03:01 AM)
  // 'longTime': equivalent to 'h:mm:ss a z' (e.g. 9:03:01 AM GMT+1)
  // 'fullTime': equivalent to 'h:mm:ss a zzzz' (e.g. 9:03:01 AM GMT+01:00)
  //
  transform(date: string | moment.Moment | null | undefined, pattern = 'medium'): string | null {
    if (!date) {
      return null;
    }
    const currentLang = this.translateService.currentLang;
    const dateString: string = moment.isMoment(date) ? date.format() : date;

    // if we ask another time for the same date & locale, return the last value
    if (dateString === this.lastDate && currentLang === this.lastLang) {
      return this.value;
    }

    try {
      this.value = new DatePipe(currentLang).transform(DateUtils.fromServerString(dateString), pattern);
    } catch (e) {
      this.value = dateString;
      console.error(e);
    }
    this.lastDate = dateString;
    this.lastLang = currentLang;

    return this.value;
  }
}
