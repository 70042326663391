<div class="editor-property-opacity">
  <div class="opacity-header">
    <label jhiTranslate="styleEditor.editor.properties.opacity">Opacity</label>
    <button mat-icon-button (click)="clearOpacity()">
      <mat-icon title="{{ 'styleEditor.editor.properties.reset' | translate }}" svgIcon="reset"></mat-icon>
    </button>
  </div>
  <div class="opacity-slider">
    <mat-slider [max]="1" [step]="0.1">
      <input matSliderThumb [(ngModel)]="opacityValue" (ngModelChange)="updateOpacity()" #slider />
    </mat-slider>
    <span class="editor-property-opacity-value-display">{{ (opacityValue || 0) * 100 }}%</span>
  </div>
</div>
