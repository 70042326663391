import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { forkJoin } from 'rxjs';

import { ArevioService } from 'app/core/service/arevio.service';
import { IConcept, IEntryPointTable } from 'app/shared/model/concept.model';
import { IFact, IFactsByRole } from 'app/shared/model/fact.model';
import { PluginsCommand } from 'app/html-editor/plugins/plugins-commands';
import { SearchType } from 'app/shared/enum/search-type.enum';
import { ITaxonomyConceptSelected } from 'app/shared/model/taxonomy.model';
import { XBRLType } from 'app/shared/enum/xbrl-type.enum';
import { FormatService } from 'app/core/service/format.service';
import { NumberFormat, XbrlFormat, DateFormat } from 'app/shared/model/format.model';
import { BalanceType } from 'app/shared/enum/balance-type.enum';
import { ProjectService } from 'app/core/service/project.service';
import { IProject } from 'app/shared/model/project.model';
import { filterConcept } from 'app/shared/util/xbrl-utils';
import { IMonetaryUnit } from 'app/shared/model/monetary-unit.model';
import { ContextService } from 'app/core/service/context.service';

@Component({
  selector: 'jhi-xbrl-fact-picker',
  templateUrl: './xbrl-fact-picker.component.html',
  styleUrls: ['./xbrl-fact-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class XbrlFactPickerComponent implements OnInit {
  @Input() isTextBlock = false;
  @Input() isTextFact = false;
  @Input() disableSave = false;
  @Output() public selected = new EventEmitter<{
    command: PluginsCommand;
    params: { data: IFact; format: any; projectUnits: IMonetaryUnit[] };
    toFormat: boolean;
  }>();

  public tablesAndConcepts: IEntryPointTable[];
  public listAndConcepts: IEntryPointTable[];
  public filteredListConcepts: IEntryPointTable[];
  public facts: IFact[];
  public selectedFactsList: IFact[];
  private preSelectedFactsList: IFact[];
  private disabledConcepts: IConcept[];
  public isDisabledConcept = false;
  public filterText: string;
  public previousFilterText: string;
  public readonly SearchType = SearchType;
  public treeView = true;
  public conceptLabel: string;
  public conceptType: XBRLType;
  public selectedFact: IFact;
  public selectedConcept: IConcept | null;
  public nextButtonLabel: string;
  public noFormat = false;
  public defaultFormats: { number: XbrlFormat; date: XbrlFormat };
  public expanded = false;
  public projectUnits: IMonetaryUnit[];

  get nextEnabled(): boolean {
    return (!!this.selectedFact?.id && !this.disableSave) || (this.preSelectedFactsList?.length ?? 0) > 0;
  }

  get isFormatLabel(): boolean {
    return !this.noFormat && (!!this.selectedFact?.id || (this.selectedFactsList?.length ?? 0) > 0);
  }

  get filteredListAndConcepts(): IEntryPointTable[] {
    const currentLanguageCode = this.contextService.currentDocumentContext.language.code;
    if (currentLanguageCode && (!this.filteredListConcepts || this.filterText !== this.previousFilterText)) {
      const lists: IEntryPointTable[] = [];
      this.listAndConcepts.forEach(({ concepts, id, labels, orderNumber }) => {
        const subList = concepts.filter((concept: IConcept) =>
          filterConcept(concept, currentLanguageCode.substring(0, 2), this.filterText || '', SearchType.ALL)
        );
        if (subList.length) {
          lists.push({
            concepts: subList,
            id,
            labels,
            orderNumber,
          });
        }
      });
      this.filteredListConcepts = lists;
      this.previousFilterText = this.filterText;
      this.expanded = !!this.previousFilterText;
    }
    return this.filteredListConcepts;
  }

  constructor(
    private arevioService: ArevioService,
    private formatService: FormatService,
    private projectService: ProjectService,
    private cdr: ChangeDetectorRef,
    private contextService: ContextService
  ) {}

  ngOnInit(): void {
    this.projectService.getProjectMonetaryUnits().subscribe(units => (this.projectUnits = units));

    forkJoin([this.projectService.getProjectInformation(), this.formatService.getDefaultFormats()]).subscribe(
      ([project, [number, date]]: [IProject, [NumberFormat, DateFormat]]) => {
        this.defaultFormats = {
          number: {
            scale: project.project.publicationScale * -1,
            balance: BalanceType.CREDIT,
            format: number,
          },
          date: {
            format: date,
          },
        };
      }
    );

    this.arevioService.getFactByRoles(this.isTextBlock, this.isTextFact).subscribe(({ tables }: IFactsByRole) => {
      this.tablesAndConcepts = tables;
      this.listAndConcepts = [];

      const reduceFn = (acc: IConcept[], concept: IConcept) => {
        if (concept.children) {
          acc = concept.children.reduce(reduceFn, acc);
        }
        if (concept.facts?.length) {
          acc.push(concept);
        }
        return acc;
      };
      tables.forEach(table => {
        const list = table.concepts.reduce(reduceFn, []);
        this.listAndConcepts.push({
          id: table.id,
          orderNumber: table.orderNumber,
          labels: table.labels,
          concepts: list,
        });
      });
      this.cdr.markForCheck();
    });

    this.arevioService.getConceptDisabledList(!this.isTextFact).subscribe((list: IConcept[]) => {
      this.disabledConcepts = list;
    });
  }

  onSelected({ concept }: ITaxonomyConceptSelected): void {
    this.selectedFact = {};
    this.selectedConcept = null;
    this.preSelectedFactsList = [];
    if (!concept.abstract) {
      this.onConceptSelected(concept);
    }
  }

  onConceptSelected(concept: IConcept): void {
    const currentLanguageCode = this.contextService.currentDocumentContext.language.code;
    if (!currentLanguageCode) {
      return;
    }
    const selectedFacts = concept.facts?.filter(fact => fact.language === null || fact.language === currentLanguageCode.substring(0, 2));
    this.selectedConcept = concept;
    this.isDisabledConcept = this.disabledConcepts?.filter(disabledConcept => disabledConcept.conceptId === concept.conceptId).length > 0;

    if (selectedFacts?.length === 1) {
      this.onFactSelected(selectedFacts[0]);
    } else if ((selectedFacts?.length ?? 0) > 1) {
      this.selectedFact = {};
      this.conceptLabel = concept.qname.split(':')[1];
      this.conceptType = concept.type;
      this.preSelectedFactsList = selectedFacts ?? [];
    }
  }

  onFactSelected(fact: IFact): void {
    if (fact.duplicateInfo) {
      return;
    }
    this.selectedFact = Object.assign(fact, { concept: this.selectedConcept });
    switch (this.selectedFact.concept?.type) {
      case XBRLType.DATE:
      case XBRLType.PER_SHARE:
      case XBRLType.PER_SHARE_2021:
      case XBRLType.SHARES:
      case XBRLType.MONETARY:
      case XBRLType.DECIMAL:
        this.noFormat = false;
        break;
      default:
        this.noFormat = true;
        break;
    }
  }

  pickFact(): void {
    if (this.selectedFact?.id) {
      const type = this.selectedFact.concept?.type ?? ('' as XBRLType);
      const toFormat = [XBRLType.DATE, XBRLType.PER_SHARE, XBRLType.PER_SHARE_2021, XBRLType.MONETARY, XBRLType.DECIMAL].includes(type);

      this.selected.emit({
        command: PluginsCommand.INSERT_XBRL,
        params: { data: this.selectedFact, format: {}, projectUnits: this.projectUnits },
        toFormat,
      });

      this.selectedFactsList = [];
    } else {
      this.selectedFactsList = this.preSelectedFactsList;
      this.preSelectedFactsList = [];
    }
  }

  searchTables(filter: string): void {
    this.filterText = filter;
  }

  swapView(): void {
    this.treeView = !this.treeView;
    if (!this.treeView) {
      this.filteredListConcepts = [];
      this.previousFilterText = '';
    }
  }
}
