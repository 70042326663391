import {
  Component,
  ViewEncapsulation,
  OnInit,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
  ViewChild,
  ElementRef,
  Renderer2,
} from '@angular/core';
import { visualDomDiff } from 'visual-dom-diff';

import { PluginComponent } from '../plugin.component';
import { PluginPanelService } from '../plugin-panel.service';
import { ICommit } from '../../../shared/model/version-history.model';
import * as moment from 'moment';
import { UpdateTypes } from 'app/shared/model/version-history.model';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { ActivatedRoute } from '@angular/router';
import { DocumentaryUnitService } from 'app/core/service/documentary-unit.service';
import { CUSTOM_STYLES_CONTAINER } from 'app/shared/constants/styles.constants';
import { IStyle, LIST_CATEGORIES, SUMMARY_CATEGORIES } from 'app/shared/model/style.model';
import { ConfigurationService } from 'app/core/service/configuration.service';
import { ICKDocument, ICKStyles } from 'app/core/user/ck-config.model';
import { LabelService } from 'app/core/service/label.service';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'jhi-versions-panel-plugin',
  templateUrl: './versions-panel.component.html',
  styleUrls: ['./versions-panel.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VersionsPanelComponent extends PluginComponent implements OnInit {
  public _commits: ICommit[];
  public commitsTree: ICommit[][];
  public selectedCommit: ICommit | null;
  private _selectedData: string;

  public typeTextualSelected = true;
  public typeShapingSelected = false;

  public showTypeFilters = false; // Set to true to see filters

  @ViewChild('diffViewer', { static: false })
  public diffViewerRef!: ElementRef<HTMLElement>;

  private CKEditorConfig: any;
  private editorWithPreviousData: any;

  private diffNodeSelector = 'diffNode';

  constructor(
    pluginPanelService: PluginPanelService,
    private documentaryUnitService: DocumentaryUnitService,
    private cdr: ChangeDetectorRef,
    private activatedRoute: ActivatedRoute,
    private renderer: Renderer2,
    private configurationService: ConfigurationService,
    private labelService: LabelService
  ) {
    super(pluginPanelService);
  }

  private _filterCommits(): void {
    const commitsTree: ICommit[][] = [];
    const dayIndexes: string[] = [];
    this._commits.forEach((commit: ICommit, index: number) => {
      let includeCommit = index === 0; // actual version is always included
      if (!includeCommit && this.typeTextualSelected) {
        includeCommit = commit.message.types.includes(UpdateTypes.TEXT);
      }
      if (!includeCommit && this.typeShapingSelected) {
        includeCommit = commit.message.types.includes(UpdateTypes.SHAPING);
      }
      if (includeCommit) {
        const day = moment(commit.date).format('YYYYMMDD');
        const dayIdx = dayIndexes.indexOf(day);
        if (dayIdx >= 0) {
          commitsTree[dayIdx].push(commit);
        } else {
          dayIndexes.push(day);
          commitsTree.push([commit]);
        }
      }
    });
    this.commitsTree = commitsTree;
  }

  ngOnInit(): void {
    const styles = this.activatedRoute.snapshot.data.styles;
    const tablesModels = this.activatedRoute.snapshot.data.tablesModels;

    const listStyles = styles.filter((style: IStyle) => LIST_CATEGORIES.includes(style.category));
    const summaryStyles = styles.filter((style: IStyle) => SUMMARY_CATEGORIES.includes(style.category));

    const CKEditorConfigStyles: ICKStyles = {
      list: styles ?? [],
      tablesModels: tablesModels ?? [],
      listStyles,
      summaryStyles,
    };
    forkJoin({ config: this.configurationService.getConfiguration(), labels: this.labelService.getLabels() }).subscribe(data => {
      // TODO: maybe create an instance dedicated to this purpose (as footnote one)
      // instead of using the main one and removing some plugins
      this.CKEditorConfig = {
        licenseKey: data.config.ckeditorLicenseKey, // Is a offline editor, need to include licenseKey
        document: this.editor?.config.get('document'),
        correspondenceTables: this.editor?.config.get('correspondenceTables'),
        toolbar: [],
        styles: CKEditorConfigStyles,
        removePlugins: [
          'PasteFromOffice',
          'RealTimeCollaborativeEditing',
          'Autosave',
          'WebSpellChecker',
          'RealTimeCollaborativeComments',
          'RealTimeCollaborativeTrackChanges',
          'Comments',
          'PgdpComments',
          'StylingToolbar',
          'FindReplace',
          'VersionHistory',
          'TrackChanges',
          'TrackChangesEditing',
          'TrackChangesData',
        ],
        labels: data.labels,
      };

      this.documentaryUnitService
        .getCommitHistory('' + (this.editor?.config.get('document') as ICKDocument).documentaryUnit?.id)
        .subscribe(commits => {
          this._commits = commits;
          this._filterCommits();
          this.cdr.markForCheck();
        });
    });
  }

  isToday(date: moment.Moment): boolean {
    return moment().isSame(date, 'day');
  }

  showDiff(commit: ICommit): void {
    this.selectedCommit = commit;
    const container: HTMLElement = this.diffViewerRef.nativeElement;
    if (container.querySelector(`.${this.diffNodeSelector}`)) {
      this.renderer.removeChild(parent, container.querySelector(`.${this.diffNodeSelector}`));
    }
    this.pluginPanelService.openDiff();

    this.documentaryUnitService
      .getVersion('' + (this.editor?.config.get('document') as ICKDocument).documentaryUnit?.id, commit.sha1)
      .subscribe(data => {
        this._selectedData = data;
        CKEditor.DecoupledEditor.create(data, this.CKEditorConfig).then((editor: any) => {
          editor.editing.view.change((writer: any) => {
            writer.setAttribute('spellcheck', 'false', editor.editing.view.document.getRoot());
          });
          this.editorWithPreviousData = editor;

          const div1: HTMLElement = this.renderer.createElement('div');
          div1.innerHTML = this.editor?.getData() ?? '';

          const div2: HTMLElement = this.renderer.createElement('div');
          div2.innerHTML = this.editorWithPreviousData.getData();

          const diffNode: DocumentFragment = visualDomDiff(div2, div1);

          this.renderer.addClass(diffNode.firstElementChild, this.diffNodeSelector);
          this.renderer.addClass(diffNode.firstElementChild, CUSTOM_STYLES_CONTAINER);

          this.renderer.appendChild(container, diffNode);

          this.renderer.removeClass(container, 'hidden');
          return editor;
        });
      });
  }

  hideDiff(): void {
    const container: HTMLElement = this.diffViewerRef.nativeElement;
    this.renderer.addClass(container, 'hidden');
    if (container.querySelector(`.${this.diffNodeSelector}`)) {
      this.renderer.removeChild(parent, container.querySelector(`.${this.diffNodeSelector}`));
    }

    this.pluginPanelService.closeDiff();

    this.selectedCommit = null;
  }

  toggleTypeSelected(type: string, { checked }: MatCheckboxChange): void {
    this[`type${type}Selected`] = checked;
    this._filterCommits();
    this.selectedCommit = null;
    this.hideDiff();
    this.cdr.markForCheck();
  }

  restoreVersion(): void {
    // TODO pubsub
    this.editor?.data.set(this._selectedData, { suppressErrorInCollaboration: true });
    this.onClickClosePanel();
  }

  isLastSavedVersionDisplayed(): boolean {
    if (this._commits && this.selectedCommit) {
      return this.selectedCommit.sha1 === this._commits[0]?.sha1;
    }
    return false;
  }
}
