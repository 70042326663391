<div class="panel-block-right dm-panel">
  <div class="panel-header-block">
    <button mat-icon-button class="header-close" (click)="close()">
      <mat-icon svgIcon="chevron-right"></mat-icon>
    </button>

    <div class="header-title">
      <span jhiTranslate="global.dm.title"></span>
    </div>
  </div>
  <div class="panel-content-block">
    <div *ngIf="!entryList.length" class="no-export">
      <span jhiTranslate="global.dm.no-export"> No export available</span>
      <div class="html-editor" *ngIf="htmlEditor" (click)="onHtmlEditorPanel()">
        <mat-icon svgIcon="icon-export"></mat-icon>
        <span jhiTranslate="global.dm.open-export">Open export panel</span>
      </div>
    </div>

    <div class="entriesContainer" *ngIf="entryList.length">
      <div class="accordionsContainer">
        <mat-accordion *ngFor="let entry of entryList; let i = index">
          <mat-expansion-panel hideToggle [expanded]="i === 0">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <div class="title-content" [matTooltip]="entry.filename">
                  {{ 'global.dm.type.' + entry.exportType | translate }}{{ oktpLabel(entry) }}_{{ entry.filename }}
                </div>
                <div class="cercle {{ entry.exportStatus?.toLowerCase() }}"></div>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="export-type">{{ 'global.dm.type.' + entry.exportType | translate }}</div>
            <div class="export-type">{{ entry.exportTime | localizedDate: 'short' }}</div>

            <div class="state">
              <jhi-loading *ngIf="entry.exportStatus === ExportStatus.IN_PROGRESS" [show]="true"></jhi-loading>
              <div *ngIf="entry.exportStatus !== ExportStatus.IN_PROGRESS" class="cercle {{ entry.exportStatus?.toLowerCase() }}"></div>
              <span jhiTranslate="global.dm.state.{{ entry.exportStatus }}"></span>
            </div>

            <div
              class="information"
              *ngIf="
                entry.extension &&
                entry.weight &&
                entry.exportStatus !== ExportStatus.IN_PROGRESS &&
                entry.exportStatus !== ExportStatus.ERROR
              "
            >
              {{ entry.extension }} - {{ entry.weight }}
            </div>

            <div
              class="information"
              jhiTranslate="global.dm.info.{{ entry.exportStatus }}"
              [translateValues]="{ date: entry.expireDate | localizedDate: 'short' }"
            ></div>

            <div class="information" *ngIf="entry.exportStatus === ExportStatus.ERROR && entry.error" [innerHtml]="entry.error"></div>
            <div class="lore-container" *ngIf="entry.exportType === 'LORE'">
              <div
                class="warning"
                *ngIf="entry.exportStatus === ExportStatus.DONE_WITH_WARNINGS"
                jhiTranslate="global.dm.report.lore_report_warning"
              ></div>
            </div>
            <div class="buttons-container">
              <button
                class="grey"
                *ngIf="entry.exportStatus === ExportStatus.IN_PROGRESS || entry.exportStatus === ExportStatus.QUEUED"
                type="button"
                mat-flat-button
                mat-button
                jhiTranslate="global.dm.button.cancel"
                (click)="cancelGeneration(entry.id)"
              ></button>

              <button
                type="button"
                *ngIf="entry.exportStatus === ExportStatus.DONE || entry.exportStatus === ExportStatus.DONE_WITH_WARNINGS"
                color="primary"
                (click)="downloadFile(entry.id)"
                mat-flat-button
                mat-button
                jhiTranslate="global.dm.button.download"
              ></button>

              <div class="lore-container" *ngIf="entry.exportType === 'LORE'">
                <button
                  type="button"
                  *ngIf="!!entry.loreReportUrl && entry.exportStatus === ExportStatus.DONE"
                  color="primary"
                  (click)="downloadLoreFile(entry.loreReportUrl)"
                  mat-flat-button
                  mat-button
                  target="_blank"
                  jhiTranslate="global.dm.report.lore_report_link"
                ></button>
              </div>

              <button
                color="primary"
                *ngIf="entry.exportStatus === ExportStatus.ERROR"
                type="button"
                mat-flat-button
                mat-button
                jhiTranslate="global.dm.button.recall"
                (click)="recall(entry.id)"
              ></button>

              <button
                type="button"
                *ngIf="entry.reportErrors"
                color="primary"
                (click)="downloadErrorReport(entry.id)"
                mat-flat-button
                mat-button
                jhiTranslate="global.dm.button.downloadError"
              ></button>

              <div
                class="export-report"
                *ngIf="
                  isESMAWithReport(entry.exportType, entry.reportStatus) ||
                  (entry.exportStatus === ExportStatus.DONE_WITH_WARNINGS && isAdmin)
                "
              >
                <span
                  *ngIf="entry.exportStatus === 'DONE_WITH_WARNINGS'; else xbrlReport"
                  class="export-report-title"
                  jhiTranslate="global.dm.report.warnings.title"
                ></span>
                <ng-template #xbrlReport class="export-report-title" jhiTranslate="global.dm.report.title"></ng-template>

                <div class="export-report-button">
                  <button
                    color="primary"
                    type="button"
                    mat-flat-button
                    mat-button
                    jhiTranslate="global.dm.report.download"
                    [disabled]="
                      (!entry.reportStatus || entry.reportStatus === 'IN_PROGRESS' || entry.reportStatus === 'ERROR') &&
                      entry.exportStatus !== ExportStatus.DONE_WITH_WARNINGS
                    "
                    (click)="downloadReport(entry.reportId, entry.id)"
                  ></button>
                  <span>&emsp;</span>
                  <button
                    class="grey"
                    type="button"
                    mat-flat-button
                    mat-button
                    jhiTranslate="global.dm.report.display"
                    [disabled]="
                      (!entry.reportStatus || entry.reportStatus === 'IN_PROGRESS') &&
                      entry.exportStatus !== ExportStatus.DONE_WITH_WARNINGS
                    "
                    (click)="showReport(entry.reportId, entry.id)"
                  ></button>
                </div>

                <button
                  class="grey"
                  *ngIf="entry.reportStatus === 'READY' || entry.reportStatus === 'ERROR' || entry.reportStatus === 'WARNING'"
                  type="button"
                  mat-flat-button
                  mat-button
                  jhiTranslate="global.dm.button.delete"
                  (click)="deleteFile(entry.id, entry.filename)"
                ></button>
              </div>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
      <div class="deleteAllButtonContainer">
        <button
          class="grey"
          type="button"
          mat-flat-button
          mat-button
          jhiTranslate="global.dm.button.deleteAll"
          (click)="deleteAll()"
        ></button>
      </div>
    </div>
  </div>
</div>
