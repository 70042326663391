import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

export enum DIALOG_ITEM_STATE {
  success,
  warn,
  error,
}
@Component({
  selector: 'jhi-dialog-item-state',
  templateUrl: './dialog-item-state.component.html',
  styleUrls: ['./dialog-item-state.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DialogItemStateComponent {
  @Input() state = DIALOG_ITEM_STATE.success;
  @Input() messageKey: string;
  @Input() taIdPrefix = 'dialog-item-state';

  public DIALOG_ITEM_STATE = DIALOG_ITEM_STATE;
}
