import { Component, Output, Input, ViewEncapsulation, EventEmitter, ViewChild } from '@angular/core';
import { UntypedFormControl, Validators, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { FrontErrorComponent } from 'app/shared/dialog/error/frontError/front-error.component';
import { IColor, ICMYK } from 'app/shared/model/color.model';
import { ColorUtils } from 'app/shared/util/color-utils';
import { RGBA, HSLA, ColorWrap } from 'ngx-color';
import { ColorEvent } from 'ngx-color/color-wrap.component';

@Component({
  selector: 'jhi-color-picker',
  templateUrl: './color-picker.component.html',
  styleUrls: ['./color-picker.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ColorPickerComponent {
  @ViewChild('colorPicker', { static: false }) colorPicker: ColorWrap;
  @Output() update = new EventEmitter<{ color: IColor; cancel: boolean }>();
  @Input() colors: IColor[];

  @Input()
  get color(): IColor {
    return this._color;
  }

  set color(value: IColor) {
    if (value) {
      this._color = value;
      this.initForm();
    }
  }

  private _color: IColor;
  public colorForm: UntypedFormGroup;
  public rgbaCode: RGBA;

  constructor(private fb: UntypedFormBuilder, private dialog: MatDialog) {}

  public initForm(): void {
    const hslaCode: HSLA | null = ColorUtils.convertStringToHsla(this.color.hslaCode);
    const cmykCode: ICMYK | null = ColorUtils.convertStringToCmyk(this.color.cmykCode);
    this.rgbaCode = this.color.rgbCode
      ? ColorUtils.convertStringToRgba(this.color.rgbCode)
      : ColorUtils.hexToRgba(this.color.hexCode ?? '');

    this.colorForm = this.fb.group({
      colorName: new UntypedFormControl(this.color.colorName, Validators.required),
      hexCode: new UntypedFormControl(this.color.hexCode, [Validators.required, Validators.pattern('^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$')]),
      hslaCodeH: new UntypedFormControl(hslaCode?.h, [Validators.min(0), Validators.max(360)]),
      hslaCodeS: new UntypedFormControl(hslaCode?.s, [Validators.min(0), Validators.max(100)]),
      hslaCodeL: new UntypedFormControl(hslaCode?.l, [Validators.min(0), Validators.max(100)]),
      hslaCodeA: new UntypedFormControl(hslaCode?.a, [Validators.min(0), Validators.max(1.0)]),
      rgbCode: new UntypedFormControl(this.color.rgbCode),
      pantoneCode: new UntypedFormControl(this.color.pantoneCode),
      cmykCodeC: new UntypedFormControl(cmykCode?.c, [Validators.required, Validators.min(0), Validators.max(100)]),
      cmykCodeM: new UntypedFormControl(cmykCode?.m, [Validators.required, Validators.min(0), Validators.max(100)]),
      cmykCodeY: new UntypedFormControl(cmykCode?.y, [Validators.required, Validators.min(0), Validators.max(100)]),
      cmykCodeK: new UntypedFormControl(cmykCode?.k, [Validators.required, Validators.min(0), Validators.max(100)]),
    });
  }

  public cancelUpdated(): void {
    this.update.emit({ color: this.color, cancel: true });
  }

  public onChangeColor(colorValue: ColorEvent): void {
    const rgbCode: RGBA = colorValue.color.rgb;
    const cmykCode: ICMYK = ColorUtils.rgbaToCmyk(rgbCode);
    this.colorForm.patchValue({
      hexCode: colorValue.color.hex,
      rgbCode: ColorUtils.convertRgbaToString(rgbCode),
      hslaCodeH: +colorValue.color.hsl.h.toFixed(0),
      hslaCodeS: ColorUtils.convertToPercent(colorValue.color.hsl.s),
      hslaCodeL: ColorUtils.convertToPercent(colorValue.color.hsl.l),
      hslaCodeA: colorValue.color.hsl.a,
    });

    if (!colorValue.$event.target?.['className'].includes('alpha-') && !colorValue.$event.target?.['className'].includes('sketch-')) {
      // Mantis#0181507 do not change pantone and cmyk code when changing opacity
      this.colorForm.patchValue({
        cmykCodeC: cmykCode.c,
        cmykCodeM: cmykCode.m,
        cmykCodeK: cmykCode.k,
        cmykCodeY: cmykCode.y,
        pantoneCode: ColorUtils.rgbaToPantone(rgbCode),
      });
    }
  }

  updateColor(): void {
    if (this.color.id && ColorUtils.isNameAlreadyExist(this.colorForm.get('colorName')?.value, this.color.id, this.colors)) {
      this.dialog.open(FrontErrorComponent, {
        data: 'colorName-duplicate',
      });
    } else {
      this.color = ColorUtils.setNewColor(this.colorForm, this.color);
      this.update.emit({ color: this.color, cancel: false });
    }
  }
}
