export const PLUGIN_PANEL_COMPONENT_KEYS = {
  COMMENTS: 'comments',
  CONVERT_CLASS: 'convert-class',
  DOCUMENT_EXPORT: 'document-export',
  LOCALIZE_NUMBERS: 'localize-numbers',
  CONFIGURATION_EXPORT_PDF: 'configuration-export-pdf',
  CONFIGURATION_EXPORT_ESEF: 'configuration-export-esef',
  CONFIGURATION_EXPORT_WORD: 'configuration-export-word',
  CONFIGURATION_EXPORT_XLIFF: 'configuration-export-xliff',
  CONFIGURATION_EXPORT_LORE: 'configuration-export-lore',
  DYNAMIC_DATA: 'dynamic-data',
  DYNAMIC_DATA_PICKING: 'dynamic-data-picking',
  DYNAMIC_DATA_TAGGING: 'dynamic-data-tagging',
  DYNAMIC_DATA_VIEWER: 'dynamic-data-viewer',
  FIND_REPLACE: 'find-replace',
  IMAGE_PICKER: 'image-picker',
  IMAGE_PLACE_HOLDER: 'image-placeholder',
  SPECIAL_CHARACTERS: 'special-characters',
  STYLING: 'styling',
  SUMMARY: 'summary',
  VERSION_HISTORY: 'versionHistory',
  HIGHCHART: 'highchart',
  TABLE_PANEL: 'tablePanel',
  TABLE_ACCESSIBILITY_PANEL: 'tableAccessibilityPanel',
  COLUMN_PANEL: 'columnPanel',
  ROW_PANEL: 'rowPanel',
  IMPORT_FILE: 'importFile',
  VERSION_TRACKING: 'versionTracking',
  COLUMN_SPREAD: 'columnSpread',
  ANNOTATE_FACT: 'annotateFact',
  ANNOTATE_DETAIL_PANEL: 'annotateDetailPanel',
  LOOKUP: 'lookup',
  IMPORT_GRAPHICAL_PAGES: 'importGraphicalPages',
  TAG_TEXT_BLOCK: 'tagTextBlock',
};
