<div class="title-level-selection-container" *ngIf="tocConfig">
  <mat-form-field>
    <mat-label>{{ 'htmlEditor.toc.titlesLevel.levels.label' | translate }}</mat-label>
    <mat-select [(ngModel)]="tocConfig.includedTitleLevels" multiple (selectionChange)="updateFilteredTitles()">
      <mat-select-trigger>{{ tocConfig.includedTitleLevels.sort().join(', ') }}</mat-select-trigger>
      <mat-option *ngFor="let level of titleLevels" [value]="level" [disabled]="[1, 2].includes(level)">
        {{ 'htmlEditor.toc.titlesLevel.levels.option' | translate }} {{ level }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title>{{ 'htmlEditor.toc.titlesLevel.titles' | translate }}</mat-panel-title>
    </mat-expansion-panel-header>

    <div class="select-button">
      <button type="button" mat-raised-button color="primary" (click)="selectAll()" [disabled]="selectAllDisabled">
        <span [jhiTranslate]="allTitlesSelected ? 'htmlEditor.toc.titlesLevel.unselectAll' : 'htmlEditor.toc.titlesLevel.selectAll'"></span>
      </button>
    </div>
    <ul class="excluded-titles">
      <li *ngFor="let title of filteredTitles" [style.margin-left.px]="title.level * 10">
        <mat-checkbox
          color="primary"
          [checked]="!tocConfig.excludedTitles.includes(title.id)"
          [disabled]="[1, 2].includes(title.level)"
          (change)="excludeTitle(title)"
        >
          <span [innerHtml]="title.numbering | safeHtml"></span> <span [innerHtml]="title.content | safeHtml"></span>
        </mat-checkbox>
      </li>
    </ul>
  </mat-expansion-panel>
  <div class="buttons">
    <button
      type="button"
      mat-raised-button
      color="accent"
      jhiTranslate="htmlEditor.toc.titlesLevel.cancelChanges"
      (click)="cancelChanges()"
    ></button>

    <button
      type="button"
      mat-raised-button
      color="primary"
      jhiTranslate="htmlEditor.toc.titlesLevel.applyChanges"
      (click)="applyChanges()"
    ></button>
  </div>
</div>
