<h1 mat-dialog-title>
  <span jhiTranslate="dataEditor.taxonomy.dialog.validation.titleXbrl"></span>
  <button type="button" mat-icon-button (focus)="(false)" class="mat-dialog-close-btn" (click)="close()">
    <fa-icon icon="times"></fa-icon>
  </button>
</h1>

<div mat-dialog-content>
  <mat-accordion>
    <mat-expansion-panel [expanded]="panelErrorOpenState">
      <mat-expansion-panel-header>
        <mat-panel-title class="error-text">
          {{ 'dataEditor.xbrl.dialog.error.title' | translate: '{count:' + this.errors.length + '}' }}
        </mat-panel-title>
        <mat-panel-description jhiTranslate="dataEditor.xbrl.dialog.error.tip"> </mat-panel-description>
      </mat-expansion-panel-header>
      <jhi-logs-report-table [logs]="errors"></jhi-logs-report-table>
    </mat-expansion-panel>
    <mat-expansion-panel [expanded]="panelWarningOpenState">
      <mat-expansion-panel-header>
        <mat-panel-title class="warning-text">
          {{ 'dataEditor.xbrl.dialog.warning.title' | translate: '{count:' + this.warnings.length + '}' }}
        </mat-panel-title>
        <mat-panel-description jhiTranslate="dataEditor.xbrl.dialog.warning.tip"> </mat-panel-description>
      </mat-expansion-panel-header>
      <jhi-logs-report-table [logs]="warnings"></jhi-logs-report-table>
    </mat-expansion-panel>
  </mat-accordion>
</div>

<div mat-dialog-actions>
  <button
    *ngIf="!isAMFReport()"
    cdkFocusInitial
    type="submit"
    mat-raised-button
    color="primary"
    class="mat-flat-button mat-button"
    jhiTranslate="dataEditor.taxonomy.dialog.validation.validateBtn"
    (click)="close()"
  ></button>
  <button
    *ngIf="isAMFReport()"
    cdkFocusInitial
    type="submit"
    mat-raised-button
    color="primary"
    class="mat-flat-button mat-button"
    jhiTranslate="dataEditor.taxonomy.dialog.validation.download"
    (click)="download()"
  ></button>
  <button
    *ngIf="isAMFReport()"
    cdkFocusInitial
    type="submit"
    mat-raised-button
    color="secondary"
    class="mat-flat-button mat-button"
    jhiTranslate="dataEditor.taxonomy.dialog.validation.close"
    (click)="close()"
  ></button>
</div>
