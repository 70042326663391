import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, forkJoin, share, ReplaySubject } from 'rxjs';

import { SERVER_API_URL } from 'app/app.constants';
import { NumberFormat, DateFormat, LocalizeNumberFormat } from 'app/shared/model/format.model';
import { ArevioService } from './arevio.service';
import pubsub from 'app/pubsub';
import { FORMAT_ASKFORMATEDDATA, FORMAT_GOTFORMATEDDATA, FORMAT_ASKRCSFFORMATEDDATA, FORMAT_GOTRCSFFORMATEDDATA } from 'app/pubsub.topics';
import { IFact } from 'app/shared/model/fact.model';
import { flatMap, filter, mergeAll } from 'rxjs/operators';
import { RCSFCell, RCSFFile } from 'app/shared/model/rcsf.model';
import { ProjectService } from 'app/core/service/project.service';
import { IProject, Project } from 'app/shared/model/project.model';
import { XBRLType } from 'app/shared/enum/xbrl-type.enum';
import { VALUE_TYPE } from 'app/shared/enum/xslx.enum';
import { ScaleType } from 'app/shared/enum/scale-type.enum';
import { ContextService } from './context.service';
import { IMonetaryUnit } from 'app/shared/model/monetary-unit.model';

interface RcsfFormattedDataDetail {
  filename: string;
  sheetName: string;
  row: any;
  colName: string;
  formatId: string;
  editorId: string;
}

@Injectable({ providedIn: 'root' })
export class FormatService {
  public numericFormatUrl: string;
  public dateFormatUrl: string;

  private _projectConfiguration: Project;

  private numberFormatCache$?: Observable<NumberFormat[]>;
  private localizeNumberFormatCache$?: Observable<LocalizeNumberFormat>;
  private dateFormatCache$?: Observable<DateFormat[]>;

  constructor(
    private http: HttpClient,
    private contextService: ContextService,
    private projectService: ProjectService,
    private arevioService: ArevioService
  ) {
    const projectId = this.contextService.currentProjectId;
    this.numericFormatUrl = `${SERVER_API_URL}/api/${projectId}/numeric-formats`;
    this.dateFormatUrl = `${SERVER_API_URL}/api/${projectId}/date-formats`;
    this.projectService.getProjectInformation().subscribe(({ project }: IProject) => (this._projectConfiguration = project));
    // here we are on a singleton service, .off is not necessary
    pubsub.on(
      FORMAT_ASKFORMATEDDATA,
      ({ detail }: CustomEvent<{ factXbrlId: string; formatId: string; editorId: string; factIndex: number }>) => {
        // detail.factXbrlId could be a multi-indexed fact id, search the first fact in the list
        const firstFactXbrlId = detail.factXbrlId.split(',')[0];
        this.arevioService.getFactById(firstFactXbrlId).subscribe((fact: IFact) => {
          this.formatFormattedData(detail, fact);
        });
      }
    );
    pubsub.on(FORMAT_ASKRCSFFORMATEDDATA, ({ detail }: CustomEvent<RcsfFormattedDataDetail>) => {
      this.arevioService.getRcsfSheetData(detail.filename, detail.sheetName).subscribe((file: RCSFFile) => {
        this.formatRcsfFormattedData(detail, file);
      });
    });
  }

  private formatFormattedData(detail: { factXbrlId: string; formatId: string; editorId: string; factIndex: number }, fact: IFact): void {
    const formatId = detail.formatId ? `/${detail.formatId}` : '';
    // pubsub event use the full factXbrlId, keep "detail.factXbrlId"
    const topic = `${FORMAT_GOTFORMATEDDATA}/${detail.factXbrlId}${formatId}`;
    if (fact) {
      const numFormatId = parseInt(detail.formatId, 10);
      switch (fact.concept?.type) {
        case XBRLType.MONETARY:
        case XBRLType.PER_SHARE:
        case XBRLType.PER_SHARE_2021:
        case XBRLType.SHARES:
        case XBRLType.DECIMAL:
          forkJoin([this.getNumericFormats(), this.getDefaultFormats(), this.projectService.getProjectMonetaryUnits()]).subscribe(
            ([formats, [numericDefaultFormat], projectUnits]) => {
              let format = formats.find(_format => _format.id === numFormatId) ?? numericDefaultFormat;
              if (format.id === numFormatId) {
                this.fireFactNumberFormat(topic, fact, format, projectUnits, detail);
              } else {
                // MANTIS#194890 : format changed by a concurrent web instance
                this.getNumericFormats(true).subscribe(refreshedFormats => {
                  format = refreshedFormats.find(_format => _format.id === numFormatId) ?? numericDefaultFormat;
                  this.fireFactNumberFormat(topic, fact, format, projectUnits, detail);
                });
              }
            }
          );
          break;
        case XBRLType.DATE:
          forkJoin([this.getDateFormats(), this.getDefaultFormats(), this.projectService.getProjectMonetaryUnits()]).subscribe(
            ([formats, [, dateDefaultFormat], projectUnits]) => {
              let format = formats.find(_format => _format.id === numFormatId) ?? dateDefaultFormat;
              if (format.id === numFormatId) {
                pubsub.fire(topic, { fact, format, projectUnits }, detail.editorId);
              } else {
                // MANTIS#194890 : format changed by a concurrent web instance
                this.getDateFormats(true).subscribe(refreshedFormats => {
                  format = refreshedFormats.find(_format => _format.id === numFormatId) ?? dateDefaultFormat;
                  pubsub.fire(topic, { fact, format, projectUnits }, detail.editorId);
                });
              }
            }
          );
          break;
        default:
          // factIndex for STRING Fact multi zone
          fact.factIndex = detail.factIndex;
          pubsub.fire(topic, { fact }, detail.editorId);
          break;
      }
    } else {
      pubsub.fire(topic, { fact: null }, detail.editorId);
    }
  }

  private formatRcsfFormattedData(detail: RcsfFormattedDataDetail, file: RCSFFile): void {
    const formatId = detail.formatId ? `/${detail.formatId}` : '';
    const topic = `${FORMAT_GOTRCSFFORMATEDDATA}/${detail.filename}/${detail.sheetName}/${detail.row}/${detail.colName}${formatId}`;
    const cell = file.sheets[0]?.rows.find(row => row.rowIdx === detail.row)?.cols.find(col => col.colName === detail.colName);
    const isSheetExist = !!file.sheets[0]?.sheetName;
    const numFormatId = parseInt(detail.formatId, 10);
    switch (cell?.valueType) {
      case VALUE_TYPE.NUMERIC:
        forkJoin([this.getNumericFormats(), this.getDefaultFormats()]).subscribe(([formats, [numericDefaultFormat]]) => {
          let format = formats.find(_format => _format.id === numFormatId) ?? numericDefaultFormat;
          if (format.id === numFormatId) {
            this.fireNumberFormat(topic, cell, format, detail, isSheetExist);
          } else {
            // MANTIS#194890 : format changed by a concurrent web instance
            this.getNumericFormats(true).subscribe(refreshedFormats => {
              format = refreshedFormats.find(_format => _format.id === numFormatId) ?? numericDefaultFormat;
              this.fireNumberFormat(topic, cell, format, detail, isSheetExist);
            });
          }
        });
        break;
      case VALUE_TYPE.DATE:
        forkJoin([this.getDateFormats(), this.getDefaultFormats()]).subscribe(([formats, [, dateDefaultFormat]]) => {
          let format = formats.find(_format => _format.id === numFormatId) ?? dateDefaultFormat;
          if (format.id === numFormatId) {
            pubsub.fire(topic, { cell, format, isSheetExist }, detail.editorId);
          } else {
            // MANTIS#194890 : format changed by a concurrent web instance
            this.getDateFormats(true).subscribe(refreshedFormats => {
              format = refreshedFormats.find(_format => _format.id === numFormatId) ?? dateDefaultFormat;
              pubsub.fire(topic, { cell, format, isSheetExist }, detail.editorId);
            });
          }
        });
        break;
      default:
        pubsub.fire(topic, { cell, isSheetExist }, detail.editorId);
        break;
    }
  }

  private fireNumberFormat(topic: string, cell: RCSFCell, format: NumberFormat, detail: any, isSheetExist: boolean): void {
    pubsub.fire(
      topic,
      {
        cell,
        isSheetExist,
        format,
        defaultDecimalsSeparator: this._projectConfiguration.decimalsSeparator,
        defaultGroupingSeparator: this._projectConfiguration.groupingSeparator,
        defaultPublicationScale:
          this._projectConfiguration.scaleType === ScaleType.PUBLICATION_SCALE
            ? cell.importScale
            : this._projectConfiguration.publicationScale,
      },
      detail.editorId
    );
  }

  private fireFactNumberFormat(topic: string, fact: IFact, format: NumberFormat, projectUnits: IMonetaryUnit[], detail: any): void {
    pubsub.fire(
      topic,
      {
        fact,
        format,
        defaultDecimalsSeparator: this._projectConfiguration.decimalsSeparator,
        defaultGroupingSeparator: this._projectConfiguration.groupingSeparator,
        defaultPublicationScale:
          this._projectConfiguration.scaleType === ScaleType.PUBLICATION_SCALE ? 0 : this._projectConfiguration.publicationScale,
        projectUnits,
      },
      detail.editorId
    );
  }

  public getNumericFormats(reset = false): Observable<NumberFormat[]> {
    if (!this.numberFormatCache$ || reset) {
      this.numberFormatCache$ = this.http.get<NumberFormat[]>(`${this.numericFormatUrl}`).pipe(
        share({
          connector: () => new ReplaySubject(1),
          resetOnError: false,
          resetOnComplete: false,
          resetOnRefCountZero: true,
        })
      );
    }
    return this.numberFormatCache$;
  }

  public getDateFormats(reset = false): Observable<DateFormat[]> {
    if (!this.dateFormatCache$ || reset) {
      this.dateFormatCache$ = this.http.get<DateFormat[]>(`${this.dateFormatUrl}`).pipe(
        share({
          connector: () => new ReplaySubject(1),
          resetOnError: false,
          resetOnComplete: false,
          resetOnRefCountZero: true,
        })
      );
    }
    return this.dateFormatCache$;
  }

  public getDefaultFormats(): Observable<[NumberFormat, DateFormat]> {
    return this.projectService.getProjectInformation().pipe(
      flatMap(({ project }: IProject) => {
        return forkJoin([
          this.getNumericFormats().pipe(
            mergeAll(),
            filter(num => num.id === project.defaultNumericFormatId)
          ),
          this.getDateFormats().pipe(
            mergeAll(),
            filter(date => date.id === project.defaultDateFormatId)
          ),
        ]);
      })
    );
  }

  public addNumericFormat(format: NumberFormat, displayAlert = false): Observable<NumberFormat> {
    delete this.numberFormatCache$;
    const projectId: number = this.contextService.currentProjectId;
    const params: HttpParams = new HttpParams().set('skipError', 'true').set('displayAlert', `${displayAlert}`);

    if (format.id) {
      return this.http.put<NumberFormat>(this.numericFormatUrl, { ...format, projectId }, { params });
    }
    return this.http.post<NumberFormat>(this.numericFormatUrl, { ...format, projectId }, { params });
  }

  public deleteNumericFormat(formatId: number): Observable<any> {
    delete this.numberFormatCache$;
    return this.http.delete(`${this.numericFormatUrl}/${formatId}`);
  }

  public addDateFormat(format: DateFormat, displayAlert = false): Observable<DateFormat> {
    delete this.dateFormatCache$;
    const projectId: number = this.contextService.currentProjectId;
    const params: HttpParams = new HttpParams().set('skipError', 'true').set('displayAlert', `${displayAlert}`);

    if (format.id) {
      return this.http.put<DateFormat>(this.dateFormatUrl, { ...format, projectId }, { params });
    }
    return this.http.post<DateFormat>(this.dateFormatUrl, { ...format, projectId }, { params });
  }

  public deleteDateFormat(formatId: number): Observable<any> {
    delete this.dateFormatCache$;
    return this.http.delete(`${this.dateFormatUrl}/${formatId}`);
  }

  public localizeNumberFormat(): Observable<LocalizeNumberFormat> {
    if (!this.localizeNumberFormatCache$) {
      this.localizeNumberFormatCache$ = this.http.get<LocalizeNumberFormat>(`${SERVER_API_URL}/api/format/numeric-patterns`).pipe(
        share({
          connector: () => new ReplaySubject(1),
          resetOnError: false,
          resetOnComplete: false,
          resetOnRefCountZero: true,
        })
      );
    }
    return this.localizeNumberFormatCache$;
  }
}
