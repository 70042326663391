import { Pipe, PipeTransform } from '@angular/core';
import { XBRLType } from '../enum/xbrl-type.enum';
import { SpreadsheetUtils } from '../util/spreadsheet-utils';
import { VALUE_TYPE } from '../enum/xslx.enum';
import { TranslateService } from '@ngx-translate/core';
import { DecimalPipe } from '@angular/common';
import { AccountService } from 'app/core/auth/account.service';

@Pipe({
  name: 'xbrlFactValue',
  pure: true,
})
export class XbrlFactValuePipe implements PipeTransform {
  constructor(private translateService: TranslateService, private accountService: AccountService) {}

  transform(value: string | undefined, type: XBRLType): string {
    if (!value) {
      return '';
    }
    switch (type) {
      case XBRLType.MONETARY:
      case XBRLType.PER_SHARE:
      case XBRLType.PER_SHARE_2021:
      case XBRLType.SHARES:
      case XBRLType.DECIMAL:
        return new DecimalPipe(this.translateService.currentLang).transform(value, '1.0-2') ?? value;
      case XBRLType.DATE:
        return SpreadsheetUtils.manageDateISO8601(value, VALUE_TYPE.DATE, this.accountService.getLocale());
      default:
        return value;
    }
  }
}
