import { Component } from '@angular/core';
import { Observable } from 'rxjs';

import { AccountService } from 'app/core/auth/account.service';
import { NavbarService } from './navbar.service';
import { ConfigurationService } from 'app/core/service/configuration.service';
import { ContextService } from 'app/core/service/context.service';
import { Router } from '@angular/router';
import { Authority } from 'app/shared/enum/authority.enum';

@Component({
  selector: 'jhi-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent {
  public cockpitURL: string;
  public isNavbarOpened$: Observable<boolean>;
  public readonly Authority = Authority;

  constructor(
    private accountService: AccountService,
    private navbarService: NavbarService,
    private configurationService: ConfigurationService,
    public contextService: ContextService,
    public router: Router
  ) {
    this.isNavbarOpened$ = this.navbarService.isNavbarOpened();
    this.configurationService.getConfiguration().subscribe(data => {
      this.cockpitURL = data.sppUri;
    });
  }

  public navigateToDocumentRoute(path: string): void {
    if (this.contextService.currentDocumentContext?.id) {
      this.router.navigate([`/document/${this.contextService.currentDocumentContext.id}/${path}`]).finally(() => this.collapseNavbar());
    }
  }

  public isAuthenticated(): boolean {
    return this.accountService.isAuthenticated();
  }

  public toggleNavbar(): void {
    this.navbarService.toggleNavbar();
  }

  public collapseNavbar(): void {
    this.navbarService.collapseNavbar();
  }
}
