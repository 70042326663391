import { Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy, Renderer2, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { ContextService } from 'app/core/service/context.service';
import { DialogService } from 'app/core/service/dialog.service';
import { Subscription, forkJoin, Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';

import { PluginComponent } from '../plugin.component';
import { PluginPanelService } from '../plugin-panel.service';
import { EDITOR_PLUGIN_INSERTIMAGE, EDITOR_PLUGIN_TOGGLEPANEL } from 'app/pubsub.topics';
import pubsub from 'app/pubsub';

import { IAsset } from 'app/shared/model/asset.model';
import { ImageFolder } from 'app/shared/enum/image-folder.enum';
import { FileService } from 'app/core/service/file.service';
import { MatDialog } from '@angular/material/dialog';
import { FrontErrorComponent } from 'app/shared/dialog/error/frontError/front-error.component';
import { OverlayContainer } from '@angular/cdk/overlay';
import { ElementTrackingService } from 'app/core/service/element-tracking.service';
import { DeleteImageIdConfirmation } from 'app/shared/model/delete-image.confirmation.model';
import { PLUGIN_PANEL_COMPONENT_KEYS } from 'app/html-editor/plugins/plugin-panel-component-keys';
import { ImagePlaceholderService, PlaceHolderValue } from '../image-placeholder/image-placeholder.service';
import { ImageUsageDialogComponent } from './image-usage-dialog/image-usage-dialog.component';
import { ImageMoveFolderDialogComponent } from './image-move-folder-dialog/image-move-folder-dialog.component';
import { Authority } from 'app/shared/enum/authority.enum';
import { AccountService } from 'app/core/auth/account.service';

@Component({
  selector: 'jhi-plugin-image-picker',
  templateUrl: './image-picker.component.html',
  styleUrls: ['./image-picker.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImagePickerPluginComponent extends PluginComponent implements OnInit, OnDestroy {
  public readonly Authority = Authority;
  public readonly ImageFolder = ImageFolder;

  public folders: ImageFolder[] = [ImageFolder.GENERAL, ImageFolder.PROJECT];
  public languageFolders: string[] = [];
  public currentFolder: string | ImageFolder;
  public filterText: string;
  public deletePopupModel: DeleteImageIdConfirmation = new DeleteImageIdConfirmation();
  public enableImageDeletion = false;
  public enableImageMove = false;
  public enableImageDownload = false;
  public checkNumberOfUses = false;
  public loading = false;

  public hasGeneralRights = false;

  public images: IAsset[] = [];
  public buttonBack = 'htmlEditor.plugins.imagePlaceholder.backToPlaceholder';
  public isModePlaceholder = false;
  public currentPlaceholder: PlaceHolderValue;
  public placeholderImage: IAsset;
  private placeholderSubscription: Subscription;

  constructor(
    pluginPanelService: PluginPanelService,
    private contextService: ContextService,
    private fileService: FileService,
    private dialog: MatDialog,
    private dialogService: DialogService,
    private overlayContainer: OverlayContainer,
    private renderer: Renderer2,
    private elementTrackingService: ElementTrackingService,
    private imagePlaceholderService: ImagePlaceholderService,
    private cdRef: ChangeDetectorRef,
    protected accountService: AccountService
  ) {
    super(pluginPanelService);
  }

  ngOnInit(): void {
    this.currentPlaceholder = this.data?.placeholder;
    this.isModePlaceholder = !!this.data?.placeholder;
    this.updateButtonBack();
    this.placeholderSubscription = this.imagePlaceholderService.getDataObservable().subscribe((data: PlaceHolderValue) => {
      this.currentPlaceholder = data;
      this.isModePlaceholder = !!data?.placeholder;
      this.updateButtonBack();
      this.cdRef.markForCheck();
    });

    // Mantis#0003515: Only GT and Admin can add and delete images from GENERAL folder
    this.hasGeneralRights =
      this.accountService.hasAnyAuthority(Authority.LAB_ADMIN) || this.accountService.hasAnyAuthority(Authority.LAB_GT);

    this.currentFolder = ImageFolder.PROJECT;
    this.filterText = '';
    forkJoin([this.fileService.getPlaceholderImage(), this.getImagesList()]).subscribe(([placeholder, images]: [IAsset, IAsset[]]) => {
      this.placeholderImage = placeholder;
      this.setImagesList(images);
      this.changeFolder();
    });

    this.fileService.getImagesFolders().subscribe((languageFolders: string[]) => {
      this.languageFolders = languageFolders;
      this.cdRef.markForCheck();
    });

    // disable animation on overlayContainer to prevent animation on mat-select panel that is not
    // ok on all browsers because of overriding css we made to force the panel having the same width as
    // the mat-select field
    this.renderer.setProperty(this.overlayContainer.getContainerElement(), '@.disabled', true);
  }

  ngOnDestroy(): void {
    this.renderer.setProperty(this.overlayContainer.getContainerElement(), '@.disabled', false);
    this.placeholderSubscription.unsubscribe();
  }

  private updateButtonBack(): void {
    if (this.currentPlaceholder) {
      const isPlaceholder =
        this.currentPlaceholder.placeholder === 'true' || Number(this.currentPlaceholder.damId) === this.placeholderImage?.id;
      this.buttonBack = `htmlEditor.plugins.imagePlaceholder.${isPlaceholder ? 'backToPlaceholder' : 'backToImageProperties'}`;
    }
  }

  public changeFolder(): void {
    this.enableImageDeletion = false;
    this.enableImageDownload = false;
    this.enableImageMove = false;

    if (
      (((this.currentFolder as ImageFolder) === ImageFolder.PROJECT || this.fileService.isLanguageFolder(this.currentFolder)) &&
        this.accountService.hasAnyAuthority(Authority.INSERT_IMAGE)) ||
      ((this.currentFolder as ImageFolder) === ImageFolder.GENERAL && this.hasGeneralRights)
    ) {
      this.enableImageDeletion = true;
    }

    if (
      ((this.currentFolder as ImageFolder) === ImageFolder.PROJECT || this.fileService.isLanguageFolder(this.currentFolder)) &&
      this.accountService.hasAnyAuthority(Authority.INSERT_IMAGE)
    ) {
      this.enableImageDownload = true;
    }

    if (
      ((this.currentFolder as ImageFolder) === ImageFolder.PROJECT || this.fileService.isLanguageFolder(this.currentFolder)) &&
      this.accountService.hasAnyAuthority(Authority.MOVE_IMAGE)
    ) {
      this.enableImageMove = true;
    }

    this.getImagesList().subscribe(data => this.setImagesList(data));
  }

  private getImagesList(): Observable<IAsset[]> {
    this.loadingList();
    return this.fileService.getImages(this.currentFolder, true).pipe(finalize(() => (this.loading = false)));
  }

  private setImagesList(data: IAsset[]): void {
    this.images = data;
    this.cdRef.markForCheck();
  }

  private loadingList(): void {
    this.loading = true;
    this.setImagesList([]);
  }

  /*
    check if the current folder (and by deduction the asset) respect document language rules
    this check must be done before insert or replace an asset in the document
  */
  private _isCurrentFolderValid(): boolean {
    if (this.fileService.isLanguageFolder(this.currentFolder) && this.currentFolder !== this.contextService.twoLettersLanguage) {
      this.dialog.open(FrontErrorComponent, { data: 'image-picker-file-language-not-valid' });
      return false;
    }
    return true;
  }

  public addImageToDoc(asset: IAsset): void {
    if (!this._isCurrentFolderValid()) {
      return;
    }
    if (!this.isModePlaceholder && asset.id) {
      const src = this.getAssetUrl(asset.id);
      const damId = `${asset.id}`;
      const name = `${asset.filename}`;
      const placeholder = (this.isModePlaceholder = asset.id === this.placeholderImage?.id);
      pubsub.fire(EDITOR_PLUGIN_INSERTIMAGE, { src, damId, placeholder, name }, this.getEditorTopicContext());
    }
  }

  public uploadFile(files: FileList): void {
    if (!files?.length) {
      return;
    }
    this.loadingList();
    this.fileService.uploadImage(this.currentFolder, files, this.languageFolders).subscribe((response: any) => {
      if (response.status === 200) {
        this.changeFolder();
      }

      if (response.errorFileFormat) {
        this.dialog.open(FrontErrorComponent, { data: 'image-picker-file-format-not-valid' });
        this.changeFolder();
      } else if (response.errorLanguageFolder) {
        this.dialog.open(FrontErrorComponent, { data: 'image-picker-file-folder-not-valid' });
        this.changeFolder();
      }
    });
  }

  public deleteImage(event: Event, asset: IAsset): void {
    event.stopPropagation();
    const assetId = asset.id;
    if (!assetId) {
      return;
    }
    this.checkNumberOfUses = true;
    this.elementTrackingService
      .getImagesCount(assetId)
      .pipe(finalize(() => (this.checkNumberOfUses = false)))
      .subscribe(images => {
        asset.count = images.reduce((total, image) => total + image.counter, 0);
        if (asset.count > 0) {
          this.deletePopupModel.imageCounter = asset.count;
          this.deletePopupModel.deleteImageIdConfirmation = assetId;
          this.cdRef.markForCheck();
        } else {
          this.deleteImageAsset(asset);
        }
      });
  }

  public downloadImage(event: Event, asset: IAsset): void {
    event.stopPropagation();
    if (!asset.id) {
      return;
    }
    this.fileService.downloadImage(asset.id);
  }

  public confirmImageDeletion(event: Event, asset: IAsset): void {
    event.stopPropagation();
    this.deleteImageAsset(asset);
  }

  private deleteImageAsset(asset: IAsset): void {
    this.loadingList();
    if (!asset.id) {
      return;
    }
    this.fileService.deleteImageAsset(asset.id, this.currentFolder).subscribe(() => {
      this.deletePopupModel.deleteImageIdConfirmation = 0;
      this.changeFolder();
      this.imagePlaceholderService.deleteImage(this.placeholderImage, asset);
    });
  }

  public cancelImageDeletion(event: Event, imageId: number): void {
    event.stopPropagation();
    if (this.deletePopupModel.deleteImageIdConfirmation === imageId) {
      this.deletePopupModel.deleteImageIdConfirmation = 0;
    }
  }

  public getAssetUrl(assetId: number): string {
    return `${this.fileService.getRootAssetUrl()}${assetId}`;
  }

  public backToPlaceholder(): void {
    pubsub.fire(EDITOR_PLUGIN_TOGGLEPANEL, {
      component: PLUGIN_PANEL_COMPONENT_KEYS.IMAGE_PLACE_HOLDER,
      title: `htmlEditor.plugins.imagePlaceholder.${this.currentPlaceholder?.placeholder === 'true' ? 'title' : 'titleImage'}`,
    });
  }

  public replacePlaceholder(event: Event, asset: IAsset, isPlaceholder = false): void {
    event.stopPropagation();
    if (!this._isCurrentFolderValid()) {
      return;
    }
    if (asset) {
      this.imagePlaceholderService.setEditorId(this.getEditorTopicContext());
      this.imagePlaceholderService.replaceImage(asset, isPlaceholder);
      this.cdRef.markForCheck();
    }
  }

  public moveImage(event: Event, asset: IAsset): void {
    event.stopPropagation();
    const moveDialog = this.dialog.open(ImageMoveFolderDialogComponent, {
      width: '700px',
      data: {
        currentFolder: this.currentFolder,
        languageFolders: this.languageFolders,
        asset,
      },
    });
    // refresh folder after moving an image
    moveDialog.afterClosed().subscribe(result => {
      if (result) {
        this.changeFolder();
      }
    });
  }

  public showUsage(event: Event, asset: IAsset): void {
    event.stopPropagation();
    this.dialog.open(ImageUsageDialogComponent, {
      width: '700px',
      data: asset,
    });
  }
}
