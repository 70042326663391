import { Directive, Output, EventEmitter, HostListener, Input, HostBinding } from '@angular/core';

@Directive({
  selector: '[jhiAppDnd]',
  exportAs: 'DnDDirective',
})
export class DndDirective {
  @HostBinding('class.dnd-disabled') @Input() disabled = false;

  @Output() fileDropped = new EventEmitter<any>();
  public isHighlighted = false;

  @HostListener('dragover', ['$event']) public onDragOver(evt: DragEvent): void {
    this.isHighlighted = true;
    evt.preventDefault();
    evt.stopPropagation();
  }

  @HostListener('dragleave', ['$event']) public onDragLeave(evt: DragEvent): void {
    this.isHighlighted = false;
    evt.preventDefault();
    evt.stopPropagation();
  }

  @HostListener('drop', ['$event']) public ondrop(evt: DragEvent): void {
    evt.preventDefault();
    evt.stopPropagation();
    if (!this.disabled) {
      this.isHighlighted = false;
      const files = evt.dataTransfer?.files ?? [];
      if (files.length > 0) {
        this.fileDropped.emit(files);
      }
    }
  }
}
