export enum StyleProperty {
  FONT_FAMILY = 'font-family',
  POSITION = 'position',
  TOP = 'top',
  BOTTOM = 'bottom',
  COLOR = 'color',
  FONT_SIZE = 'font-size',
  OPACITY = 'opacity',
  LINE_HEIGHT = 'line-height',
  LETTER_SPACING = 'letter-spacing',
  FONT_WEIGHT = 'font-weight',
  FONT_STYLE = 'font-style',
  TEXT_DECORATION = 'text-decoration',
  TEXT_TRANSFORM = 'text-transform',
  TEXT_ALIGN = 'text-align',
  TEXT_INDENT = 'text-indent',
  VERTICAL_ALIGN = 'vertical-align',
  MARGIN_TOP = 'margin-top',
  MARGIN_RIGHT = 'margin-right',
  MARGIN_BOTTOM = 'margin-bottom',
  MARGIN_LEFT = 'margin-left',
  PADDING_TOP = 'padding-top',
  PADDING_RIGHT = 'padding-right',
  PADDING_BOTTOM = 'padding-bottom',
  PADDING_LEFT = 'padding-left',
  Z_INDEX = 'z-index',
  COLUMN_COUNT = 'column-count',
  LIST_BULLET = 'list-style-type',
  CONTENT = 'content',
  COUNTER_INCREMENT = 'counter-increment',
  COUNTER_RESET = 'counter-reset',
  WIDTH = 'width',
  MIN_WIDTH = 'min-width',
  HEIGHT = 'height',
  HEIGHT_VAR = '--row-height',
  MIN_HEIGHT = 'min-height',
  DISPLAY = 'display',
  BACKGROUND_COLOR = 'background-color',
  COLUMN_GAP = 'column-gap',
  COLUMN_RULE_WIDTH = 'column-rule-width',
  COLUMN_RULE_COLOR = 'column-rule-color',
  COLUMN_RULE_STYLE = 'column-rule-style',
  BORDER_TOP_WIDTH = 'border-top-width',
  BORDER_LEFT_WIDTH = 'border-left-width',
  BORDER_BOTTOM_WIDTH = 'border-bottom-width',
  BORDER_RIGHT_WIDTH = 'border-right-width',
  BORDER_TOP_COLOR = 'border-top-color',
  BORDER_LEFT_COLOR = 'border-left-color',
  BORDER_BOTTOM_COLOR = 'border-bottom-color',
  BORDER_RIGHT_COLOR = 'border-right-color',
  BORDER_STYLE = 'border-style',
  BORDER_COLOR = 'border-color',
  BORDER_WIDTH = '--border-width',
  BORDER_CORNER_LINKED = '--border-corner-linked',
  BORDER_TOP_LEFT_STYLE = '--border-top-left-style',
  BORDER_TOP_RIGHT_STYLE = '--border-top-right-style',
  BORDER_BOTTOM_LEFT_STYLE = '--border-bottom-left-style',
  BORDER_BOTTOM_RIGHT_STYLE = '--border-bottom-right-style',
  BORDER_TOP_LEFT_RADIUS = 'border-top-left-radius',
  BORDER_TOP_RIGHT_RADIUS = 'border-top-right-radius',
  BORDER_BOTTOM_LEFT_RADIUS = 'border-bottom-left-radius',
  BORDER_BOTTOM_RIGHT_RADIUS = 'border-bottom-right-radius',
  MARKER = '--marker',
  MARKER_SUFFIX = '--marker-suffix',
  MARKER_PREFIX = '--marker-prefix',
  MARKER_TYPE = '--marker-type',
  FLEX_BASIS = 'flex-basis',
  FLEX_GROW = 'flex-grow',
  COLUMN_SPAN = 'column-span',
  COLUMN_BREAK_AFTER = 'break-after',
  JUSTIFY_CONTENT = 'justify-content',
}

export enum BORDER_STYLE {
  NONE = 'none',
  SOLID = 'solid',
  DASHED = 'dashed',
  DOTTED = 'dotted',
}

export enum TEXT_ALIGN {
  LEFT = 'left',
  CENTER = 'center',
  RIGHT = 'right',
  JUSTIFY = 'justify',
}

export const BorderStyleValues = [BORDER_STYLE.NONE, BORDER_STYLE.DOTTED, BORDER_STYLE.DASHED, BORDER_STYLE.SOLID];

export enum CORNER_STYLE {
  RIGHT = 'right',
  ROUND = 'round',
}
