import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { MarginModel } from 'app/shared/model/margin.model';

class MarginDisabled {
  top: boolean;
  right: boolean;
  bottom: boolean;
  left: boolean;
}
@Component({
  selector: 'jhi-margin-input',
  templateUrl: 'margin-input.component.html',
  styleUrls: ['./margin-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MarginInputComponent {
  @Input() label: string;
  @Input() revertNullToZero: boolean;
  @Input() marginDisabled: MarginDisabled = { top: false, right: false, bottom: false, left: false };

  @Input()
  get margin(): MarginModel {
    return this._margin;
  }
  set margin(value: MarginModel) {
    this._margin = value;
    this.changeDetector.markForCheck();
  }
  private _margin: MarginModel;

  @Output() updateMargin = new EventEmitter<MarginModel>();

  constructor(private changeDetector: ChangeDetectorRef) {}

  onUpdateMargin(value: number, prop: string): void {
    if (Object.prototype.hasOwnProperty.call(this.margin, prop)) {
      this.margin[prop] = value;
      this.updateMargin.emit(this.margin);
    }
  }

  reset(): void {
    this.updateMargin.emit(undefined);
    this.changeDetector.markForCheck();
  }
}
