import { Moment } from 'moment';
import { IDocumentaryUnitAuthor } from './documentary-unit-content.model';

export enum UpdateTypes {
  TEXT,
  SHAPING,
  NONE,
}

export interface ICommitMessage {
  users: IDocumentaryUnitAuthor[];
  types: UpdateTypes[];
}

export interface ICommit {
  sha1: string;
  author: string;
  date: string | Moment;
  branch: string;
  message: ICommitMessage;
  diff?: string;
}

export interface IFilters {
  since: string;
  until: string;
  untilFilledByUser: boolean;
  typesModification: string[];
  users: string[];
  sections: number[];
  currentLang: string;
  pageNumber?: number;
  pageSize?: number;
}

export interface IVersionTrackingResult {
  modificationDate: string;
  user: string;
  section: number | string;
  date: string;
  commitIsImportWord: boolean;
  modificationType: string[];
}

export interface IStickerModificationsDocument {
  stickerModificationByDates: Map<string, IStickerModification[]>;
  stickerHtmlDocument: string;
}

export interface IStickerModification {
  modificationDate: string;
  user: string;
  sectionId: number | string;
  editType: string;
  number: number;
  diff: string;
  sha1: string;
  htmlDocument: string;
}
