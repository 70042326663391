import { Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import pubsub from 'app/pubsub';
import { EDITOR_PLUGIN_CLICK_IMPORT_BTN, EDITOR_PLUGIN_EXECUTE, EDITOR_PLUGIN_ONGOING_IMPORT } from 'app/pubsub.topics';
import { SERVER_API_URL } from 'app/app.constants';

import { PluginPanelService } from '../plugin-panel.service';
import { DocumentaryUnitService } from 'app/core/service/documentary-unit.service';
import { ContextService } from 'app/core/service/context.service';
import { PluginComponent } from '../plugin.component';
import { IDocumentaryUnitContent } from 'app/shared/model/documentary-unit-content.model';

import { HttpClient } from '@angular/common/http';
import { FileService } from 'app/core/service/file.service';
import { EditorService } from 'app/core/service/editor.service';
import { IImportUploadReponse } from 'app/shared/model/import-upload.model';
import { PluginsCommand } from '../plugins-commands';
import { catchError, finalize } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { LoaderService } from 'app/core/service/loader.service';
import { ICKDocument } from 'app/core/user/ck-config.model';
import RealTimeCollaborationClient from '@ckeditor/ckeditor5-real-time-collaboration/src/realtimecollaborativeediting/realtimecollaborationclient';

@Component({
  selector: 'jhi-plugin-import-file',
  templateUrl: './import-file.component.html',
  styleUrls: ['./import-file.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImportFileComponent extends PluginComponent implements OnInit {
  public fileToUpload: File | null;
  public importResponse: IImportUploadReponse | null = null;
  public loading = true;
  public hasError = false;
  public documentaryUnitContent: IDocumentaryUnitContent;
  public resourceUrl: string;

  constructor(
    public pluginPanelService: PluginPanelService,
    public fileService: FileService,
    public documentaryUnitService: DocumentaryUnitService,
    private changeDetectorRef: ChangeDetectorRef,
    private contextService: ContextService,
    private http: HttpClient,
    private loadingService: LoaderService
  ) {
    super(pluginPanelService);
    this.resourceUrl = `${SERVER_API_URL}api/documents/${this.contextService.currentDocumentContext.id}/documentary-units`;
  }

  public ngOnInit(): void {
    this.fileToUpload = this.data.fileToUpload;
    if (this.fileToUpload) {
      this.fileService
        .uploadPrepaCopyFile(this.fileToUpload)
        .pipe(
          catchError((error: any) => {
            this.loading = false;
            this.hasError = true;
            this.changeDetectorRef.markForCheck();
            return throwError(error);
          })
        )
        .subscribe((response: IImportUploadReponse) => {
          this.loading = false;
          this.importResponse = response;
          this.changeDetectorRef.markForCheck();
        });
    }
  }

  public onClickImportBtn(): void {
    this.loadingService.show();
    pubsub.fire(EDITOR_PLUGIN_ONGOING_IMPORT, {
      ongoing: true,
    });

    // Why a setTimeout here ? It's to display the loader, without it, the loader is not displayed ¯\(°_o)/¯
    setTimeout(() => {
      pubsub.fire(
        EDITOR_PLUGIN_EXECUTE,
        {
          command: PluginsCommand.IMPORT_FILE,
          params: { elements: this.importResponse?.elements },
        },
        this.getEditorTopicContext()
      );

      const currentDocumentaryUnitContent: IDocumentaryUnitContent = {
        ...((this.editor.config.get('document') as ICKDocument).documentaryUnitContent as IDocumentaryUnitContent),
      };
      currentDocumentaryUnitContent.content = this.editor.getData();
      currentDocumentaryUnitContent.cloudDocumentVersion = (this.editor.plugins.get(
        'RealTimeCollaborationClient'
      ) as RealTimeCollaborationClient).cloudDocumentVersion;

      this.http
        .put<IDocumentaryUnitContent>(`${this.resourceUrl}/collaboration/import`, {
          sectionId: EditorService.getSectionId(this.getEditorTopicContext()),
          documentaryUnit: currentDocumentaryUnitContent,
          message: '',
        })
        .pipe(
          finalize(() => {
            this.loadingService.hide();
            pubsub.fire(EDITOR_PLUGIN_ONGOING_IMPORT, {
              ongoing: false,
            });
          })
        )
        .subscribe();

      this.closePanel();
    });
  }

  public onClickNewImportFilteBtn(): void {
    pubsub.fire(EDITOR_PLUGIN_CLICK_IMPORT_BTN, {}, this.getEditorTopicContext());

    this.onClickClosePanel();
  }
}
