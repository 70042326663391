<div class="plugin-container dynamic-data-viewer">
  <ng-container *ngIf="displayAttachXbrlFact">
    <jhi-xbrl-data-tagger
      [fact]="factToAttach"
      [filteredType]="filteredType"
      (factUpdated)="updateFact($event)"
      (stepUpdated)="updateStep()"
    >
    </jhi-xbrl-data-tagger>
    <div class="alert alert-warning" *ngIf="!!warningMessage">
      <div class="warning-message">
        <span>{{ warningMessage }}</span>
      </div>
    </div>
    <div class="action-buttons">
      <button mat-raised-button color="accent" (click)="cancelAttachXbrlFact()">
        <span jhiTranslate="htmlEditor.plugins.dynamicData.viewer.cancel"></span>
      </button>
      <button mat-raised-button color="primary" [disabled]="!isFactToAttachValid" (click)="attachXbrlFact()">
        <span jhiTranslate="htmlEditor.plugins.dynamicData.viewer.save"></span>
      </button>
    </div>
  </ng-container>
  <div *ngIf="selectedFact && !displayAttachXbrlFact">
    <jhi-xbrl-data-viewer
      [eolData]="false"
      [showFilter]="true"
      [fact]="selectedFact"
      [factIdList]="selectedFactIdList"
      [formatAttributes]="formatAttributes"
      (selectFactDirection)="goToDynamicData($event)"
      (scrollToAnnotation)="scrollToAnnotation($event)"
      [hasAnnotationsList]="true"
      (goToEditFormat)="goToEditFormat()"
      (goToAttachXbrlFact)="goToAttachXbrlFact()"
      (detachXbrlFact)="detachXbrlFact($event)"
      [isEditable]="isEditable"
    >
    </jhi-xbrl-data-viewer>
  </div>
  <div *ngIf="selectedDynamicData">
    <jhi-dynamic-data-viewer
      [showFilter]="true"
      [data]="selectedDynamicData"
      [formatAttributes]="formatAttributes"
      (selectDataDirection)="goToDynamicData($event)"
    >
    </jhi-dynamic-data-viewer>
  </div>
  <div class="action-buttons" *ngIf="!selectedData && isEditable && !selectedFact">
    <button mat-raised-button color="primary" (click)="goToEditFormat()">
      <span jhiTranslate="htmlEditor.plugins.dynamicData.viewer.edit"></span>
    </button>
  </div>
  <div *ngIf="selectedData">
    <jhi-dynamic-data-formater
      [editor]="editor"
      (resetPanel)="onClickClosePanel()"
      [dynamicData]="selectedData"
      [formatAttributes]="formatAttributes"
      [edition]="true"
    ></jhi-dynamic-data-formater>
  </div>
</div>
