<div class="export-configuration-container">
  <div class="back-container">
    <button mat-icon-button class="header-close" (click)="goBack()">
      <mat-icon svgIcon="arrow-back" color="primary"></mat-icon>
    </button>
    <span jhiTranslate="htmlEditor.plugins.configurationExport.goBack"></span>
  </div>
  <hr />
  <div class="configuration-content">
    <div class="type-reminder">
      <mat-icon class="export-svg" svgIcon="export-xliff"></mat-icon>
      <span jhiTranslate="htmlEditor.plugins.documentExport.xliffExport"></span>
    </div>

    <mat-form-field>
      <mat-label jhiTranslate="htmlEditor.plugins.configurationExport.XLIFF.language"></mat-label>
      <mat-select [(value)]="selectedLanguage" required>
        <mat-option *ngFor="let language of languages" [value]="language.code">
          {{ language.shortValue }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <label id="export-content-label" jhiTranslate="htmlEditor.plugins.configurationExport.export"></label>
    <mat-radio-group aria-labelledby="export-content-label" [value]="export" color="primary">
      <mat-radio-button [value]="ExportType.DOCUMENTARY_UNIT" (change)="setExport(ExportType.DOCUMENTARY_UNIT)">
        {{ 'htmlEditor.plugins.configurationExport.du_only' | translate }}
      </mat-radio-button>
      <mat-radio-button [value]="ExportType.CHAPTER" (change)="setExport(ExportType.CHAPTER)">
        {{ 'htmlEditor.plugins.configurationExport.chapter' | translate }}
      </mat-radio-button>
      <mat-radio-button [value]="ExportType.DOCUMENT" (change)="setExport(ExportType.DOCUMENT)">
        {{ 'htmlEditor.plugins.configurationExport.document' | translate }}
      </mat-radio-button>
    </mat-radio-group>

    <mat-slide-toggle class="toggle" color="primary" [(ngModel)]="marksToggle">
      {{ 'htmlEditor.plugins.configurationExport.XLIFF.marks' | translate }}
    </mat-slide-toggle>

    <div class="button-container">
      <button
        [disabled]="!selectedLanguage"
        type="button"
        mat-flat-button
        mat-button
        color="primary"
        (click)="exportDocumentXliff()"
        jhiTranslate="htmlEditor.plugins.configurationExport.button"
      ></button>
      <span *ngIf="!selectedLanguage" jhiTranslate="htmlEditor.plugins.configurationExport.XLIFF.disabled"></span>
    </div>
  </div>
</div>
