import { Component, Input, Output, EventEmitter } from '@angular/core';
import { IConcept } from 'app/shared/model/concept.model';

@Component({
  selector: 'jhi-concept-detailled',
  templateUrl: 'concept-detailled.component.html',
  styleUrls: ['./concept-detailled.component.scss'],
})
export class ConceptDetailledComponent {
  @Input() concept: IConcept;
  @Input() conceptIdSelected: { pnodeId: number; parentId: number };
  @Output() public selected = new EventEmitter<IConcept>();

  public onSelected(concept: IConcept): void {
    this.selected.emit(concept);
  }
}
