import { CKEditorModel } from 'app/shared/enum/ckeditor-model.enum';
import first from '@ckeditor/ckeditor5-utils/src/first'; // Don't touch it it can create duplicated module error
import { Element } from '@ckeditor/ckeditor5-engine';

export function isContainingBorder(blocks: IterableIterator<Element>): boolean {
  let actualState = false;
  const blocksArr = Array.from(blocks);
  if (blocksArr?.length) {
    actualState = !!blocksArr.find(
      block => (block?.name as CKEditorModel) === CKEditorModel.FRAME || !!block?.findAncestor(CKEditorModel.FRAME_EDITABLE)
    );
  }
  return actualState;
}

export function isInTable(blocks: IterableIterator<Element>): boolean {
  const firstBlock = first(blocks);
  if (!firstBlock) {
    return false;
  }
  return !!firstBlock?.findAncestor(CKEditorModel.TABLE);
}

export function isInXbrlFootnote(blocks: IterableIterator<Element>): boolean {
  const firstBlock = first(blocks);
  if (!firstBlock) {
    return false;
  }
  return !!firstBlock?.findAncestor(CKEditorModel.XBRL_FOOTNOTE);
}

export function isContainingXbrlFootnote(blocks: IterableIterator<Element>): boolean {
  let actualState = false;
  const blocksArr = Array.from(blocks);
  if (blocksArr?.length) {
    actualState = !!blocksArr.find(
      block => (block?.name as CKEditorModel) === CKEditorModel.XBRL_FOOTNOTE || !!block?.findAncestor(CKEditorModel.XBRL_FOOTNOTE)
    );
  }
  return actualState;
}
