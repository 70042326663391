import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map, publishReplay, refCount } from 'rxjs/operators';

import { SERVER_API_URL } from 'app/app.constants';
import { IDate } from 'app/shared/model/date.model';
import { Project, IProject, IProjectExport, IProjectCheckResponse, IProjectProgress } from 'app/shared/model/project.model';
import { IMonetaryUnit } from 'app/shared/model/monetary-unit.model';
import { ArevioService } from './arevio.service';

import pubsub from 'app/pubsub';
import { LS_GET_STATE, LS_SET_STATE, SESSION_STORAGE_GET_STATE, SESSION_STORAGE_SET_STATE } from 'app/pubsub.topics';
import { ContextService } from './context.service';
import { FileUtils } from 'app/shared/util/file-utils';

@Injectable({ providedIn: 'root' })
export class ProjectService {
  private projectConfigurationCache$: Observable<IProject> | null;
  private projectUnitsCache$: Observable<IMonetaryUnit[]> | null;
  private globalConfigurationUrl: string;

  constructor(private httpClient: HttpClient, private contextService: ContextService, private arevioService: ArevioService) {
    this.globalConfigurationUrl = `${SERVER_API_URL}/api/configuration`;

    // here we are on a singleton service, .off is not necessary
    pubsub.on(LS_GET_STATE, ({ detail }: CustomEvent) => {
      const value = this.getState(detail.prefix, localStorage);
      pubsub.fire(detail.callback, { value });
    });

    pubsub.on(LS_SET_STATE, ({ detail }: CustomEvent) => {
      this.setState(detail.prefix, detail.newValue, localStorage);
    });

    // here we are on a singleton service, .off is not necessary
    pubsub.on(SESSION_STORAGE_GET_STATE, ({ detail }: CustomEvent) => {
      const value = this.getState(detail.prefix, sessionStorage);
      pubsub.fire(detail.callback, { value });
    });

    pubsub.on(SESSION_STORAGE_SET_STATE, ({ detail }: CustomEvent) => {
      this.setState(detail.prefix, detail.newValue, sessionStorage);
    });
  }

  public getProjectInformation(): Observable<IProject> {
    if (!this.projectConfigurationCache$) {
      this.projectConfigurationCache$ = this.httpClient.get<IProject>(this.getConfigurationUrl()).pipe(publishReplay(1), refCount());
    }
    return this.projectConfigurationCache$;
  }

  public updateProjectInformation(project: Project): Observable<IProject> {
    this.projectConfigurationCache$ = null;
    this.projectUnitsCache$ = null;
    return this.httpClient
      .put<IProject>(this.getConfigurationUrl(), { project })
      .pipe(
        map((response: IProject) => {
          if (response.project) {
            this.contextService.currentDocumentContext.projectScale = response.project.scale;
            return response;
          } else {
            throw new Error('Project configuration response malformed');
          }
        })
      );
  }

  public getDatesConfiguration(): Observable<{ periods: IDate[] }> {
    return this.httpClient.get<{ periods: IDate[] }>(`${this.getConfigurationUrl()}/dates`);
  }

  public createDate(date: IDate): Observable<IDate> {
    return this.httpClient.post<IDate>(`${this.getConfigurationUrl()}/dates/period`, date);
  }

  public updateDate(date: IDate): Observable<IDate> {
    this.arevioService.clearFactCache(); // Clear cache for periods update
    return this.httpClient.put<IDate>(`${this.getConfigurationUrl()}/dates/period/${date.id}`, date);
  }

  public deleteDate(date: IDate): Observable<void> {
    this.arevioService.clearFactCache(); // Clear cache for periods update
    return this.httpClient.delete<void>(`${this.getConfigurationUrl()}/dates/period/${date.id}`);
  }

  public getAllMonetaryUnits(): Observable<IMonetaryUnit[]> {
    return this.httpClient.get<IMonetaryUnit[]>(`${this.globalConfigurationUrl}/monetaryUnits`);
  }

  public getProjectMonetaryUnits(): Observable<IMonetaryUnit[]> {
    if (!this.projectUnitsCache$) {
      this.projectUnitsCache$ = this.httpClient
        .get<IMonetaryUnit[]>(`${this.getConfigurationUrl()}/monetaryUnits`)
        .pipe(publishReplay(1), refCount());
    }
    return this.projectUnitsCache$;
  }

  public getState(prefix: string, storage: Storage): boolean {
    const state = storage.getItem(`${prefix}_${this.contextService.currentProjectId}`);
    if (state) {
      return JSON.parse(state);
    }
    return false;
  }

  public setState(prefix: string, state: boolean, storage: Storage): void {
    storage.setItem(`${prefix}_${this.contextService.currentProjectId}`, JSON.stringify(state));
  }

  public getConfigurationUrl(): string {
    return `${SERVER_API_URL}api/projects/${this.contextService.currentProjectId}/configuration`;
  }

  public downloadMissingParentUrl(): string {
    return `${SERVER_API_URL}api/projects/${this.contextService.currentProjectId}/export/xbrl-missing-hierarchy`;
  }

  public addMissingParents(): Observable<void> {
    return this.httpClient.get<void>(`${SERVER_API_URL}api/projects/${this.contextService.currentProjectId}/taxonomy/parents/add`);
  }

  /** IMPORT-EXPORT PROJECT */
  public exportProject(): Observable<IProjectExport> {
    const params: HttpParams = new HttpParams().set('spinner', 'none').set('skipError', 'true');
    return this.httpClient.post<IProjectExport>(
      `${SERVER_API_URL}api/projects/${this.contextService.currentProjectId}/exports`,
      {},
      {
        params,
      }
    );
  }

  public updateNotes(exportId: number, note: string): Observable<IProjectExport> {
    return this.httpClient.post<IProjectExport>(
      `${SERVER_API_URL}api/projects/${this.contextService.currentProjectId}/exports/${exportId}/notes`,
      note
    );
  }

  public getExports(): Observable<IProjectExport[]> {
    return this.httpClient.get<IProjectExport[]>(`${SERVER_API_URL}api/projects/${this.contextService.currentProjectId}/exports`);
  }

  public deleteExports(ids: number[]): Observable<void> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: ids,
    };
    return this.httpClient.delete<void>(`${SERVER_API_URL}api/projects/${this.contextService.currentProjectId}/exports`, options);
  }

  public checkImportProject(file: File): Observable<IProjectCheckResponse> {
    const formData = new FormData();
    formData.append('file', FileUtils.sanitizeFile(file));
    const params: HttpParams = new HttpParams().set('skipError', 'true');
    return this.httpClient.post<IProjectCheckResponse>(
      `${SERVER_API_URL}api/projects/${this.contextService.currentProjectId}/imports/check`,
      formData,
      { params }
    );
  }

  public importProject(file: File, languages: string[], importFromPreviousYear: boolean): Observable<void> {
    const formData = new FormData();
    // Only return filename, backend already got the archive. It's been use for validation.
    formData.append('filename', file.name);
    formData.append('languages', languages.join(','));
    formData.append('importFromPreviousYear', importFromPreviousYear ? 'true' : 'false');
    return this.httpClient.post<void>(`${SERVER_API_URL}api/projects/${this.contextService.currentProjectId}/imports`, formData);
  }

  public getImportProgress(projectId: number): Observable<any[]> {
    return this.httpClient.get<IProjectProgress[]>(`${SERVER_API_URL}api/projects/${projectId}/imports/progress`);
  }
}
