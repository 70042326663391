<div class="plugin-container versions-plugin-container">
  <span *ngIf="showTypeFilters" class="filter" jhiTranslate="htmlEditor.plugins.versions.form.types"></span>
  <div *ngIf="showTypeFilters" class="checkbox-container">
    <mat-checkbox [aria-label]="'htmlEditor.plugins.versions.textual' | translate" color="primary"
      [checked]="typeTextualSelected" (change)="toggleTypeSelected('Textual', $event)">
      {{ 'htmlEditor.plugins.versions.textual' | translate }}
    </mat-checkbox>
    <mat-checkbox aria-label="'htmlEditor.plugins.versions.shaping' | translate" color="primary"
      [checked]="typeShapingSelected" (change)="toggleTypeSelected('Shaping', $event)">
      {{ 'htmlEditor.plugins.versions.shaping' | translate }}
    </mat-checkbox>
  </div>
  <mat-accordion multi class="standard">
    <mat-expansion-panel hideToggle *ngFor="let dayCommits of commitsTree;let dayIndex = index"
      [expanded]="dayIndex === 0">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <ng-container *ngIf="isToday(dayCommits[0].date); else notToday">
            <span jhiTranslate="htmlEditor.plugins.versions.today"></span>
          </ng-container>
          <ng-template #notToday>
            <span>{{ dayCommits[0].date | localizedDate:'longDate' }}</span>
          </ng-template>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <ul>
        <li *ngFor="let commit of dayCommits;let commitIndex = index" (click)=showDiff(commit)
          [ngClass]="{'selected': selectedCommit === commit}">
          <div class="time">{{ commit.date | localizedDate:'medium' }}</div>
          <div class="time" *ngIf="dayIndex === 0 && commitIndex === 0">{{ 'htmlEditor.plugins.versions.actual' |
            translate }}</div>
          <div class="user" *ngFor="let user of commit.message?.users">{{user.name}}</div>
        </li>
      </ul>
    </mat-expansion-panel>
  </mat-accordion>
  <div #diffViewer class="diff hidden">
    <div class="viewerBar">
      <button mat-icon-button class="plugin-close-btn" (click)="hideDiff()">
        <mat-icon svgIcon="chevron-right"></mat-icon>
      </button>
      <span *ngIf="selectedCommit">{{ selectedCommit.date | localizedDate }}</span>
      <button *ngIf="!isLastSavedVersionDisplayed(); else lastSavedVersion" mat-raised-button color="primary"
        class="btn-restore" (click)="restoreVersion()">
        <span jhiTranslate="htmlEditor.plugins.versions.restore"></span>
      </button>
      <ng-template #lastSavedVersion>
        <span class="label-version">{{ 'htmlEditor.plugins.versions.actual' | translate }}</span>
      </ng-template>
    </div>
  </div>
</div>
