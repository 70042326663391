<h1 mat-dialog-title class="error-title">
  <span jhiTranslate="dialog.errors.front.{{key}}.title"></span>
  <button type="button" mat-icon-button class="mat-dialog-close-btn" (click)="closeModal()">
      <fa-icon icon="times"></fa-icon>
  </button>
</h1>

<div mat-dialog-content>
  <span class="error-message" jhiTranslate="dialog.errors.front.{{key}}.message"></span>
</div>

<div mat-dialog-actions>
  <button mat-raised-button color="primary" (click)="closeModal()"><span
          jhiTranslate="dialog.btn-understood"></span></button>
</div>
