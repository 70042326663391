import { Component, OnInit, HostBinding, Input, ElementRef, Output, EventEmitter, ChangeDetectionStrategy, OnDestroy } from '@angular/core';
import { fromEvent, Subscription } from 'rxjs';
import { ResizableLayoutComponent } from './resizable-layout.component';
import { ResizableDirection, ResizableEventInfo } from './resizable.model';

@Component({
  selector: 'jhi-resizable',
  templateUrl: 'resizable.component.html',
  styleUrls: ['resizable.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResizableComponent implements OnInit, OnDestroy {
  @HostBinding('class.resizable') resizable = true;
  @HostBinding('class.dragging') isDragging = false;
  @HostBinding('style.width') width: string;
  @HostBinding('style.height') height: string;
  @HostBinding('style.flex-basis') flexBasis: string;
  @HostBinding('style.max-width') maxWidth: string;
  @HostBinding('style.max-height') maxHeight: string;

  @Input() directions: ResizableDirection[] = [];
  @Input() minSize: string;

  @Output() resizeStart = new EventEmitter<ResizableEventInfo>();
  @Output() resizeEnd = new EventEmitter<ResizableEventInfo>();

  public nativeElt: HTMLElement;

  layout: ResizableLayoutComponent;
  remaining = false;
  size: number;
  min: number;

  private fromCoord: number;
  private dragDir: ResizableDirection;
  private axis: 'x' | 'y';
  private subs = new Subscription();
  private alreadyResizing: NodeJS.Timeout | null;

  constructor(private elt: ElementRef) {
    this.nativeElt = this.elt.nativeElement;
  }

  ngOnInit(): void {
    this.remaining = this.directions.length === 0;
    if (this.remaining) {
      // this panel will keep the remaining space
      this.resizable = false;
    }
    if (this.resizable) {
      this.handleResize();
      this.handleMouseEvents();
    }
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  onMousedown(event: MouseEvent, direction: ResizableDirection): void {
    if (event.button === 0) {
      this.dragStart(event, direction);
    }
  }
  onMouseup(event: MouseEvent): void {
    if (this.isDragging) {
      this.dragEnd(event);
    }
  }
  onMousemove(event: MouseEvent): void {
    if (this.isDragging) {
      this.dragging(event);
    }
  }

  dragStart(e: MouseEvent, direction: ResizableDirection): void {
    this.isDragging = true;
    this.layout.dragging = true;
    this.dragDir = direction;
    this.axis = this.dragDir === 'left' || this.dragDir === 'right' ? 'x' : 'y';
    this.fromCoord = this.axis === 'x' ? e.clientX : e.clientY;
    this.refreshValues();
    this.resizeStart.emit(this.getInfo(e));
  }

  dragEnd(e: MouseEvent): void {
    if (!this.isDragging) {
      return;
    }
    this.resizeEnd.emit(this.getInfo(e));
    this.isDragging = false;
    this.layout.dragging = false;
  }

  getMin(): number {
    if (!this.minSize) {
      return 0;
    }
    let min = parseInt(this.minSize, 10);
    if (isNaN(min)) {
      return 0;
    }
    if (this.minSize.endsWith('%')) {
      const layoutSize = this.layout.size;
      min = (min * layoutSize) / 100;
    }
    return min;
  }

  private liveSize(): number {
    return this.axis === 'x' ? this.nativeElt.offsetWidth : this.nativeElt.offsetHeight;
  }

  private dragging(e: MouseEvent): void {
    const offset = this.axis === 'x' ? this.fromCoord - e.clientX : this.fromCoord - e.clientY;

    const operand = this.dragDir === 'top' || this.dragDir === 'left' ? -1 : 1;
    let newSize = this.size - offset * operand;

    if (this.layout) {
      if (this.min - newSize > 0) {
        newSize = this.min;
      } else {
        newSize = this.layout.getNewSizeAccordingMinRemainingSize(this, newSize);
      }
    }
    this.refreshFlexBasis(newSize);
  }

  private getInfo(e: Event): ResizableEventInfo {
    return {
      event: e,
      currentSizeInPixel: parseInt(this.flexBasis, 10),
    };
  }

  private refreshValues(): void {
    this.size = this.liveSize();
    this.layout.refreshValues();
    this.min = this.getMin();
  }

  private handleMouseEvents(): void {
    const mouseUpObs = fromEvent<MouseEvent>(document, 'mouseup');
    this.subs.add(mouseUpObs.subscribe(e => this.onMouseup(e)));
    const mouseMoveObs = fromEvent<MouseEvent>(document, 'mousemove');
    this.subs.add(mouseMoveObs.subscribe(e => this.onMousemove(e)));
  }

  private handleResize(): void {
    // Resize the pages when window is resized
    const resizeObservable = fromEvent(window, 'resize');
    this.subs.add(
      resizeObservable.subscribe((event: Event) => {
        if (this.alreadyResizing) {
          clearTimeout(this.alreadyResizing);
        }
        this.alreadyResizing = setTimeout(() => {
          this.alreadyResizing = null;
          this.refreshFlexBasis(null);
          this.resizeEnd.emit({ event, currentSizeInPixel: 0 });
        }, 500);
      })
    );
  }

  private refreshFlexBasis(newSize: number | null): void {
    if (this.axis === 'x') {
      this.flexBasis = this.maxWidth = newSize ? `${newSize}px` : '';
    } else {
      this.flexBasis = this.maxHeight = newSize ? `${newSize}px` : '';
    }
  }
}
