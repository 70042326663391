import { HttpErrorResponse } from '@angular/common/http';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { ArevioService } from 'app/core/service/arevio.service';
import { DialogService } from 'app/core/service/dialog.service';
import { DocumentaryUnitService } from 'app/core/service/documentary-unit.service';
import { XbrlActionService } from 'app/core/service/xbrl-action.service';
import { CodeErrorServer } from 'app/shared/enum/code-error-server.enum';
import { Direction } from 'app/shared/enum/direction.enum';
import { DynamicDataFilter } from 'app/shared/enum/dynamic-data.enum';
import { PeriodType } from 'app/shared/enum/period-type.enum';
import { SepXbrlMultiPart } from 'app/shared/enum/sep-xbrl-multi-part.enum';
import { TEXT_BLOCK_TYPES, XBRLType } from 'app/shared/enum/xbrl-type.enum';
import { XBRLUnit } from 'app/shared/enum/xbrl-units.enum';
import { IConcept } from 'app/shared/model/concept.model';
import { IFact, IFactUtils } from 'app/shared/model/fact.model';
import { IFormatAttributes } from 'app/shared/model/formatted.model';
import { ReferencedFootnote } from 'app/shared/model/xbrl-footnote.model';

@Component({
  selector: 'jhi-xbrl-data-viewer',
  templateUrl: './xbrl-data-viewer.component.html',
  styleUrls: ['./xbrl-data-viewer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class XbrlDataViewerComponent implements OnChanges {
  @Input() public eolData = false;
  @Input() public eolDataXbrlView = false;
  @Input() public showFilter = false;
  @Input() fact: IFact;
  @Input() factIdList: string[] = [];
  @Input() public formatAttributes: IFormatAttributes;
  @Input() public hasAnnotationsList = false;
  @Input() public isEditable: boolean;
  @Input() public editorialTextFactsConcept: IConcept[] = [];
  @Output() public goToEditFormat = new EventEmitter();
  @Output() public goToAttachXbrlFact = new EventEmitter();
  @Output() public detachXbrlFact = new EventEmitter();
  @Output() public deleteXbrlFact = new EventEmitter();
  @Output() public closed = new EventEmitter<void>();
  @Output() public selectFactDirection = new EventEmitter<Direction | { dir: Direction; filter: DynamicDataFilter }>();
  @Output() public scrollToAnnotation = new EventEmitter<number>();
  @Output() public assignDuplicateValue = new EventEmitter<string | null>();

  public readonly maxFactID = 10;
  public readonly periodType = PeriodType;
  public readonly direction = Direction;
  public readonly filter = DynamicDataFilter;
  public navigationFilter = DynamicDataFilter.ALL;
  public annotations: ReferencedFootnote[];
  public duplicateValue: string = '';
  public selectedValue: string | null = null;
  public displayDeleteConfirmation = false;
  public isMultipleMode = false;
  public currentIndex = 0;
  public showNavigationMultiFact = false;
  public factValueMultiPart: string[];
  public isEditorialFact: boolean;
  public isTextBlock: boolean;
  private footnoteIdToBeDeleted: number;
  private currentValue: string | null = null;

  constructor(
    private cdr: ChangeDetectorRef,
    private xbrlActionService: XbrlActionService,
    private dialogService: DialogService,
    private documentaryUnitService: DocumentaryUnitService,
    private arevioService: ArevioService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.fact) {
      this.selectedValue = null;
      if (this.hasAnnotationsList) {
        this.updateFootnotesList();
      }
      this._checkIsMultipleMode();
      this.factValueMultiPart = [];
      if (this.fact.concept?.type === XBRLType.STRING) {
        const partValues = this.fact.factValue?.split(SepXbrlMultiPart.DATA) ?? [];
        this.factValueMultiPart = partValues.length > 1 ? partValues : [];
      }
      this._checkIsMultipleMode();

      this.isEditorialFact =
        this.editorialTextFactsConcept?.find((concept: IConcept) => concept.conceptId === this.fact?.concept?.conceptId) !== undefined;

      this.isTextBlock = this.fact?.concept?.type ? TEXT_BLOCK_TYPES.includes(this.fact.concept.type) : false;
    }
  }

  public setSelectedValue(value: string): void {
    this.selectedValue = value;
    this.cdr.markForCheck();
  }

  public emitValueSelection(): void {
    this.assignDuplicateValue.emit(this.selectedValue);
  }

  public updateFootnotesList(): void {
    const documentUnitId = this.documentaryUnitService.currentSelectedDocumentaryUnit?.id;
    if (documentUnitId) {
      this.xbrlActionService.getListFootnotesForFactKeyByUD(this.fact, documentUnitId).subscribe(result => {
        this.annotations = result;
        this.cdr.markForCheck();
      });
    }
  }

  public selectDirection(dir: Direction): void {
    if (this.showFilter) {
      this.selectFactDirection.emit({ dir, filter: this.navigationFilter });
    } else {
      this.selectFactDirection.emit(dir);
    }
  }

  public setFactValue(fact: IFact): void {
    this.fact.factValue = fact.factValue;
    this.fact.duplicateInfo = fact.duplicateInfo;
    this.cdr.detectChanges();
  }

  public hasOimUnit(fact: IFact): boolean {
    if (!fact?.oimUnit || (fact.oimUnit as XBRLUnit) === XBRLUnit.PURE) {
      return false;
    }
    return true;
  }

  public isNumber(fact: IFact): boolean {
    if (!fact.factValue) {
      return false;
    }
    const f = parseFloat(fact?.factValue);
    return !isNaN(f) && f.toString().length === fact?.factValue?.length;
  }

  public displayAnnotationInSection(footnoteId: number): void {
    this.scrollToAnnotation.emit(footnoteId);
  }

  public deleteLinkBetweenFactAndFootnote(footnoteId: number): void {
    this.footnoteIdToBeDeleted = footnoteId;
    this.displayDeleteConfirmation = true;
  }

  public onRemoveConfirmation(): void {
    const documentaryUnitId = this.documentaryUnitService.currentSelectedDocumentaryUnit?.id;
    if (!this.fact.factXbrlId || !documentaryUnitId) {
      return;
    }
    this.xbrlActionService.removeLinkBetweenFactAndFootnote(this.fact.factXbrlId, documentaryUnitId, this.footnoteIdToBeDeleted).subscribe(
      () => {
        this.displayDeleteConfirmation = false;
        const annotationIndex = this.annotations.findIndex(annotation => annotation.id === this.footnoteIdToBeDeleted);
        if (annotationIndex !== -1) {
          this.annotations.splice(annotationIndex, 1);
        }
        this.cdr.markForCheck();
      },
      (error: HttpErrorResponse) => {
        if (error.error?.detail === CodeErrorServer.ONLY_ONE_FACT_RELATED_TO_FOOTNOTE) {
          this.dialogService.openErrorDialog(CodeErrorServer.ONLY_ONE_FACT_RELATED_TO_FOOTNOTE);
        } else {
          // show default message
          this.dialogService.openErrorDialog();
        }
        this.displayDeleteConfirmation = false;
        this.cdr.markForCheck();
      }
    );
  }

  public onRemoveCancel(): void {
    this.displayDeleteConfirmation = false;
  }

  public deleteFact() {
    this.deleteXbrlFact.emit(this.fact);
  }

  public detach() {
    this.detachXbrlFact.emit(this.factIdList[this.currentIndex]);
    // after detach a fact display the previous fact in the list. For index 0, nothing to do because fact will change
    if (this.currentIndex > 0) {
      this.currentIndex -= 1;
      this._selectFact();
    }
  }

  private _selectFact() {
    if (!this.currentValue) {
      // save current value for unknown fact
      this.currentValue = this.fact.factValue ?? null;
    }
    if (this.currentValue) {
      this.arevioService.getFactById(this.factIdList[this.currentIndex]).subscribe(fact => {
        // createFactFromXbrlId for unknown fact in list
        this.fact = fact ?? IFactUtils.createFactFromXbrlId(this.factIdList[this.currentIndex], this.currentValue as string);
      });
    }
  }

  public selectNextFact(): void {
    this.currentIndex += 1;
    if (this.currentIndex === this.factIdList.length) {
      this.currentIndex = 0;
    }
    this._selectFact();
  }

  public selectPrevFact(): void {
    if (this.currentIndex === 0) {
      this.currentIndex = this.factIdList.length;
    }
    this.currentIndex -= 1;
    this._selectFact();
  }

  /**
   * init "isMultipleMode" and "showNavigationMultiFact"
   *
   * fact multi index only for one segment xbrl string fact
   * multi index not allowed for fact multi zone
   *
   * navigation multi fact is visible if we have more than one fact
   */
  private _checkIsMultipleMode() {
    this.isMultipleMode = !this.eolData && this.fact?.concept?.type === XBRLType.STRING && IFactUtils.nbZoneFactString(this.fact) === 1;
    this.showNavigationMultiFact = this.factIdList.length > 1;
    this.cdr.markForCheck();
  }
}
