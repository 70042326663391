import { FocusMonitor } from '@angular/cdk/a11y';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { OverlayContainer } from '@angular/cdk/overlay';
import { Component, ElementRef, HostBinding, Input, OnInit, Optional, Self, OnDestroy, ViewChild } from '@angular/core';
import { ControlValueAccessor, UntypedFormControl, NgControl } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatFormFieldControl } from '@angular/material/form-field';
import { Subject } from 'rxjs';

type Primitive = string | number | boolean;
type SelectOption = { label: string; labelLocKey: string; value: any; classNames: string };
export type EditableSelectOption = SelectOption | Primitive;

@Component({
  selector: 'jhi-editable-select',
  templateUrl: './editable-select.component.html',
  styleUrls: ['./editable-select.component.scss'],
  providers: [{ provide: MatFormFieldControl, useExisting: EditableSelectComponent }],
})
export class EditableSelectComponent implements ControlValueAccessor, MatFormFieldControl<any>, OnInit, OnDestroy {
  // ****************************************************** //
  // * Implementation of MatFormFieldControl (properties) * //
  // ****************************************************** //
  private static nextId = 0;
  _placeholder: string;
  _required = false;
  _disabled = false;

  focused = false;
  stateChanges = new Subject<void>();
  controlType = 'internal-select';

  @Input()
  get value(): any {
    return this.inputControl.value;
  }
  // here we want value as having any type because this component has to be able to manage any type of vale
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  set value(value: any) {
    this.inputControl.setValue(value);
    this.stateChanges.next();
  }

  @Input()
  get placeholder(): string {
    return this._placeholder;
  }
  set placeholder(ph: string) {
    this._placeholder = ph;
    this.stateChanges.next();
  }

  @Input()
  get required(): boolean {
    return this._required;
  }
  set required(required: boolean) {
    this._required = coerceBooleanProperty(required);
    this.stateChanges.next();
  }

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }
  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
    this._disabled ? this.ngControl.control?.disable() : this.ngControl.control?.enable();
    this.stateChanges.next();
  }

  @Input() ariaDescribedBy: string;

  get empty(): boolean {
    return !this.inputControl.value;
  }

  @HostBinding('class.floating')
  get shouldLabelFloat(): boolean {
    return this.focused || !this.empty;
  }

  @HostBinding() id: string;

  get errorState(): boolean {
    return !!this.ngControl?.errors && this.inputControl.dirty;
  }

  // ***************************************************** //
  // * Used to implement ControlValueAccessor            * //
  // ***************************************************** //
  _onChange: (_: any) => void;
  _onTouched: () => void;

  /**
   * The options for the select dropdown
   * An option may be:
   * - a structured object {label: string, labelLocKey: string, value: any, class?: string}
   * - a primitive type (string, number, boolean) (in this case, label = value = the value in primitive type)
   */
  @Input() options: EditableSelectOption[];

  inputControl = new UntypedFormControl();
  normalizedOptions: SelectOption[];
  @ViewChild(MatAutocompleteTrigger) autocompleTrigger: MatAutocompleteTrigger;

  constructor(
    @Optional() @Self() public ngControl: NgControl,
    private overlayContainer: OverlayContainer,
    private fm: FocusMonitor,
    private elRef: ElementRef<HTMLElement>
  ) {
    if (this.ngControl != null) {
      // Setting the value accessor directly (instead of using
      // the providers) to avoid running into a circular import.
      this.ngControl.valueAccessor = this;
    }
    EditableSelectComponent.nextId++;
    this.id = `editable-select-${EditableSelectComponent.nextId}`;
  }

  // ****************************************************** //
  // * Implementation of MatFormFieldControl (functions)  * //
  // ****************************************************** //
  setDescribedByIds(ids: string[]): void {
    const controlElement = this.elRef.nativeElement.querySelector('.editable-select-container');
    if (controlElement) {
      controlElement.setAttribute('aria-describedby', ids.join(' '));
    }
  }

  onContainerClick(event: MouseEvent): void {
    if ((event.target as Element).tagName.toLowerCase() !== 'input') {
      this.elRef.nativeElement.querySelector('input')?.focus();
    }
  }

  // ****************************************************** //
  // * Implementation of ControlValueAccessor (functions) * //
  // ****************************************************** //
  writeValue(val: string): void {
    this.value = val;
  }

  registerOnChange(fn: (_: any) => void): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this._onTouched = fn;
  }

  // ****************************************************** //
  // * editable-select specific code                      * //
  // ****************************************************** //

  ngOnInit(): void {
    this._handleFocusedState();
    this._normalizeOptions();
  }

  ngOnDestroy(): void {
    this.fm.stopMonitoring(this.elRef.nativeElement);
  }

  showSelectedInDropdown(): void {
    if (!this.value) {
      return;
    }
    const panel = this.overlayContainer.getContainerElement().getElementsByClassName(this.id)[0];
    if (!panel) {
      return;
    }
    const optionElt = panel.querySelector(`mat-option[ng-reflect-value="${this.value}"]`);
    // highlight option selected
    const currentlySelected = panel.querySelector('mat-option.mat-active');
    if (currentlySelected !== optionElt) {
      if (optionElt) {
        optionElt.classList.add('mat-active');
      }
      if (currentlySelected) {
        currentlySelected.classList.remove('mat-active');
      }
    }
    // scroll option selected into view
    if (optionElt) {
      const panelRect = panel.getBoundingClientRect();
      const optionRect = optionElt.getBoundingClientRect();
      const isViewable = optionRect.top >= panelRect.top && optionRect.top <= panelRect.top + panel.clientHeight;
      if (!isViewable) {
        panel.scrollTop = optionRect.top + panel.scrollTop - panelRect.top;
      }
    }
  }

  _handleInput(): void {
    if (this._onChange) {
      this._onChange(this.value);
    }
    if (this.errorState) {
      this.autocompleTrigger.closePanel();
    }
  }

  /**
   * Implementation of MatFormFieldControl interface: compute focused state of the form field.
   */
  private _handleFocusedState(): void {
    const nativeElt = this.elRef.nativeElement;
    const matFormField: HTMLDivElement | null = nativeElt.closest('div.mat-mdc-form-field-flex');
    const focusElement = matFormField ?? nativeElt;
    this.fm.monitor(focusElement, true).subscribe(origin => {
      this.focused = !!origin;
      this.stateChanges.next();
      if (!this.autocompleTrigger.panelOpen && this.focused && !this.errorState) {
        setTimeout(() => {
          this.autocompleTrigger.openPanel();
        });
      }
    });
  }

  private _normalizeOptions(): void {
    if (!this.options) {
      return;
    }
    const transformedOptions: any[] = [];
    for (const option of this.options) {
      if (Object(option) !== option) {
        // primitive option, we transform it in an object
        transformedOptions.push({
          // eslint-disable-next-line @typescript-eslint/no-base-to-string
          label: `${option}`,
          labelLocKey: null,
          value: option,
          classNames: null,
        });
      } else {
        transformedOptions.push(option as SelectOption);
      }
    }
    this.normalizedOptions = transformedOptions;
  }
}
