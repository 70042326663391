<div *ngIf="username || avatarUrl" class="account-button">
  <button mat-button [matMenuTriggerFor]="accountMenu" class="menu-account" [matTooltip]="username">
    <img [src]="avatarUrl" class="avatar" alt="avatar" />
  </button>
  <mat-menu #accountMenu="matMenu">
    <button (click)="logout()" id="logout" mat-menu-item>
      <fa-icon icon="sign-out-alt" fixedWidth="true"></fa-icon>
      <span jhiTranslate="global.menu.account.logout"></span>
    </button>
  </mat-menu>
</div>
