<div class="summary-panel-container" [@.disabled]="disableAnimation">
  <mat-form-field>
    <mat-label>{{ 'htmlEditor.plugins.summary.type.label' | translate }}</mat-label>
    <mat-select [(ngModel)]="summary.type" (selectionChange)="onSummaryTypeChange()">
      <mat-option *ngFor="let type of summaryTypes" [value]="type">{{ 'htmlEditor.plugins.summary.type.' + type | translate }}</mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field>
    <mat-label>{{ 'htmlEditor.plugins.summary.levels.label' | translate }}</mat-label>
    <mat-select [(ngModel)]="summary.includedTitleLevels" multiple (selectionChange)="updateFilteredTitles()">
      <mat-select-trigger>{{ summary.includedTitleLevels.sort().join(', ') }}</mat-select-trigger>
      <mat-option *ngFor="let level of titleLevels" [value]="level">
        {{ 'htmlEditor.plugins.summary.levels.option' | translate }} {{ level }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title>{{ 'htmlEditor.plugins.summary.titles' | translate }}</mat-panel-title>
    </mat-expansion-panel-header>

    <div class="select-button">
      <button type="button" mat-raised-button color="primary" (click)="selectAll()">
        <span jhiTranslate="htmlEditor.plugins.summary.selectAll" *ngIf="!allTitlesSelected"></span>
        <span jhiTranslate="htmlEditor.plugins.summary.unselectAll" *ngIf="allTitlesSelected"></span>
      </button>
    </div>
    <ul class="excluded-titles">
      <li *ngFor="let title of filteredTitles" [style.margin-left.px]="title.level * 10">
        <mat-checkbox color="primary" [checked]="!summary.excludedTitles.includes(title.id)" (change)="excludeTitle(title)">
          <span [innerHtml]="title.numbering | safeHtml"></span> <span [innerHtml]="title.content | safeHtml"></span>
        </mat-checkbox>
      </li>
    </ul>
  </mat-expansion-panel>

  <div class="buttons">
    <button
      type="button"
      mat-raised-button
      color="accent"
      jhiTranslate="htmlEditor.plugins.summary.cancelBtn"
      (click)="onClickCancel()"
    ></button>

    <button
      type="button"
      mat-raised-button
      color="primary"
      [jhiTranslate]="!summary?.id ? 'htmlEditor.plugins.summary.insertBtn' : 'htmlEditor.plugins.summary.updateBtn'"
      (click)="onClickInsertBtn()"
    ></button>
  </div>
</div>
