<div class="dialog-item-state">
  <div class="col-state">
    <div class="row justify-content-end">
      <span [jhiTranslate]="messageKey"></span>
    </div>
  </div>
  <div class="col-state">
    <div class="justify-content-start success" *ngIf="state === DIALOG_ITEM_STATE.success">
      <fa-icon [icon]="['far', 'check-circle']"></fa-icon>
      {{ 'dataEditor.taxonomy.dialog.control.success' | translate }}
    </div>
    <div class="justify-content-start warning" *ngIf="state === DIALOG_ITEM_STATE.warn">
      <fa-icon [icon]="'exclamation-circle'"></fa-icon>
      {{ 'dataEditor.taxonomy.dialog.control.errors' | translate }}
    </div>
    <div class="justify-content-start danger" *ngIf="state === DIALOG_ITEM_STATE.error">
      <fa-icon [icon]="'exclamation-circle'"></fa-icon>
      {{ 'dataEditor.taxonomy.dialog.control.errors' | translate }}
    </div>
  </div>
</div>
