import {
  Component,
  Input,
  ViewChild,
  AfterViewInit,
  ViewContainerRef,
  OnDestroy,
  Output,
  EventEmitter,
  ViewEncapsulation,
  OnInit,
} from '@angular/core';
import { Observable } from 'rxjs';
import { PluginPanelService } from './plugin-panel.service';

@Component({
  selector: 'jhi-plugin-panel',
  template: `
    <div class="plugin-panel" [ngClass]="panelClassName$ | async">
      <div class="plugin-panel_header" [ngClass]="{ hidden: pluginPanelService.hideHeader }">
        <button mat-icon-button class="header-close" (click)="close()">
          <mat-icon svgIcon="chevron-right"></mat-icon>
        </button>
        <div class="plugin-title" *ngIf="pluginPanelService.panelTitle" [jhiTranslate]="pluginPanelService.panelTitle"></div>
      </div>
      <ng-container #panelContent></ng-container>
    </div>
  `,
  styleUrls: ['./plugin-panel.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PluginPanelComponent implements AfterViewInit, OnInit, OnDestroy {
  @ViewChild('panelContent', { read: ViewContainerRef, static: false })
  public panelViewContainerRef!: ViewContainerRef;

  @Input()
  public hideHeader = false;

  @Input()
  public zoom: number;

  @Input()
  public editor: any;

  @Output() public closed = new EventEmitter<void>();

  public title: string | undefined;
  public panelClassName$: Observable<string>;

  constructor(public pluginPanelService: PluginPanelService) {}

  public ngOnInit(): void {
    this.panelClassName$ = this.pluginPanelService.pluginClassName$;
  }

  public ngAfterViewInit(): void {
    this.pluginPanelService.setViewContainerRef(this.panelViewContainerRef);
  }

  public ngOnDestroy(): void {
    this.panelViewContainerRef.remove();
  }

  public close(): void {
    this.pluginPanelService.closePanel();
  }
}
