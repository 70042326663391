<mat-form-field class="select-field-d-us">
  <mat-label jhiTranslate="htmlEditor.plugins.configurationExport.UD"></mat-label>
  <mat-select multiple [(ngModel)]="documentaryUnitsId" [disabled]="disabled" (selectionChange)="selectionChange()" required>
    <mat-option
      [ngClass]="{ 'chapter-child-option': enableChapterChildOption && titlesBySectionId[section?.id]?.level > 1 }"
      *ngFor="let section of sections"
      [value]="section.id"
    >
      <span *ngIf="section.type == ContentType.CHAPTER">
        <span *ngIf="section.defaultTitle; else missingTitle">{{ getTitleFullContent(section, sections) }}</span>
        <ng-template #missingTitle>
          <span>{{ 'htmlEditor.toc.type.missingNumbering' | translate: { numbering: getNumbering(section, sections) } }}</span>
        </ng-template>
      </span>
      <span *ngIf="section.type == ContentType.INTRODUCTION" jhiTranslate="htmlEditor.toc.type.introduction"></span>
      <span *ngIf="section.type == ContentType.COVER_I_II" jhiTranslate="htmlEditor.toc.type.covers12"></span>
      <span *ngIf="section.type == ContentType.COVER_III_IV" jhiTranslate="htmlEditor.toc.type.covers34"></span>
    </mat-option>
  </mat-select>
</mat-form-field>
