export enum XbrlNumericTypes {
  MONETARY_ITEM = 'xbrli:monetaryItemType',
  DECIMALS = 'xbrli:decimalsType',
  NON_ZERO_DECIMAL = 'xbrli:nonZeroDecimal',
  INTEGER_ITEM = 'xbrli:integerItemType',
  INT_ITEM = 'xbrli:intItemType',
  DECIMAL_ITEM = 'xbrli:decimalItemType',
  DOUBLE_ITEM = 'xbrli:doubleItemType',
  LONG_ITEM = 'xbrli:longItemType',
  NEGATIVE_ITEM_ITEM = 'xbrli:negativeIntegerItemType',
  NON_NEGATIVE_INTEGER_ITEM = 'xbrli:nonNegativeIntegerItemType',
  NON_POSITIVE_INTEGER_ITEM = 'xbrli:nonPositiveIntegerItemType',
  POSITIVE_INTEGER_ITEM = 'xbrli:positiveIntegerItemType',
  SHORT_ITEM = 'xbrli:shortItemType',
  UNSIGNED_INT_ITEM = 'xbrli:unsignedIntItemType',
  UNSIGNED_LONG_ITEM = 'xbrli:unsignedLongItemType',
  UNSIGNED_SHORT_ITEM = 'xbrli:unsignedShortItemType',
}
