import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { finalize } from 'rxjs/operators';
import * as moment from 'moment';

import { PluginComponent } from '../plugin.component';
import { PluginPanelService } from '../plugin-panel.service';
import pubsub from 'app/pubsub';
import { EDITOR_PLUGIN_HIDE_TOOLBAR, TOC_UPDATED } from 'app/pubsub.topics';
import { DocumentaryUnitService } from 'app/core/service/documentary-unit.service';
import { ICommit, IFilters, IStickerModificationsDocument } from 'app/shared/model/version-history.model';
import { IDocumentaryUnit } from 'app/shared/model/documentary-unit.model';

class TrackStickerHtmlDocument {
  content: string;
  loaded: boolean;
  loading: boolean;
  display: boolean;
}

@Component({
  selector: 'jhi-versions-tracking-plugin',
  templateUrl: './versions-tracking.component.html',
  styleUrls: ['./versions-tracking.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VersionsTrackingComponent extends PluginComponent implements OnInit, OnDestroy {
  private static readonly MAT_EXPANSION_PANEL_TAG = 'MAT-EXPANSION-PANEL';
  private static readonly DISABLED_SHOW_MORE_BUTTON = '+';
  private static readonly ENABLED_SHOW_MORE_BUTTON = '-';
  private static readonly SHOW_MORE_STICKER_ENABLE = 'show-more-sticker-enable';
  private static readonly HIGHLIGHT_ELEMENT = 'highlight_element';

  public stickerModificationsDocument: IStickerModificationsDocument;
  public versionsDates: string[] = [];
  public sections: IDocumentaryUnit[];
  public selectedCommit: ICommit;
  public lastDate: string;
  public firstDate: string;
  public filterLoading = false;
  public isFiltered = false;
  public stickerHtmlDocument: TrackStickerHtmlDocument;
  public stickerDisplay: string[] = [];
  public documentStylesheet: HTMLStyleElement | null;
  private filters: IFilters;
  private tocUpdatedRef: Function;
  public modificationsPanelExpanded: boolean[] = [];

  constructor(
    pluginPanelService: PluginPanelService,
    private documentaryUnitService: DocumentaryUnitService,
    private cdr: ChangeDetectorRef
  ) {
    super(pluginPanelService);
  }

  ngOnInit(): void {
    this.loadSections(true);
    this.tocUpdatedRef = this.loadSections.bind(this);
    pubsub.on(TOC_UPDATED, this.tocUpdatedRef);
  }

  ngOnDestroy(): void {
    this.reset();
    pubsub.fire(EDITOR_PLUGIN_HIDE_TOOLBAR, { hideToolBar: false });
    pubsub.off(TOC_UPDATED, this.tocUpdatedRef);
  }

  private loadSections(showSpinner = false): void {
    this.documentaryUnitService.getSections(showSpinner).subscribe(sections => (this.sections = sections));
  }

  private reset(): void {
    this.stickerHtmlDocument = new TrackStickerHtmlDocument();
    this.versionsDates = [];
    this.lastDate = '';
    this.firstDate = '';
    this.stickerModificationsDocument = {} as IStickerModificationsDocument;
    this.modificationsPanelExpanded = [];
    this.displaySideNav(true);
  }

  public isToday(date: moment.Moment): boolean {
    return moment().isSame(date, 'day');
  }

  public filterHistory(filters: IFilters): void {
    this.reset();
    this.filterLoading = true;
    this.isFiltered = true;
    this.cdr.markForCheck();
    this.filters = filters;
    pubsub.fire(EDITOR_PLUGIN_HIDE_TOOLBAR, { hideToolBar: true });
    this.documentaryUnitService
      .getStickerFilteredHistory(filters)
      .pipe(finalize(() => (this.filterLoading = false)))
      .subscribe((result: IStickerModificationsDocument) => {
        this.stickerModificationsDocument = result;
        this.versionsDates = Object.keys(result.stickerModificationByDates);
        const versionDateLength = this.versionsDates?.length;
        if (versionDateLength) {
          for (let i = 0; i < versionDateLength; i++) {
            this.modificationsPanelExpanded.push(i === 0);
          }
          this.lastDate = this.versionsDates[0];
          this.firstDate = this.versionsDates[versionDateLength - 1];
        }
        this.initStickerHtmlDocument();
        this.loadStickerHtmlDocument();
      });
  }

  private initStickerHtmlDocument(): void {
    this.stickerHtmlDocument = new TrackStickerHtmlDocument();
    this.stickerHtmlDocument = { loading: false, loaded: false, display: false, content: '' };
    this.cdr.markForCheck();
  }

  public setTrackChanges(commit: ICommit): void {
    this.selectedCommit = commit;
  }

  public hideTrackChanges(): void {
    this.pluginPanelService.closeDiff();
    this.onClickClosePanel();
  }

  private loadStickerHtmlDocument(): void {
    this.stickerHtmlDocument = {
      loaded: true,
      content: this.stickerModificationsDocument.stickerHtmlDocument,
      loading: false,
      display: true,
    };
    this.cdr.markForCheck();
    this.displaySideNav();
    // We put latency here because the stickerHtmlDocument must be shown first in DOM with innerHtml.
    setTimeout(() => {
      this.linkStickers();
      this.linkShowMoreButton();
    }, 500);
  }

  private linkStickers(): void {
    const stickerButtons = document.querySelectorAll('.button-container button.sticker, .sticker-content button.sticker');
    stickerButtons.forEach(s => s.addEventListener('click', this.animateSticker.bind(this), false));
  }

  private linkShowMoreButton(): void {
    const showMoreButton = document.querySelectorAll('.show-more-button-container button.show-more-button');
    showMoreButton.forEach(s => s.addEventListener('click', this.expandSticker.bind(this), false));
  }

  animateSticker(event: any): void {
    const buttonId: string = event?.target?.id ? event.target.id : event.target.children[0].id;
    if (buttonId) {
      const animationElements = buttonId.includes('_doc')
        ? {
            stickerElement: document.getElementById(buttonId.replace('_doc', '_modifs'))?.parentElement,
            elementToScroll: document.getElementById('stickerModifications'),
            highlightElement: document.getElementById(buttonId.replace('_doc', '_highlight')),
          }
        : {
            stickerElement: document.getElementById(buttonId.replace('_modifs', '_doc')),
            elementToScroll: document.getElementById('stickerDocument'),
            highlightElement: document.getElementById(buttonId.replace('_modifs', '_doc'))?.parentElement,
          };
      const child = animationElements.highlightElement?.parentElement?.parentElement?.parentElement;
      if (child?.nodeName.toUpperCase() === VersionsTrackingComponent.MAT_EXPANSION_PANEL_TAG) {
        const parent = child.parentElement;
        const index = Array.prototype.indexOf.call(parent?.children, child);
        if (!this.modificationsPanelExpanded[index]) {
          animationElements.highlightElement = child;
          animationElements.stickerElement = child;
        }
      }
      if (animationElements.elementToScroll) {
        animationElements.elementToScroll.scrollTop = this.getOffset(animationElements.stickerElement);
      }

      this.highlightSticker(animationElements.highlightElement);
    }
  }

  expandSticker(event: any): void {
    const showMoreButtonElement = event.target;
    const showMoreButtonActivated = showMoreButtonElement.textContent === VersionsTrackingComponent.DISABLED_SHOW_MORE_BUTTON;
    const buttonParentContainer = showMoreButtonElement.parentElement.parentElement;
    const buttonContainer = buttonParentContainer.querySelector('.button-container');
    showMoreButtonActivated
      ? buttonContainer.classList.add(VersionsTrackingComponent.SHOW_MORE_STICKER_ENABLE)
      : buttonContainer.classList.remove(VersionsTrackingComponent.SHOW_MORE_STICKER_ENABLE);

    showMoreButtonElement.textContent = showMoreButtonActivated
      ? VersionsTrackingComponent.ENABLED_SHOW_MORE_BUTTON
      : VersionsTrackingComponent.DISABLED_SHOW_MORE_BUTTON;
  }

  public setModificationsPanelExpanded(index: number, isClosed = false): void {
    this.modificationsPanelExpanded[index] = !isClosed;
  }

  private highlightSticker(highlightElement: any): void {
    highlightElement.classList.add(VersionsTrackingComponent.HIGHLIGHT_ELEMENT);
    setTimeout(() => highlightElement.classList.remove(VersionsTrackingComponent.HIGHLIGHT_ELEMENT), 500);
  }

  private getOffset(elem: any): number {
    let y = elem.offsetTop;
    while (elem) {
      elem = elem.offsetParent;
      y += elem && !elem.className?.includes('track-changes') ? elem.offsetTop : 0;
    }
    return y;
  }

  public exportChanges(isExportPdf: boolean, isPaginated: boolean): void {
    this.filterLoading = false;
    this.documentaryUnitService.exportVersionTrackingPdfOrHtml(this.filters, isExportPdf, isPaginated);
  }

  public displaySideNav(reset = false): void {
    if (this.documentStylesheet) {
      this.documentStylesheet.remove();
      this.documentStylesheet = null;
    } else if (!reset) {
      this.documentStylesheet = document.createElement('style');
      this.documentStylesheet.appendChild(
        document.createTextNode(
          '.ck-inspector { display: none!important; }' +
            '.editor-sidenav { width: 0!important; }' +
            '.eolng_footnote_body { margin-left: 25px!important; text-indent: -25px!important;}' +
            'li[class*="eolng_"] { position: inherit !important;}' +
            'li[class*="eolng_"]::before { position: relative!important;}'
        )
      );
      document?.body?.appendChild(this.documentStylesheet);
    }
  }

  onResizeVerticalPanelStart(): void {
    this.cdr.markForCheck();
  }

  onResizeVerticalPanelEnd(): void {
    setTimeout(() => {
      this.cdr.markForCheck();
    });
  }
}
