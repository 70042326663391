import { ICKEToolbarItems } from 'app/html-editor/html-editor-authorities.utils';
import { Authority } from 'app/shared/enum/authority.enum';
import { PluginsCommand } from '../plugins-commands';

export const FOOTNOTE_EDITOR_TOOLBAR_AUTH: ICKEToolbarItems[] = [
  {
    value: PluginsCommand.BOLD,
    hasAnyAuthorities: [Authority.UPDATE_CONTENT_FORMAT],
  },
  {
    value: PluginsCommand.ITALIC,
    hasAnyAuthorities: [Authority.UPDATE_CONTENT_FORMAT],
  },
  {
    value: PluginsCommand.UNDERLINE,
    hasAnyAuthorities: [Authority.UPDATE_CONTENT_FORMAT],
  },
  {
    value: PluginsCommand.STRIKE,
    hasAnyAuthorities: [Authority.UPDATE_CONTENT_FORMAT],
  },
  {
    value: 'highlight',
    hasAnyAuthorities: [Authority.UPDATE_CONTENT_FORMAT],
  },
  {
    value: PluginsCommand.SUBSCRIPT,
    hasAnyAuthorities: [Authority.UPDATE_CONTENT_FORMAT],
  },
  {
    value: PluginsCommand.SUPSCRIPT,
    hasAnyAuthorities: [Authority.UPDATE_CONTENT_FORMAT],
  },
  {
    value: '|',
  },
  {
    value: 'stylingToolbarPlugin',
    hasAnyAuthorities: [Authority.APPLY_STYLE],
  },
  {
    value: '|',
  },
  {
    value: 'link',
    commands: ['link', 'unlink'],
    hasAnyAuthorities: [Authority.UPDATE_LINK],
  },
];
