import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'jhi-lookup-infos-dialog',
  templateUrl: './lookup-infos-dialog.component.html',
  styleUrls: ['./lookup-infos-dialog.component.scss'],
})
export class LookupInfosDialogComponent {
  constructor(public dialogRef: MatDialogRef<LookupInfosDialogComponent>) {}

  public close(): void {
    this.dialogRef.close();
  }
}
