import { HttpErrorResponse } from '@angular/common/http';
import { BalanceType, MappingBalance } from 'app/shared/enum/balance-type.enum';
import { VALUE_TYPE } from 'app/shared/enum/xslx.enum';
import { IAssetResponse } from 'app/shared/model/asset.model';
import { IFact, IFactUpdateResult } from './fact.model';
import { XbrlFormat } from 'app/shared/model/format.model';
import { IMonetaryUnit } from 'app/shared/model/monetary-unit.model';
import { ITableImportOptions } from 'app/shared/model/table-import-options.model';
import { ExportWarningDTO } from 'app/shared/model/taxonomy.model';

export const SpreadsheetRowHeight = 24;

export type RCSFData = RCSFFile | RCSFSheet;

export interface RCSFDataSelection {
  sheetName: string;
  range: string;
  single: string;
  value: string;
  rowIndex: number;
  colIndex: number;
  valueType?: VALUE_TYPE;
}

export interface RCSFSelection {
  selection: RCSFDataSelection;
}

// see: RcsfDataDTO server side
export interface RCSFCell {
  balance: BalanceType | null;
  booleanValue: boolean;
  colIdx: number;
  rowIdx: number;
  colName: string;
  createdAt: string | null;
  dateValue: string | null;
  fact: IFact | null;
  filename: string;
  formattedValue: string;
  id: number | null;
  importScale: number | null;
  language: string | null;
  numericValue: number | null;
  projectId: number | null;
  scaledValue: number | null;
  sheetName: string;
  stringValue: string;
  updatedAt: string | null;
  valueType: VALUE_TYPE | null;
  cellStyle?: string;
  colspan?: number;
  rowspan?: number;
  mappingScale: number | null;
  oimUnit: string | null;
  mappingBalance: MappingBalance | null;
  editable?: boolean;
}

export interface RCSFRow {
  rowIdx: number;
  cols: RCSFCell[];
}

export interface RCSFSheet {
  deleted?: boolean;
  sheetName: string;
  rows: RCSFRow[];
}

export interface RCSFFile {
  deletedSheets?: string[];
  filename: string;
  sheets: RCSFSheet[];
}

export interface IDynamicData {
  filename: string;
  sheetName: string;
  row: number;
  cell: RCSFCell;
  missing?: boolean;
}

export interface ITabularDynamicData {
  filename: string;
  datas: RCSFSheet;
  defaultFormats: { number: XbrlFormat; date: XbrlFormat };
  defaultGroupingSeparator: string;
  defaultDecimalsSeparator: string;
  projectUnits: IMonetaryUnit[];
  tableImportOptions: ITableImportOptions;
}

export interface RCSFImportData {
  errorsWarnings: ExportWarningDTO;
  factUpdateList: {
    facts: IFact[];
    addInsertedUpdatedDetails: boolean;
    createNewProjectPeriods: boolean;
  };
  /* Details of the update */
  factUpdateResult: IFactUpdateResult;

  /*   Number of deleted items */
  numDeleted: number;
  /* Number of files */
  numFiles: number;
  /* Number of inserted items */
  numInserted: number;
  /*   Number of sheets */
  numSheets: number;
  /*   Number of updated items */
  numUpdated: number;

  /* uploaded in dam */
  uploaded: boolean;
  /* imported as dynamic data */
  imported: boolean;
}

/**
 * Information about an RCSF mapped file import/update result
 */
export interface CheckRcsfImportResult {
  response: RCSFImportData;
  assetResponse?: IAssetResponse;
}

export interface IRCSFImportError extends HttpErrorResponse {
  DAMupload: boolean;
}

/**
 * Information about an RCSF mapped file import/update result
 */
export interface CheckRcsfImportResult {
  response: RCSFImportData;
  assetResponse?: IAssetResponse;
}

export interface ISheetDescriptor {
  filename: string;
  sheetName: string;
  sheetNames?: null;
}

export interface IRCSFFileSheetsNames {
  filename: string;
  sheetNames: string[];
  sheetName?: null;
}

export interface IFilesAndSheetsNames {
  rcsfFilenamesList: IRCSFFileSheetsNames[];
}

export interface IRCSFDataUpdated {
  rowIndex: number | null;
  colIndex: number | null;
  colName: string;
  sheetName: string;
  filename: string;
  value: string;
  valueType: VALUE_TYPE | null;
  factId?: number;
  factXbrlId?: string;
  scale?: number;
  balance?: BalanceType;
  lang?: string | null;
}

// https://www.typescriptlang.org/docs/handbook/2/narrowing.html#using-type-predicates
export function isDynamicData(object: IDynamicData | IFact): object is IDynamicData {
  return 'cell' in object;
}

export interface ICellSelectionDTO {
  rcsfData: IRCSFDataUpdated;
  oldValue: string;
  oldAddress: string;
}

export function getColumnNameFromIndex(columnIndex: number): string {
  let returnValue = '';
  const aChar = 'A'.charCodeAt(0);

  while (columnIndex >= 0) {
    const carIndex = columnIndex % 26;

    returnValue = returnValue + String.fromCharCode(aChar + carIndex);

    columnIndex -= 26;
  }

  return returnValue;
}

export function getColumnIndexFromName(columnName: string): number {
  let returnValue = 0;
  let i = 0;
  const len = columnName.length;
  const aChar = 'A'.charCodeAt(0);

  while (i < len) {
    const charCode = columnName.charCodeAt(i) - aChar;

    returnValue += charCode + 26 * i;

    i++;
  }

  return returnValue;
}
