import { Component, ViewEncapsulation, ChangeDetectorRef, ChangeDetectionStrategy, OnDestroy, OnInit } from '@angular/core';
import { PluginComponent } from '../../plugin.component';
import { utils as xlsxUtils } from 'xlsx';

import { PluginPanelService } from '../../plugin-panel.service';
import { TablePanelService } from '../table-panel.service';
import { Subscription } from 'rxjs';
import { ITableModelFull, IElementSelected, IRowOrColumnSelected } from 'app/shared/model/table-model.model';
import pubsub from 'app/pubsub';
import { EDITOR_PLUGIN_APPLY_COLUMN_LABEL, EDITOR_PLUGIN_DISABLE_PYJAMA_COLUMN } from 'app/pubsub.topics';
import { LabelService } from 'app/core/service/label.service';

@Component({
  selector: 'jhi-column-panel-plugin',
  templateUrl: './column-panel.component.html',
  styleUrls: ['./column-panel.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ColumnPanelComponent extends PluginComponent implements OnDestroy, OnInit {
  public panel: string;
  public open: boolean[] = [];
  public activated: string[] = [];
  public encodeColName: Function = xlsxUtils.encode_col;

  private _columnSubscription: Subscription;
  public actualColumn: IElementSelected;
  public actualModel: ITableModelFull;
  public columnsLabelsList: { id: number; name: string }[];

  public disablePyjama = false;
  public disablePyjamaField = false;

  constructor(
    pluginPanelService: PluginPanelService,
    private tablePanelService: TablePanelService,
    private changeDetectorRef: ChangeDetectorRef,
    private labelService: LabelService
  ) {
    super(pluginPanelService);
  }

  ngOnInit(): void {
    this._columnSubscription = this.tablePanelService.getSelectedColumnObservable().subscribe((column: IRowOrColumnSelected) => {
      this.setData(column);
    });
    this.setData(this.tablePanelService.getActualColumn());
  }

  ngOnDestroy(): void {
    this._columnSubscription.unsubscribe();
  }

  private setData(column: IRowOrColumnSelected): void {
    this.actualColumn = column.element;
    this.actualModel = column.model;
    this.disablePyjama = column.element.disablePyjama;
    this.disablePyjamaField = column.element.disablePyjamaField;
    this.retrieveLabelsLists(this.actualModel);
    this.changeDetectorRef.markForCheck();
  }

  private retrieveLabelsLists(model: ITableModelFull): void {
    this.labelService.getLabels().subscribe(labels => {
      const data = this.labelService.retrieveLabelsLists(model, labels, true, false);
      this.columnsLabelsList = data.columnsLabelsList;
      this.changeDetectorRef.markForCheck();
    });
  }

  public changeColumnLabel(labelId: number): void {
    pubsub.fire(EDITOR_PLUGIN_APPLY_COLUMN_LABEL, { labelId, columnId: this.actualColumn.index }, this.getEditorTopicContext());
  }

  public changePyjamaValue(enabled: boolean): void {
    pubsub.fire(EDITOR_PLUGIN_DISABLE_PYJAMA_COLUMN, { enabled, rowId: this.actualColumn.index }, this.getEditorTopicContext());
  }
}
