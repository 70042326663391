<h1 mat-dialog-title [class]="{ 'error-title': !data.blueBanner, 'blue-title': data.blueBanner }">
  <span *ngIf="data.translatedTitle">{{ data.translatedTitle }}</span>
  <span *ngIf="!data.translatedTitle">&nbsp;</span>
  <button type="button" mat-icon-button class="mat-dialog-close-btn" [mat-dialog-close]="false">
    <fa-icon icon="times"></fa-icon>
  </button>
</h1>
<div mat-dialog-content class="message-block" *ngIf="data.translatedMessage">{{ data.translatedMessage }}</div>
<div mat-dialog-content class="message-block" *ngIf="data.message">{{ data.message | translate }}</div>
<div mat-dialog-actions>
  <button mat-raised-button color="secondary" [mat-dialog-close]="false">
    <ng-container *ngIf="data.cancelBtn">{{ data.cancelBtn }}</ng-container>
    <ng-container *ngIf="!data.cancelBtn">{{ 'global.confirm.no' | translate }}</ng-container>
  </button>
  <button mat-raised-button color="primary" [mat-dialog-close]="true" cdkFocusInitial>
    <ng-container *ngIf="data.continueBtn">{{ data.continueBtn }}</ng-container>
    <ng-container *ngIf="!data.continueBtn">{{ 'global.confirm.yes' | translate }}</ng-container>
  </button>
</div>
