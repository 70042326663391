import { Component, ViewEncapsulation } from '@angular/core';

import { PluginComponent } from '../../plugin.component';
import { IFact } from 'app/shared/model/fact.model';
import { IDynamicData } from 'app/shared/model/rcsf.model';
import { PluginsCommand } from '../../plugins-commands';
import pubsub from 'app/pubsub';
import { EDITOR_PLUGIN_EXECUTE } from 'app/pubsub.topics';
import { PluginPanelService } from '../../plugin-panel.service';
import { ContextService } from 'app/core/service/context.service';
import { DataManagementType } from 'app/shared/enum/data-management-type.enum';

@Component({
  selector: 'jhi-dynamic-data-picking-plugin',
  templateUrl: './dynamic-data-picking-plugin.component.html',
  styleUrls: ['./dynamic-data-picking-plugin.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DynamicDataPickingPluginComponent extends PluginComponent {
  public panel: string | null;
  public selectedData: IFact | IDynamicData;
  public hasTaxonomy: boolean;
  public isXbrlProject: boolean;
  public dataTypeAccess: DataManagementType;

  constructor(public pluginPanelService: PluginPanelService, contextService: ContextService) {
    super(pluginPanelService);

    this.hasTaxonomy = contextService.hasTaxonomyImported;
    this.dataTypeAccess = contextService.currentDocumentContext.dataTypeAccess;
    this.isXbrlProject = this.dataTypeAccess === DataManagementType.XBRL_AND_DD;
  }

  showPanel(panel: string | null): void {
    this.panel = panel;
  }

  selectFormatPanel(event: { command: PluginsCommand; params: any; toFormat: boolean } | null): void {
    if (!event) {
      this.showPanel(null);
    } else {
      const { command, params, toFormat }: { command: PluginsCommand; params: any; toFormat: boolean } = event;
      if (toFormat) {
        this.panel = 'formatingData';
        this.selectedData = params.data;
      } else {
        pubsub.fire(
          EDITOR_PLUGIN_EXECUTE,
          {
            command,
            params,
          },
          this.getEditorTopicContext()
        );
        this.showPanel(null);
      }
    }
  }
}
