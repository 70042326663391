import { Injectable } from '@angular/core';
import { zip } from 'rxjs';
import { ConfigurationService } from './configuration.service';

@Injectable({
  providedIn: 'root'
})
export class InitialisationService {
  constructor(private configurationService: ConfigurationService) {}

  initializedApp(): Promise<any> {
    return new Promise((resolve, reject) => {
      const observables = [this.configurationService.getConfiguration()];

      zip(...observables).subscribe(
        data => resolve(data),
        () => reject(null)
      );
    });
  }
}
