<div #commentsContainer class="plugin-container comments-plugin-container">
  <mat-accordion class="standard">
    <mat-expansion-panel hideToggle expanded [formGroup]="formFilter">
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{ 'htmlEditor.plugins.comments.form.title' | translate }}
          <div class="buttons-container">
            <button mat-icon-button [matTooltip]="'htmlEditor.plugins.comments.form.reset' | translate" (click)="resetFormValues($event)">
              <mat-icon svgIcon="refresh" class="reset-button"></mat-icon>
            </button>
          </div>
        </mat-panel-title>
      </mat-expansion-panel-header>

      <div class="checkbox-container">
        <mat-checkbox color="primary" formControlName="displayComment">
          {{ 'htmlEditor.plugins.comments.form.showComments' | translate }}
        </mat-checkbox>

        <mat-checkbox color="primary" formControlName="displaySuggestion">
          {{ 'htmlEditor.plugins.comments.form.showSuggestions' | translate }}
        </mat-checkbox>
      </div>

      <div class="user-container">
        <mat-form-field>
          <mat-label jhiTranslate="htmlEditor.plugins.comments.form.users"></mat-label>
          <mat-select multiple formControlName="users">
            <mat-option *ngFor="let user of userList" [value]="user.value">{{ user.name }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="period-row">
        <div class="col-start">
          <jhi-calendar
            formControlName="periodDateStart"
            [changePlaceholder]="false"
            placeholder="{{ 'htmlEditor.plugins.comments.form.period_date_start' | translate }}"
          >
          </jhi-calendar>
        </div>
        <span class="col-sep" jhiTranslate="htmlEditor.plugins.comments.form.period_date_link"></span>
        <div class="col-end">
          <jhi-calendar
            formControlName="periodDateEnd"
            [changePlaceholder]="false"
            placeholder="{{ 'htmlEditor.plugins.comments.form.period_date_end' | translate }}"
            [minDate]="formFilter.get('periodDateStart')?.value"
          >
          </jhi-calendar>
        </div>
      </div>

      <div class="period-row">
        <div class="col-start">
          <mat-form-field>
            <input
              formControlName="periodHourStart"
              matInput
              type="time"
              [placeholder]="'htmlEditor.plugins.comments.form.period_hour_start' | translate"
            />
          </mat-form-field>
        </div>
        <span class="col-sep" jhiTranslate="htmlEditor.plugins.comments.form.period_hour_link"></span>
        <div class="col-end">
          <mat-form-field>
            <input
              formControlName="periodHourEnd"
              matInput
              type="time"
              [placeholder]="'htmlEditor.plugins.comments.form.period_hour_end' | translate"
            />
          </mat-form-field>
        </div>
      </div>

      <div class="section-title" jhiTranslate="htmlEditor.plugins.comments.form.order_title"></div>

      <div class="filter-row">
        <div class="col-start">
          <mat-form-field>
            <mat-label jhiTranslate="htmlEditor.plugins.comments.form.orderBy"></mat-label>
            <mat-select formControlName="orderBy">
              <mat-option *ngFor="let sort of commentFilterOrderByList" [value]="sort">
                {{ 'htmlEditor.plugins.comments.form.orderByValues.' + sort | translate }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-sep"></div>
        <div class="col-end">
          <mat-form-field>
            <mat-label jhiTranslate="htmlEditor.plugins.comments.form.order"></mat-label>
            <mat-select formControlName="order">
              <mat-option *ngFor="let sort of commentFilterOrderList" [value]="sort">
                {{ 'htmlEditor.plugins.comments.form.orderValues.' + sort | translate }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <div class="action-row">
        <button
          type="button"
          mat-flat-button
          color="primary"
          class="plugin-filter-btn"
          jhiTranslate="htmlEditor.plugins.comments.form.applyFilter"
          (click)="applyFilters()"
        ></button>
      </div>
      <div class="no-result-found-row" *ngIf="displayNoResultFound" jhiTranslate="htmlEditor.plugins.comments.form.noResult"></div>
    </mat-expansion-panel>
  </mat-accordion>
</div>
