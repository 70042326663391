import { Component, ViewEncapsulation, ChangeDetectorRef, ChangeDetectionStrategy, OnDestroy, OnInit } from '@angular/core';
import { PluginComponent } from '../../plugin.component';

import { PluginPanelService } from '../../plugin-panel.service';
import { TablePanelService } from '../table-panel.service';
import { Subscription } from 'rxjs';
import { ITableModelFull, IElementSelected, IRowOrColumnSelected } from 'app/shared/model/table-model.model';
import pubsub from 'app/pubsub';
import {
  EDITOR_PLUGIN_APPLY_ROW_HEADER,
  EDITOR_PLUGIN_APPLY_ROW_LABEL,
  EDITOR_PLUGIN_DISABLE_PYJAMA_ROW,
  EDITOR_PLUGIN_REVERSE_PYJAMA_ROW,
  EDITOR_PLUGIN_UPDATE_HEIGHT_ROW,
} from 'app/pubsub.topics';
import { LabelService } from 'app/core/service/label.service';

@Component({
  selector: 'jhi-row-panel-plugin',
  templateUrl: './row-panel.component.html',
  styleUrls: ['./row-panel.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RowPanelComponent extends PluginComponent implements OnDestroy, OnInit {
  public panel: string;
  public header = false;
  public open: boolean[] = [];
  public activated: string[] = [];

  public actualRow: IElementSelected;
  public actualModel: ITableModelFull;
  public rowsLabelsList: { id: number; name: string }[];

  public disablePyjama = false;
  public reversePyjama = false;

  private _rowSubscription: Subscription;

  constructor(
    pluginPanelService: PluginPanelService,
    private tablePanelService: TablePanelService,
    private changeDetectorRef: ChangeDetectorRef,
    private labelService: LabelService
  ) {
    super(pluginPanelService);
  }

  ngOnInit(): void {
    this._rowSubscription = this.tablePanelService.getSelectedRowObservable().subscribe(row => this.setData(row));
    this.setData(this.tablePanelService.getActualRow());
  }

  ngOnDestroy(): void {
    this._rowSubscription.unsubscribe();
  }

  private setData(row: IRowOrColumnSelected): void {
    this.actualRow = row.element;
    this.actualModel = row.model;
    this.header = row.element.isHeader || false;
    this.disablePyjama = row.element.disablePyjama || false;
    this.reversePyjama = row.element.reversePyjama || false;
    this.retrieveLabelsLists(this.actualModel);
    this.changeDetectorRef.markForCheck();
  }

  private retrieveLabelsLists(model: ITableModelFull): void {
    this.labelService.getLabels().subscribe(labels => {
      const data = this.labelService.retrieveLabelsLists(model, labels, false, true);
      this.rowsLabelsList = data.rowsLabelsList;
      this.changeDetectorRef.markForCheck();
    });
  }

  public changeRowLabel(labelId: number): void {
    pubsub.fire(EDITOR_PLUGIN_APPLY_ROW_LABEL, { labelId, rowId: this.actualRow.index }, this.getEditorTopicContext());
  }

  public changeRowHeader(enabled: boolean): void {
    pubsub.fire(EDITOR_PLUGIN_APPLY_ROW_HEADER, { enabled, rowId: this.actualRow.index }, this.getEditorTopicContext());
  }

  public changePyjamaValue(enabled: boolean): void {
    pubsub.fire(EDITOR_PLUGIN_DISABLE_PYJAMA_ROW, { enabled, rowId: this.actualRow.index }, this.getEditorTopicContext());
  }

  public changeReverseValue(enabled: boolean): void {
    pubsub.fire(EDITOR_PLUGIN_REVERSE_PYJAMA_ROW, { enabled, rowId: this.actualRow.index }, this.getEditorTopicContext());
  }

  public changeRowHeight(): void {
    pubsub.fire(
      EDITOR_PLUGIN_UPDATE_HEIGHT_ROW,
      { height: this.actualRow.height, rowId: this.actualRow.index },
      this.getEditorTopicContext()
    );
  }
}
