<div class="plugin-container table-accessiblity-panel-plugin-container">
  <div class="nav-bar">
    <div class="nav-button" (click)="selectDirection(direction.DESC)">
      <button mat-icon-button>
        <mat-icon svgIcon="arrow-left"></mat-icon>
      </button>
      <span jhiTranslate="htmlEditor.plugins.tableAccessibility.previous">previous</span>
    </div>

    <div class="nav-button" (click)="selectDirection(direction.ASC)">
      <span jhiTranslate="htmlEditor.plugins.tableAccessibility.next">next</span>
      <button mat-icon-button>
        <mat-icon svgIcon="arrow-right"></mat-icon>
      </button>
    </div>
  </div>

  <div class="container">
    <div
      class="containerTitle"
      jhiTranslate="htmlEditor.plugins.tableAccessibility.tableDescription"
      (click)="toggle(tableDescription)"
    ></div>
    <div #tableDescription class="table-desc">
      <div class="desc-toggle">
        <mat-label [ngClass]="{ highlight: !descManualBool }" jhiTranslate="htmlEditor.plugins.tableAccessibility.descTitle"></mat-label>
        <mat-slide-toggle [checked]="descManualBool" (change)="descManualBool = !descManualBool" color="primary"></mat-slide-toggle>
        <mat-label [ngClass]="{ highlight: descManualBool }" jhiTranslate="htmlEditor.plugins.tableAccessibility.descManual"></mat-label>
      </div>

      <mat-form-field *ngIf="descManualBool">
        <mat-label jhiTranslate="htmlEditor.plugins.tableAccessibility.description"></mat-label>
        <input matInput [(ngModel)]="descManualValue" (focusout)="manualDescriptionChanged()" maxlength="255" />
      </mat-form-field>

      <label *ngIf="!descManualBool" id="select-title" jhiTranslate="htmlEditor.plugins.tableAccessibility.selectTitle"></label>
      <mat-radio-group *ngIf="!descManualBool" class="titles-list" aria-labelledby="select-title" [value]="selectedTitle">
        <mat-radio-button *ngFor="let title of DUTitles" [(value)]="title.uuid" color="primary" (change)="titleSelected(title.uuid)">
          <span [innerHTML]="title.numbering | safeHtml"></span>
          <span [innerHTML]="title.content | safeHtml"></span>
        </mat-radio-button>
      </mat-radio-group>
    </div>
  </div>

  <div class="container">
    <div
      class="containerTitle"
      jhiTranslate="htmlEditor.plugins.tableAccessibility.columnsHeader"
      (click)="toggle(columnsHeaderContainer)"
    ></div>
    <div #columnsHeaderContainer class="column-header">
      <div class="column-list">
        <div *ngFor="let column of columnsLabels; let i = index">
          <mat-checkbox color="primary" (change)="changeColumnSelection($event, i)" [checked]="columnsHeader[i] ?? false"
            ><span jhiTranslate="htmlEditor.plugins.tableAccessibility.column">Column</span> {{ i + 1 }} -
            {{ columns.get(column) }}</mat-checkbox
          >
        </div>
      </div>
    </div>
  </div>
</div>
