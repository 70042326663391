export enum faviconManagement {
  PATH = 'content/images/favicon/',

  // Extentions
  IMAGE_EXTENTION = '.png',
  ICON_EXTENTION = '.svg',

  // folders
  DEFAULT_FAVICON = 'default/',
  APPLETOUCH = 'apple-touch/',
  CHROME192 = 'android-chrome/192/',
  CHROME512 = 'android-chrome/512/',
  X16 = '16/',
  X32 = '32/',

  // favicon html selectors
  ICON_32 = '#favIconx32',
  ICON_16 = '#favIconx16',
  ICON_APPLE = '#appleTouch_favIcon',
  ICON_DEFAULT = '#default_favIcon',
}
