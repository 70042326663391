import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'jhi-front-error',
  templateUrl: './front-error.component.html',
  styleUrls: ['./front-error.component.scss'],
})
export class FrontErrorComponent {
  constructor(public dialogRef: MatDialogRef<FrontErrorComponent>, @Inject(MAT_DIALOG_DATA) public key: string) {}

  closeModal(): void {
    this.dialogRef.close();
  }
}
