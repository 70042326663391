import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'splitQname',
})
export class SplitQnamePipe implements PipeTransform {
  transform(qname: string, type?: string): string {
    const values = qname.split(':');
    if (type && type === 'name' && values?.length > 0) {
      return values[1];
    } else {
      return values[0];
    }
  }
}
