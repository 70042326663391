<form role="form" [formGroup]="entryForm" class="lookup-entry-form">
  <jhi-accordion-form-group
    #accordionFormGroup
    title="{{ 'htmlEditor.plugins.lookup.entry' | translate: { order: entryIndex } }}"
    (deleteRule)="deleteEntry.emit()"
    (moveRuleUp)="moveEntryUp.emit(entry)"
    (moveRuleDown)="moveEntryDown.emit(entry)"
    [showActionButtons]="true"
    [expanded]="expanded"
    [disableMoveDownButton]="disableMoveDownButton"
    [disableMoveUpButton]="disableMoveUpButton"
  >
    <mat-form-field>
      <mat-label jhiTranslate="htmlEditor.plugins.lookup.linkType.label"></mat-label>
      <mat-select formControlName="linkType">
        <mat-option *ngFor="let type of linkTypes" [value]="type">{{
          'htmlEditor.plugins.lookup.linkType.' + type | translate
        }}</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="fill">
      <mat-label jhiTranslate="htmlEditor.plugins.lookup.searchTitles"></mat-label>
      <input matInput type="text" formControlName="searchedTitles" />

      <fa-icon
        icon="times"
        *ngIf="entryForm.get('searchedTitles')?.value"
        matSuffix
        aria-label="Clear"
        (click)="clearSearchField(); $event.stopPropagation()"
      ></fa-icon>

      <button
        mat-icon-button
        matSuffix
        *ngIf="isJointType()"
        [disabled]="disabledScrollToLeftTitleButton()"
        [matTooltip]="'htmlEditor.plugins.lookup.scrollToFirstTitle' | translate"
        (click)="accordionFormGroup.scrollToSelectedTitle(true); $event.stopPropagation()"
      >
        <mat-icon svgIcon="icon-arrow-left-no-border"></mat-icon>
      </button>
      <button
        mat-icon-button
        matSuffix
        [disabled]="disabledScrollToTitleRightButton()"
        [matTooltip]="
          (isJointType() ? 'htmlEditor.plugins.lookup.scrollToSecondTitle' : 'htmlEditor.plugins.lookup.scrollToSelectedTitle') | translate
        "
        (click)="accordionFormGroup.scrollToSelectedTitle(!isJointType()); $event.stopPropagation()"
      >
        <mat-icon svgIcon="icon-arrow-right-no-border"></mat-icon>
      </button>
    </mat-form-field>

    <!-- For DISJOINT SELECTION -->
    <mat-radio-group *ngIf="!isJointType()" formControlName="disjointTitle" class="titles-list">
      <mat-radio-button *ngFor="let title of documentTitles | titlesFilter: searchedTitles.value" [value]="title" color="primary">
        <span [innerHTML]="title.numbering | safeHtml"></span>
        <span [innerHTML]="title.content | safeHtml"></span>
      </mat-radio-button>
    </mat-radio-group>

    <!-- For JOINT SELECTION -->
    <div *ngIf="isJointType()" class="titles-list">
      <mat-checkbox
        #titleCheckbox
        color="primary"
        *ngFor="let title of documentTitles | titlesFilter: searchedTitles.value"
        [checked]="isChecked(title.uuid)"
        [disabled]="jointTitles.value.length >= 2 && !titleCheckbox.checked"
        (change)="changeCheckboxValue($event, title)"
      >
        <span [innerHTML]="title.numbering | safeHtml"></span>
        <span [innerHTML]="title.content | safeHtml"></span>
      </mat-checkbox>
    </div>

    <!-- Display type -->
    <mat-form-field *ngIf="entry.lookupType !== 'title-ref'">
      <mat-label jhiTranslate="htmlEditor.plugins.lookup.displayType.label"></mat-label>
      <mat-select formControlName="displayType">
        <mat-option *ngFor="let displayType of displayTypes" [value]="displayType">{{
          'htmlEditor.plugins.lookup.displayType.' + displayType | translate
        }}</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field *ngIf="entry.lookupType === 'title-ref'">
      <mat-label jhiTranslate="htmlEditor.plugins.lookup.displayType.label"></mat-label>
      <mat-select formControlName="displayType">
        <mat-option *ngFor="let type of TypePageRef" [value]="type">{{
          'htmlEditor.plugins.lookup.displayType.pageRef.' + type | translate
        }}</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-slide-toggle color="primary" formControlName="addNewLine">
      {{ 'htmlEditor.plugins.lookup.addNewLine' | translate }}
    </mat-slide-toggle>
  </jhi-accordion-form-group>
</form>
