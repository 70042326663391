<div class="main-block">
    <div class="header-block">
        <jhi-input-search [disabled]="!dimensions?.length && !filtered"
            placeholder="dataEditor.dimension.search.placeholder" (searchTermChanged)="filter($event)">
        </jhi-input-search>
    </div>
    <div class="content-block">
        <div class="table-wrapper">
            <!-- Arevio considered a dimension like a concept -->
            <jhi-concept-detailled *ngFor="let dimension of dimensions; let i = index;" [concept]="dimension"
                    (click)="onSelected(i)"></jhi-concept-detailled>
        </div>
    </div>
</div>
