import { Pipe, PipeTransform } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { ContextService } from 'app/core/service/context.service';
import { SearchType } from 'app/shared/enum/search-type.enum';
import { filterConcept } from 'app/shared/util/xbrl-utils';

@Pipe({
  name: 'annotateItemsFilter',
})
export class AnnotateItemsFilterPipe implements PipeTransform {
  public documentLanguage: string;
  constructor(private contextService: ContextService) {
    this.documentLanguage = this.contextService.currentDocumentContext.language.code?.substring(0, 2) ?? '';
  }
  transform(items: AbstractControl[], query: string): AbstractControl[] {
    if (!items) {
      return [];
    }
    if (query === '') {
      return items;
    }
    return items.filter(({ value: { fact } }) =>
      filterConcept(fact.concept, this.documentLanguage, query.toLowerCase() || '', SearchType.ALL)
    );
  }
}
