import { AssetFileType } from './asset-file-type.enum';

export enum DataManagementType {
  NONE = 'N/A',
  DYNAMIC_DATA = 'DYNAMIC DATA ONLY',
  XBRL_AND_DD = 'XBRL & DYNAMIC DATA',
}

export const DataManagementTypeToFileExtensions = {
  'DYNAMIC DATA ONLY': [AssetFileType.XLS, AssetFileType.XLSX],
  'XBRL & DYNAMIC DATA': [
    AssetFileType.XBRL,
    AssetFileType.XML,
    AssetFileType.XHTML,
    AssetFileType.HTML,
    AssetFileType.RCSF,
    AssetFileType.TAXONOMY,
    AssetFileType.RAR,
    AssetFileType.ZIP,
    AssetFileType.XLS,
    AssetFileType.XLSX,
  ],
  'N/A': [AssetFileType.OTHER],
};
