import { SepXbrlMultiPart } from 'app/shared/enum/sep-xbrl-multi-part.enum';
import { IDate } from './date.model';
import { IConcept, IEntryPointTable } from 'app/shared/model/concept.model';
import { PeriodType } from '../enum/period-type.enum';
import { Moment } from 'moment';
import { RCSFCell } from './rcsf.model';
import { ISheetSelection } from './sheet-selection.model';
import { ProjectEntryPoint } from './xbrl.model';
import { VALUE_TYPE } from '../enum/xslx.enum';
import { IFootnote } from './footnote.model';
import { XBRLType } from '../enum/xbrl-type.enum';

export interface IDimension {
  dimAxis: IConcept;
  dimMember: IConcept;
}

export interface IContext {
  dimensions: Array<IDimension>;
  entityIdentifier: string | null;
  entityScheme: string;
  id?: number;
  period: IDate | null;
}

//
export interface IFactsByRole {
  projectEntrypoint: ProjectEntryPoint;
  tables: IEntryPointTable[];
}

export class IFactUtils {
  public static isStringMultiZone(fact: IFact) {
    return fact?.concept?.type === XBRLType.STRING && fact?.factValue && fact?.factValue.split(SepXbrlMultiPart.DATA).length > 1;
  }

  public static nbZoneFactString(fact: IFact) {
    return fact?.concept?.type === XBRLType.STRING && fact?.factValue ? fact.factValue.split(SepXbrlMultiPart.DATA).length : 0;
  }

  /**
   * use only for unknown fact
   * create a fact from factXbrlId
   * set "conceptName" fake properties (used in view to detect unknown fact)
   * @param factXbrlId
   * @param value
   */
  public static createFactFromXbrlId(factXbrlId: string, value: string): IFact {
    const factXbrlList = factXbrlId.split('#');
    const periodType = factXbrlList[4] as PeriodType;

    const fact: IFact = {
      conceptName: factXbrlList[0],
      periodType,
      factValue: value,
      startDate: periodType === PeriodType.PERIOD ? factXbrlList[5] : null,
      endDate: periodType === PeriodType.PERIOD ? factXbrlList[6] : null,
      oimUnit: factXbrlList[2],
      factXbrlId: factXbrlList[3],
    };

    return fact;
  }
}

// see : FactDTO server side
export interface IFact {
  concept?: IConcept;
  conceptName?: string; // fake name
  context?: IContext;
  createdAt?: string | Moment;
  projectEntrypointId?: number;
  decimals?: number;
  edited?: boolean;
  factValue?: string;
  factXbrlId?: string;
  id?: number;
  language?: string;
  oimUnit?: string;
  updatedAt?: string | Moment;
  xhtmlValue?: string;
  selection?: ISheetSelection;
  factIndex?: number | null;

  /** TODO : Refactor ArevioApi-v0604 refactor **/
  dimensions?: any; // use IContext
  currency?: any; // use oimUnit or Concept Type
  date?: IDate; // User IPeriod in IContext
  periodType?: PeriodType; // User IPeriod in IContext
  startDate?: string | null; // User IPeriod in IContext
  endDate?: string | null; // User IPeriod in IContext

  duplicateInfo?: IDuplicateInfos;
}
export interface IFactUpdateResult {
  deletedFacts?: IFact[];
  dimensionAxesDelta?: number;
  dimensionMembersDelta?: number;
  insertedFacts?: IFact[];
  numDeletedFacts?: number;
  numIgnoredCompleteDuplicates?: number;
  numInsertedFacts?: number;
  numRelatedRcsfUpdated?: number;
  numUpdatedFacts?: number;
  propagatedCells?: RCSFCell[];
  unitsDelta?: number;
  updatedFacts?: IFact[];
}

// see: FactUpdate DTO server side
export interface IUpdateFact {
  decimals?: number;
  dimensions?: { axisQname: string; memberQnames: string; memberValue?: string }[];
  endDate?: string | Moment;
  entityIdnetifier?: string;
  entityScheme?: string;
  factValue?: string;
  id?: number;
  language?: string;
  oimUnit?: string;
  periodType?: PeriodType;
  qname: string;
  rcsfData?: RCSFCell; // see: RcsfDataDTO server side
  startDate?: string | Moment;
}

export interface IUpdateFactResponse {
  insertedFacts: IFact[];
  updatedFacts: IFact[];
}

export interface IDeleteFactResponse {
  documentId: number;
  factXbrlId: string;
}

export interface IFactValueUpdate {
  factId: number | null;
  factValue: string;
}

export interface IDuplicateInfos {
  dupValues?: IDuplicateInfo[];
  selectedValue?: IDuplicateInfo;
}

export interface IDuplicateInfo {
  decimals: number; // value used in the UI
  value: string; // value scaled with the other duplicated fact value's scale
  scale?: number; // value of cell data
  index?: number; // index related to the selected duplicated value chosen 0 = oldValue, 1 = newValue
}

// XbrlFactDTO backEnd used in document or Fact linked to XBRL Footnote
export interface ReferencedFact {
  id?: number;
  active?: boolean;
  factKey: string;
  documentaryUnitId: number;
}

export interface FactVerificationErrorDTO {
  errors: LogEntryFactValidationDTO[];
}

export interface LogEntryFactValidationDTO {
  message: string;
  footnote: string;
  fact: IFact;
}

export interface IDuplicateFactData {
  id: number;
  factXbrlId: string;
  oldValue: string;
  factQName: string;
  duplicateInfo: IDuplicateInfo[];
  conceptType: XBRLType;
  concept?: IConcept;
  dataType?: VALUE_TYPE;
  scale?: number;
}

export interface IFactReferenceToCheck {
  valueToBeInserted: string;
  oimUnit: string;
  lang: string;
  balance: string;
  conceptQname: string;
  dimMemberQname: string;
  durationStartDate: string;
  durationEndDate: string;
  importScale: number;
  mappingScale: number;
}

export interface IFactDTO {
  concept: IConcept;
  context: IContext;
  createdAt: string | Moment;
  decimals: number;
  duplicateInfo: IDuplicateInfos;
  edited: boolean;
  factValue: string;
  factXbrlId: string;
  footnotes: IFootnote;
  id: number;
  language: string;
  oimUnit: string;
  updatedAt: string | Moment;
  xhtmlValue: string;
}
