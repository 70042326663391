import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'splitString',
  pure: true,
})
export class SplitStringPipe implements PipeTransform {
  transform(str: string): string {
    const strArr = str.split('.');
    return strArr[0];
  }
}
