export enum Authority {
  LAB_ADMIN = 'ROLE_LAB_ADMIN',
  LAB_CDP = 'ROLE_LAB_CDP',
  LAB_CDP_EXT = 'ROLE_LAB_CDP_EXT',
  LAB_GT = 'ROLE_LAB_GT',
  LAB_RCL = 'ROLE_LAB_RCL',
  LAB_DCL = 'ROLE_LAB_DCL',
  LAB_RES = 'ROLE_LAB_RES',
  CLI_COR = 'ROLE_CLI_COR',
  ACCESS_EOL_PAGE = 'ACCESS_EOL_PAGE',
  ACCESS_EOL_PREVIEW = 'ACCESS_EOL_PREVIEW',
  ACCESS_EOL_DATA = 'ACCESS_EOL_DATA',
  ACCESS_EOL_STYLE = 'ACCESS_EOL_STYLE',
  ACCESS_ADMIN = 'ACCESS_ADMIN',
  UPDATE_COMMENTS = 'UPDATE_COMMENTS',
  INSERT_IMAGE = 'INSERT_IMAGE',
  MOVE_IMAGE = 'MOVE_IMAGE',
  RESIZE_IMAGE = 'RESIZE_IMAGE',
  INSERT_DYNAMIC_DATA = 'INSERT_DYNAMIC_DATA',
  TAG_DYNAMIC_DATA = 'TAG_DYNAMIC_DATA',
  FIND_AND_REPLACE = 'FIND_AND_REPLACE',
  HISTORY = 'HISTORY',
  UPDATE_SUGGESTION = 'UPDATE_SUGGESTIONS',
  SPELLCHECK = 'SPELLCHECK',
  FOOTNOTES = 'FOOTNOTES',
  APPLY_STYLE = 'APPLY_STYLE',
  UPDATE_CONTENT_FORMAT = 'FORMAT_CONTENT',
  DOCUMENT_LAYOUT_OPERATIONS = 'DOCUMENT_LAYOUT_OPERATIONS',
  UPDATE_LINK = 'UPDATE_LINK',
  UPDATE_CONTENT = 'UPDATE_CONTENT',
  TRACK_CHANGES = 'TRACK_CHANGES',
  EXPORT_XHTML = 'EXPORT_XHTML',
  EXPORT_PDF_LD = 'EXPORT_PDF_LD',
  EXPORT_PDF_HD = 'EXPORT_PDF_HD',
  EXPORT_CLEANED_HTML = 'EXPORT_CLEANED_HTML',
  EXPORT_XHTML_WITH_VIEWER = 'EXPORT_XHTML_WITH_VIEWER',
  EXPORT_SITE_FORMAT = 'EXPORT_SITE_FORMAT',
  EXPORT_ESEF = 'EXPORT_ESEF',
  EXPORT_WORD = 'EXPORT_WORD',
  EXPORT_XLIFF = 'EXPORT_XLIFF',
  UPDATE_DATA_CONFIGURATION = 'UPDATE_DATA_CONFIGURATION',
  UPDATE_DATA = 'UPDATE_DATA',
  ADD_REMOVE_TOC_SECTION = 'ADD_REMOVE_TOC_SECTION',
  SECTION_LOCK = 'SECTION_LOCK',
  UPDATE_GRAPH_MODEL = 'UPDATE_GRAPH_MODEL',
  UPDATE_STYLE_MODEL = 'UPDATE_STYLE_MODEL',
  OVERRIDE_ELEMENTS = 'OVERRIDE_ELEMENTS',
  TOC_SYNC = 'TOC_SYNC',
  ACCESS_SECTION = 'ACCESS_SECTION',
  COPY_PASTE_CONTENT = 'COPY_PASTE_CONTENT',
  MANUAL_SAVE = 'MANUAL_SAVE',
  EXPORT_LORE = 'EXPORT_LORE',
  IMPORT_EXPORT_NOMENCLATURE_FILE = 'IMPORT_EXPORT_NOMENCLATURE_FILE',
  IMPORT_EXPORT_XLIFF = 'IMPORT_EXPORT_XLIFF',
  IMPORT_SEPARATORS = 'IMPORT_SEPARATORS',
  USER_LIST = 'USER_LIST',
  SUPER_ADMIN = 'SUPER_ADMIN',
  SPLIT_DU = 'SPLIT_DU',
  IMPORT_EXPORT_MACRO_TAGGING = 'IMPORT_EXPORT_MACRO_TAGGING',
  PRIORITY_EXPORT = 'PRIORITY_EXPORT',
}
