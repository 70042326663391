import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { debounceTime, publishReplay, refCount, tap } from 'rxjs/operators';

import {
  GROUP_OF_FACTS_INSERTED_IN_EDITOR,
  FACT_INSERTED_IN_EDITOR,
  FOOTNOTE_DELETED_FROM_EDITOR,
  FOOTNOTE_INSERTED_IN_EDITOR,
  FOOTNOTE_LIST_IN_EDITOR,
  FOOTNOTE_LIST_IN_EDITOR_REQUEST,
  FOOTNOTE_HIGHLIGHT_IN_EDITOR,
} from 'app/pubsub.topics';
import pubsub from 'app/pubsub';
import { IFact, ReferencedFact } from 'app/shared/model/fact.model';
import { SERVER_API_URL } from 'app/app.constants';
import { ReferencedFootnote } from 'app/shared/model/xbrl-footnote.model';
import { ContextService } from './context.service';
import { EditorService } from './editor.service';

@Injectable({
  providedIn: 'root',
})
export class XbrlActionService {
  private apiRootUrl = `${SERVER_API_URL}/api`;
  private xbrlFootnoteCache$: Map<number, Observable<ReferencedFootnote[]>> = new Map<number, Observable<ReferencedFootnote[]>>();
  private updateXbrlFootnoteCacheSubject: Map<number, Subject<number>> = new Map<number, Subject<number>>();
  private highlightEnabled = false;

  constructor(private httpClient: HttpClient, private contextService: ContextService) {
    pubsub.on(FACT_INSERTED_IN_EDITOR, ({ detail }: CustomEvent) => {
      if (detail?.params?.data && detail?.params?.documentUnitId) {
        const factDto = this.toFactReference(detail.params.data, detail.params.documentUnitId);
        this.createOrGetFactforUD(factDto).subscribe();
      }
    });

    pubsub.on(GROUP_OF_FACTS_INSERTED_IN_EDITOR, ({ detail }: CustomEvent) => {
      if (detail?.params?.data && detail?.params?.documentUnitId) {
        const xbrlFactKeys: string[] = detail.params.data;
        const documentUnitId = detail.params.documentUnitId;
        this.saveFactsForUd(xbrlFactKeys, documentUnitId).subscribe();
      }
    });

    pubsub.on(FOOTNOTE_DELETED_FROM_EDITOR, ({ detail }: CustomEvent) => {
      if (detail?.xbrlFootnote) {
        this.deleteXbrlFootnote(detail.xbrlFootnote).subscribe();
      }
    });

    pubsub.on(FOOTNOTE_INSERTED_IN_EDITOR, ({ detail }: CustomEvent) => {
      if (detail?.xbrlFootnote) {
        const xbrlFootnote = detail.xbrlFootnote;
        if (detail.isNewXbrlFootnote) {
          this.updateCache(xbrlFootnote.documentaryUnitId);
        } else {
          xbrlFootnote.active = true;
          this.updateXbrlFootnote(xbrlFootnote).subscribe();
        }
      }
    });

    pubsub.on(FOOTNOTE_LIST_IN_EDITOR_REQUEST, ({ detail }: CustomEvent) => {
      this.getXbrlFootnotesByUD(detail.documentaryUnitId).subscribe();
    });
    pubsub.on(FOOTNOTE_HIGHLIGHT_IN_EDITOR, ({ detail }: CustomEvent) => {
      this.highlightEnabled = detail.enabled || false;
    });
  }

  public removeLinkBetweenFactAndFootnote(factKey: string, documentaryUnitId: number, footnoteId: number): Observable<any> {
    const params: HttpParams = new HttpParams().set('skipError', 'true');
    const factKeyEncoded = btoa(factKey);
    return this.httpClient
      .delete(`${this.apiRootUrl}/xbrl-fact/${factKeyEncoded}/documentary-unit/${documentaryUnitId}/xbrl-footnote/${footnoteId}`, {
        params,
      })
      .pipe(tap(() => this.updateCache(documentaryUnitId)));
  }

  private createOrGetFactforUD(fact: ReferencedFact): Observable<ReferencedFact> {
    return this.httpClient
      .post<ReferencedFact>(`${this.apiRootUrl}/xbrl-fact`, fact)
      .pipe(tap(() => this.updateCache(fact.documentaryUnitId)));
  }

  private saveFactsForUd(facts: string[], documentUnitId: number): Observable<ReferencedFact[]> {
    return this.httpClient
      .post<ReferencedFact[]>(`${this.apiRootUrl}/xbrl-fact/list/documentUnitId/${documentUnitId}`, facts)
      .pipe(tap(() => this.updateCache(documentUnitId)));
  }

  public getListFootnotesForFactKeyByUD(iFact: IFact, documentUnitId: number): Observable<ReferencedFootnote[]> {
    const factKey = btoa(iFact.factXbrlId ?? '');
    return this.httpClient.get<ReferencedFootnote[]>(
      `${this.apiRootUrl}/xbrl-footnote/documentary-unit/${documentUnitId}/fact-key/${factKey}`
    );
  }

  public getListFactbyUD(documentUnitId: number): Observable<IFact[]> {
    const projectId = this.contextService.currentProjectId;
    const lang = this.contextService.currentDocumentContext.language.code?.substring(0, 2);
    return this.httpClient.get<IFact[]>(`${this.apiRootUrl}/xbrl-fact/documentary-unit/${documentUnitId}/${projectId}/${lang}`);
  }

  public createXbrlFootnote(xbrlFootnote: ReferencedFootnote): Observable<ReferencedFootnote> {
    return this.httpClient
      .post<ReferencedFootnote>(`${this.apiRootUrl}/xbrl-footnote`, xbrlFootnote)
      .pipe(tap(() => this.updateCache(xbrlFootnote.documentaryUnitId)));
  }

  public updateXbrlFootnote(xbrlFootnote: ReferencedFootnote): Observable<ReferencedFootnote> {
    return this.httpClient
      .put<ReferencedFootnote>(`${this.apiRootUrl}/xbrl-footnote`, xbrlFootnote)
      .pipe(tap(() => this.updateCache(xbrlFootnote.documentaryUnitId)));
  }

  public deleteXbrlFootnote(xbrlFootnote: ReferencedFootnote): Observable<ReferencedFootnote> {
    xbrlFootnote.active = false;
    return this.httpClient
      .put<ReferencedFootnote>(`${this.apiRootUrl}/xbrl-footnote`, xbrlFootnote)
      .pipe(tap(() => this.updateCache(xbrlFootnote.documentaryUnitId)));
  }

  public getXbrlFootnote(footnoteId: number): Observable<ReferencedFootnote> {
    const projectId = this.contextService.currentProjectId;
    const lang = this.contextService.currentDocumentContext.language.code?.substring(0, 2);
    return this.httpClient.get<ReferencedFootnote>(`${this.apiRootUrl}/xbrl-footnote/project/${projectId}/${lang}/footnote/${footnoteId}`);
  }

  private toFactReference(iFact: IFact, documentaryUnitId: number): ReferencedFact {
    return { documentaryUnitId, factKey: iFact?.factXbrlId ?? '' };
  }

  public removeCache(documentUnitId: number): void {
    if (this.xbrlFootnoteCache$.has(documentUnitId)) {
      this.xbrlFootnoteCache$.delete(documentUnitId);
    }
  }

  private updateCache(documentUnitId: number): void {
    if (this.highlightEnabled) {
      let subject = this.updateXbrlFootnoteCacheSubject.get(documentUnitId);
      if (!subject) {
        subject = new Subject();
        this.updateXbrlFootnoteCacheSubject.set(documentUnitId, subject);
        subject.pipe(debounceTime(150)).subscribe((documentUnitId: number) => {
          this.getXbrlFootnotesByUD(documentUnitId, true).subscribe();
        });
      }
      subject.next(documentUnitId);
    } else {
      // remove data in cache (the next annotation list calling will update the cache)
      this.removeCache(documentUnitId);
    }
  }

  private getXbrlFootnotesByUD(documentUnitId: number, force = false): Observable<ReferencedFootnote[]> {
    if (force || !this.xbrlFootnoteCache$.has(documentUnitId)) {
      const params: HttpParams = new HttpParams().set('spinner', 'none');
      this.xbrlFootnoteCache$.set(
        documentUnitId,
        this.httpClient
          .get<ReferencedFootnote[]>(`${this.apiRootUrl}/xbrl-footnote/documentary-unit/${documentUnitId}`, { params })
          .pipe(
            tap(footnotes => {
              const factkeys = this.getAllFactKeys(footnotes);
              // Notify the ckeditor plugin (dynamic-data.editing)
              pubsub.fire(FOOTNOTE_LIST_IN_EDITOR, { factkeys }, EditorService.getEditorId(documentUnitId));
            }),
            publishReplay(1),
            refCount()
          )
      );
    }
    return this.xbrlFootnoteCache$.get(documentUnitId) as Observable<ReferencedFootnote[]>;
  }

  private getAllFactKeys(footnotes: ReferencedFootnote[]): string[] {
    const factkeys: string[] = [];
    if (footnotes) {
      footnotes.forEach(footnote => {
        if (footnote) {
          footnote.xbrlFacts?.forEach(fact => {
            if (!factkeys.includes(fact.factKey)) {
              factkeys.push(fact.factKey);
            }
          });
        }
      });
    }

    return factkeys;
  }
}
