import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ISubMenu } from 'app/shared/enum/vertical-menu.enum';

@Component({
  selector: 'jhi-vertical-menu-item',
  templateUrl: './vertical-menu-item.component.html',
  styleUrls: ['./vertical-menu-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VerticalMenuItemComponent {
  @Input() item: ISubMenu;
  @Input() isSubMenu = false;
  @Input() isSelected = false;
  @Input() public type: string;

  @Output() clickEvent = new EventEmitter<ISubMenu>();

  onClick(item: ISubMenu): void {
    this.clickEvent.emit(item);
  }
}
