<div class="main-block">
  <div *ngIf="tablesAndConcepts?.length === 0; else tablesAndConceptsTpl" class="empty-concept-list">
    <ng-container *ngIf="filteredType; else emptyConceptListTpl">{{
      'dataEditor.concept.emptyTypeConceptList' | translate: { type: filteredType }
    }}</ng-container>
    <ng-template #emptyConceptListTpl>{{ 'dataEditor.concept.emptyConceptList' | translate }}</ng-template>
  </div>
  <ng-template #tablesAndConceptsTpl>
    <ng-container *ngIf="treeView; else listView">
      <div class="taxonomy-tree-content">
        <jhi-input-search placeholder="dataEditor.taxonomy.search.placeholder" (searchTermChanged)="filterTable($event)"></jhi-input-search>
        <mat-tab-group>
          <mat-tab label="{{ 'dataEditor.taxonomy.tab.presentation' | translate }}">
            <div class="table-wrapper">
              <div class="table-list">
                <div *ngFor="let table of tablesAndConcepts">
                  <jhi-taxonomy-browser-table
                    [table]="table"
                    [search]="filterText"
                    [conceptIdSelected]="conceptIdSelected"
                    [searchType]="SearchType.ALL"
                    (selected)="onSelected($event)"
                  >
                  </jhi-taxonomy-browser-table>
                </div>
              </div>
            </div>
          </mat-tab>
          <!-- TODO Fonctionnalité masquée temporairement, voir Mantis#172613 -->
          <!-- <mat-tab label="{{'dataEditor.taxonomy.tab.calcul'|translate}}">
                  </mat-tab> -->
        </mat-tab-group>
      </div>
    </ng-container>
    <ng-template #listView>
      <div class="taxonomy-list-content">
        <jhi-concept-list
          (selected)="onSelected($event)"
          [conceptIdSelected]="conceptIdParentIDSelected"
          [table]="tablesAndConcepts"
          [displayAbstract]="displayAbstract"
          [filteredType]="filteredType"
        ></jhi-concept-list>
      </div>
    </ng-template>
    <div class="taxonomy-toggle">
      <mat-slide-toggle color="primary" [checked]="treeView" (toggleChange)="viewConceptChange()">
        {{ 'dataEditor.taxonomy.viewToggle' | translate }}
      </mat-slide-toggle>
    </div>
  </ng-template>
</div>
