import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { SERVER_API_URL } from 'app/app.constants';
import { IElementTracking } from 'app/shared/model/element-tracking.model';
import { IElementTrackingUsageDocument } from 'app/shared/model/element-tracking-usage.model';
import { ContextService } from './context.service';

@Injectable({ providedIn: 'root' })
export class ElementTrackingService {
  public resourceUrl: string;

  constructor(protected http: HttpClient, private contextService: ContextService) {
    this.resourceUrl = `${SERVER_API_URL}api/projects/${this.contextService.currentProjectId}/element-tracking`;
  }

  public getImagesCount(imageId: number): Observable<IElementTracking[]> {
    const params: HttpParams = new HttpParams().set('spinner', 'none');
    return this.http.get<IElementTracking[]>(`${this.resourceUrl}/images/count/${imageId}`, { params });
  }
  public getImagesUsage(imageId: number): Observable<IElementTrackingUsageDocument[]> {
    const params: HttpParams = new HttpParams().set('spinner', 'none');
    return this.http.get<IElementTrackingUsageDocument[]>(`${this.resourceUrl}/images/usage/${imageId}`, { params });
  }
}
