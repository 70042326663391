import * as moment from 'moment-timezone';
import { Moment } from 'moment';

export class DateUtils {
  private static readonly DEFAULT_TIMEZONE = 'Europe/Paris';
  private static serverTimezone = DateUtils.DEFAULT_TIMEZONE;

  public static setTimezone(timezone: string): void {
    DateUtils.serverTimezone = timezone || DateUtils.DEFAULT_TIMEZONE;
  }
  public static getServerTimezone(): string {
    return DateUtils.serverTimezone;
  }

  public static fromServerString(date: string): string {
    return date ? moment.tz(date, DateUtils.serverTimezone).format('YYYY-MM-DDTHH:mm:ss') : '';
  }

  public static fromServerDate(date: string): Date | null {
    return date.length ? new Date(DateUtils.fromServerString(date)) : null;
  }

  public static toServerString(date: Date | string, withTime = false): string | null {
    if (!date) {
      return null;
    }
    if (typeof date === 'string') {
      return date;
    } else if (withTime) {
      return moment.tz(date, DateUtils.serverTimezone).format('YYYY-MM-DDTHH:mm:ssZ');
    } else {
      const year = date.getFullYear();
      const month = date.getMonth() + 1;
      const day = date.getDate();
      const dateStr = `${year}-${(month < 10 ? '0' : '') + month}-${(day < 10 ? '0' : '') + day}`;
      return dateStr;
    }
  }

  public static test(): void {
    let year = new Date().getFullYear();
    let month = 12;
    let day = 31;
    const date = new Date();
    date.setFullYear(year);
    date.setMonth(month - 1);
    date.setDate(day);
    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);
    date.setMilliseconds(0);
    while (date.getFullYear() >= 1970) {
      const expectedDate = `${year}-${(month < 10 ? '0' : '') + month}-${(day < 10 ? '0' : '') + day}`;
      const newDate = DateUtils.toServerString(date, false);
      if (newDate !== expectedDate) {
        // eslint-disable-next-line no-console
        console.log(date, expectedDate, newDate);
      }
      day--;
      if (day === 0) {
        month--;
        if (month === 0) {
          month = 12;
          year--;
        }
        switch (month) {
          case 1: // janvier
          case 3: // mars
          case 5: // mai
          case 7: // juillet
          case 8: // aout
          case 10: // octobre
          case 12: // décembre
            day = 31;
            break;
          case 4: // avril
          case 6: // juin
          case 9: // septembre
          case 11: // novembre
            day = 30;
            break;
          case 2: // fevrier
            day = 28; // no 29 test
            break;
        }
      }
      date.setFullYear(year);
      date.setMonth(month - 1);
      date.setDate(day);
    }
  }
  public static sortDate(endDateA: Moment, endDateB: Moment, isAsc: boolean): number {
    return isAsc ? moment(endDateA).diff(endDateB) : moment(endDateB).diff(endDateA);
  }
}
