<div class="plugin-container column-panel-plugin-container">
  <div class="columnLabelSection">
    <mat-form-field>
      <mat-label jhiTranslate="htmlEditor.plugins.tableEdition.columnLabel"
        [translateValues]="{index: encodeColName(actualColumn.index)}"></mat-label>
      <mat-select (selectionChange)="changeColumnLabel($event.value)" [value]="actualColumn.labelID">
        <mat-option *ngFor="let label of columnsLabelsList" [value]="label.id">{{label.name}}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="columnPyjamaSection">
    <mat-slide-toggle color="primary" [disabled]="disablePyjamaField" [checked]="disablePyjama"
      (change)="changePyjamaValue($event.checked)">
      {{ 'htmlEditor.plugins.tableEdition.disablePyjama' | translate }}
    </mat-slide-toggle>
  </div>
</div>
