import { Moment } from 'moment';

export enum IHistoryModificationType {
  ADDITION = 'ADDITION',
  MODIFICATION = 'MODIFICATION',
  DELETION = 'DELETION',
}
export enum IHistoryDataValueType {
  NUMERIC = 'NUMERIC',
  STRING = 'STRING',
  BOOLEAN = 'BOOLEAN',
  DATE = 'DATE',
}
export enum IHistoryDataBalanceType {
  CREDIT = 'CREDIT',
  DEBIT = 'DEBIT',
}

export interface IHistoryData {
  dataHistorizationId: number;
  updatedAt: string | Moment;
  updatedBy: string;
  modificationType: IHistoryModificationType;
  value: string;
  cellType?: IHistoryDataValueType;
  balance?: IHistoryDataBalanceType;
  scale?: number;
  xbrlFactId?: string;
  updatedByPropagation: boolean;
}
