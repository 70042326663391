import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SERVER_API_URL } from 'app/app.constants';
import { ContextService } from 'app/core/service/context.service';
import { IHistoryData } from 'app/shared/model/history-data.model';
import { IDataHistoryParams } from 'app/shared/model/sheet-selection.model';
import { Observable, Subject } from 'rxjs';
import { IFact } from 'app/shared/model/fact.model';
import { RCSFDataSelection } from 'app/shared/model/rcsf.model';

@Injectable({ providedIn: 'root' })
export class SpreadsheetService {
  private _cellValue = new Subject<string>();
  public cellValues$ = this._cellValue.asObservable();

  private _tagging = new Subject<IFact>();
  public taggings$ = this._tagging.asObservable();

  private _isCellHistoryMode = new Subject<boolean>();
  public historyMode$ = this._isCellHistoryMode.asObservable();

  // UI spreedsheet cell value
  private _spreedsheetCellValue = new Subject<{ cellValue: string; cellToUpdate?: RCSFDataSelection }>();
  public spreedsheetCellValues$ = this._spreedsheetCellValue.asObservable();

  constructor(private http: HttpClient, private contextService: ContextService) {}

  addCellValue(cellValue: string): void {
    this._cellValue.next(cellValue);
  }

  addTagging(fact: IFact): void {
    this._tagging.next(fact);
  }

  updateSpreedsheetCellValue(spreedsheetCellValue: { cellValue: string; cellToUpdate?: RCSFDataSelection }): void {
    this._spreedsheetCellValue.next(spreedsheetCellValue);
  }

  getHistoryData(cellParams: IDataHistoryParams): Observable<IHistoryData[]> {
    const projectId = this.contextService.currentDocumentContext.projectId;
    return this.http.post<IHistoryData[]>(`${SERVER_API_URL}api/projects/${projectId}/rcsf/data/historization`, cellParams);
  }

  getHistoryXbrl(xbrlFactId: string): Observable<IHistoryData[]> {
    const projectId = this.contextService.currentDocumentContext.projectId;
    return this.http.post<IHistoryData[]>(`${SERVER_API_URL}api/projects/${projectId}/dynamic-data/fact/historization`, { xbrlFactId });
  }

  public leaveHistoryMode(): void {
    this._isCellHistoryMode.next(false);
  }
}
