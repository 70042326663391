import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { IImportResponse } from 'app/shared/model/import-upload.model';
import { XliffFilename } from 'app/shared/model/xliff.model';
import { Authority } from 'app/shared/enum/authority.enum';

@Component({
  selector: 'jhi-import-export',
  templateUrl: './import-export.component.html',
  styleUrls: ['./import-export.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImportExportComponent {
  @Input() public type: string;
  @Input() public showExport = true;
  @Input() public showInput = true;
  @Input() public disableExport = false;
  @Input() public tooltipOnDisabledExport: string = '';
  @Input() public formats: string;
  @Input()
  get importResponse(): IImportResponse | null {
    return this._importResponse;
  }

  set importResponse(value: IImportResponse | null) {
    this._importResponse = value;
    this.changeDetectorRef.markForCheck();
  }

  @Input()
  get openDocumentaryUnits(): XliffFilename[] {
    return this._openDocumentaryUnits;
  }

  set openDocumentaryUnits(value: XliffFilename[]) {
    this._openDocumentaryUnits = value;
    this.changeDetectorRef.markForCheck();
  }

  @Output() public fileToImport = new EventEmitter<{ file: File | null; withFixes: boolean }>();
  @Output() public exportFile = new EventEmitter<void>();
  @Output() public fileImportXliff = new EventEmitter<{ file: File | null; withFixes: boolean }>();

  public importedFile: File | null;
  private _importResponse: IImportResponse | null;
  private _openDocumentaryUnits: XliffFilename[];
  public readonly Authority = Authority;

  constructor(private changeDetectorRef: ChangeDetectorRef) {}

  clickOnInput(input: HTMLInputElement): void {
    input.value = '';
    input.click();
  }

  onFileChanged(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (!input.files) {
      return;
    }
    this.importedFile = input.files[0];
    this.changeDetectorRef.markForCheck();
  }

  import(withFixes: boolean): void {
    this.fileToImport.emit({ file: this.importedFile, withFixes });
  }

  importXliff(withFixes: boolean): void {
    this.fileImportXliff.emit({ file: this.importedFile, withFixes });
  }

  clickOnExport(): void {
    this.exportFile.emit();
  }

  reset(): void {
    this.importedFile = null;
    this.importResponse = null;
    this.openDocumentaryUnits = [];
  }
}
