<table mat-table [dataSource]="dataSource" aria-describedby="logs">
  <ng-container matColumnDef="chapterTitle">
    <th id="chapterTitle" mat-header-cell *matHeaderCellDef jhiTranslate="global.dm.report.warnings.chapterTitle"></th>
    <td mat-cell *matCellDef="let logEntry">{{ logEntry.chapterTitle }}</td>
  </ng-container>
  <ng-container matColumnDef="documentaryUnitId">
    <th id="documentaryUnitId" mat-header-cell *matHeaderCellDef jhiTranslate="global.dm.report.warnings.documentaryUnitId"></th>
    <td mat-cell *matCellDef="let logEntry">
      <a
        *ngIf="logEntry.documentaryUnitId != -1"
        [href]="'/document/' + docId + '/preview?sectionId=' + logEntry.documentaryUnitId"
        target="_blank"
        >{{ logEntry.documentaryUnitId }}</a
      >
    </td>
  </ng-container>
  <ng-container matColumnDef="stepName">
    <th id="stepName" mat-header-cell *matHeaderCellDef jhiTranslate="global.dm.report.warnings.stepName"></th>
    <td mat-cell *matCellDef="let logEntry">{{ logEntry.stepName }}</td>
  </ng-container>
  <ng-container matColumnDef="shortMessages">
    <th id="shortMessages" mat-header-cell *matHeaderCellDef jhiTranslate="global.dm.report.warnings.shortMessages"></th>
    <td mat-cell *matCellDef="let logEntry">
      <ul>
        <li *ngFor="let shortMessage of logEntry.shortMessages">{{ shortMessage }}</li>
      </ul>
    </td>
  </ng-container>
  <tr mat-header-row *matHeaderRowDef="columns"></tr>
  <tr mat-row *matRowDef="let row; columns: columns"></tr>
</table>
