import { Directive, ElementRef, Input, Renderer2 } from '@angular/core';

const redColor = '#f60000';
const orangeColor = '#ff7350';
const greenColor = '#77b20e';

@Directive({
  selector: '[jhiMatchingScore]',
})
export class MatchingScoreDirective {
  @Input()
  public set jhiMatchingScore(score: number) {
    this.computeColorWithScore(score);
  }

  constructor(public el: ElementRef, private renderer: Renderer2) {}

  private computeColorWithScore(score: number): void {
    let color = redColor;

    if (score > 80) {
      color = greenColor;
    } else if (score >= 50) {
      color = orangeColor;
    }

    this.renderer.setStyle(this.el.nativeElement, 'color', color);
  }
}
