<div class="analysis" *ngIf="!isCovers">
  <h2 jhiTranslate="htmlEditor.plugins.import.importContent"></h2>

  <div *ngIf="nbPages && !hasError" class="container">
    <div>
      <span
        *ngIf="nbPages <= 35"
        [translateValues]="{ number: nbPages }"
        jhiTranslate="htmlEditor.plugins.import.graphicalPagesCount"
      ></span>

      <span
        *ngIf="nbPages > 35"
        [translateValues]="{ number: nbPages }"
        jhiTranslate="htmlEditor.plugins.import.graphicalPagesToMuchPages"
      ></span>

      <p *ngIf="!isGraphicalPage; else graphicalPageCase" jhiTranslate="htmlEditor.plugins.import.graphicalPagesCountContinue"></p>
      <ng-template #graphicalPageCase>
        <p jhiTranslate="htmlEditor.plugins.import.graphicalPagesCountOnGraphicalPageDU"></p>
      </ng-template>
    </div>

    <mat-radio-group *ngIf="isGraphicalPage" [value]="replaceContent" class="radio-buttons" color="primary">
      <mat-radio-button (click)="setReplaceContent(true)" [value]="true">
        {{ 'htmlEditor.plugins.import.replaceContent' | translate }}
      </mat-radio-button>
      <mat-radio-button (click)="setReplaceContent(false)" [value]="false">
        {{ 'htmlEditor.plugins.import.append' | translate }}
      </mat-radio-button>
    </mat-radio-group>

    <div class="action-buttons">
      <button mat-raised-button color="accent" jhiTranslate="htmlEditor.plugins.import.cancelBtn" (click)="onClickClosePanel()"></button>
      <button mat-raised-button color="primary" jhiTranslate="htmlEditor.plugins.import.importBtn" (click)="onClickImportBtn()"></button>
    </div>
  </div>

  <span *ngIf="loading" jhiTranslate="htmlEditor.plugins.import.analyzing"></span>
  <span *ngIf="hasError" jhiTranslate="htmlEditor.plugins.import.importFailed"></span>
  <hr />
</div>

<div class="loading-or-error" *ngIf="loading || hasError || isCovers">
  <div class="wrapper">
    <jhi-loading [show]="loading"></jhi-loading>

    <span jhiTranslate="htmlEditor.plugins.import.loadingAndAnalyzing" *ngIf="loading"></span>

    <div *ngIf="hasError" class="error-container">
      <mat-icon svgIcon="error"></mat-icon>
      <span jhiTranslate="htmlEditor.plugins.import.importFailedTryAgain"></span>
      <button
        class="import-btn"
        mat-raised-button
        color="accent"
        jhiTranslate="htmlEditor.plugins.import.importAgainBtn"
        (click)="onClickNewImportFileBtn()"
      ></button>
    </div>

    <div *ngIf="isCovers" class="error-container">
      <mat-icon svgIcon="error"></mat-icon>
      <span jhiTranslate="htmlEditor.plugins.import.impossibleOnCovers"></span>
    </div>
  </div>
</div>
