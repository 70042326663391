import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { IDocumentaryUnit, ITitle } from 'app/shared/model/documentary-unit.model';
import { ContentType } from 'app/shared/enum/content-type.enum';

@Component({
  selector: 'jhi-select-d-us-field',
  templateUrl: './select-d-us-field.component.html',
  styleUrls: ['./select-d-us-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: SelectDUsFieldComponent,
    },
  ],
})
export class SelectDUsFieldComponent implements ControlValueAccessor {
  public readonly ContentType = ContentType;
  public onChange = (documentaryUnits: number[]) => {}; // eslint-disable-line
  public onTouched = () => {};
  public touched = false;
  public disabled = false;
  public titlesBySectionId = new Map<number, ITitle>();
  private _sections: IDocumentaryUnit[] = [];
  public chaptersLevelOne: IDocumentaryUnit[];

  @Input()
  public get sections(): IDocumentaryUnit[] {
    return this._sections;
  }
  public set sections(sections: IDocumentaryUnit[]) {
    this._sections = sections || [];
    this._sections.forEach(section => {
      if (section.id) {
        this.titlesBySectionId[section.id] = this.getTitle(section);
      }
    });
    this.chaptersLevelOne = this._sections.filter(
      section =>
        section.type === ContentType.CHAPTER &&
        section.id &&
        (this.titlesBySectionId[section.id]?.level === 1 || section.titles.length === 0)
    );

    this.changeDetector.markForCheck();
  }

  @Input()
  public documentaryUnitsId: number[];

  // add a left padding for sub sections if enable
  @Input()
  public enableChapterChildOption = true;

  constructor(private changeDetector: ChangeDetectorRef) {}

  writeValue(documentaryUnitsId: number[]): void {
    this.documentaryUnitsId = documentaryUnitsId;
  }

  registerOnChange(onChange: any): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  markAsTouched(): void {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  setDisabledState(disabled: boolean): void {
    this.disabled = disabled;
    this.changeDetector.markForCheck();
  }

  selectionChange(): void {
    this.onChange(this.documentaryUnitsId);
  }

  /**
   *  return the first most important level title in the section titles list
   */
  public getTitle(section: IDocumentaryUnit): ITitle | null {
    let mainTitle: ITitle | null = null;
    for (const title of Array.from(section?.titles || [])) {
      if (title && (!mainTitle || (mainTitle.level ?? 10) > (title.level ?? 10))) {
        mainTitle = title;
        // if a level one title is found, return it (we cant found most important title)
        if (mainTitle.level === 1) {
          return mainTitle;
        }
      }
    }
    return mainTitle;
  }

  public getNumbering(currentSection: IDocumentaryUnit, sections: IDocumentaryUnit[], title: ITitle | null = null): string {
    if (!this.chaptersLevelOne) {
      return '';
    }
    return title && (title.level ?? 0) > 1 && title.numbering !== undefined && title.numbering !== null
      ? title.numbering
      : (currentSection?.chapterNumber ?? this.chaptersLevelOne.indexOf(currentSection) + 1).toString();
  }

  public getTitleFullContent(currentSection: IDocumentaryUnit, sections: IDocumentaryUnit[]): string {
    if (!currentSection.id) {
      return '';
    }
    const title = this.titlesBySectionId[currentSection.id];
    const titleContent = title?.content ? title.content : currentSection.defaultTitle;
    return this.getNumbering(currentSection, sections, title) + ' ' + titleContent;
  }
}
