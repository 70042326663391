import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'textFilter' })
export class TextFilterPipe implements PipeTransform {
  /**
   * Pipe filters the list of elements based on the search text provided
   *
   * @param items list of elements to search in
   * @param searchText search string
   * @returns list of elements filtered by search text or []
   */
  transform(items: any[], searchText: string): any[] {
    if (!items) {
      return [];
    }
    if (!searchText) {
      return items;
    }
    searchText = searchText.toLocaleLowerCase();

    return items.filter(item => {
      return item.toLocaleLowerCase().includes(searchText);
    });
  }
}

@Pipe({ name: 'titlesFilter' })
export class TitlesFilterPipe implements PipeTransform {
  /**
   * Pipe filters the list of elements based on the search text provided
   *
   * @param items list of elements to search in
   * @param searchText search string
   * @returns list of elements filtered by numbering or titles or []
   */
  transform(items: any[], searchText: string): any[] {
    if (!items) {
      return [];
    }

    if (!searchText) {
      return items;
    }

    searchText = searchText.toLocaleLowerCase();

    return items.filter(item => {
      return item.content?.toLocaleLowerCase().includes(searchText) || item.numbering?.toLocaleLowerCase().includes(searchText);
    });
  }
}
