import { Pipe, PipeTransform } from '@angular/core';
import { PeriodLabel } from '../enum/period-label.enum';
import { TranslateService } from '@ngx-translate/core';
@Pipe({
  name: 'dateLabel'
})
export class DateLabel implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  transform(dateLabel: string): string {
    switch (dateLabel as PeriodLabel) {
      case PeriodLabel.CURRENT_PERIOD:
        return this.translateService.instant('dataEditor.date.label.periodN');
      case PeriodLabel.PREVIOUS_PERIOD:
        return this.translateService.instant('dataEditor.date.label.periodNM1');
      case PeriodLabel.CURRENT_INSTANT_START:
        return this.translateService.instant('dataEditor.date.label.periodInstantNStart');
      case PeriodLabel.CURRENT_INSTANT_END:
        return this.translateService.instant('dataEditor.date.label.periodInstantNEnd');
      case PeriodLabel.PREVIOUS_INSTANT_START:
        return this.translateService.instant('dataEditor.date.label.periodInstantNM1Start');
      case PeriodLabel.PREVIOUS_INSTANT_END:
        return this.translateService.instant('dataEditor.date.label.periodInstantNM1End');
      default:
        return dateLabel;
    }
  }
}
