import { Component, Input, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'jhi-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SpinnerComponent {
  @Input() show = false;
  @Input() color: string;
}
