import { Component, Input, OnInit } from '@angular/core';
import { AccountService } from 'app/core/auth/account.service';

import { DEFAULT_AVATAR } from 'app/core/service/data/avatar';
import { Account } from 'app/core/user/account.model';

import { LoginService } from 'app/core/login/login.service';
import { urlCacheBust } from 'app/shared/util/url-utils';

@Component({
  selector: 'jhi-account-button',
  templateUrl: './account-button.component.html',
  styleUrls: ['./account-button.component.scss'],
})
export class AccountButtonComponent implements OnInit {
  public username: string = '';
  public avatarUrl: string = '';

  @Input() showDefault = true;

  constructor(private accountService: AccountService, private loginService: LoginService) {}

  public ngOnInit(): void {
    this.accountService.identity().subscribe(account => this.setAccount(account));
  }

  public logout(): void {
    this.loginService.logout();
  }

  private setAccount(account: Account | null): void {
    if (account ?? this.showDefault) {
      this.username = `${account?.firstName ? account?.firstName : ''} ${account?.lastName ? account.lastName : ''}`;
      const avatarUrl = account ? account.imageUrl || DEFAULT_AVATAR : DEFAULT_AVATAR;
      this.avatarUrl = urlCacheBust(avatarUrl);
    }
  }
}
