<div class="export-configuration-lore-container">
  <div class="back-container">
    <button mat-icon-button class="header-close" (click)="goBack()">
      <mat-icon svgIcon="arrow-back" color="primary"></mat-icon>
    </button>
    <span jhiTranslate="htmlEditor.plugins.configurationExport.goBack"></span>
  </div>
  <hr />
  <div class="configuration-content">
    <div class="type-reminder">
      <mat-icon class="export-svg" svgIcon="export-lore"></mat-icon>
      <span jhiTranslate="htmlEditor.plugins.documentExport.loreExport"></span>
    </div>

    <div class="configuration-options-toggle">
      <mat-slide-toggle class="toggle" color="primary" [disabled]="syncLoreDisabled" [(ngModel)]="syncLore">
        {{ 'htmlEditor.plugins.configurationExport.LORE.sync' | translate }}
      </mat-slide-toggle>
      <p class="info" jhiTranslate="htmlEditor.plugins.configurationExport.LORE.info"></p>
    </div>

    <div class="button-container">
      <button
        type="button"
        mat-flat-button
        mat-button
        color="primary"
        (click)="downloadLoreExport()"
        jhiTranslate="htmlEditor.plugins.configurationExport.button"
      ></button>
    </div>
  </div>
</div>
