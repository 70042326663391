import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { IDocument, IDocumentContext } from 'app/shared/model/document.model';
import { ContextService } from '../service/context.service';

@Injectable({ providedIn: 'root' })
export class DocumentResolver implements Resolve<IDocument> {
  constructor(private router: Router, private contextService: ContextService) {}

  resolve(route: ActivatedRouteSnapshot): Observable<IDocument> {
    const documentId = +route.params.documentId;

    if (Number.isNaN(documentId)) {
      this.handleRoutingError();
    }

    if (this.contextService.currentDocumentContext?.id === documentId) {
      return of(this.contextService.currentDocumentContext);
    }

    return this.contextService.getContext(documentId).pipe(
      catchError(() => this.handleRoutingError()),
      map((documentContext: IDocumentContext) => {
        this.contextService.initFromDocumentContext(documentContext);
        return documentContext;
      })
    );
  }

  private handleRoutingError(): Observable<null> {
    this.router.navigate(['/404']);
    return of(null);
  }
}
