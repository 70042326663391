import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';

export enum SNACKBAR_TYPE {
  success = 'success',
  info = 'info',
  warning = 'warning',
  danger = 'danger',
}
@Injectable({ providedIn: 'root' })
export class SnackbarService {
  private horizontalPosition: MatSnackBarHorizontalPosition = 'end';
  private verticalPosition: MatSnackBarVerticalPosition = 'top';
  constructor(private snackBar: MatSnackBar, private translate: TranslateService) {}
  public open(msgI18nKey: string, type = SNACKBAR_TYPE.success, errorCause = ''): void {
    this.snackBar.open(this.translate.instant(msgI18nKey) + errorCause, this.translate.instant('snackBar.closeBtnLabel'), {
      duration: 5000,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
      panelClass: `eolng-snackbar--${type}`,
    });
  }
}
