<div class="table-model-column-width" [formGroup]="columnsWidthForm">
  <mat-form-field class="table-model-columns-width-input">
    <mat-label>
      <span jhiTranslate="styleEditor.tableModeler.properties.width" [translateValues]="{ colName: colNameValue }"></span>
      <mat-icon
        *ngIf="isSplitColumn"
        matSuffix
        svgIcon="split-table"
        color="primary"
        [matTooltip]="'styleEditor.tableModeler.splitTable' | translate"
      ></mat-icon>
    </mat-label>
    <input type="number" min="1" matInput formControlName="width" autocomplete="off" />
  </mat-form-field>

  <div class="table-model-columns-width-toggle">
    <mat-slide-toggle color="primary" formControlName="isRelative"></mat-slide-toggle>
    <mat-label jhiTranslate="styleEditor.tableModeler.properties.relative"></mat-label>
  </div>
</div>
