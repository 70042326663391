<div class="image-picker-plugin-container">
  <div class="panel-back" *ngIf="isModePlaceholder">
    <button mat-icon-button class="header-close" (click)="backToPlaceholder()">
      <mat-icon svgIcon="arrow-back" color="primary"></mat-icon>
    </button>
    <span [jhiTranslate]="buttonBack"></span>
  </div>

  <mat-form-field class="filter-by-name" appearance="outline">
    <input
      matInput
      placeholder="{{ 'htmlEditor.plugins.imagePicker.filterPlaceholder' | translate }}"
      [(ngModel)]="filterText"
      [disabled]="loading"
    />
    <mat-icon matSuffix svgIcon="search" color="primary"></mat-icon>
  </mat-form-field>

  <mat-select
    class="imageSelect"
    [(ngModel)]="currentFolder"
    (ngModelChange)="changeFolder()"
    panelClass="force-panel-under-field"
    [disabled]="loading"
  >
    <mat-option *ngFor="let folder of folders" [value]="folder">{{ 'htmlEditor.plugins.imagePicker.' + folder | translate }} </mat-option>
    <mat-option *ngFor="let language of languageFolders" [value]="language"
      >{{ 'htmlEditor.plugins.imagePicker.LANGUAGE' | translate: { lang: language } }}
    </mat-option>
  </mat-select>

  <jhi-loading [show]="loading"></jhi-loading>

  <div class="imageOuterContainer" *ngIf="images" [class.placeholder-div]="isModePlaceholder">
    <ng-container *ngIf="images | imageFilter: filterText as filteredImages">
      <div class="no-images-found" *ngIf="!loading && filteredImages.length == 0">
        <mat-icon svgIcon="image-not-found" color="primary"></mat-icon>
        <span jhiTranslate="htmlEditor.plugins.imagePicker.noImagesFound" [translateValues]="{ word: filterText }"></span>
      </div>
      <div class="imageContainer" *ngFor="let image of filteredImages">
        <img [src]="getAssetUrl(image.id)" class="image" [alt]="image.filename" [attr.data-damId]="image.id" />

        <div
          class="hoverContainer"
          *ngIf="!enableImageDeletion || deletePopupModel.deleteImageIdConfirmation !== image.id; else deleteOverlay"
          (click)="addImageToDoc(image)"
        >
          <div class="image-info-box">
            <span class="text" [ngClass]="{ defaultImageTitle: !enableImageDeletion }"> {{ image.filename | splitString }}</span>
            <div class="number-of-uses" *ngIf="enableImageDeletion && currentFolder !== ImageFolder.GENERAL">
              <span jhiTranslate="htmlEditor.plugins.imagePicker.numberOfUses"></span>
              <span class="number-of-uses-value" *ngIf="!checkNumberOfUses" (click)="showUsage($event, image)">{{ image.count || 0 }}</span>
              <jhi-spinner color="white" [show]="checkNumberOfUses"></jhi-spinner>
            </div>
          </div>
          <button
            mat-icon-button
            *ngIf="enableImageDownload && image?.id !== placeholderImage?.id"
            [disableRipple]="true"
            class="download-icon"
            [matTooltip]="'htmlEditor.plugins.imagePicker.downloadHDfile' | translate"
            (click)="downloadImage($event, image)"
            aria-label="Download icon"
          >
            <mat-icon svgIcon="download"></mat-icon>
          </button>
          <button
            mat-icon-button
            *ngIf="enableImageDeletion && image?.id !== placeholderImage?.id"
            [disableRipple]="true"
            class="delete-icon"
            (click)="deleteImage($event, image)"
            aria-label="Delete icon"
          >
            <mat-icon svgIcon="delete-outline"></mat-icon>
          </button>
          <button
            mat-icon-button
            *ngIf="enableImageMove && image?.id !== placeholderImage?.id"
            [disableRipple]="true"
            class="move-image-icon"
            [matTooltip]="'htmlEditor.plugins.imagePicker.moveImage' | translate"
            (click)="moveImage($event, image)"
            aria-label="Move icon"
          >
            <mat-icon svgIcon="move-image"></mat-icon>
          </button>

          <ng-container *ngIf="isModePlaceholder">
            <ng-container *ngIf="image.id != placeholderImage?.id; else resetPlaceholderButtons">
              <div class="replace-placeholder-current" *ngIf="image.id == currentPlaceholder?.damId; else replaceImageButton">
                <div class="replace-placeholder-text" jhiTranslate="htmlEditor.plugins.imagePlaceholder.background"></div>
                <div
                  class="replace-placeholder-link"
                  jhiTranslate="htmlEditor.plugins.imagePlaceholder.removeImage"
                  (click)="replacePlaceholder($event, placeholderImage, true)"
                ></div>
              </div>
              <ng-template #replaceImageButton>
                <button
                  type="button"
                  mat-raised-button
                  class="replace-placeholder-button"
                  (click)="replacePlaceholder($event, image, false)"
                  aria-label="replace image"
                  *ngIf="placeholderImage?.id != currentPlaceholder?.damId; else setAsImageButton"
                >
                  <span jhiTranslate="htmlEditor.plugins.imagePlaceholder.replaceImage"></span>
                </button>
                <ng-template #setAsImageButton>
                  <button
                    type="button"
                    mat-raised-button
                    class="replace-placeholder-button"
                    (click)="replacePlaceholder($event, image, false)"
                    aria-label="set image"
                  >
                    <span jhiTranslate="htmlEditor.plugins.imagePlaceholder.setAsImage"></span>
                  </button>
                </ng-template>
              </ng-template>
            </ng-container>
            <ng-template #resetPlaceholderButtons>
              <button
                type="button"
                mat-raised-button
                class="replace-placeholder-button"
                (click)="replacePlaceholder($event, image, true)"
                aria-label="remove image"
                *ngIf="placeholderImage?.id != currentPlaceholder?.damId"
              >
                <span jhiTranslate="htmlEditor.plugins.imagePlaceholder.removeImage"></span>
              </button>
            </ng-template>
          </ng-container>
        </div>

        <ng-template #deleteOverlay>
          <div class="deletePopupOverlay">
            <div class="delete-popup-row">
              <div class="delete-confirm-warning" jhiTranslate="htmlEditor.plugins.imagePicker.warningDelete"></div>
              <button
                type="button"
                class="delete-confim-button"
                mat-raised-button
                jhiTranslate="htmlEditor.plugins.imagePicker.delete"
                (click)="confirmImageDeletion($event, image)"
                aria-label="Delete confirm"
              ></button>
            </div>
            <div class="delete-popup-row">
              <div
                *ngIf="deletePopupModel.imageCounter !== 0"
                class="delete-confirm-text"
                jhiTranslate="htmlEditor.plugins.imagePicker.warningDeleteText"
              ></div>
              <div
                *ngIf="deletePopupModel.imageCounter === 0"
                class="delete-confirm-text"
                jhiTranslate="htmlEditor.plugins.imagePicker.warningDeleteZero"
              ></div>
            </div>
            <div class="delete-popup-row">
              <div class="image-name">{{ image.filename | splitString }}</div>
            </div>
            <div class="delete-popup-row">
              <div class="number-of-uses">
                <span jhiTranslate="htmlEditor.plugins.imagePicker.numberOfUses"></span>
                <span class="number-of-uses-value" (click)="showUsage($event, image)">{{ deletePopupModel.imageCounter }}</span>
              </div>
              <div
                class="cancel-text"
                (click)="cancelImageDeletion($event, image.id)"
                jhiTranslate="htmlEditor.plugins.imagePicker.cancel"
              ></div>
            </div>
          </div>
        </ng-template>
      </div>
    </ng-container>
  </div>

  <div class="files-upload" *jhiHasAnyAuthority="[Authority.INSERT_IMAGE]">
    <div
      class="dropzone"
      [ngClass]="{ highlight: dropzone.isHighlighted }"
      #dropzone="DnDDirective"
      jhiAppDnd
      [disabled]="loading || (currentFolder === ImageFolder.GENERAL && !this.hasGeneralRights)"
      (fileDropped)="uploadFile($event)"
    >
      <div>
        <mat-icon svgIcon="upload" color="primary"></mat-icon>
        <span jhiTranslate="htmlEditor.upload.dnd"></span>
      </div>
      <div>
        <div jhiTranslate="htmlEditor.upload.or"></div>
        <!--
            add (click)="$event.target.value=null" for change event work on the same file.
            Exemple : upload toto_fr.pdf in project folder give an error.
            select fr folder and upload again toto_fr.pdf => doesn't work without reset $event.target.value
        -->
        <input
          #inputFile
          type="file"
          multiple
          [disabled]="loading"
          (change)="uploadFile($event.target.files)"
          (click)="$event.target.value = null"
        />
        <input
          #inputFile2
          type="file"
          multiple
          [disabled]="loading"
          (change)="uploadFile($event.target.files)"
          accept="application/pdf"
          (click)="$event.target.value = null"
        />
        <button mat-raised-button color="primary" [disabled]="loading">
          <span jhiTranslate="htmlEditor.upload.browse"></span>
        </button>
      </div>
    </div>
  </div>
</div>
