<div class="text-block-panel">
  <form role="form" [formGroup]="textBlockForm">
    <div class="toggle-type" *ngIf="!isSelection && displayActionButton && !textBlock && !isDeletion">
      <mat-label
        [ngClass]="{ highlight: !textBlockForm.controls.closingType.value }"
        jhiTranslate="htmlEditor.plugins.{{ translationRoot }}.form.opening"
      ></mat-label>
      <mat-slide-toggle formControlName="closingType" color="primary" [disabled]="!inEdition"></mat-slide-toggle>
      <mat-label
        [ngClass]="{ highlight: textBlockForm.controls.closingType.value }"
        jhiTranslate="htmlEditor.plugins.{{ translationRoot }}.form.closing"
      ></mat-label>
    </div>

    <div class="opening-choice" *ngIf="!textBlock && inEdition">
      <button
        mat-raised-button
        color="primary"
        (click)="openingTagsChoice(EditionType.CREATE)"
        *ngIf="!this.textBlockForm.controls.closingType.value"
      >
        <span jhiTranslate="htmlEditor.plugins.{{ translationRoot }}.form.create"></span>
      </button>
      <button mat-raised-button color="primary" (click)="openingTagsChoice(EditionType.RESTRICTIVE)">
        <span jhiTranslate="htmlEditor.plugins.{{ translationRoot }}.form.chooseExisting"></span>
      </button>
    </div>

    <div
      class="xbrl-text-block-tagger"
      *ngIf="textBlock || (!inEdition && !isDeletion)"
      [ngClass]="{ 'padding-15': textBlock === EditionType.RESTRICTIVE }"
    >
      <jhi-xbrl-data-tagger
        *ngIf="textBlock === EditionType.CREATE || !inEdition"
        [fact]="fact"
        [editorialTextFactDisabled]="false"
        [filteredType]="!!textBlock ? textBlockXBRLType : null"
        [disabled]="!inEdition"
        (factUpdated)="updateFact($event)"
        (stepUpdated)="updateStep($event)"
      >
      </jhi-xbrl-data-tagger>

      <jhi-xbrl-fact-picker
        *ngIf="textBlock === EditionType.RESTRICTIVE"
        [isTextBlock]="textBlockXBRLType === textBlockTypeRef"
        [isTextFact]="textBlockXBRLType !== textBlockTypeRef"
        (selected)="insertRestrictive($event)"
        [disableSave]="disableSave"
      ></jhi-xbrl-fact-picker>
    </div>

    <div *ngIf="isDeletion" class="confirm-deletion-container">
      <div id="confirmDeletionLabel" jhiTranslate="htmlEditor.plugins.{{ translationRoot }}.form.deleteConfirmation"></div>
      <mat-radio-group #deletionTypeInput aria-labelledby="confirmDeletionLabel" color="primary">
        <mat-radio-button [value]="DeletionType.ONE" checked (change)="radioChange($event.value)">
          {{ 'htmlEditor.plugins.' + translationRoot + '.form.deleteOne' | translate }}
        </mat-radio-button>
        <mat-radio-button [value]="DeletionType.PAIR" (change)="radioChange($event.value)">
          {{ 'htmlEditor.plugins.' + translationRoot + '.form.deletePair' | translate }}
        </mat-radio-button>
        <mat-radio-button [value]="DeletionType.ALL" (change)="radioChange($event.value)">
          {{ 'htmlEditor.plugins.' + translationRoot + '.form.deleteAll' | translate }}
        </mat-radio-button>
      </mat-radio-group>
    </div>

    <div class="flex-1"></div>
    <div class="action-buttons" *ngIf="displayActionButton && textBlock !== EditionType.RESTRICTIVE">
      <button mat-raised-button *ngIf="!inEdition && !isDeletion" class="delete-button" (click)="openDeleteConfirmation()">
        <span jhiTranslate="htmlEditor.plugins.{{ translationRoot }}.form.delete"></span>
      </button>
      <div class="flex-1" *ngIf="inEdition"></div>
      <button mat-raised-button color="primary" *ngIf="inEdition" [disabled]="disableSave || !fact.context?.period?.id" (click)="save()">
        <span jhiTranslate="htmlEditor.plugins.{{ translationRoot }}.form.save"></span>
      </button>
      <div *ngIf="!inEdition && !isDeletion">
        <button mat-raised-button color="primary" (click)="update(EditionType.RESTRICTIVE)">
          <span jhiTranslate="htmlEditor.plugins.{{ translationRoot }}.form.chooseExisting"></span>
        </button>
        <button mat-raised-button color="primary" *ngIf="!textBlockForm.controls.closingType.value" (click)="update(EditionType.CREATE)">
          <span jhiTranslate="htmlEditor.plugins.{{ translationRoot }}.form.update"></span>
        </button>
      </div>

      <div *ngIf="isDeletion" class="deletion-buttons">
        <button mat-raised-button color="accent" (click)="cancelDeletion()">
          <span jhiTranslate="htmlEditor.plugins.{{ translationRoot }}.form.cancel"></span>
        </button>
        <button mat-raised-button class="delete-button" (click)="delete()">
          <span jhiTranslate="htmlEditor.plugins.{{ translationRoot }}.form.delete"></span>
        </button>
      </div>
    </div>
  </form>
</div>
