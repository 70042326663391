<div class="panel-block-left verticalEditor">
  <div class="panel-header-block">
    <div class="header-title">
      <span jhiTranslate="{{ type }}.menu.header"></span>
    </div>
    <div class="app-version">{{ appVersion }}</div>
    <button mat-icon-button class="header-close" (click)="close()">
      <mat-icon svgIcon="chevron-left"></mat-icon>
    </button>
  </div>
  <div class="panel-content-block" #parentDiv>
    <ng-container *ngFor="let item of menuItems">
      <jhi-vertical-menu-item
        [type]="type"
        [item]="item"
        [isSelected]="item.name === itemSelected"
        (clickEvent)="clickHandler(item, null, parentDiv)"
      ></jhi-vertical-menu-item>
      <div id="menuSubMenuDiv-{{ item.name }}" *ngIf="item?.children" [ngClass]="{ hidden: item?.showChildren === false }">
        <jhi-vertical-sub-menu
          [type]="type"
          [parentItem]="item"
          [itemSelected]="itemSelected"
          (clickEvent)="clickHandler(item, $event, parentDiv)"
        >
        </jhi-vertical-sub-menu>
      </div>
    </ng-container>
  </div>
</div>
