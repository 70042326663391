import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { ArevioService } from 'app/core/service/arevio.service';
import { SearchType } from 'app/shared/enum/search-type.enum';
import { XBRLType } from 'app/shared/enum/xbrl-type.enum';
import { IConcept, IEntryPointTable } from 'app/shared/model/concept.model';
import { ITaxonomyConceptSelected } from 'app/shared/model/taxonomy.model';
import { Subject } from 'rxjs';
import { ConceptTranslatedLabelPipe } from 'app/shared/pipe/concept-translated-label.pipe';

@Component({
  selector: 'jhi-concept-list',
  templateUrl: './concept-list.component.html',
  styleUrls: ['./concept-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConceptListComponent implements OnInit {
  @Input() searchType: SearchType = SearchType.ALL;
  @Input() public table: IEntryPointTable[];
  @Input() public displayAbstract = true;
  @Input() public conceptIdSelected: { pnodeId: number; parentId: number };
  @Input() public filteredType: XBRLType | null = null;
  public expanded = false;

  @Output() public selected = new EventEmitter<ITaxonomyConceptSelected>();

  public filtered = false;
  public concepts: IConcept[];
  public searchTermChanged: Subject<string> = new Subject<string>();
  public data: IEntryPointTable[];
  public entryPointTables: IEntryPointTable[];

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private arevioService: ArevioService,
    private conceptTranslatedLabelPipe: ConceptTranslatedLabelPipe
  ) {}

  ngOnInit(): void {
    if (!this.table) {
      if (this.filteredType) {
        this.arevioService.getFilteredConcepts(this.filteredType).subscribe((data: IEntryPointTable[]) => {
          this.table = data;
          this.flatTable();
          this.changeDetectorRef.markForCheck();
        });
      } else {
        this.arevioService.getTablesFromEntryPoint(true).subscribe((data: IEntryPointTable[]) => {
          this.table = data;
          this.flatTable();
          this.changeDetectorRef.markForCheck();
        });
      }
    } else {
      this.flatTable();
    }
  }

  public trackTable(element: IEntryPointTable): number | null {
    return element ? element.id : null;
  }

  public filter(filterText: string): void {
    if (filterText) {
      const entryPointTables: IEntryPointTable[] = [];
      this.data.forEach((group: IEntryPointTable) => {
        const search = filterText.toLowerCase();
        const newGroup: IEntryPointTable = {
          id: group.id,
          labels: group.labels,
          orderNumber: group.orderNumber,
          concepts: [],
        };

        if (group?.concepts?.length > 0) {
          group.concepts.forEach((concept: IConcept) => {
            const newconcept = this.filterConcept(concept, search);
            if (newconcept) {
              newGroup.concepts.push(newconcept);
              concept.highlight = newconcept.highlight;
            }
          });
          entryPointTables.push(newGroup);
        }
      });
      this.entryPointTables = entryPointTables;

      this.expanded = true;
    } else {
      this.entryPointTables = this.data;
      this.expanded = false;
      this.resetHighlight();
    }
    this.changeDetectorRef.markForCheck();
  }

  private resetHighlight(): void {
    this.data.forEach((group: IEntryPointTable) => {
      group.concepts.forEach((concept: IConcept) => {
        delete concept.highlight;
      });
    });
  }

  private filterConcept(concept: IConcept, filterText: string): IConcept | null {
    let conceptMatch: IConcept | null = null;
    switch (this.searchType) {
      case SearchType.QNAME:
        conceptMatch = concept.qname.toLowerCase() === filterText ? concept : null;
        break;
      case SearchType.LABEL:
        conceptMatch = this.conceptTranslatedLabelPipe.transform(concept).toLowerCase().includes(filterText) ? concept : null;
        break;
      case SearchType.ALL:
        conceptMatch =
          concept.qname.toLowerCase().includes(filterText) ||
          this.conceptTranslatedLabelPipe.transform(concept).toLowerCase().includes(filterText)
            ? concept
            : null;
        break;
    }

    if (conceptMatch) {
      let qname: string = '';
      const regEx = new RegExp(filterText, 'gi');
      switch (this.searchType) {
        case SearchType.QNAME:
          qname = `<span class='highlight'>${conceptMatch.qname}</span>`;
          break;
        case SearchType.LABEL:
        case SearchType.ALL:
          qname = conceptMatch.qname.replace(regEx, `<span class='highlight'>$&</span>`);
          break;
      }
      return qname ? Object.assign({}, conceptMatch, { highlight: qname }) : conceptMatch;
    }
    return null;
  }

  public onSelected(concept: IConcept, table: IEntryPointTable): void {
    const entryPointTable: IEntryPointTable = {
      id: table.id,
      labels: table.labels,
      orderNumber: table.orderNumber,
    } as IEntryPointTable;
    this.selected.emit({ entryPointTable, concept });
  }

  // Todo:  Check performances in large list. Maybe we should only display concepts on group opening
  private flatTable(): void {
    this.entryPointTables = [];
    this.table.forEach((entry: IEntryPointTable) => {
      const entryPointTable: IEntryPointTable = {
        id: entry.id,
        labels: entry.labels,
        orderNumber: entry.orderNumber,
        concepts: [],
      };
      entry.concepts.forEach((concept: IConcept) => {
        this.flatConcept(concept, entryPointTable);
      });
      this.entryPointTables.push(entryPointTable);
    });
    this.data = this.entryPointTables.map(x => x);
  }

  private flatConcept(concept: IConcept, entryPointTable: IEntryPointTable): void {
    const newConcept = { ...concept };
    if (this.displayAbstract || !newConcept.abstract) {
      entryPointTable.concepts.push(newConcept);
    }
    if (concept.children) {
      delete newConcept.children;
      concept.children.forEach((child: IConcept) => {
        this.flatConcept(child, entryPointTable);
      });
    }
  }
}
