import { IStyle, ISubExportType } from 'app/shared/model/style.model';
import { ITableBorderRule } from 'app/shared/model/table-border-rules.model';
import { IColor } from 'app/shared/model/color.model';
export interface ITableModel {
  id?: number;
  modelName?: string;
  modelNameFr: string;
  modelNameEn: string;
  styleId?: number;
  classToApply?: string;
  projectId?: number;
  pyjamaActivated?: boolean;
  pyjamaColor1?: IColor;
  pyjamaColor2?: IColor;
  isUsed?: boolean;
}

export interface ITableModelFull {
  id?: number;
  modelName?: string;
  modelNameFr: string;
  modelNameEn: string;
  substitutionColorActivated?: boolean;
  substitutionExports?: ISubExportType[];
  substitutionColor?: IColor;
  pyjamaActivated?: boolean;
  pyjamaOverridden?: boolean;
  pyjamaColor1?: IColor;
  pyjamaColor2?: IColor;
  style?: IStyle;
  columns: Array<IColumn>;
  rows: Array<IRow>;
  cells: Array<ICell>;
  isUsed?: boolean;
}

export enum LABEL_TYPE {
  COLUMN = 'COLUMN',
  ROW = 'ROW',
  EXTENDED = 'EXTENDED',
  PROVISORY = 'PROVISORY',
}

export interface ILabel {
  id?: number;
  nameFr: string;
  nameEn?: string;
  displayedName: string;
  type?: LABEL_TYPE;
  deprecated?: boolean;
  position?: number | null;
}

export enum COLUMN_WIDTH_TYPE {
  REAL = 'REAL',
  RELATIVE = 'RELATIVE',
}

export interface IColumn {
  id?: number;
  index: number;
  widthValue: number;
  widthType: COLUMN_WIDTH_TYPE;
  cellStyle?: IStyle;
  paragraphStyle?: IStyle;
  label: ILabel;
  pyjamaActivated?: boolean;
  pyjamaOverridden?: boolean;
  pyjamaColor1?: IColor;
  pyjamaColor2?: IColor;
}

export interface IRow {
  id?: number;
  index: number;
  isHeader: boolean;
  cellStyle?: IStyle;
  paragraphStyle?: IStyle;
  label: ILabel;
}

export interface ICell {
  id?: number;
  labelGenerated: string;
  cellStyle?: IStyle;
  paragraphStyle?: IStyle;
  row: IRow;
  column: IColumn;
}

export enum SELECTION_TYPE {
  TABLE = 'TABLE',
  COLUMN = 'COLUMN',
  ROW = 'ROW',
  CELL = 'CELL',
}

export interface ITableModelSelection {
  type: SELECTION_TYPE;
  table?: ITableModelFull;
  columns?: IColumn[];
  rows?: IRow[];
  cells?: ICell[];
}

export interface IColWidthFormValue {
  colName: string;
  width: number;
  isRelative: boolean;
}

export interface ITableSelected {
  data: any;
  model: ITableModelFull;
  columnsWidth: { type: COLUMN_WIDTH_TYPE; value: number }[];
  rows: number[];
  columns: number[];
  dblPageSplitColumn: number;
  accessibility: { manualValue: string; selectedTitle: string | null; columnsHeader: boolean[] };
}

export interface IRowOrColumnSelected {
  model: ITableModelFull;
  element: IElementSelected;
}

export interface IElementSelected {
  type: SELECTION_TYPE;
  labelID: number;
  index: number;
  isHeader: boolean;
  disablePyjama: boolean;
  disablePyjamaField: boolean;
  reversePyjama: boolean;
  height: number;
}

export const LABEL_EXTERIOR = 'EXTERIOR';
export const LABEL_ALL = 'ALL';
export const LABEL_TETIERE = 'Têtière';

export interface IBag {
  size: number;
  sizeEnd: number;
  rules: Map<string, ITableBorderRule>;
  afterLabels: Set<string>;
}
