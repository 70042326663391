<h4>System</h4>

<ng-container *ngIf="!updating && systemMetrics">
  <div class="row">
    <div class="col-md-4">Uptime</div>
    <div class="col-md-8 text-end">{{ convertMillisecondsToDuration(systemMetrics['process.uptime']) }}</div>
  </div>

  <div class="row">
    <div class="col-md-4">Start time</div>
    <div class="col-md-8 text-end">{{ systemMetrics['process.start.time'] | date: 'full' }}</div>
  </div>

  <div class="row">
    <div class="col-md-9">Process CPU usage</div>
    <div class="col-md-3 text-end">{{ 100 * systemMetrics['process.cpu.usage'] | number: '1.0-2' }} %</div>
  </div>

  <ngb-progressbar [value]="100 * systemMetrics['process.cpu.usage']" [striped]="true" [animated]="false" type="success">
    <span>{{ 100 * systemMetrics['process.cpu.usage'] | number: '1.0-2' }} %</span>
  </ngb-progressbar>

  <div class="row">
    <div class="col-md-9">System CPU usage</div>
    <div class="col-md-3 text-end">{{ 100 * systemMetrics['system.cpu.usage'] | number: '1.0-2' }} %</div>
  </div>

  <ngb-progressbar [value]="100 * systemMetrics['system.cpu.usage']" [striped]="true" [animated]="false" type="success">
    <span>{{ 100 * systemMetrics['system.cpu.usage'] | number: '1.0-2' }} %</span>
  </ngb-progressbar>

  <div class="row">
    <div class="col-md-9">System CPU count</div>
    <div class="col-md-3 text-end">{{ systemMetrics['system.cpu.count'] }}</div>
  </div>

  <div class="row">
    <div class="col-md-9">System 1m Load average</div>
    <div class="col-md-3 text-end">{{ systemMetrics['system.load.average.1m'] | number: '1.0-2' }}</div>
  </div>

  <div class="row">
    <div class="col-md-9">Process files max</div>
    <div class="col-md-3 text-end">{{ systemMetrics['process.files.max'] | number: '1.0-0' }}</div>
  </div>

  <div class="row">
    <div class="col-md-9">Process files open</div>
    <div class="col-md-3 text-end">{{ systemMetrics['process.files.open'] | number: '1.0-0' }}</div>
  </div>
</ng-container>
