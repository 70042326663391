import { Injectable } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class PaginatorIntlService extends MatPaginatorIntl {
  translate: TranslateService;

  getRangeLabel = function (this: PaginatorIntlService, page: number, pageSize: number, length: number) {
    if (length === 0 || pageSize === 0) {
      return '';
    }
    length = Math.max(length, 0);
    const startIndex = page * pageSize;
    // If the start index exceeds the list length, do not try and fix the end index to the end.
    const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
    return this.translate.instant('paginator.of', { start: startIndex + 1, end: endIndex, total: length });
  };

  injectTranslateService(translate: TranslateService) {
    this.translate = translate;

    this.translate.onLangChange.subscribe(() => {
      this.translateLabels();
    });

    this.translateLabels();
  }

  translateLabels() {
    super.itemsPerPageLabel = this.translate.instant('paginator.items_per_page');
    super.nextPageLabel = this.translate.instant('paginator.next_page');
    super.previousPageLabel = this.translate.instant('paginator.previous_page');
    super.firstPageLabel = this.translate.instant('paginator.first_page');
    super.lastPageLabel = this.translate.instant('paginator.last_page');
    this.changes.next();
  }
}
