import { Injectable } from '@angular/core';
import { SERVER_API_URL } from 'app/app.constants';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IEditorConfiguration } from 'app/shared/model/editor-configuration.model';
import { ProjectService } from 'app/core/service/project.service';

@Injectable({ providedIn: 'root' })
export class EditorService {
  public resourceUrlProject = `${SERVER_API_URL}api/projects`;

  constructor(private http: HttpClient, protected projectService: ProjectService) {}

  static getChannelId(documentId: number, sectionId: number | null = null): string {
    let channelId = `document-${documentId}`;
    if (sectionId !== null) {
      channelId += `-section-${sectionId}`;
    }
    return channelId;
  }

  static getEditorId(sectionId: number): string {
    return `editor-${sectionId}`;
  }
  static getSectionId(editorId: string): number | null {
    if (!editorId) {
      return null;
    }
    const match = /editor-(\d+)/.exec(editorId);
    return match?.[1] ? Number(match[1]) : null;
  }
  getConfiguration(documentId: number): Observable<IEditorConfiguration> {
    return this.http.get<IEditorConfiguration>(`${SERVER_API_URL}/api/documents/${documentId}/editor/configuration`);
  }

  getCKCSTokenUrl(documentId: number, sectionId: number): string {
    return `${SERVER_API_URL}/api/documents/${documentId}/editor/token-for-section/${sectionId}`;
  }
}
