import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class LoaderService {
  loaderSubject = new Subject<boolean>();
  loaderState = this.loaderSubject.asObservable();

  private counter = 0;

  show(): void {
    this.counter++;
    this.loaderSubject.next(true);
  }

  hide(): void {
    if (this.counter > 0) {
      this.counter--;
    }
    if (this.counter <= 0) {
      this.loaderSubject.next(false);
      this.counter = 0;
    }
  }
}
