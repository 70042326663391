import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AccountService } from 'app/core/auth/account.service';
import { DownloadManagerService } from 'app/core/service/download-manager.service';
import { PDFReportResult } from 'app/shared/model/download-manager.model';

@Component({
  selector: 'jhi-pdf-report-dialog-result',
  templateUrl: 'pdf-report-dialog-result.component.html',
  styleUrls: ['pdf-report-dialog-result.component.scss'],
})
export class PDFReportDialogResultComponent {
  public errorColumns: string[] = ['chapterTitle', 'documentaryUnitId', 'stepName', 'shortMessages'];
  public warningsColumns: string[] = ['chapterTitle', 'documentaryUnitId', 'stepName', 'shortMessages'];
  public errors: PDFReportResult[];
  public warnings: PDFReportResult[];

  constructor(
    public dialogRef: MatDialogRef<PDFReportDialogResultComponent>,
    public dialog: MatDialog,
    public downloadManagerService: DownloadManagerService,
    public accountService: AccountService,
    @Inject(MAT_DIALOG_DATA) public data: { response: PDFReportResult[]; downloadEntryId: number }
  ) {
    this.warnings = this.data.response.filter(entry => entry?.warnings?.length > 0);
    this.errors = this.data.response.filter(entry => entry?.errors?.length > 0);
  }

  public close(): void {
    this.dialogRef.close();
  }

  public download(): void {
    window.open(this.downloadManagerService.downloadPDFReportURL(this.data.downloadEntryId), '_blank');
  }
}
