<mat-form-field *ngIf="colorValue" class="color-field" floatLabel="{{ colorValue?.placeholder ? 'always' : '' }}">
  <mat-label *ngIf="label">{{ label }}</mat-label>
  <mat-label *ngIf="!label" [jhiTranslate]="'styleEditor.editor.properties.' + fieldName"></mat-label>
  <mat-select [(value)]="colorValue.value" (selectionChange)="updateColorValue($event)" [placeholder]="colorValue.placeholder">
    <mat-option class="editor-reset-option" *ngIf="reinit" [value]="null">{{
      'styleEditor.editor.properties.reset' | translate
    }}</mat-option>
    <mat-option *ngFor="let color of colors" [value]="color.id">{{ color.colorName }}</mat-option>
  </mat-select>
</mat-form-field>
