/* eslint-disable no-console */
import { isDevMode } from '@angular/core';

export interface PubSub {
  subscribers: any;
  fire: Function;
  on: Function;
  once: Function;
  off: Function;
}

// global variable is on purpose
const pubsub: PubSub = window['pubsub'] || {
  subscribers: {},

  // Context
  // Used to pass editor instance id
  fire(topic: string, detail: any = {}, context = ''): void {
    const type = context ? `${context}/${topic}` : topic;
    if (isDevMode()) {
      console.info(`PubSub - Fired - ${type}`, detail);
    }
    dispatchEvent(
      new CustomEvent(type, {
        detail,
      })
    );
  },

  on(topic: string, callback: EventListenerObject, context = ''): void {
    const type = context ? `${context}/${topic}` : topic;
    if (isDevMode()) {
      console.info(`PubSub - On - ${type}`);
    }
    if (!this.subscribers[type]) {
      this.subscribers[type] = new WeakSet();
    }
    if (!this.subscribers[type].has(callback)) {
      addEventListener(type, callback);
      this.subscribers[type].add(callback);
    }
  },

  once(topic: string, callback: Function, context = ''): void {
    const type = context ? `${context}/${topic}` : topic;
    if (isDevMode()) {
      console.info(`PubSub - Once - ${type}`);
    }
    const cb = (evt: Event) => {
      callback(evt);
      pubsub.off(type, cb);
    };
    pubsub.on(type, cb);
  },

  off(topic: string, callback: EventListenerObject, context = ''): void {
    const type = context ? `${context}/${topic}` : topic;
    if (isDevMode()) {
      console.info(`PubSub - Off - ${type}`);
    }
    if (this.subscribers[type]?.has(callback)) {
      removeEventListener(type, callback);
    }
  },
};

window['pubsub'] = pubsub;

export default pubsub;
