import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FileService } from 'app/core/service/file.service';
import { ImageFolder } from 'app/shared/enum/image-folder.enum';
import { IAsset } from 'app/shared/model/asset.model';

@Component({
  templateUrl: 'image-move-folder-dialog.component.html',
  styleUrls: ['image-move-folder-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImageMoveFolderDialogComponent implements OnInit {
  public form: UntypedFormGroup;
  public currentFolder: string | ImageFolder;
  public asset: IAsset;
  public languageFolders: string[];
  public ImageFolder = ImageFolder;
  public currentFilename: string;
  public targetFolderFilenames: IAsset[] = [];
  public targetFolder: string | ImageFolder;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private fileService: FileService,
    public dialogRef: MatDialogRef<ImageMoveFolderDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { currentFolder: string | ImageFolder; languageFolders: string[]; asset: IAsset }
  ) {
    this.currentFolder = data.currentFolder;
    this.languageFolders = data.languageFolders;
    this.asset = data.asset;
    this.currentFilename = this.removeFileExtension(this.asset?.filename ?? '');
    this.targetFolder = (this.currentFolder as ImageFolder) === ImageFolder.PROJECT ? this.languageFolders[0] : ImageFolder.PROJECT;

    this.form = new UntypedFormGroup({
      folder: new UntypedFormControl(this.targetFolder),
      filename: new UntypedFormControl(this.currentFilename, [
        Validators.required,
        Validators.pattern(/^[a-zA-Z0-9 _@\-^!#$%&+={}]+$/),
        this.filenameLanguageValidator.bind(this),
      ]),
    });
  }

  ngOnInit() {
    this.form.controls.folder.valueChanges.subscribe(this.updateFilenameValidity.bind(this));
    this.fileService.getImages(this.targetFolder).subscribe((images: IAsset[]) => {
      this.targetFolderFilenames = images;
    });
  }

  public updateFilenameValidity() {
    this.targetFolder = (this.currentFolder as ImageFolder) === ImageFolder.PROJECT ? this.form.get('folder')?.value : ImageFolder.PROJECT;
    this.fileService.getImages(this.targetFolder).subscribe((images: IAsset[]) => {
      this.targetFolderFilenames = images;
      this.form.controls.filename.updateValueAndValidity();
      this.form.controls.filename.markAsDirty();
      this.changeDetectorRef.markForCheck();
    });
  }

  public save() {
    if (!this.form.valid || !this.asset.id) {
      return;
    }
    this.fileService
      .moveImage(this.asset.id, this.form.get('filename')?.value, this.targetFolder, this.currentFolder)
      .subscribe(() => this.dialogRef.close(true));
  }

  public filenameLanguageValidator(control: AbstractControl): { [key: string]: boolean } | null {
    if (!this.targetFolder || !this.languageFolders) {
      return null;
    }

    if (!this.fileService.isLanguageFolderValid(control.value, this.targetFolder, this.languageFolders)) {
      return (this.targetFolder as ImageFolder) === ImageFolder.PROJECT
        ? { isFilenameLanguageProject: true }
        : { isFilenameLanguage: true };
    }

    if (
      this.targetFolderFilenames.find((image: IAsset) => {
        return this.removeFileExtension(image.filename ?? '') === control.value;
      })
    ) {
      return { isFilenameExist: true };
    }

    return null;
  }

  private removeFileExtension(filename: string): string {
    return filename?.split('.')[0] ?? '';
  }
}
