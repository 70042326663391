export enum ArevioCodeError {
  DEFAULT = 'DEFAULT',
  NOT_ALLOWED = 'NOT_ALLOWED',
  RCSF_MAP_UNKNOWN_CONCEPT = 'RCSF_MAP_UNKNOWN_CONCEPT',
  RCSF_MAP_UNKNOWN_DIMS = 'RCSF_MAP_UNKNOWN_DIMS',
  RCSF_MAP_NO_MAPPING_SHEET = 'RCSF_MAP_NO_MAPPING_SHEET',
  RCSF_MAP_FILENAME = 'RCSF_MAP_FILENAME',
  RCSF_MAP_EXTENSION = 'RCSF_MAP_EXTENSION',
  RCSF_MAP_SHEET_NAME = 'RCSF_MAP_SHEET_NAME',
  RCSF_MAP_ROW_NUMBER = 'RCSF_MAP_ROW_NUMBER',
  RCSF_MAP_COL_LETTER = 'RCSF_MAP_COL_LETTER',
  RCSF_MAP_CONCEPT_NAME = 'RCSF_MAP_CONCEPT_NAME',
  RCSF_MAP_CONCEPT_PREFIX = 'RCSF_MAP_CONCEPT_PREFIX',
  RCSF_MAP_PERIOD = 'RCSF_MAP_PERIOD',
  RCSF_MAP_PERIOD_FORMAT = 'RCSF_MAP_PERIOD_FORMAT',
  RCSF_MAP_UNIT_UNSUPPORTED = 'RCSF_MAP_UNIT_UNSUPPORTED',
  RCSF_MAP_UNIT = 'RCSF_MAP_UNIT',
  RCSF_MAP_SCALE = 'RCSF_MAP_SCALE',
  RCSF_MAP_SIGN = 'RCSF_MAP_SIGN',
  RCSF_MAP_DUPLICATE = 'RCSF_MAP_DUPLICATE',
  RCSF_MAP_DUPLICATE_FACT = 'RCSF_MAP_DUPLICATE_FACT',
  RCSF_MAP_FSRC_WITH_TWO_DIFFERENT_FACTS = 'RCSF_MAP_FSRC_WITH_TWO_DIFFERENT_FACTS',
  RCSF_DATA_DUPLICATE = 'RCSF_DATA_DUPLICATE',
  RCSF_IMPORT_UNUSED_MAP_LINE = 'RCSF_IMPORT_UNUSED_MAP_LINE',
  CUSTOMER_ALREADY_EXISTING_NAME = 'CUSTOMER_ALREADY_EXISTING_NAME',
  CUSTOMER_ALREADY_EXISTING_EXTID = 'CUSTOMER_ALREADY_EXISTING_EXTID',
  TAXO_BAD_TYPE = 'TAXO_BAD_TYPE',
  TAXO_CANNOT_DELETE_STILL_USED = 'TAXO_CANNOT_DELETE_STILL_USED',
  TAXO_ENTRYPOINT_NOT_FOUND = 'TAXO_ENTRYPOINT_NOT_FOUND',
  PERIOD_ALREADY_EXISTING_NAME = 'PERIOD_ALREADY_EXISTING_NAME',
  REPORT_GENERATION_PROBLEM = 'REPORT_GENERATION_PROBLEM',
  XBRL_IMPORT_BAD_EXTENSION = 'XBRL_IMPORT_BAD_EXTENSION',
  XBRL_IMPORT_BAD_PERIOD = 'XBRL_IMPORT_BAD_PERIOD',
  XBRL_IMPORT_BAD_IDENTIFIER = 'XBRL_IMPORT_BAD_IDENTIFIER',
  RCSF_BAD_EXTENSION = 'RCSF_BAD_EXTENSION',
  RCSF_CANNOT_MAP_UNIMPORTED_FILENAME = 'RCSF_CANNOT_MAP_UNIMPORTED_FILENAME',
}
