import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ChangeDetectorRef, OnInit } from '@angular/core';
import { IConcept } from 'app/shared/model/concept.model';

import { IDate } from 'app/shared/model/date.model';
import { IAxis, IAxisMemberItem, IMember } from 'app/shared/model/dimension.model';
import { ITaxonomyConceptSelected } from 'app/shared/model/taxonomy.model';
import { TaggingStep } from 'app/shared/enum/tagging-step.enum';

import { IFact } from 'app/shared/model/fact.model';
import { PeriodType } from 'app/shared/enum/period-type.enum';
import { ArevioService } from 'app/core/service/arevio.service';
import { XBRLType } from 'app/shared/enum/xbrl-type.enum';
import { LanguageService } from 'app/core/service/language.service';
import { ILanguage } from 'app/shared/model/language.model';
import { ContextService } from 'app/core/service/context.service';

@Component({
  selector: 'jhi-xbrl-data-tagger',
  templateUrl: './xbrl-data-tagger.component.html',
  styleUrls: ['./xbrl-data-tagger.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class XbrlDataTaggerComponent implements OnInit {
  @Input() public step = TaggingStep.CHOICE;
  @Input() public showLang = false;
  @Input() public filteredType: XBRLType | null = null;
  @Input() public disabled = false;
  @Input() public editorialTextFactDisabled = true;

  @Input()
  get fact(): IFact {
    return this._fact;
  }

  set fact(value: IFact) {
    this._fact = value;
    if (this._fact) {
      this.selectedLanguage = this.contextService.currentDocumentContext.language.code ?? '';
      if (this._fact?.concept?.type === XBRLType.STRING) {
        this._fact.language = this._fact?.language ? this._fact.language : this.selectedLanguage.substring(0, 2);
        const retrieveLanguage = this.languages.find(lang => lang.code?.includes(`${this._fact.language}_`))?.code;
        if (retrieveLanguage) {
          this.selectedLanguage = retrieveLanguage;
        }
      }
      this.cdr.markForCheck();
    }
  }
  @Output() public closed = new EventEmitter<void>();
  @Output() public factUpdated = new EventEmitter<IFact>();
  @Output() public stepUpdated = new EventEmitter<TaggingStep>();

  public readonly PeriodType = PeriodType;

  public currencies: string[] = [];
  public perShareCurrencies: string[];
  public shareCurrencies: string[] = ['xbrli:shares'];

  public dimensions: IAxis[] = [];

  public memberItems: IAxisMemberItem[] = [];
  public readonly TaggingStep = TaggingStep;
  public axisItems: IAxisMemberItem[] = [];

  public languages: ILanguage[] = [];
  public selectedLanguage: string;

  public selectButtonDisabled = true;
  public isConceptDisabled = false;

  private _conceptDisabledList: IConcept[];
  private _fact: IFact;

  constructor(
    private cdr: ChangeDetectorRef,
    private arevioService: ArevioService,
    private languageService: LanguageService,
    private contextService: ContextService
  ) {}

  public ngOnInit(): void {
    this.arevioService.getConceptMonetaryUnits().subscribe((list: string[]) => {
      this.currencies = list;
      this.perShareCurrencies = list.map(currency => `${currency}/xbrli:shares`);
    });

    this.selectedLanguage = this.contextService.currentDocumentContext.language.code ?? '';

    this.languageService.getProjectLanguages().subscribe((list: ILanguage[]) => {
      this.languages = list;
      if (this.fact.language) {
        const retrieveLanguage = this.languages.find(lang => lang.code?.includes(`${this.fact.language}_`))?.code;
        if (retrieveLanguage) {
          this.selectedLanguage = retrieveLanguage;
        }
        this.cdr.markForCheck();
      }
    });

    this.arevioService.getConceptDisabledList(this.editorialTextFactDisabled).subscribe((list: IConcept[]) => {
      this._conceptDisabledList = list;
    });
  }

  public onConceptSelected(taxonomyConceptSelected: ITaxonomyConceptSelected): void {
    const { concept } = taxonomyConceptSelected;
    this.isConceptDisabled =
      this._conceptDisabledList?.find(editorialConcept => editorialConcept.conceptId === concept.conceptId) !== undefined;

    this.selectButtonDisabled = concept.abstract || this.isConceptDisabled;
    if (concept.abstract) {
      return;
    }

    this.fact.concept = concept;
  }

  public selectConcept(): void {
    if (this.fact.concept?.abstract) {
      return;
    }

    this.updateStep(TaggingStep.CHOICE);

    // Units management
    // TODO: handle diffrent unit type
    if (this.fact.concept?.type === XBRLType.MONETARY) {
      if (this.currencies?.length === 1) {
        // Set the unique value
        this.fact.oimUnit = this.currencies[0];
      }
    } else if (this.fact.concept?.type === XBRLType.PER_SHARE || this.fact.concept?.type === XBRLType.PER_SHARE_2021) {
      if (this.perShareCurrencies?.length === 1) {
        this.fact.oimUnit = this.perShareCurrencies[0];
      }
    } else {
      delete this.fact.oimUnit;
    }

    if (this.fact.context) {
      // Clear period
      this.fact.context.period = null;

      // Clear dimensions
      this.fact.context.dimensions[0] = { dimAxis: {} as IConcept, dimMember: {} as IConcept };
    }

    this.factUpdated.emit(this.fact);
    this._clearDimensionsList();
    this.cdr.markForCheck();

    if (this.fact.concept) {
      // Get associated dimensions
      this.arevioService
        .getDimensions(
          this.fact.concept.projectEntrypointId,
          this.fact.concept.conceptId,
          this.fact.concept.parentId !== null ? this.fact.concept.parentId : 0
        )
        .subscribe(
          (data: IAxis[]) => this._createDimensionsList(data),
          null, // TODO: handle errors ?
          () => this.cdr.markForCheck()
        );
    }
  }

  public onDimensionSelected(axisIndex: number): void {
    if (!this.fact.context) {
      return;
    }

    this.fact.context.dimensions[0] = {
      dimAxis: {
        ...this.dimensions[axisIndex].axisItem,
      },
      dimMember: this.fact.context.dimensions[0]?.dimMember || null,
    };

    const flatList = (member: IMember[]) => {
      return member.reduce((m: IAxisMemberItem[], r: IMember) => {
        m.push(r.memberItem);
        if (r.children?.length) {
          m = m.concat(flatList(r.children));
        }
        return m;
      }, []);
    };

    // Remove hierachical representation to list all member
    this.memberItems = flatList(this.dimensions[axisIndex].children);

    // Auto-select if one axis
    if (this.memberItems.length < 2) {
      this.onMemberSelected(0);
    }

    this.factUpdated.emit(this.fact);
    this.updateStep(TaggingStep.CHOICE);
  }

  public onMemberSelected(memberIndex: number): void {
    if (!this.fact.context) {
      return;
    }
    this.fact.context.dimensions[0].dimMember = this.memberItems[memberIndex];
    this.factUpdated.emit(this.fact);
    this.updateStep(TaggingStep.CHOICE);
  }

  public onTimeSelected(date: IDate): void {
    if (!this.fact.context) {
      return;
    }
    this.fact.context.period = date;
    this.factUpdated.emit(this.fact);
    this.updateStep(TaggingStep.CHOICE);
  }

  public onCurrencySelected(currency: string): void {
    this.fact.oimUnit = currency;
    this.factUpdated.emit(this.fact);
    this.cdr.markForCheck();
  }

  private _clearDimensionsList(): void {
    this.dimensions = [];
    this.axisItems = [];
    this.memberItems = [];
  }

  private _createDimensionsList(data: IAxis[]): void {
    if (data) {
      this.dimensions = data;

      this.axisItems = data.map((axis: IAxis) => axis.axisItem);

      // Auto-select if one axis
      if (this.fact.context?.dimensions[0]?.dimAxis?.qname) {
        this.onDimensionSelected(
          this.axisItems.findIndex((axis: IAxisMemberItem) => axis.qname === this.fact.context?.dimensions[0]?.dimAxis?.qname)
        );
      } else if (this.axisItems.length === 1) {
        this.onDimensionSelected(0);
      }
    }
  }

  public updateStep(newStep: TaggingStep): void {
    this.step = newStep;
    this.stepUpdated.emit(newStep);
    this.cdr.markForCheck();
  }

  public onLanguagesUpdate(): void {
    this.fact.language = this.selectedLanguage.substring(0, 2);
    this.factUpdated.emit(this.fact);
    this.updateStep(TaggingStep.CHOICE);
  }
}
