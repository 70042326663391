import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { ActivatedRouteSnapshot, Router, NavigationEnd, Data } from '@angular/router';
import pubsub from 'app/pubsub';
import { EDITOR_PLUGIN_HIDE_TOOLBAR } from 'app/pubsub.topics';
import { Authority } from 'app/shared/enum/authority.enum';

import { NavbarService } from 'app/layouts/navbar/navbar.service';
@Component({
  selector: 'jhi-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  @Output() private triggerSaveCKDoc = new EventEmitter<boolean>();
  @Output() public zoom = new EventEmitter<number>();

  public title = '';
  public favIcon = '';
  public readonly Authority = Authority;
  public displaySaveButton: boolean;
  public isToolbarVisible = true;
  public displayRefresh: boolean;
  public currentZoom: number;
  private handleChangeToolbarVisibility: Function;

  constructor(private navbarService: NavbarService, private router: Router) {}

  public ngOnInit(): void {
    this.displaySaveButton = /[/a-z0-9]+\/html-editor|preview+/i.test(this.router.url);
    this.displayRefresh = true;
    let data = this.getPageData(this.router.routerState.snapshot.root);
    this.title = data.pageTitle;
    this.favIcon = data.favIcon;
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        data = this.getPageData(this.router.routerState.snapshot.root);
        this.title = data.pageTitle;
      }
    });

    this.handleChangeToolbarVisibility = this.changeToolbarVisibility.bind(this);
    pubsub.on(EDITOR_PLUGIN_HIDE_TOOLBAR, this.handleChangeToolbarVisibility);
  }

  public toggleNavbar(): void {
    this.navbarService.toggleNavbar();
  }

  private getPageData(routeSnapshot: ActivatedRouteSnapshot): Data {
    let data: Data = routeSnapshot.data || {};
    if (routeSnapshot.firstChild) {
      data = { ...this.getPageData(routeSnapshot.firstChild), ...data };
    }
    return data;
  }

  public saveCKDocument(trigger: boolean): void {
    this.triggerSaveCKDoc.emit(trigger);
  }

  public updateZoom(currentZoom: number): void {
    this.zoom.emit(currentZoom);
  }

  private changeToolbarVisibility({ detail }: any) {
    this.isToolbarVisible = !detail?.hideToolBar;
  }

  ngOnDestroy(): void {
    pubsub.off(EDITOR_PLUGIN_HIDE_TOOLBAR, this.handleChangeToolbarVisibility);
  }
}
