<div class="find-replace-container">
  <div>
    <ul class="nav nav-tabs">
      <li class="nav-item">
        <a
          class="nav-link"
          jhiTranslate="htmlEditor.plugins.findReplace.find"
          [class.active]="selectedTab === FindReplaceConstants.FIND"
          (click)="onTabClick(FindReplaceConstants.FIND)"
        ></a>
      </li>
      <li class="nav-item">
        <a
          class="nav-link"
          jhiTranslate="htmlEditor.plugins.findReplace.replace"
          [class.active]="selectedTab === FindReplaceConstants.REPLACE"
          (click)="onTabClick(FindReplaceConstants.REPLACE)"
        ></a>
      </li>
    </ul>
  </div>

  <div class="search-form">
    <mat-form-field>
      <mat-label jhiTranslate="htmlEditor.plugins.findReplace.searchText"></mat-label>
      <textarea matInput [(ngModel)]="textToFind" (click.enter)="find()" (change)="searchChange()"></textarea>
    </mat-form-field>

    <div class="action-line">
      <mat-checkbox id="case-sensitive" [(ngModel)]="isCaseSensitive" color="primary" (change)="searchChange()">
        {{ 'htmlEditor.plugins.findReplace.caseSensitive' | translate }}
      </mat-checkbox>
      <mat-checkbox id="whole-words-only" [(ngModel)]="wholeWordsOnly" color="primary" (change)="searchChange()">
        {{ 'htmlEditor.plugins.findReplace.wholeWordsOnly' | translate }}
      </mat-checkbox>
      <button mat-raised-button color="primary" (click)="find()" [class.button-disabled]="textToFind.length <= 0">
        <mat-icon svgIcon="search"></mat-icon>
      </button>
    </div>

    <mat-form-field *ngIf="selectedTab === FindReplaceConstants.REPLACE">
      <mat-label jhiTranslate="htmlEditor.plugins.findReplace.replaceText"></mat-label>
      <input matInput [(ngModel)]="textToReplace" />
    </mat-form-field>
  </div>

  <div *ngIf="total > 0" class="result-container">
    <div *ngIf="selectedTab === FindReplaceConstants.REPLACE" class="replace-buttons">
      <button mat-raised-button color="primary" jhiTranslate="htmlEditor.plugins.findReplace.replace" (click)="replace()"></button>
      <button mat-raised-button color="primary" jhiTranslate="htmlEditor.plugins.findReplace.replaceAll" (click)="replaceAll()"></button>
    </div>

    <div class="result-manip">
      <span
        *ngIf="index > -1 && activeButton !== 'replaceAll' && activeButton !== FindReplaceConstants.REPLACE"
        jhiTranslate="htmlEditor.plugins.findReplace.findcount"
        [translateValues]="{ param1: index + 1, param2: total }"
      ></span>

      <div>
        <mat-icon
          svgIcon="arrow-up"
          (click)="prev()"
          matTooltip="{{ 'htmlEditor.plugins.findReplace.previous' | translate }}"
          matTooltipPosition="above"
        ></mat-icon>
        <mat-icon
          svgIcon="arrow-down"
          (click)="next()"
          matTooltip="{{ 'htmlEditor.plugins.findReplace.next' | translate }}"
          matTooltipPosition="above"
        ></mat-icon>
      </div>
    </div>
  </div>

  <div class="message_container">
    <span *ngIf="total === 0" class="no-result-message" jhiTranslate="htmlEditor.plugins.findReplace.noresult"></span>
    <span
      *ngIf="removed > 1"
      jhiTranslate="htmlEditor.plugins.findReplace.replaceallresult"
      [translateValues]="{ param1: removed, param2: textToFind }"
    ></span>
    <span
      *ngIf="removed === 1"
      jhiTranslate="htmlEditor.plugins.findReplace.replaceresult"
      [translateValues]="{ param1: textToFind }"
    ></span>
  </div>
</div>
