import { Component, ViewEncapsulation, ChangeDetectorRef, ChangeDetectionStrategy, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { PluginComponent } from '../../plugin.component';
import { utils as xlsxUtils } from 'xlsx';

import { PluginPanelService } from '../../plugin-panel.service';
import { TablePanelService } from '../table-panel.service';
import { Subscription } from 'rxjs';
import { ITableModelFull, ITableSelected, IColWidthFormValue, COLUMN_WIDTH_TYPE } from 'app/shared/model/table-model.model';
import pubsub from 'app/pubsub';
import {
  EDITOR_PLUGIN_APPLY_TABLE_MODEL,
  EDITOR_PLUGIN_APPLY_COLUMNS_WIDTH,
  EDITOR_PLUGIN_RESTORE_COLUMNS_WIDTH,
  EDITOR_PLUGIN_APPLY_ROW_LABEL,
  EDITOR_PLUGIN_APPLY_COLUMN_LABEL,
} from 'app/pubsub.topics';
import { ICKStyles } from 'app/core/user/ck-config.model';
import { LabelService } from 'app/core/service/label.service';

@Component({
  selector: 'jhi-table-panel-plugin',
  templateUrl: './table-panel.component.html',
  styleUrls: ['./table-panel.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TablePanelComponent extends PluginComponent implements OnDestroy, OnInit {
  public panel: string;
  public open: boolean[] = [];
  public activated: string[] = [];
  public encodeColName: Function = xlsxUtils.encode_col;

  private _tableSubscription: Subscription;
  public tableModels: ITableModelFull[];
  public actualModel: ITableModelFull;
  public columnsWidth: IColWidthFormValue[];
  public rowsLabelsList: { id: number; name: string }[];
  public columnsLabelsList: { id: number; name: string }[];
  public rowsLabels: number[];
  public columnsLabels: number[];
  public dblPageSplitColumn: number;

  constructor(
    pluginPanelService: PluginPanelService,
    private tablePanelService: TablePanelService,
    private changeDetectorRef: ChangeDetectorRef,
    private renderer: Renderer2,
    private labelService: LabelService
  ) {
    super(pluginPanelService);
  }

  ngOnInit(): void {
    this.tableModels = (this.editor?.config.get('styles') as ICKStyles).tablesModels;
    this._tableSubscription = this.tablePanelService.getSelectedTableObservable().subscribe((element: ITableSelected) => {
      this.setData(element);
    });
    this.setData(this.tablePanelService.getActualTable());
  }

  ngOnDestroy(): void {
    this._tableSubscription.unsubscribe();
  }

  private setData(element: ITableSelected): void {
    this.actualModel = element.model;
    this.rowsLabels = element.rows;
    this.columnsLabels = element.columns;
    this.dblPageSplitColumn = element.dblPageSplitColumn;

    this.retrieveColumnsWidth(element);
    this.retrieveLabelsLists(this.actualModel);

    this.changeDetectorRef.markForCheck();
  }

  private retrieveColumnsWidth(table: ITableSelected): void {
    this.columnsWidth = table.columnsWidth?.map((columnWidth: { type: COLUMN_WIDTH_TYPE; value: number }, index: number) => {
      return {
        colName: xlsxUtils.encode_col(index),
        width: columnWidth.value,
        isRelative: columnWidth.type === COLUMN_WIDTH_TYPE.RELATIVE,
      };
    });
  }

  private retrieveLabelsLists(model: ITableModelFull): void {
    this.labelService.getLabels().subscribe(labels => {
      const data = this.labelService.retrieveLabelsLists(model, labels, true, true);
      this.rowsLabelsList = data.rowsLabelsList;
      this.columnsLabelsList = data.columnsLabelsList;
      this.changeDetectorRef.markForCheck();
    });
  }

  public trackByIndex(index: number): number {
    return index;
  }

  public changeModel({ value }: { value: number }): void {
    pubsub.fire(EDITOR_PLUGIN_APPLY_TABLE_MODEL, value, this.getEditorTopicContext());
  }

  public toggle(element: HTMLElement): void {
    if (element.classList.contains('hidden')) {
      this.renderer.removeClass(element, 'hidden');
    } else {
      this.renderer.addClass(element, 'hidden');
    }
  }

  public columnWidthChange($event: IColWidthFormValue, index: number): void {
    const formValue = { ...$event };
    formValue.colName = `${index}`;

    pubsub.fire(EDITOR_PLUGIN_APPLY_COLUMNS_WIDTH, formValue, this.getEditorTopicContext());
  }

  public restoreModelColumnsWidth(): void {
    pubsub.fire(EDITOR_PLUGIN_RESTORE_COLUMNS_WIDTH, this.actualModel, this.getEditorTopicContext());
  }

  public changeRowLabel(labelId: number, index: number): void {
    pubsub.fire(EDITOR_PLUGIN_APPLY_ROW_LABEL, { labelId, rowId: index }, this.getEditorTopicContext());
  }

  public changeColumnLabel(labelId: number, index: number): void {
    pubsub.fire(EDITOR_PLUGIN_APPLY_COLUMN_LABEL, { labelId, columnId: index }, this.getEditorTopicContext());
  }
}
