import { Pipe, PipeTransform } from '@angular/core';
import { IFact } from '../model/fact.model';
import { XbrlFormat } from '../model/format.model';
import { XBRLType } from '../enum/xbrl-type.enum';
@Pipe({
  name: 'formatFact',
  pure: true,
})
export class FormatFactPipe implements PipeTransform {
  transform(fact: IFact, formats: { number: XbrlFormat; date: XbrlFormat }): XbrlFormat | null {
    switch (fact.concept?.type) {
      case XBRLType.MONETARY:
      case XBRLType.PER_SHARE:
      case XBRLType.PER_SHARE_2021:
      case XBRLType.SHARES:
      case XBRLType.DECIMAL:
        return formats.number;
      case XBRLType.DATE:
        return formats.date;
      default:
        return null;
    }
  }
}
