import { Injectable, OnDestroy } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import pubsub from 'app/pubsub';
import { OPEN_DIALOG } from 'app/pubsub.topics';
import { DeletionConfirmationComponent } from 'app/shared/dialog/deletion-confirmation/deletion-confirmation.component';
import { DialogErrorComponent } from 'app/shared/dialog/error/dialog-error.component';
import { ArevioCodeError } from 'app/shared/enum/arevio-code-error.enum';
import { HttpErrorCustom } from 'app/shared/model/http-error-custom-response.model';
import { DeleteUsedFontComponent } from 'app/style-editor/dialog/delete-used-font/delete-used-font.component';

@Injectable({ providedIn: 'root' })
export class DialogService implements OnDestroy {
  public errorDialogRef:
    | MatDialogRef<DialogErrorComponent>
    | MatDialogRef<DeleteUsedFontComponent>
    | MatDialogRef<DeletionConfirmationComponent>;
  private _openDialogSubscription: Function;

  constructor(private dialog: MatDialog) {
    this._openDialogSubscription = this.pubsubOpening.bind(this);
    pubsub.on(OPEN_DIALOG, this._openDialogSubscription);
  }

  ngOnDestroy(): void {
    pubsub.off(OPEN_DIALOG, this._openDialogSubscription);
  }

  public pubsubOpening({ detail }: CustomEvent): void {
    this.openErrorDialog(detail.errors);
  }

  public openErrorDialog(errors?: HttpErrorCustom | ArevioCodeError | string): void {
    if (this.dialog.openDialogs.length === 0) {
      this.errorDialogRef = this.dialog.open(DialogErrorComponent, {
        data: {
          errors,
        },
      });
    }
  }

  public openConfirmationDUSplit(canSynchronize = true): Promise<boolean> {
    return new Promise(resolve => {
      this.errorDialogRef = this.dialog.open(DeletionConfirmationComponent, {
        data: {
          type: canSynchronize ? 'split-du' : 'split-du-no-synchro',
          name: 'save',
          deleteMessage: 'dialog.confirmation.split-du.button',
        },
      });

      this.errorDialogRef.afterClosed().subscribe((result: any) => {
        resolve(result);
      });
    });
  }
}
