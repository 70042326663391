// ANGULAR
import { Component, ChangeDetectionStrategy, OnInit, EventEmitter, Input, Output, ChangeDetectorRef } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

// SERVICES
import { LookupService } from 'app/core/service/lookup.service';

// ENUMS, MODELS, ETC...
import { ILookupEntry, ITitleTemplate } from 'app/shared/model/lookup.model';

@Component({
  selector: 'jhi-lookup-entries-form',
  templateUrl: 'lookup-entries.component.html',
  styleUrls: ['lookup-entries.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LookupEntriesComponent implements OnInit {
  @Input() public lookupType: UntypedFormControl;
  @Input() public entries: ILookupEntry[];

  @Output() public addEntry = new EventEmitter<void>();
  @Output() public deleteEntry = new EventEmitter<number>();
  @Output() public moveEntryUp = new EventEmitter<{ entry: ILookupEntry; index: number }>();
  @Output() public moveEntryDown = new EventEmitter<{ entry: ILookupEntry; index: number }>();
  @Output() public updateEntry = new EventEmitter<{ entry: ILookupEntry; index: number; moved: boolean }>();

  public documentTitles: ITitleTemplate[] = [];

  constructor(private lookupService: LookupService, private changeDetectorRef: ChangeDetectorRef) {}

  public ngOnInit(): void {
    this.lookupService.getLookupTitles().subscribe((titles: ITitleTemplate[]) => {
      this.documentTitles = titles;
      this.changeDetectorRef.markForCheck();
    });
  }
}
