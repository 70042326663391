<form class="version-filter-form-container" role="form" [formGroup]="versionForm">
  <mat-accordion class="standard">
    <mat-expansion-panel hideToggle expanded>
      <mat-expansion-panel-header>
        <mat-panel-title jhiTranslate="htmlEditor.plugins.versions.form.title"> </mat-panel-title>
      </mat-expansion-panel-header>

      <span *ngIf="showTypeFilters" class="section-title" jhiTranslate="htmlEditor.plugins.versions.form.types"></span>

      <span class="section-title" jhiTranslate="htmlEditor.plugins.versions.form.period_date"></span>

      <div class="period_row">
        <jhi-calendar
          formControlName="periodDateStart"
          [changePlaceholder]="false"
          (valueChanged)="periodDateStartChanged($event.value)"
          placeholder="{{ 'htmlEditor.plugins.versions.form.period_date_start' | translate }}"
          [maxDate]="getMaxDate()"
        >
        </jhi-calendar>
        <span jhiTranslate="htmlEditor.plugins.versions.form.period_date_link"></span>
        <jhi-calendar
          formControlName="periodDateEnd"
          [changePlaceholder]="false"
          (valueChanged)="periodDateEndChanged($event.value)"
          placeholder="{{ 'htmlEditor.plugins.versions.form.period_date_end' | translate }}"
          [minDate]="versionForm.get('periodDateStart')?.value"
          [maxDate]="actualDate"
        >
        </jhi-calendar>
      </div>

      <span class="section-title" jhiTranslate="htmlEditor.plugins.versions.form.period_hour"></span>

      <div class="period_row">
        <mat-form-field>
          <input
            formControlName="periodHourStart"
            matInput
            type="time"
            [placeholder]="'htmlEditor.plugins.versions.form.period_hour_start' | translate"
          />
        </mat-form-field>
        <span jhiTranslate="htmlEditor.plugins.versions.form.period_hour_link"></span>
        <mat-form-field>
          <input
            formControlName="periodHourEnd"
            matInput
            type="time"
            [placeholder]="'htmlEditor.plugins.versions.form.period_hour_end' | translate"
          />
        </mat-form-field>
      </div>

      <jhi-select-d-us-field [sections]="sections" formControlName="sections" class="select-field"></jhi-select-d-us-field>

      <mat-form-field class="select-field">
        <mat-label jhiTranslate="htmlEditor.plugins.versions.form.users"></mat-label>
        <mat-select multiple formControlName="users">
          <mat-option *ngFor="let user of users" [value]="user">{{ user.firstName }} {{ user.lastName }} </mat-option>
        </mat-select>
      </mat-form-field>

      <button
        class="apply-filters-button"
        type="button"
        mat-flat-button
        mat-button
        color="primary"
        (click)="applyFilters()"
        jhiTranslate="htmlEditor.plugins.versions.form.apply-filter"
        [disabled]="versionForm.pristine"
      ></button>
    </mat-expansion-panel>
  </mat-accordion>
</form>
