import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { FormatService } from 'app/core/service/format.service';
import { PluginPanelService } from 'app/html-editor/plugins/plugin-panel.service';
import { PluginComponent } from 'app/html-editor/plugins/plugin.component';
import pubsub from 'app/pubsub';
import { EDITOR_LOCALIZE_NUMBERS_REPLACE, EDITOR_PLUGIN_SELECTION_TEXT_BLOCK } from 'app/pubsub.topics';
import { LocalizeNumberFormat, LocalizeNumbersReplace } from 'app/shared/model/format.model';

@Component({
  selector: 'jhi-localize-numbers',
  templateUrl: './localize-numbers.component.html',
  styleUrls: ['./localize-numbers.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LocalizeNumbersComponent extends PluginComponent implements OnInit, OnDestroy {
  public localizeForm: UntypedFormGroup;
  public formats: LocalizeNumberFormat;
  public emptyDecorator = {};
  public noChangeSelected = false;
  public isSelectionValid = false;
  private handleSelection: Function;

  constructor(
    public pluginPanelService: PluginPanelService,
    private formatService: FormatService,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    super(pluginPanelService);

    this.localizeForm = new UntypedFormGroup({
      format: new UntypedFormControl(null, [Validators.required]),
      toFormat: new UntypedFormControl(null, [Validators.required]),
      decoratorsTransformation: new UntypedFormControl(this.emptyDecorator),
    });
    this.localizeForm.valueChanges.subscribe(() => this.localizeFormValidator());
  }

  ngOnInit(): void {
    this.formatService.localizeNumberFormat().subscribe((data: LocalizeNumberFormat) => {
      this.formats = data;
      this.changeDetectorRef.markForCheck();
    });

    this.isSelectionValid = this.data?.isSelection;

    this.handleSelection = this.selection.bind(this);
    pubsub.on(EDITOR_PLUGIN_SELECTION_TEXT_BLOCK, this.handleSelection);
  }

  private selection({ detail }): void {
    this.isSelectionValid = detail?.isSelection;
    this.changeDetectorRef.markForCheck();
  }

  // keep the current selection visible in the editor
  public displayFocusInEditor(): void {
    // todo find a better way to avoid : this.editor?.editing
    this.editor?.editing.view.focus();
  }

  public localizeFormValidator(): void {
    const format = this.localizeForm.controls.format.value;
    const toFormat = this.localizeForm.controls.toFormat.value;
    const decoratorsTransformation = this.localizeForm.controls.decoratorsTransformation.value;
    this.noChangeSelected = format === toFormat && decoratorsTransformation === this.emptyDecorator;
    this.changeDetectorRef.markForCheck();
  }

  public applyFormat(): void {
    this.displayFocusInEditor();
    const format = this.localizeForm.controls.format.value;
    const toFormat = this.localizeForm.controls.toFormat.value;
    const decoratorsTransformation = this.localizeForm.controls.decoratorsTransformation.value;

    const localizeFormat: LocalizeNumbersReplace = { format };
    if (format && format.example !== toFormat?.example) {
      localizeFormat.toFormat = toFormat;
    }
    if (Object.keys(decoratorsTransformation).length > 0) {
      localizeFormat['decoratorsTransformation'] = decoratorsTransformation;
    }
    pubsub.fire(EDITOR_LOCALIZE_NUMBERS_REPLACE, localizeFormat, this.editor?.config.get('editorId') as string);
  }

  public ngOnDestroy(): void {
    pubsub.off(EDITOR_PLUGIN_SELECTION_TEXT_BLOCK, this.handleSelection);
  }
}
