import { Pipe, PipeTransform } from '@angular/core';
import { IAsset } from '../model/asset.model';
@Pipe({
  name: 'assetIcon',
  pure: true,
})
export class AssetIconPipe implements PipeTransform {
  transform(asset: IAsset): string {
    if (!asset) {
      return 'document';
    }
    switch (asset.type) {
      case 'xslx':
        return 'xslx';
      case 'xhtml':
        return 'xhtml';
      case 'xbrlTable':
        return 'xbrlTable';
      case 'document':
        return 'spreadsheet';
      case 'archive':
        return 'document';
      default:
        return 'document';
    }
  }
}
