export enum AssetFileType {
  XBRL = 'XBRL',
  XML = 'XML', // XBRL XML file
  XHTML = 'XHTML', // xHTML iXBRL file
  HTML = 'HTML', // HTML iXBRL file
  RCSF = 'RCSF',
  XLSX = 'XLSX', // MS Excel file
  XLS = 'XLS',
  TAXONOMY = 'TAXONOMY', // Taxonomy package archive
  RAR = 'RAR',
  ZIP = 'ZIP',
  OTHER = 'OTHER',
}
