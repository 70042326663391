<div class="placeholder-plugin-container">
  <button type="button" mat-raised-button color="primary" class="bt-image-picker" (click)="openImagePicker()">
    <span jhiTranslate="htmlEditor.plugins.imagePlaceholder.showImagePicker"></span>
  </button>
  <div *ngIf="!placeholder && placeholderImage">
    <div class="restoreLink" jhiTranslate="htmlEditor.plugins.imagePlaceholder.removeImage" (click)="resetPlaceholder()"></div>
  </div>
  <jhi-properties-form-row *ngIf="placeholder || enableResize">
    <mat-form-field class="placeholder-width">
      <mat-label>{{ 'htmlEditor.plugins.imagePlaceholder.width' | translate }}</mat-label>
      <jhi-input-number
        autocomplete="off"
        name="width"
        [(ngModel)]="width"
        [min]="0"
        [decimal]="1"
        (valueChange)="updateHeight()"
      ></jhi-input-number>
    </mat-form-field>

    <mat-form-field>
      <mat-label>{{ 'htmlEditor.plugins.imagePlaceholder.height' | translate }}</mat-label>
      <jhi-input-number
        autocomplete="off"
        name="height"
        [(ngModel)]="height"
        [min]="0"
        [decimal]="1"
        (valueChange)="updateWidth()"
      ></jhi-input-number>
    </mat-form-field>
  </jhi-properties-form-row>
  <div *ngIf="enableResize && naturalPtWidth && naturalPtHeight" class="natural-image-size">
    {{ 'htmlEditor.plugins.imagePlaceholder.naturalImageSize' | translate: { width: naturalPtWidth, height: naturalPtHeight } }}
  </div>
  <mat-form-field>
    <mat-label>{{ 'htmlEditor.plugins.imagePlaceholder.horizontal.label' | translate }}</mat-label>
    <mat-select name="horizontalAlign" [(ngModel)]="horizontalAlign" (valueChange)="onHorizontalAlign($event)">
      <mat-option [value]="HORIZONTAL_ALIGNS.LEFT">{{ 'htmlEditor.plugins.imagePlaceholder.horizontal.left' | translate }}</mat-option>
      <mat-option [value]="HORIZONTAL_ALIGNS.CENTER">{{ 'htmlEditor.plugins.imagePlaceholder.horizontal.center' | translate }}</mat-option>
      <mat-option [value]="HORIZONTAL_ALIGNS.RIGHT">{{ 'htmlEditor.plugins.imagePlaceholder.horizontal.right' | translate }}</mat-option>
    </mat-select>
  </mat-form-field>

  <jhi-margin-input
    name="margin"
    [margin]="margin"
    [marginDisabled]="{ left: leftAuto, right: rightAuto }"
    (updateMargin)="updateMargin($event)"
    class="placeholder-margin"
    label="{{ 'htmlEditor.plugins.imagePlaceholder.margin' | translate }}"
  ></jhi-margin-input>

  <div class="placeholder-float">
    <label jhiTranslate="htmlEditor.plugins.imagePlaceholder.float"></label>
    <mat-button-toggle-group name="float" [(ngModel)]="float" (change)="updateProperties()">
      <mat-button-toggle [value]="FloatStyle.LEFT"><mat-icon svgIcon="object-left"></mat-icon></mat-button-toggle>
      <mat-button-toggle [value]="FloatStyle.FULL"><mat-icon svgIcon="object-full-width"></mat-icon></mat-button-toggle>
      <mat-button-toggle [value]="FloatStyle.RIGHT"><mat-icon svgIcon="object-right"></mat-icon></mat-button-toggle>
    </mat-button-toggle-group>
  </div>

  <mat-form-field>
    <mat-label>{{ 'htmlEditor.plugins.imagePlaceholder.alternate' | translate }}</mat-label>
    <input type="text" autocomplete="off" matInput name="alternate" [(ngModel)]="alternate" (input)="updateProperties()" />
  </mat-form-field>
</div>
