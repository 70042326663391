import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { IDimension } from 'app/shared/model/fact.model';

@Component({
  selector: 'jhi-xbrl-data-viewer-dimension',
  templateUrl: 'xbrl-data-viewer-dimension.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class XbrlDataViewerDimensionComponent {
  @Input() public dimension: IDimension;
}
