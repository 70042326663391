/**
 * TODO A étendre car on a :
 *  dans la spec XBRL :

    FLOAT = 'floatItemType', //	float	yes
    DOUBLE = 'doubleItemType', //	double	yes
    INTEGER = 'integerItemType', //	integer	yes
    NON_POSITIVE_INTEGER = 'nonPositiveIntegerItemType', //	nonPositiveInteger	yes
    NEGATIVE_INTEGER = 'negativeIntegerItemType', //	negativeInteger	yes
    LONG = 'longItemType', //	long	yes
    INT = 'intItemType', //	int	yes
    SHORT = 'shortItemType', //	short	yes
    BYTE = 'byteItemType', //	byte	yes
    NON_NEGATIVE_INTEGER = 'nonNegativeIntegerItemType', //	nonNegativeInteger	yes
    UNSIGNED_LONG = 'unsignedLongItemType', //	unsignedLong	yes
    UNSIGNED_INT = 'unsignedIntItemType', //	unsignedInt	yes
    UNSIGNED_SHORT = 'unsignedShortItemType', //	unsignedShort	yes
    UNSIGNED_BYTE = 'unsignedByteItemType', //	unsignedByte	yes
    POSITIVE_INTEGER = 'positiveIntegerItemType', //	positiveInteger	yes
    PURE = 'xbrli:pureItemType', //	xbrli:pure	yes
    FRACTION = 'fractionItemType', //	complex type with the numerator being a decimal and the denominator being a non-zero, decimal (xbrli:nonZeroDecimal)	yes
    BOOLEAN = 'booleanItemType', //	Boolean	no
    HEX_BINARY = 'hexBinaryItemType', //	hexBinary	no
    BASE64_BINARY = 'base64BinaryItemType', //	base64Binary	no
    ANY_URI = 'anyURIItemType', //	anyURI	no
    QNAME = 'QNameItemType', //	QName	no
    DURATION = 'durationItemType', //	duration	no
    TIME = 'timeItemType', //	time	no
    DATE = 'dateItemType', //	date	no
    G_YEAR_MONTH = 'gYearMonthItemType', //	gYearMonth	no
    G_YEAR = 'gYearItemType', //	gYear	no
    G_MONTH_DAY = 'gMonthDayItemType', //	gMonthDay	no
    G_DAY = 'gDayItemType', //	gDay	no
    G_MONTH = 'gMonthItemType', //	gMonth	no
    NORMALISED_STRING = 'normalizedStringItemType', //	normalizedString	no
    TOKEN = 'tokenItemType', //	token	no
    LANGUAGE = 'languageItemType', //	language	no
    NAME = 'NameItemType', //	Name	no
    NC_NAME = 'NCNameItemType', //	NCName	no
 */

export enum XBRLType {
  DECIMAL = 'xbrli:decimalItemType', // decimal yes
  PERCENT = 'dtr-types:percentItemType',
  PERCENT_2021 = 'num:percentItemType',
  MONETARY = 'xbrli:monetaryItemType', //	xbrli:monetary	yes
  SHARES = 'xbrli:sharesItemType', //	xbrli:shares	yes
  PER_SHARE = 'dtr-types:perShareItemType',
  PER_SHARE_2021 = 'num:perShareItemType',
  STRING = 'xbrli:stringItemType', //	string	no
  DATE = 'xbrli:dateItemType',
  TIME = 'xbrli:timeItemType',
  DATE_TIME = 'xbrli:dateTimeItemType', //	xbrli:dateUnion (union of date and dateTime)
  TEXT_BLOCK = 'dtr-types:textBlockItemType',
  TEXT_BLOCK_2021 = 'nonnum:textBlockItemType',
}

export const TEXT_BLOCK_TYPES = [XBRLType.TEXT_BLOCK, XBRLType.TEXT_BLOCK_2021];
