import { Injectable, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({ providedIn: 'root' })
export class SanitizeService {
  constructor(private sanitized: DomSanitizer) {}

  public sanitizeHtml(text: string): string {
    if (!text) {
      return text;
    }
    return this.sanitized.sanitize(SecurityContext.HTML, text) ?? ''; // for XSS attack;
  }

  public stripHtml(text: string): string {
    if (!text) {
      return text;
    }
    const tmp = document.createElement('DIV');
    tmp.innerHTML = this.sanitized.sanitize(SecurityContext.HTML, text) ?? ''; // for XSS attack;
    const cleaned = tmp.textContent ?? tmp.innerText ?? '';
    tmp.innerHTML = '';
    return cleaned;
  }
}
