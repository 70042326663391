import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

import { XbrlActionService } from 'app/core/service/xbrl-action.service';
import { ContextService } from 'app/core/service/context.service';
import { AccountService } from 'app/core/auth/account.service';

import { PeriodType } from 'app/shared/enum/period-type.enum';
import { ReferencedFootnote } from 'app/shared/model/xbrl-footnote.model';
import { Authority } from 'app/shared/enum/authority.enum';

import { PLUGIN_PANEL_COMPONENT_KEYS } from 'app/html-editor/plugins/plugin-panel-component-keys';
import { PluginComponent } from 'app/html-editor/plugins/plugin.component';
import { PluginPanelService } from 'app/html-editor/plugins/plugin-panel.service';
import pubsub from 'app/pubsub';
import { EDITOR_PLUGIN_TOGGLEPANEL } from 'app/pubsub.topics';

@Component({
  selector: 'jhi-annotate-detail-panel',
  templateUrl: './annotate-detail-panel.component.html',
  styleUrls: ['./annotate-detail-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AnnotateDetailPanelComponent extends PluginComponent implements OnInit, OnDestroy {
  public xbrlFootnoteFactList: any[] = [];
  public footnoteId: number;
  public readonly periodType = PeriodType;
  public documentLanguage: string;
  public numberOfFactsSelected = 0;
  public langKey = '';
  public xbrlFootnoteId: number;
  public displayEditBtn: boolean;
  private subscriptions = new Subscription();

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private xbrlActionService: XbrlActionService,
    public pluginPanelService: PluginPanelService,
    private contextService: ContextService,
    private accountService: AccountService
  ) {
    super(pluginPanelService);
  }

  ngOnInit(): void {
    this.footnoteId = this.data?.footnoteId;
    this.documentLanguage = this.contextService.currentDocumentContext.language.code?.substring(0, 2) ?? '';
    this.displayEditBtn = this.accountService.hasAnyAuthority(Authority.TAG_DYNAMIC_DATA);
    this.getAllFacts();
  }

  private getAllFacts(): void {
    if (this.footnoteId) {
      const xbrlAnnotation$ = this.xbrlActionService.getXbrlFootnote(this.footnoteId);
      this.subscriptions.add(
        xbrlAnnotation$.subscribe((xbrlFootnote: ReferencedFootnote) => {
          if (xbrlFootnote.id) {
            this.langKey = xbrlFootnote.lang;
            this.xbrlFootnoteId = xbrlFootnote.id;
            this.xbrlFootnoteFactList = xbrlFootnote.facts ?? [];
            this.numberOfFactsSelected = this.xbrlFootnoteFactList.length;
            this.changeDetectorRef.markForCheck();
          }
        })
      );
    }
  }

  public openEditPanel(): void {
    pubsub.fire(EDITOR_PLUGIN_TOGGLEPANEL, {
      component: PLUGIN_PANEL_COMPONENT_KEYS.ANNOTATE_FACT,
      data: {
        footnoteId: this.footnoteId,
      },
      title: 'htmlEditor.plugins.annotate.title',
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
