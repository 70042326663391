import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Observable, ReplaySubject, of, Subject } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { StateStorageService } from 'app/core/auth/state-storage.service';

import { SERVER_API_URL } from 'app/app.constants';
import { Account } from 'app/core/user/account.model';
import { JhiLanguageService } from 'app/shared/jhipster/service/language.service';
import { ProfileService } from 'app/layouts/profiles/profile.service';
import { ProfileInfo } from 'app/layouts/profiles/profile-info.model';
import { ContextService } from '../service/context.service';
import { LOCALE_ENUM } from 'app/shared/enum/locale.enum';

import * as Sentry from '@sentry/browser';

@Injectable({ providedIn: 'root' })
export class AccountService {
  private userIdentity: Account | null = null;
  private authenticationState = new ReplaySubject<Account | null>(1);
  private accountCache$?: Observable<Account | null>;
  private projectAccountCache$?: { [key: string]: Observable<Account> } | null;
  private profile: ProfileInfo;
  public documentId: number;
  public sectionId: number;
  private actualLang: Subject<string> = new Subject();

  constructor(
    private http: HttpClient,
    private stateStorageService: StateStorageService,
    private router: Router,
    private languageService: JhiLanguageService,
    private profileService: ProfileService,
    private route: ActivatedRoute,
    private contextService: ContextService
  ) {}

  save(account: Account): Observable<{}> {
    return this.http.post(`${SERVER_API_URL}api/account`, account);
  }

  authenticate(account: Account | null): void {
    this.userIdentity = account;
    this.authenticationState.next(this.userIdentity);
  }

  hasAnyAuthority(authorities: string[] | string): boolean {
    if (!this.userIdentity?.authorities) {
      return false;
    }
    if (!Array.isArray(authorities)) {
      authorities = [authorities];
    }

    let userAuthorities = [
      ...(this.userIdentity?.authorities ?? []),
      ...(this.userIdentity?.projectAuthorities ?? []),
      ...(this.userIdentity?.documentAuthorities?.[this.documentId] ?? []),
      ...(this.userIdentity?.documentaryUnitAuthorities?.[this.sectionId] ?? []),
    ];
    userAuthorities = this.debugAuthorities(userAuthorities);
    return userAuthorities.some(authority => authorities.includes(authority));
  }

  hasAnyAuthorityForSection(authorities: string | string[], sectionId: number): boolean {
    if (
      !this.documentId &&
      !this.userIdentity?.authorities &&
      !this.userIdentity?.projectAuthorities &&
      !this.userIdentity?.documentAuthorities &&
      !this.userIdentity?.documentaryUnitAuthorities?.[sectionId]
    ) {
      return false;
    }

    let userAuthorities = [
      ...(this.userIdentity?.authorities ?? []),
      ...(this.userIdentity?.projectAuthorities ?? []),
      ...(this.userIdentity?.documentAuthorities?.[this.documentId] ?? []),
      ...(this.userIdentity?.documentaryUnitAuthorities?.[sectionId] ?? []),
    ];

    userAuthorities = this.debugAuthorities(userAuthorities);

    return userAuthorities.some(authority => authorities.includes(authority));
  }

  tapAccount(account: Account | null): void {
    this.authenticate(account);
    // After retrieve the account info, the language will be changed to
    // the user's language configured in the account setting
    if (account?.langKey) {
      const langKey = account.langKey;
      this.languageService.changeLanguage(langKey);
      this.actualLang.next(langKey);
    }

    // After retrieve the account info, set User in sentry
    Sentry.setUser({ email: account?.email });

    this.profileService.getProfileInfo().subscribe(profile => {
      this.profile = profile;
    });
  }

  mergeDocumentaryUnitAuthoritiesWithDocumentAutorities(account: Account | null): any {
    let result: string[] = account?.projectAuthorities ?? [];
    if (account) {
      if (this.documentId) {
        if (!this.sectionId || !account.documentaryUnitAuthorities[this.sectionId]) {
          if (account.documentAuthorities[this.documentId]) {
            result = [...result, ...account.documentAuthorities[this.documentId]] ?? [];
          }
        } else {
          result = [...result, ...account.documentaryUnitAuthorities[this.sectionId], ...account.documentAuthorities[this.documentId]];
        }
      }
    }
    return result;
  }

  identity(force = false): Observable<Account | null> {
    let cache$ = this.contextService.currentProjectId
      ? this.projectAccountCache$?.[this.contextService.currentProjectId]
      : this.accountCache$;
    if (!cache$ || force || !this.isAuthenticated()) {
      cache$ = this.fetch().pipe(
        catchError(() => of(null)),
        tap((account: Account | null) => this.tapAccount(account))
        // shareReplay()
      );
    } else {
      this.authenticate(this.userIdentity);
    }
    return cache$;
  }

  isAuthenticated(): boolean {
    return this.userIdentity !== null;
  }

  getAuthenticationState(): Observable<Account | null> {
    return this.authenticationState.asObservable();
  }

  getAccount(): Observable<Account | null> {
    return of(this.userIdentity);
  }

  getAccountObject(): Account | null {
    return this.userIdentity;
  }

  getImageUrl(): string {
    return this.userIdentity ? this.userIdentity.imageUrl : '';
  }

  getLocale(): LOCALE_ENUM {
    return this.userIdentity?.langKey === 'fr' ? LOCALE_ENUM.FR : LOCALE_ENUM.EN;
  }

  private fetch(): Observable<Account> {
    return this.http.get<Account>(
      this.contextService.currentProjectId
        ? `${SERVER_API_URL}api/projects/${this.contextService.currentProjectId}/account`
        : `${SERVER_API_URL}api/account`
    );
  }

  private debugAuthorities(userAuths: string[]): string[] {
    let result = [...userAuths];
    if (this.userIdentity?.authorities && this.profile?.activeProfiles?.includes('debugPermissions')) {
      const roles = this.route?.snapshot?.queryParams?.ROLES ?? sessionStorage.getItem('JHIROLES');
      const jhiAuthorities = sessionStorage.getItem('JHIAUTHORITIES');
      if (jhiAuthorities) {
        result = jhiAuthorities.replace(/ /g, '').split(',');
      }

      if (roles) {
        result = [...userAuths, ...roles.replace(/ /g, '').split(',')];
      }
    }
    return result;
  }

  public navigateToStoredUrl(redirectTo404 = false): void {
    // previousState can be set in the authExpiredInterceptor and in the userRouteAccessService
    // if login is successful, go to stored previousState and clear previousState
    const previousUrl = this.stateStorageService.getUrl();
    this.stateStorageService.clearUrl();

    if (previousUrl && (previousUrl?.length ?? 0) > 1) {
      // different of '/' otherwise 404
      this.router.navigateByUrl(previousUrl);
    } else if (redirectTo404) {
      this.router.navigate(['/404']);
    }
  }

  langChanged(): Observable<string> {
    return this.actualLang.asObservable();
  }
}
