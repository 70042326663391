import * as moment from 'moment';
import { ExportWarningDTO } from './taxonomy.model';

export enum ExportStatus {
  IN_PROGRESS = 'IN_PROGRESS',
  ERROR = 'ERROR',
  DONE = 'DONE',
  CANCELED = 'CANCELED',
  QUEUED = 'QUEUED',
  WARNING = 'WARNING',
  DONE_WITH_WARNINGS = 'DONE_WITH_WARNINGS',
}

export enum ExportType {
  PDF = 'PDF',
  PDF_HD = 'PDF_HD',
  PDF_LD = 'PDF_LD',
  VIEWER = 'VIEWER',
  ESMA = 'ESMA',
  ESMA_AND_REPORT = 'ESMA_AND_REPORT',
  UD_CLEANED_HTML = 'UD_CLEANED_HTML',
  DOCUMENT_CLEANED_HTML = 'DOCUMENT_CLEANED_HTML',
  XHTML = 'XHTML',
  VERSION_TRACKING = 'VERSION_TRACKING',
  VERSION_TRACKING_HTML = 'VERSION_TRACKING_HTML',
  DOCUMENTARY_UNIT = 'DOCUMENTARY_UNIT',
  CHAPTER = 'CHAPTER',
  CHAPTERS = 'CHAPTERS',
  DOCUMENT = 'DOCUMENT',
}

export enum FileTypeExport {
  PDF = 'application/pdf',
  VIEWER = 'text/html',
  ESMA = 'application/zip',
  ESMA_AND_REPORT = 'application/zip',
  UD_CLEANED_HTML = 'application/zip',
  DOCUMENT_CLEANED_HTML = 'application/zip',
  XHTML = 'text/html',
  VERSION_TRACKING = 'application/pdf',
}

export interface IDMEntry {
  id: number;
  exportStatus: ExportStatus;
  assetId?: number;
  documentId?: number;
  exportType: ExportType;
  exportTime: string | moment.Moment;
  expireDate?: string | moment.Moment;
  error?: string | null;
  filename?: string;
  extension?: string;
  weight?: string;
  includeComments: boolean;
  includeSuggestions: boolean;
  userId?: string;
  reportId?: number;
  reportStatus?: string;
  reportFilename?: string;
  reportErrors?: string;
  reportErrorsType?: string;
  loreReportUrl?: string;
  customConfig?: string;
}

export interface AMFValidateReportResult {
  errorsWarnings?: ExportWarningDTO;
  reportFilename?: string;
  reportStatus?: string;
  reportId?: number; // reportId used in query is added to the response
}

export interface PDFReportResult {
  id: number;
  stepName: string;
  progress: string;
  documentaryUnitId: number;
  chapterId: number;
  chapterTitle: string;
  warnings: string[];
  errors: string[];
  timestamp: string;
  duration: number;
  shortMessages: string[];
}
