import { Injectable } from '@angular/core';
import { IStyle, IPseudoClass, IStyleProperty } from 'app/shared/model/style.model';
import { Subject, Observable } from 'rxjs';
import { StyleProperty } from '../../../shared/enum/style-property.enum';
import { IStylePropertyUpdateEvent } from './editor-properties-form-panel.component';

export type PropertyUpdateSubjectType = IStylePropertyUpdateEvent | IStylePropertyUpdateEvent[];

@Injectable({ providedIn: 'root' })
export class EditorPropertiesService {
  private propertyUpdateSubject = new Subject<PropertyUpdateSubjectType>();

  getProperty(styleElement: IStyle, propertyName: StyleProperty): any {
    const propertyFind = styleElement?.properties?.find(property => property.name === propertyName);
    return propertyFind ? propertyFind?.data : null;
  }

  getPseudoClass(styleElement: IStyle, pseudoClassName: string, propertyName: StyleProperty): any {
    const pseudoClassFind = styleElement?.pseudoClasses?.find((pseudoClass: IPseudoClass) => pseudoClass.name === pseudoClassName);
    if (!pseudoClassFind) {
      return null;
    }

    const propertyFind = pseudoClassFind?.properties.find(property => property.name === propertyName);
    return propertyFind ? propertyFind?.data : null;
  }

  updateProperty(property: PropertyUpdateSubjectType): void {
    this.propertyUpdateSubject.next(property);
  }

  getPropertyUpdate(): Observable<PropertyUpdateSubjectType> {
    return this.propertyUpdateSubject.asObservable();
  }

  getStyleProperty(
    styleElement: IStyle,
    styleProperty: StyleProperty
  ): { styleElementProperty: IStyleProperty; parentStyle: IStyleProperty } {
    const styleElementProperty = styleElement.properties?.find(property => property.name === styleProperty) as IStyleProperty;

    return {
      styleElementProperty,
      parentStyle: styleElement.extendFlatProperties?.find(property => property.name === styleProperty) as IStyleProperty,
    };
  }
}
