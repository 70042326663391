export enum StyleCategory {
  TITLE = 'TITLE',
  TABLES = 'TABLES',
  PARAGRAPH = 'PARAGRAPH',
  OTHER = 'OTHER',
  NUMBERING = 'NUMBERING',
  TEXT_CONTAINER = 'TEXT_CONTAINER',
  TITLE_FOLIO = 'TITLE_FOLIO',
  LIST = 'LIST',
  LIST_OL = 'LIST_OL',
  LIST_UL = 'LIST_UL',
  LIST_LI = 'LIST_LI',
  CELL = 'CELL',
  CHARACTERS = 'CHARACTERS',
  SUMMARY = 'SUMMARY',
  GENERAL_SUMMARY = 'GENERAL_SUMMARY',
  LOCAL_SUMMARY = 'LOCAL_SUMMARY',
  NOTE_SUMMARY = 'NOTE_SUMMARY',
  FOOTNOTE = 'FOOTNOTE',
  FOOTNOTE_MARKER = 'FOOTNOTE_MARKER',
  FOOTNOTE_ELEMENT = 'FOOTNOTE_ELEMENT',
  FOOTNOTE_PARAGRAPH = 'FOOTNOTE_PARAGRAPH',
  FOOTNOTE_CHARACTER = 'FOOTNOTE_CHARACTER',
  TITLE_SUMMARY = 'TITLE_SUMMARY',
  BORDER = 'BORDER',
  BORDER_RULE = 'BORDER_RULE',
  PAGE_ELEMENT = 'PAGE_ELEMENT',
  TABLES_PARAGRAPH = 'TABLES_PARAGRAPH',
  PAGE = 'PAGE',
  CLEANING = 'CLEANING',
}

export const StyleCatOrder = [
  StyleCategory.TITLE,
  StyleCategory.PARAGRAPH,
  StyleCategory.CHARACTERS,
  StyleCategory.LIST,
  StyleCategory.LIST_UL,
  StyleCategory.LIST_OL,
  StyleCategory.SUMMARY,
  StyleCategory.GENERAL_SUMMARY,
  StyleCategory.LOCAL_SUMMARY,
  StyleCategory.NOTE_SUMMARY,
  StyleCategory.TITLE_SUMMARY,
  StyleCategory.FOOTNOTE,
  StyleCategory.BORDER,
  StyleCategory.CELL,
  StyleCategory.TABLES_PARAGRAPH,
];

export const ExtraCharactersFolders = ['00_carATTENTION', '04_carPUCES', '09_carGABARITS'];
export const CharactersFolders = ['01_carTEXTES', '02_carTABLEAUX', '08_carSYMBOLES'];

export const listStyleContainerCategories = [StyleCategory.LIST_UL, StyleCategory.LIST_OL];
export const listStyleItemCategories = [StyleCategory.LIST_LI];
export const listStyleCategories = [...listStyleContainerCategories, ...listStyleItemCategories];

export enum StyleGroup {
  FAVORITE = 'FAVORITE',
  ALL = 'ALL',
}

export enum StyleCategoryAllowed {
  TITLE = 'TITLE',
  PARAGRAPH = 'PARAGRAPH',
  TABLES_PARAGRAPH = 'TABLES_PARAGRAPH',
  CHARACTERS = 'CHARACTERS',
  EXTRA_CHARACTERS = 'EXTRA_CHARACTERS',
  BORDER = 'BORDER',
}

export enum FlagType {
  COMPLIANT = 'compliant',
  NONCOMPLIANT = 'noncompliant',
  FAVORITE = 'FAVORITE',
}

export const styleTitleCategoryWithOrder: StyleCategory[] = [StyleCategory.TITLE_SUMMARY, StyleCategory.TITLE];
