import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

import {
  IMAGE_PLACEHOLDER_SERVICE_UPDATE_PANEL,
  IMAGE_PLACEHOLDER_SERVICE_CLOSE_PANEL,
  EDITOR_IMAGE_PLACEHOLDER_UPDATE,
  EDITOR_IMAGE_PLACEHOLDER_DELETE,
  EDITOR_PLUGIN_COLLAPSEPANEL,
} from 'app/pubsub.topics';
import pubsub from 'app/pubsub';
import { PLUGIN_PANEL_COMPONENT_KEYS } from '../plugin-panel-component-keys';
import { IAsset } from 'app/shared/model/asset.model';
import { FileService } from 'app/core/service/file.service';

export type PlaceHolderValue = {
  margin: { top: string; right: string; bottom: string; left: string };
  width: string;
  height: string;
  float: string;
  alternate: string;
  src: string;
  damId: string;
  placeholder: string;
  name: string;
};

export enum FloatStyle {
  LEFT = 'alignLeft',
  FULL = 'full',
  RIGHT = 'alignRight',
}
@Injectable({ providedIn: 'root' })
export class ImagePlaceholderService {
  private dataSubject: Subject<PlaceHolderValue> = new Subject();
  private data: PlaceHolderValue;
  private editorId: string | null = null;

  constructor(private fileService: FileService) {
    // From ckeditor plugin
    pubsub.on(IMAGE_PLACEHOLDER_SERVICE_UPDATE_PANEL, ({ detail }: CustomEvent) => {
      this.setData(detail?.data);
      this.notifyData();
    });
    pubsub.on(IMAGE_PLACEHOLDER_SERVICE_CLOSE_PANEL, () => {
      this.setData({} as PlaceHolderValue);
      this.notifyData();
      pubsub.fire(EDITOR_PLUGIN_COLLAPSEPANEL, { component: PLUGIN_PANEL_COMPONENT_KEYS.IMAGE_PLACE_HOLDER });
    });
  }

  getDataObservable(): Observable<PlaceHolderValue> {
    return this.dataSubject.asObservable();
  }

  getData(): PlaceHolderValue {
    return this.data;
  }

  setData(data: PlaceHolderValue): void {
    this.data = data;
  }

  setEditorId(editorId: string): void {
    this.editorId = editorId;
  }

  replaceImage(asset: IAsset, isPlaceholder = false): void {
    if (!this.data) {
      return;
    }
    this.data.src = `${this.fileService.getRootAssetUrl()}${asset.id}`;
    this.data.damId = `${asset.id}`;
    this.data.name = `${asset.filename}`;
    this.data.placeholder = isPlaceholder ? 'true' : 'false';
    if (isPlaceholder) {
      this.data.float = FloatStyle.FULL;
      this.data.margin = {
        top: '0pt',
        right: '0pt',
        bottom: '0pt',
        left: '0pt',
      };
    } else {
      this.data.width = '';
      this.data.height = '';
    }
    this.notifyData();
    this.notifyPluginData();
  }

  deleteImage(placeholder: IAsset, oldAsset: IAsset): void {
    const rootAssetUrl = this.fileService.getRootAssetUrl();
    pubsub.fire(EDITOR_IMAGE_PLACEHOLDER_DELETE, {
      placeholder: {
        damId: `${placeholder.id}`,
        src: `${rootAssetUrl}${placeholder.id}`,
      },
      old: {
        damId: `${oldAsset.id}`,
        src: `${rootAssetUrl}${oldAsset.id}`,
      },
    });
  }

  updatePluginData(data: PlaceHolderValue): void {
    this.data = data;
    this.notifyPluginData();
  }

  private notifyPluginData(): void {
    pubsub.fire(EDITOR_IMAGE_PLACEHOLDER_UPDATE, this.getData(), this.editorId);
  }
  private notifyData(): void {
    this.dataSubject.next(this.data);
  }
}
