import { Routes } from '@angular/router';
import { ImportProjectDetailComponent } from 'app/layouts/import-project-detail/import-project-detail.component';

export const importProjectRoute: Routes = [
  {
    path: 'importProjectDetail/:projectId',
    component: ImportProjectDetailComponent,
    data: {
      authorities: [],
    },
  },
];
