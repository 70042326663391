<div [formGroup]="localizeForm" class="localize-numbers" *ngIf="!!formats">
  <mat-form-field>
    <mat-label>{{ 'htmlEditor.plugins.localizeNumbers.format' | translate }}</mat-label>
    <mat-select formControlName="format" (click)="displayFocusInEditor()">
      <mat-option *ngFor="let pattern of formats.numericPatterns" [value]="pattern">{{ pattern.example }}</mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field>
    <mat-label>{{ 'htmlEditor.plugins.localizeNumbers.toFormat' | translate }}</mat-label>
    <mat-select formControlName="toFormat" (click)="displayFocusInEditor()">
      <mat-option *ngFor="let pattern of formats.numericPatterns" [value]="pattern">{{ pattern.example }}</mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field>
    <mat-label>{{ 'htmlEditor.plugins.localizeNumbers.decoratorsTransformation' | translate }}</mat-label>
    <mat-select formControlName="decoratorsTransformation" (click)="displayFocusInEditor()">
      <mat-option [value]="emptyDecorator">{{ 'htmlEditor.plugins.localizeNumbers.noDecoratorsTransformation' | translate }}</mat-option>
      <mat-option *ngFor="let negativeDecorator of formats.negativeDecorators" [value]="negativeDecorator">
        {{ negativeDecorator.prefixSrc }}{{ negativeDecorator.suffixSrc }} => {{ negativeDecorator.prefixTarget
        }}{{ negativeDecorator.suffixTarget }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <div class="warning-submit-disabled" *ngIf="noChangeSelected">
    {{ 'htmlEditor.plugins.localizeNumbers.noChangeSelected' | translate }}
  </div>
  <div class="warning-submit-disabled" *ngIf="!isSelectionValid">{{ 'htmlEditor.plugins.localizeNumbers.emptySelection' | translate }}</div>
  <button
    type="submit"
    mat-raised-button
    color="primary"
    class="save_button"
    jhiTranslate="htmlEditor.plugins.localizeNumbers.applyFormat"
    (click)="applyFormat()"
    [disabled]="!localizeForm.valid || localizeForm.pristine || noChangeSelected"
  ></button>
</div>
