<div class="xbrl-data-viewer-dimension">
    <div class="property property-label">
        <mat-icon class="chevron" svgIcon="tag-circle" color="primary"></mat-icon>
        <span>{{dimension.dimAxis | conceptTranslatedLabel}}</span>
    </div>
    <div class="property property-label margin-left">
        <mat-icon class="chevron" svgIcon="tag-small-dot" color="primary"></mat-icon>
        <span>{{dimension.dimMember | conceptTranslatedLabel}}</span>
    </div>
</div>
