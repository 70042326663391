<div class="export-configuration-esef-container">
  <div class="back-container">
    <button mat-icon-button class="header-close" (click)="goBack()">
      <mat-icon svgIcon="arrow-back" color="primary"></mat-icon>
    </button>
    <span jhiTranslate="htmlEditor.plugins.configurationExport.goBack"></span>
  </div>
  <hr />
  <div class="configuration-esef-content">
    <div class="type-reminder">
      <mat-icon class="export-svg" svgIcon="export-esef"></mat-icon>
      <span jhiTranslate="htmlEditor.plugins.documentExport.ESEFExport"></span>
    </div>
    <div class="configuration-esef-validation-toggle">
      <mat-slide-toggle class="toggle" color="primary" [(ngModel)]="validate">
        {{ 'htmlEditor.plugins.documentExport.validate' | translate }}
      </mat-slide-toggle>
    </div>
    <div class="button-container">
      <button
        type="button"
        mat-flat-button
        mat-button
        color="primary"
        (click)="exportDocumentESEF()"
        jhiTranslate="htmlEditor.plugins.configurationExport.button"
      ></button>
    </div>
  </div>
</div>
