export enum LookupType {
  PAGE = 'page-number',
  TITLE_REF = 'title-ref',
}

export enum LinkTypes {
  JOINT = 'JOINT',
  DISJOINT = 'DISJOINT',
}

export enum DisplayTypes {
  PAGES = 'PAGES',
  RANGES = 'RANGES',
}

export enum DisplayTypeValues {
  PAGES = 'first;first',
  RANGES = 'first;last',
}

export enum TypePageRef {
  FULL = 'full',
  NUMBERING = 'numbering',
  CONTENT = 'content',
}
