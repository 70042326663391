<div class="error-component">
    <div class="known-error" *ngIf="errorNumber">
        <jhi-account-button [showDefault]="false"></jhi-account-button>

        <div class="center-content">
            <div class="action-content">
                <img [src]="'/content/images/error/error-' + errorNumber + '.svg'" [alt]="errorNumber">
                <div class="general-message" [jhiTranslate]="errorKey + '.general'"></div>
                <div class="error-message" [jhiTranslate]="errorKey"></div>
                <div class="link-row">
                    <ng-container *jhiHasAnyAuthority="[Authority.ACCESS_EOL_PAGE]">
                      <div *ngIf="documentId" class="link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                        <a class="html-editor" (click)="navigate('html-editor');">
                            <mat-icon svgIcon="eol-item"></mat-icon>
                        </a>
                        <span jhiTranslate="global.menu.htmlEditor"></span>
                      </div>
                    </ng-container>
                    <ng-container *jhiHasAnyAuthority="[Authority.ACCESS_EOL_DATA]">
                      <div *ngIf="documentId" class="link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                        <a class="data-editor" (click)="navigate('data-editor');">
                            <mat-icon svgIcon="eol-item"></mat-icon>
                        </a>
                        <span jhiTranslate="global.menu.dataEditor"></span>
                      </div>
                    </ng-container>
                    <ng-container *jhiHasAnyAuthority="[Authority.ACCESS_EOL_STYLE]">
                      <div *ngIf="documentId" class="link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" >
                        <a class="style-editor" (click)="navigate('style-editor');">
                            <mat-icon svgIcon="eol-item"></mat-icon>
                        </a>
                        <span jhiTranslate="global.menu.styleEditor"></span>
                      </div>
                    </ng-container>
                    <ng-container *jhiHasAnyAuthority="[Authority.ACCESS_EOL_PREVIEW]">
                      <div *ngIf="documentId" class="link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                        <a class="preview" (click)="navigate('preview');">
                            <mat-icon svgIcon="eol-item"></mat-icon>
                        </a>
                        <span jhiTranslate="global.menu.preview"></span>
                      </div>
                    </ng-container>
                    <div class="link">
                        <a class="cockpit" [href]="cockpitURL" rel="noopener noreferrer">
                            <mat-icon svgIcon="cockpit"></mat-icon>
                        </a>
                        <span jhiTranslate="global.menu.cockpit"></span>
                    </div>
                </div>
                <button class="previous-button" *ngIf="previousUrl" mat-raised-button color="primary" (click)="previous()" jhiTranslate="error.previous"></button>
            </div>
            <div class="image-container">
                <img [src]="'/content/images/error/' + errorNumber + '.svg'" [alt]="errorNumber + ' error image'">
            </div>
        </div>
    </div>

    <div *ngIf="!errorNumber" style="padding:20px;">
        <h1 jhiTranslate="error.title"></h1>
        <div *ngIf="errorKey" class="alert alert-danger" [jhiTranslate]="errorKey"></div>
    </div>
</div>
