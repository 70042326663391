<div class="wrapper scrollbar">
    <jhi-page-ribbon *ngIf="!(isNavbarOpened$ | async)"></jhi-page-ribbon>
    <jhi-navbar></jhi-navbar>

    <mat-sidenav-container class="main-sidenav-container" [hasBackdrop]="false">
        <mat-sidenav-content><router-outlet></router-outlet></mat-sidenav-content>

        <mat-sidenav #dmnav class="dm-sidenav" mode="over" position="end">
            <jhi-download-manager></jhi-download-manager>
        </mat-sidenav>
    </mat-sidenav-container>

    <div class="overlay" [ngClass]="{'active': isNavbarOpened$ | async}" (click)="collapseNavbar()"></div>
    <jhi-loader></jhi-loader>
</div>
