import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { DownloadManagerService } from 'app/core/service/download-manager.service';
import { Subscription } from 'rxjs';
import { IDMEntry, ExportStatus, ExportType, AMFValidateReportResult, PDFReportResult } from 'app/shared/model/download-manager.model';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';

import pubsub from 'app/pubsub';
import { EDITOR_PLUGIN_TOGGLEPANEL } from 'app/pubsub.topics';
import { PLUGIN_PANEL_COMPONENT_KEYS } from 'app/html-editor/plugins/plugin-panel-component-keys';
import { DeletionConfirmationComponent } from 'app/shared/dialog/deletion-confirmation/deletion-confirmation.component';
import { XbrlValidationDialogResultComponent } from 'app/data-editor/topbar-actions/actions/xbrl-validation/xbrl-validation-dialog-result.component';
import { ModalWidth } from 'app/shared/enum/modal-width.enum';
import { AccountService } from 'app/core/auth/account.service';
import { Authority } from 'app/shared/enum/authority.enum';
import { PDFReportDialogResultComponent } from './pdf-report/pdf-report-dialog-result.component';

@Component({
  selector: 'jhi-download-manager',
  templateUrl: './download-manager.component.html',
  styleUrls: ['./download-manager.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DownloadManagerComponent implements OnInit, OnDestroy {
  @Output() public closed = new EventEmitter<void>();

  public ExportStatus = ExportStatus;
  public entryList: IDMEntry[];
  public htmlEditor = false;
  public isAdmin = false;

  private _listSubscription: Subscription;

  private clickInside = false;
  private opened = false;

  // function to get all the clicks inside the panel
  @HostListener('click')
  clickIn() {
    // If the click is inside the panel, set the variable as true
    this.clickInside = true;
  }

  // Function to get all the clicks in the document
  @HostListener('document:click', ['$event.target'])
  generalClick(target: any) {
    const isDMButton = target.closest('.dm-button');
    // If the click is not inside the panel ...
    if (!this.clickInside && this.opened && !isDMButton) {
      // ... Hide the panel
      this.downloadManagerService.togglePanel(false);
      this.changeDetectorRef.markForCheck();
    }
    // Reset the variable
    this.clickInside = false;
  }

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private downloadManagerService: DownloadManagerService,
    private translateService: TranslateService,
    private dialog: MatDialog,
    protected accountService: AccountService
  ) {}

  public ngOnInit(): void {
    this._listSubscription = this.downloadManagerService.getEntryList().subscribe((list: IDMEntry[]) => {
      this.manageEntryList(list);
    });
    this.manageEntryList(this.downloadManagerService.getActualEntryList());

    this.downloadManagerService.getPanelState().subscribe(opened => {
      this.opened = opened;
    });
  }

  ngOnDestroy(): void {
    this._listSubscription.unsubscribe();
  }

  private manageEntryList(list: IDMEntry[]): void {
    this.entryList = [];
    this.htmlEditor = false;

    if (!list.length && window.location.href.includes('html-editor')) {
      this.htmlEditor = true;
    } else {
      list.forEach((entry: IDMEntry) => {
        // Files are available during 3 days after downloas request
        entry.expireDate = moment(entry.exportTime).add(3, 'days').format();
        this.entryList.push(entry);

        // Calculate extension
        entry.extension = entry.filename?.slice(entry.filename?.indexOf('.') + 1)?.toUpperCase();
        entry.weight = this.formatBytes(Number(entry.weight));
      });
    }
    this.isAdmin = this.accountService.hasAnyAuthority(Authority.SUPER_ADMIN);
    this.changeDetectorRef.markForCheck();
  }

  private formatBytes(bytes: number, decimals = 2): string {
    const isFrench = this.translateService.currentLang === 'fr';
    if (bytes === 0) {
      return isFrench ? '0 octet' : '0 byte';
    }

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = isFrench ? ['octets', 'Ko', 'Mo', 'Go', 'To', 'Po'] : ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
  }

  public close(): void {
    this.downloadManagerService.togglePanel(false);
  }

  public cancelGeneration(id: number): void {
    this.downloadManagerService.cancelExport(id).subscribe(() => {
      this.downloadManagerService.forceCall();
    });
  }

  public downloadFile(id: number): void {
    window.open(this.downloadManagerService.downloadFileURL(id), '_blank');
  }

  public downloadLoreFile(loreReportUrl: string): void {
    window.open(loreReportUrl, '_blank');
  }

  public downloadReport(reportId: number, downloadEntryId: number): void {
    if (reportId) {
      // XBRL case
      window.open(this.downloadManagerService.downloadReportURL(reportId), '_blank');
    } else {
      // PDF case
      window.open(this.downloadManagerService.downloadPDFReportURL(downloadEntryId), '_blank');
    }
  }

  public showReport(reportId: number, downloadEntryId: number): void {
    if (reportId) {
      // XBRL case
      this.downloadManagerService.getReportStatus(reportId).subscribe(result => {
        this.showValidationReport(result, reportId);
      });
    } else {
      // PDF case
      this.downloadManagerService.getPDFReport(downloadEntryId).subscribe(result => {
        this.showPDFReport(result, downloadEntryId);
      });
    }
  }

  private showPDFReport(response: PDFReportResult[], downloadEntryId: number): void {
    this.dialog.open(PDFReportDialogResultComponent, {
      width: ModalWidth.MEDIUM,
      data: { response, downloadEntryId },
    });
  }

  private showValidationReport(response: AMFValidateReportResult, reportId: number): void {
    response.reportId = reportId;
    this.dialog.open(XbrlValidationDialogResultComponent, {
      width: ModalWidth.MEDIUM,
      data: response,
    });
  }

  public deleteFile(id: number, name: string): void {
    const dialogRef = this.dialog.open(DeletionConfirmationComponent, {
      data: {
        type: 'export',
        name,
      },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.downloadManagerService.deleteById(id).subscribe(() => {
          this.downloadManagerService.forceCall();
        });
      }
    });
  }

  public recall(id: number): void {
    this.downloadManagerService.recallExport(id).subscribe(() => {
      this.downloadManagerService.forceCall();
    });
  }

  public deleteAll(): void {
    const dialogRef = this.dialog.open(DeletionConfirmationComponent, {
      data: {
        type: 'exports',
      },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.downloadManagerService.deleteAll().subscribe(() => {
          this.downloadManagerService.forceCall();
        });
      }
    });
  }

  public onHtmlEditorPanel(): void {
    pubsub.fire(EDITOR_PLUGIN_TOGGLEPANEL, {
      component: PLUGIN_PANEL_COMPONENT_KEYS.DOCUMENT_EXPORT,
      title: 'htmlEditor.plugins.documentExport.title',
    });

    this.close();
  }

  public isESMAWithReport(exportType: string, exportStatus: string): boolean {
    return !!(
      (exportType as ExportType) === ExportType.ESMA_AND_REPORT &&
      exportStatus &&
      (exportStatus === 'READY' || exportStatus === 'ERROR')
    );
  }

  public downloadErrorReport(id: number): void {
    window.open(this.downloadManagerService.downloadErrorReportURL(id), '_blank');
  }

  public isPriority(entry: IDMEntry) {
    if (entry?.customConfig) {
      try {
        const customConfig = JSON.parse(entry.customConfig);
        return customConfig.priority === true;
      } catch (e) {
        return false;
      }
    }
    return false;
  }

  public oktpLabel(entry: IDMEntry) {
    if (this.isPriority(entry)) {
      return ' - ' + this.translateService.instant('global.dm.oktp');
    }
    return '';
  }
}
