import { HttpInterceptor, HttpRequest, HttpResponse, HttpHandler, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { SnackbarService } from 'app/core/service/snackbar.service';

@Injectable()
export class NotificationInterceptor implements HttpInterceptor {
  constructor(private snackBar: SnackbarService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse && request.params.get('displayAlert') === 'true') {
          const arr = event.headers.keys();
          let alert: string | null = null;
          arr.forEach(entry => {
            if (entry.toLowerCase().endsWith('app-alert')) {
              alert = event.headers.get(entry);
            }
          });
          if (alert) {
            this.snackBar.open(alert);
          }
        }
      })
    );
  }
}
