import { AssetFileType } from 'app/shared/enum/asset-file-type.enum';
import { IAsset } from 'app/shared/model/asset.model';
import * as mime from 'mime-types';

export class FileUtils {
  private static CHARACTER_TO_REPLACE = ['<', '>', ':', '«', '/', '\\', '|', '?', '*', '%', '+', "'", ';', '#'];
  private static REPLACEMENT = '_';

  public static getExtension(filename: string): AssetFileType | null {
    let keyToReturn: AssetFileType | null = null;
    if (filename) {
      const parts = filename.split('.');
      const extToFind = parts[parts.length - 1].toUpperCase();
      Object.keys(AssetFileType).forEach(key => {
        if (AssetFileType[key] === extToFind) {
          keyToReturn = AssetFileType[key];
        }
      });
    }
    return keyToReturn;
  }

  public static getFileTypesImported(assets: IAsset[]): AssetFileType[] {
    const typesImported: AssetFileType[] = [];
    assets.forEach(asset => {
      if ((asset.childrenLength ?? 0) > 0 && !typesImported.find(type => type === asset.filename?.toUpperCase())) {
        typesImported.push(asset.filename?.toUpperCase() as AssetFileType);
      }
    });
    return typesImported;
  }

  public static checkIfExtensionMacthMimetypeForFiles(files: FileList, arrAccept: string[]): boolean {
    let extensionIsOk = false;
    for (const file of Array.from(files)) {
      const mimetypeFromExtension = mime.lookup(FileUtils.getExtension(file.name) ?? '');
      // For each accepted filetype we check if mimetype from input is ok. (we break loop on first match)
      for (const accept of arrAccept) {
        // Translation :  e.g. if accept equals '.rar' mime.lookup('.rar') translate it into real mimetype : 'application/x-rar-compressed', so mime.lookup(accept) is true and we keep 'application/x-rar-compressed'
        // on the other hand, if accept equals  'application/zip' , here mime.lookup('application/zip') return false, so we can keep the 'accept' value.
        let realTypeMime;
        if (!mime.lookup(accept)) {
          realTypeMime = accept;
        } else {
          realTypeMime = mime.lookup(accept);
        }
        // Check if mimeType from extension match the request mimetpye :
        if (realTypeMime === mimetypeFromExtension) {
          extensionIsOk = true;
        }
        if (extensionIsOk) {
          break;
        }
      }
    }
    return extensionIsOk;
  }

  public static sanitizeFilename(filename: string): string {
    filename = filename.trim();
    let sanitizedFilename = '';
    for (let i = 0; i < filename.length; i++) {
      sanitizedFilename += FileUtils.sanitizeCharacter(filename.charAt(i));
    }
    return sanitizedFilename;
  }

  public static sanitizeCharacter(char: string): string {
    if (FileUtils.CHARACTER_TO_REPLACE.indexOf(char) >= 0) {
      return FileUtils.REPLACEMENT;
    }
    return char;
  }

  /* Sanitize filename to conform to PimCore file naming requirements and to bypass rule 920120 from mod_security REQUEST-920-PROTOCOL-ENFORCEMENT.conf which prevents uploading filename containing single quotes */
  public static sanitizeFile(file: File): File {
    return new File([file], FileUtils.sanitizeFilename(file.name));
  }
}
