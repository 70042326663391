import { Component, Input } from '@angular/core';
import { PeriodType } from 'app/shared/enum/period-type.enum';
import { IHistoryData, IHistoryDataValueType, IHistoryModificationType } from 'app/shared/model/history-data.model';
import { SpreadsheetService } from 'app/shared/services/spreadsheet.service';
import * as moment from 'moment/moment';

@Component({
  selector: 'jhi-cell-data-history',
  templateUrl: './cell-data-history.component.html',
  styleUrls: ['./cell-data-history.component.scss'],
})
export class CellDataHistoryComponent {
  public PeriodType = PeriodType;
  public ModificationType = IHistoryModificationType;
  public ValueType = IHistoryDataValueType;

  @Input() public historyEntries: IHistoryData[][];
  @Input() public isFactHistory: boolean;

  constructor(private spreadsheetService: SpreadsheetService) {}

  public isToday(date: moment.Moment): boolean {
    return moment().isSame(date, 'day');
  }

  public close(): void {
    this.spreadsheetService.leaveHistoryMode();
  }
}
