import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ArevioCodeError } from 'app/shared/enum/arevio-code-error.enum';
import { CodeErrorServer } from 'app/shared/enum/code-error-server.enum';
import { HttpErrorCustom } from 'app/shared/model/http-error-custom-response.model';
import { CodeErrorClient } from 'app/shared/enum/code-error-client';

@Component({
  selector: 'jhi-data-editor-dialog-error',
  templateUrl: 'dialog-error.component.html',
  styleUrls: ['dialog-error.component.scss'],
})
export class DialogErrorComponent {
  public errorKey: ArevioCodeError | CodeErrorServer;
  public errorValues = {};

  constructor(public dialogRef: MatDialogRef<DialogErrorComponent>, @Inject(MAT_DIALOG_DATA) public errors: { errors: HttpErrorCustom }) {
    this.errorKey = this.getErrorKeyToDisplay(errors.errors);
  }

  private getErrorKeyToDisplay(errors: any): ArevioCodeError | CodeErrorServer {
    let keyError = ArevioCodeError.DEFAULT;
    if (errors?.businessErrors?.length > 0) {
      keyError = errors.businessErrors[0].messageCode;

      // Show param in error message
      const paramNames = errors.businessErrors[0].paramNames;
      const paramValues = errors.businessErrors[0].paramValues;

      if (paramNames && paramValues) {
        paramNames.forEach((element: string, index: number) => {
          this.errorValues[element] = paramValues[index];
        });
      }
    } else if (Object.values(CodeErrorServer).includes(errors)) {
      keyError = errors;
    } else if (Object.values(CodeErrorClient).includes(errors)) {
      keyError = errors;
    }
    return keyError || ArevioCodeError.DEFAULT;
  }

  onClose(): void {
    this.dialogRef.close();
  }
}
