import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ElementTrackingService } from 'app/core/service/element-tracking.service';
import { IAsset } from 'app/shared/model/asset.model';
import { IElementTrackingUsageDocument } from 'app/shared/model/element-tracking-usage.model';
import { finalize } from 'rxjs/operators';

@Component({
  templateUrl: 'image-usage-dialog.component.html',
  styleUrls: ['image-usage-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImageUsageDialogComponent {
  public documents: IElementTrackingUsageDocument[];
  public loading = false;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private elementTrackingService: ElementTrackingService,
    @Inject(MAT_DIALOG_DATA) public asset: IAsset
  ) {
    this.loading = true;
    if (this.asset.id) {
      this.elementTrackingService
        .getImagesUsage(this.asset.id)
        .pipe(finalize(() => (this.loading = false)))
        .subscribe(documents => {
          this.documents = documents;
          this.changeDetectorRef.markForCheck();
        });
    }
  }
}
